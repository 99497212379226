import { ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { ServicePriorityStrings } from "../../Models/Constants/ServicePriorityStrings";
import ServiceTreeService from "../../Models/ServiceTreeService";
import { store } from "./GlobalStateProvider";

interface IUserServicesComponent{
    id?: string,
    label?: string,
    addOther: boolean,
    showP3P4ServicesOnly: boolean,
    showAllServices: boolean,
    placeholder?: string,
    selectedServiceId?: string,
    onServiceSelected: (selectedServices?: ServiceTreeService) => void,
}

export const UserServicesComponent: FC<IUserServicesComponent> = (props: IUserServicesComponent): JSX.Element => {
    var { appState, appdispatch } = useContext(store);
    var [serviceInfo,serviceInfoSet] = useState<ServiceTreeService[]>();
    const [cmbSTServiceOptions, cmbSTServiceOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbSTSelectedService, cmbSTSelectedServiceSet] = useState<string>();
    const onServiceTreeChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        isFirstSelectionDoneSet(true);
        if (option) {
            var k = option.key as string;
            cmbSTSelectedServiceSet(k); 

            

            var selectedService = serviceInfo?.filter(x => x.serviceId === k)
            if (selectedService && selectedService.length > 0 && selectedService[0])
            {
                props.onServiceSelected(selectedService[0]);
            }
            else
            {
                if (props.addOther)
                {
                    cmbSTSelectedServiceSet('Other');
                }   
                props.onServiceSelected(undefined);
            }
        }
    };
    
    const [isFirstSelectionDone, isFirstSelectionDoneSet] = useState<boolean>(false);

    useEffect(()=>{
    },[])
    useEffect(()=>{
        let tOptions: IComboBoxOption[] = [];

        if (appState.initStateLoaded)
        {
            var services : ServiceTreeService[] = [];
            console.log("loading services");
            if (props.showP3P4ServicesOnly && appState.userServiceTreeServies && appState.userServiceTreeServies.length > 0)
            {
                console.log("showing P3P4");
                services = appState.userServiceTreeServies.filter(s => s.sdlPrioritization == ServicePriorityStrings.Priority3 || s.sdlPrioritization == ServicePriorityStrings.Priority4)
                serviceInfoSet(services);
            }
            else if (props.showAllServices && appState.userMetadata?.isServiceReader)
            {
                if (appState.userServiceTreeServies && appState.userServiceTreeServies.length > 0 && appState.serviceReaders && appState.serviceReaders.length > 0)
                {
                    console.log("showing all services");
                    services = [...appState.userServiceTreeServies, ...appState.serviceReaders];
                    services = services.filter((value, index, self) => index === self.findIndex((t) => (t['serviceId'] === value['serviceId'])))
                    serviceInfoSet(services)
                    services = services.sort((e1, e2) => e1.serviceName!.toLowerCase().localeCompare(e2.serviceName!.toLowerCase()))
                }
                else if (appState.serviceReaders && appState.serviceReaders.length > 0)
                {
                    console.log("showing only service reader services");
                    services = appState.serviceReaders;
                    serviceInfoSet(services)
                }
            }
            else if (appState.userServiceTreeServies && appState.userServiceTreeServies.length > 0)
            {
                console.log("showing owner services");
                services = appState.userServiceTreeServies;
                serviceInfoSet(services)
            }

            if (services && services.length > 0)
            {
                services.forEach(x => {
                    if (x.serviceId && x.serviceName)
                    {
                        tOptions.push({
                            key: x.serviceId,
                            text: x.serviceName
                        });
                    }
                });
            }

            if (props.addOther)
            {
                tOptions.push({ key: 'Other', text: 'Other' });
            }

            if (props.selectedServiceId)
            {
                cmbSTSelectedServiceSet(props.selectedServiceId);
            }
            else {
                if (props.addOther && isFirstSelectionDone)
                {
                    cmbSTSelectedServiceSet('Other');
                }   
            }
        }
        else {
            if (props.addOther)
            {
                tOptions.push({ key: 'Other', text: 'Other' });
            }
        }

        cmbSTServiceOptionsSet(tOptions);
    },[props])

    return(
        <ComboBox
            id={props.id}
            label={props.label}
            placeholder={props.placeholder}
            options={cmbSTServiceOptions}
            selectedKey={cmbSTSelectedService}
            useComboBoxAsMenuWidth={true}
            onChange={onServiceTreeChange}
        />);
}
import { ComboBox, IComboBox, IComboBoxOption, IDropdownStyles, Label, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { ReviewTypeStrings } from "../../Models/Constants/ReviewTypeStrings";
import MeetingsService from "../../Services/MeetingsService";
import { ReviewType } from "../../Models/Reviews/ReviewType";

interface IReviewTypeMultiSelectComponent{
    id?: string,
    label?: string,
    placeholder?: string,
    selectedReviewTypes: string[],
    loadingText?: string,
    onReviewTypeSelected: (selectedReviewTypes: string[]) => void,
}

export const ReviewTypeMultiSelectComponent: FC<IReviewTypeMultiSelectComponent> = (props: IReviewTypeMultiSelectComponent):JSX.Element => {
    
    let reviewService: MeetingsService = new MeetingsService();
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };
    const [isLoadingData, isLoadingDataSet] = useState<boolean>(false);
    const [dataReviewTypes, dataReviewTypesSet] = useState<ReviewType[]>([]);
    useEffect(()=>{
        let tReviewTeams: IComboBoxOption[] = [];
        if (dataReviewTypes && dataReviewTypes.length > 0)
        {
            dataReviewTypes.forEach((reviewType: ReviewType) => {
                if (reviewType.id && reviewType.name)
                {
                    tReviewTeams.push({ key: reviewType.id, text: reviewType.name});
                }
            })
            cmbReviewTypeOptionsSet(tReviewTeams);
        }
        else
        {
            cmbReviewTypeOptionsSet([]);
        }
    },[dataReviewTypes]);
    const [isComponentLoadError, isComponentLoadErrorSet] = useState<boolean>(false);
    const loadRevieweTypes = async () => {
        isLoadingDataSet(true);
        var reviewTypesResponse = await reviewService.getReviewTypes();
        if (reviewTypesResponse && !reviewTypesResponse.hasErrors && reviewTypesResponse.result && reviewTypesResponse.result.length > 0) {
            dataReviewTypesSet(reviewTypesResponse.result);

            if (props.selectedReviewTypes && props.selectedReviewTypes.length > 0)
            {
                cmbUserSelectedReviewTypeKeysSet(props.selectedReviewTypes);
            }
        }
        else {
            isComponentLoadErrorSet(true);
        }

        isLoadingDataSet(false);
    }

    useEffect(() => {
        if (props.selectedReviewTypes && props.selectedReviewTypes.length > 0) {
            if (props.selectedReviewTypes.length !== cmbUserSelectedReviewTypeKeys.length) {
                let deletedItems = cmbUserSelectedReviewTypeKeys.filter(x => !props.selectedReviewTypes.some(y => y === x));
                let newItems = props.selectedReviewTypes.filter(x => !cmbUserSelectedReviewTypeKeys.some(y => y === x));
                let existingItems = props.selectedReviewTypes.filter(x => cmbUserSelectedReviewTypeKeys.some(y => y === x));
                cmbUserSelectedReviewTypeKeysSet([...existingItems, ...newItems]);
            }
        }
        else {
            if (cmbUserSelectedReviewTypeKeys.length !== 0 && props.selectedReviewTypes.length === 0) {
                cmbUserSelectedReviewTypeKeysSet([]);
            }
        }
    }, [props]);
    
    const [cmbReviewTypeOptions, cmbReviewTypeOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbUserSelectedReviewTypeKeys, cmbUserSelectedReviewTypeKeysSet] = useState<string[]>([]);
    useEffect(() => {
        if (cmbUserSelectedReviewTypeKeys && cmbUserSelectedReviewTypeKeys.length > 0) {
            props.onReviewTypeSelected(cmbUserSelectedReviewTypeKeys);
        }
        else {
            props.onReviewTypeSelected([]);
        }

    }, [cmbUserSelectedReviewTypeKeys]);
    const onReviewTypeChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            let selected = option.selected;
            if (selected) {
                cmbUserSelectedReviewTypeKeysSet([...cmbUserSelectedReviewTypeKeys, option.key as string])
            }
            else {
                var remaining = cmbUserSelectedReviewTypeKeys.filter(x => x !== option.key);
                cmbUserSelectedReviewTypeKeysSet(remaining);
            }


        }
    };

    useEffect(() => {
        loadRevieweTypes();
    },[]);

    if (isLoadingData)
    {
        return <div className="row">
        <Label>{props.label}</Label>
        <Spinner size={SpinnerSize.large} label = {props.loadingText ? props.loadingText : "loading the review types."} />
        </div>
    }

    if (isComponentLoadError)
    {
        return <div className="row">
        <Label>{props.label}</Label>
        <Text>Could not load the review types.</Text>
        </div>
    }
    
    return (<ComboBox
        label={props.label}
        placeholder={props.placeholder}
        selectedKey={cmbUserSelectedReviewTypeKeys}
        styles={dropdownStyles}
        multiSelect={true}
        onChange={onReviewTypeChange}
        useComboBoxAsMenuWidth={true}
        options={cmbReviewTypeOptions} />);
}
import { Announced, ChoiceGroup, IChoiceGroupOption, Icon, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, Stack, StackItem } from "@fluentui/react";
import { FC, useEffect, useRef, useState } from "react";
import { LoadingIndicator } from "../../Components/Common/LoadingIndicatorComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import { UserReviewTeamsComponent } from "../../Components/Common/UserReviewTeamsComponent";
import { constants } from "../../Constants/constants";
import { ErrorCode } from "../../Models/common/Enums";
import { AnswerChoices } from "../../Models/Questionnaire/AnswerChoices";
import { Questions } from "../../Models/Questionnaire/Question";
import { Questionnaire } from "../../Models/Questionnaire/Questionnaire";
import { QuestionType } from "../../Models/Questionnaire/QuestionType";
import { Team } from "../../Models/Team";
import { QuestionnaireService } from "../../Services/QuestionnaireService";
import { PreviewQuestionnaire } from "./PreviewQuestionnaire";

interface IDesignQuestionnaire {
    questionnaireId?: string;
    questionsList?: Questions[],
    teamId?: string;
    onQuestionsUpdated: (questions: Questions[]) => void
}

export const DesignQuestionnairePage: FC<IDesignQuestionnaire> = (props: IDesignQuestionnaire): JSX.Element => {
    let queService = new QuestionnaireService();

    const queCountRef = useRef<number>(0);
    const [addNewQue, setAddNewQue] = useState<boolean>(false);
    const [isNewQuestionnaire, setIsNewQuestionnaire] = useState<boolean>(true);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [errorMessage, setErrorMessage] = useState<string>();
    // const [infoMessage, setInfoMessage] = useState<string>();

    const [createNewQuestionnaire, setCreateNewQuestionnaire] = useState<boolean>(false);
    const [questionsList, setQuestionsList] = useState<Questions[]>([]);

    const questionnaireRef = useRef<Questionnaire>();
    const questionsListRef = useRef<Questions[]>([]);

    const questionType: IChoiceGroupOption[] = [
        { key: 'Free Text', text: 'Free Text', styles: { field: { marginRight: "15px" } } },
        { key: 'Choice', text: 'Choice', styles: { field: { marginRight: "15px" } } },
        { key: 'Choice with free text', text: 'Choice with free text', styles: { field: { marginRight: "15px" } } },
    ];

    const stackTokens = { childrenGap: 10, };

    useEffect(() => {
        if (props.questionsList && props.questionsList.length > 0) {
            questionsListRef.current = props.questionsList;
            queCountRef.current = props.questionsList.length;
            setQuestionsList(questionsListRef.current);
            setIsNewQuestionnaire(false);
            setCreateNewQuestionnaire(true);
            setIsLoading(false);
        }
        else {
            reset();
            setIsNewQuestionnaire(true);
            setIsLoading(false);
        }
    }, [props.questionsList]);

    const onAddNewQuestionnaire = () => {
        setCreateNewQuestionnaire(true);
        toggleActionsView(false);
    }

    const toggleActionsView = (show: boolean) => {
        var actionsDiv = document.getElementById("actions");
        if (actionsDiv) {
            if (show)
                actionsDiv.style.display = 'block';
            else
                actionsDiv.style.display = 'none';
        }
    }

    const onQuestionTypeChange = (ev?: React.FormEvent<HTMLInputElement | HTMLElement> | undefined, option?: IChoiceGroupOption | undefined) => {
        if (option && option.key) {
            //setSelectedQuestionType(option.key);
            ++queCountRef.current;
            if (option.key == "Free Text") {
                var queObj: Questions = {
                    id: crypto.randomUUID(),
                    isRequiredQuestion: false,
                    questionNumber: queCountRef.current,
                    questionType: QuestionType.FreeText,
                    questionText: "",
                    answerText: "",
                }
                questionsListRef.current.push(queObj);
            }
            else if (option.key == "Choice") {
                var queObj: Questions = {
                    id: crypto.randomUUID(),
                    isRequiredQuestion: false,
                    questionNumber: queCountRef.current,
                    answerChoices: [],
                    questionType: QuestionType.Choice,
                    questionText: "",
                    answerText: "",
                    selectedAnswerChoice: ""
                }
                questionsListRef.current.push(queObj);
            }
            else {
                var queObj: Questions = {
                    id: crypto.randomUUID(),
                    isRequiredQuestion: false,
                    questionNumber: queCountRef.current,
                    answerChoices: [],
                    questionType: QuestionType.FreeTextWithChoice,
                    questionText: "",
                    answerText: "",
                    selectedAnswerChoice: ""
                }
                questionsListRef.current.push(queObj);
            }
        }
        setQuestionsList(questionsListRef.current);
        setAddNewQue(false);
    }

    const addNewQuestion = () => {
        //queCountRef.current++;
        setAddNewQue(true);
    }

    const saveQuestionnaire = async () => {
        setIsLoading(true);
        displayMessagesSet([]);
        var queList = questionsListRef.current;
        if (queList && queList.length > 0) {
            var temp = [...queList];
            var isValid = false;
            temp.map(item => {
                if (item.questionNumber == 0) {
                    displayMessagesSet([{message: "Invalid question number" , messageType: DisplayMessageType.Error}]);
                    isValid = false;
                    return;
                }
                else if (item.questionText.length == 0) {
                    displayMessagesSet([{message: "Question cannot be empty" , messageType: DisplayMessageType.Error}]);
                    isValid = false;
                    return;
                }
                else
                    isValid = true;
            })
            if (isValid) {
                var queObj: Questionnaire = {
                    teamId: props.teamId!,
                    questions: questionsListRef.current,
                }
                var saveResult = await queService.saveQuestionnaire(queObj);
                if (saveResult && !saveResult.hasErrors && saveResult.result) {
                    questionnaireRef.current = saveResult.result;
                    if (saveResult.result.questions)
                        questionsListRef.current = saveResult.result.questions;
                    setQuestionsList(questionsListRef.current);
                    
                    displayMessagesSet([{message: "Questionnaire is saved successfully", messageType: DisplayMessageType.Success}]);
                    props.onQuestionsUpdated(questionsListRef.current);
                    setIsLoading(false);
                }
                else {
                    displayMessagesSet([{message: constants.errorSomethingWentWrongHeading, messageType: DisplayMessageType.Error}]);
                    setIsLoading(false);
                }
            }
        }
        setIsLoading(false);
    }

    const deleteQuestionnaire = async () => {
        var teamId = props.teamId;
        displayMessagesSet([]);
        if (((questionnaireRef.current && questionnaireRef.current.id) || props.questionnaireId) && teamId) {
            var questionnaireId = props.questionnaireId === "" ? questionnaireRef.current?.id : props.questionnaireId;
            var response = await queService.deleteQuestionnaire(teamId, questionnaireId!);
            if (response && !response.hasErrors && response.result) {
                reset();
                displayMessagesSet([{message: "Questionnaire deleted successfully", messageType: DisplayMessageType.Success}]);
                props.onQuestionsUpdated(questionsListRef.current);
            }
            else {
                if (response?.businessErrors && response?.businessErrors.length > 0 && response.businessErrors[0].message)
                    displayMessagesSet([{message: response.businessErrors[0].message, messageType: DisplayMessageType.Error}]);
                else
                displayMessagesSet([{message: constants.errorSomethingWentWrongHeading, messageType: DisplayMessageType.Error}]);
            }
        }
        else {
            reset();
            displayMessagesSet([{message: "Questionnaire deleted successfully", messageType: DisplayMessageType.Success}]);
            props.onQuestionsUpdated(questionsListRef.current);
        }
    }

    const deleteQuestion = (questionItem: Questions) => {
        var queList = questionsListRef.current;
        if (queList && queList.length > 0) {
            var array1 = [...queList];
            var index = array1.findIndex(x => x.id == questionItem.id);
            if (index !== -1) {
                array1.splice(index, 1);
                questionsListRef.current = array1;
                resetQueOrdering(index);
            }
            if (questionsListRef.current.length == 0) {
                queCountRef.current = 0;
            }
            displayMessagesSet([]);
            props.onQuestionsUpdated(questionsListRef.current);
        }
    }

    const onQuestionTextChange = (questionItem: Questions, itemProperty: string, itemValue: string): void => {
        if (questionItem) {
            var updatedList = questionsList.map(obj => {
                if (obj.id === questionItem.id && itemProperty == "question") {
                    return { ...obj, questionText: itemValue };
                }
                else if (obj.id === questionItem.id && itemProperty == "answer") {
                    return { ...obj, answerText: itemValue };
                }
                else if (obj.id === questionItem.id && itemProperty == "questionNumber") {
                    return { ...obj, questionNumber: +itemValue };
                }

                return obj;
            });
            questionsListRef.current = updatedList;
            setQuestionsList(updatedList);
            props.onQuestionsUpdated(questionsListRef.current);
        }
    }

    const onEditChoices = (itemKey: string, itemValue: string): void => {
        var queId = itemKey.split('_')[2];
        var queVal: string = itemValue;
        var queList = questionsListRef.current;
        if (queList && queList.length > 0) {
            const idx = queList.findIndex(item => item.id == queId);
            const nextData = queList.slice();
            const nextItem = { ...nextData[idx] };
            var optionIdx = nextItem.answerChoices?.findIndex(x => x.choiceKey == itemKey)!;
            if (optionIdx != -1) {
                nextItem.answerChoices![optionIdx].choiceText = queVal;
            }
            else {
                var temp: AnswerChoices = { choiceKey: itemKey, choiceText: queVal };
                nextItem.answerChoices?.push(temp);
            }
            nextData[idx] = nextItem;
            questionsListRef.current = nextData;
            setQuestionsList(questionsListRef.current);
        }
    }

    function onToggleQue(questionItem: Questions, checked?: boolean) {
        console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
        var queList = questionsListRef.current;
        if (queList && queList.length > 0) {
            const idx = queList.findIndex(item => item.id == questionItem.id);
            const nextData = queList.slice();
            const nextItem = { ...nextData[idx] };
            nextItem.isRequiredQuestion = checked!;
            nextData[idx] = nextItem;
            questionsListRef.current = nextData;
            setQuestionsList(questionsListRef.current);
        }
    }

    const resetQueOrdering = (index: any) => {
        var tempArr = [...questionsListRef.current];
        if (index < tempArr.length) {
            for (var i = index; i < tempArr.length; i++) {
                tempArr[i].questionNumber--;
            }
            questionsListRef.current = tempArr;
        }
        queCountRef.current = questionsListRef.current.length;
        setQuestionsList(questionsListRef.current);
    }

    const reset = () => {
        questionnaireRef.current = undefined;
        questionsListRef.current = [];
        queCountRef.current = 0;
        setQuestionsList([]);
        setAddNewQue(false);
        setCreateNewQuestionnaire(false);
        displayMessagesSet([]);
        toggleActionsView(true);
    }

    const [displayMessages, displayMessagesSet] = useMessages();

    return (
        <div className="container" style={{ padding: 10 }}>
            {/* <div className="row">
                    <HeadingComponent title="Questionnaire" subtitle="This questionnaire will be provided to review teams during onboarding" />
                </div> */}

            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>
            {isLoading && <LoadingIndicator label="Loading" />}
            <div className="row" id="questionSection">
                <div className="row" id="actions" style={{ display: "block" }}>
                    {isNewQuestionnaire && (
                        <div className="col-3" style={{ margin: 20 }}>
                            <div className="row" onClick={onAddNewQuestionnaire}>
                                <Icon iconName={"Add"} style={{ fontSize: 30, color: 'blue', marginLeft: 20 }} />
                                <p style={{ fontSize: 14, color: 'blue', marginLeft: 15 }}>Create</p>
                            </div>
                        </div>)}
                </div>
                {questionsList && questionsList.length > 0 && (
                    <div className="row" style={{ marginBottom: 10 }}>
                        <div className="d-flex" style={{ padding: 0 }}>
                            <div className="ms-auto">
                                <Stack tokens={stackTokens} horizontal>
                                    <StackItem>
                                        {
                                            <PrimaryButton text="Save" title="Save the questionnaire below" onClick={saveQuestionnaire} />
                                        }
                                    </StackItem>
                                    <StackItem>
                                        {
                                            <PrimaryButton text="Delete" title="Delete the questionnaire below" onClick={deleteQuestionnaire} />
                                        }
                                    </StackItem>
                                </Stack>
                            </div>
                        </div>
                    </div>
                )}
                <br />
                {questionsList && questionsList.length > 0 && (
                    <div className="row">
                        <PreviewQuestionnaire questionsList={questionsList} isPreview={false} isDesign={true} isUserView={false} onDeleteQuestion={deleteQuestion} onEditQuestion={onQuestionTextChange} onToggleQuestion={onToggleQue} onEditChoices={onEditChoices} />
                    </div>
                )}
                <br />
                {createNewQuestionnaire && (
                    <div className="row">
                        <div className="col-2">
                            <PrimaryButton text="Add a question" onClick={addNewQuestion} style={{ margin: 10, width: 170 }}></PrimaryButton>
                        </div>
                        {addNewQue && (
                            <div className="col-6">
                                <ChoiceGroup styles={{ flexContainer: { display: "flex", marginTop: 4 }, }} options={questionType} onChange={onQuestionTypeChange}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default DesignQuestionnairePage;
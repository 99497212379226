export function htmlEscape(str: string) {
    return str
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
}

// I needed the opposite function today, so adding here too:
export function htmlUnescape(str: string) {
    return str
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&');
}

export async function copyToClipBoard(str?: string)
{
    if (str && str.length > 0)
    {
        await navigator.clipboard.writeText(str);
    }
}

export function isEmptyOrSpaces(str?: string){
    return str ===undefined || str === null || str === "" || str.match(/^ *$/) !== null;
}
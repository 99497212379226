import { ApiBaseUrl } from "../authConfig";
import { BusinessResult } from "../Models/common/BusinessResult";
import { Review } from "../Models/Reviews/Review";
import { SearchData } from "../Models/Search/SearchData";
import { SearchFilter } from "../Models/Search/SearchFilter";
import { UserSearch } from "../Models/Search/UserSearch";
import { getHeaders } from "../Utilities/headers";
import * as http from '../Utilities/http'

export class SearchService {
    searchByAuditor = async (filterCriteria: SearchFilter, offset: number): Promise<BusinessResult<Review[]> | undefined> => {
        var d = new Date();
        var offSetMinutes = d.getTimezoneOffset();
        filterCriteria.clientOffsetMinutes = offSetMinutes;
        var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Search/auditor/' + offset, filterCriteria, await getHeaders()))?.parsedBody;
        return response;
    }

    searchByReviewer = async (filterCriteria: SearchFilter, offset: number): Promise<BusinessResult<Review[]> | undefined> => {
        var d = new Date();
        var offSetMinutes = d.getTimezoneOffset();
        filterCriteria.clientOffsetMinutes = offSetMinutes;
        var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Search/reviewer/' + offset, filterCriteria, await getHeaders()))?.parsedBody;
        return response;
    }

    cacheSearchMetadata = async (): Promise<BusinessResult<string[]> | undefined> => {
        var response = await (await http.get<BusinessResult<string[]> | undefined>(ApiBaseUrl + '/api/Search/cacheSearchMetadata/', await getHeaders()))?.parsedBody;
        return response;
    }

    searchByUserInput = async(searchInput: any) : Promise<BusinessResult<UserSearch> | undefined> => {
        if (searchInput)
        {
            var response = await (await http.post<BusinessResult<UserSearch> | undefined>(ApiBaseUrl + '/api/Search/userSearch', searchInput, await getHeaders()))?.parsedBody;
            return response;
        }
    }
}
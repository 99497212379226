import { IPersonaSharedProps, IPersonaStyles, Persona, PersonaSize, Spinner, TextStyles } from "@fluentui/react";
import { Component, FC, useContext, useEffect, useState } from "react";
import UserProfile from "../../Models/UserProfile";
import {UserService} from '../../Services/UserService';
import { store } from "../Common/GlobalStateProvider";

export const UserProfileComponent: FC = (): JSX.Element => {
    let service:UserService = new UserService();
    const [isLoading, setIsLoading] = useState(false);
    const [userProfile, userProfileSet] = useState<UserProfile>();
    const [persona, personaSet] = useState<IPersonaSharedProps>();
    var {appState, appdispatch} = useContext(store);
    
    useEffect(()=>{
        const loadUserProfile = async () => {
            setIsLoading(true);
            var user = await service.getUserProfile();
            userProfileSet(user);
            setIsLoading(false);
          };

          if(!appState.userProfile)
          {
            loadUserProfile();
          }
          else
          {
            userProfileSet(appState.userProfile);
          }
          // appdispatch({type:'userProfile', payload: userProfile});
    }, []);

    useEffect(()=> {
        personaSet(
            {
                imageUrl : userProfile?.photo,
                size: PersonaSize.size32
                // text: userProfile?.displayName,
                // imageAlt: userProfile?.userPrincipalName,
                // secondaryText: userProfile?.jobTitle,
            }
        );
    }, [userProfile]);

    if(isLoading)
    {
        return <Spinner />   
    }

    if(userProfile)
    {
        return(
            <>
                <Persona
                    className="navbar-dark navbar-brand"
                    {...persona}
      />
            </>
        );
    }

    return (
        <>
            
        </>
    );
}

export default UserProfileComponent;
import { Label, Text, IPersonaProps, Persona, PersonaSize, Separator, Stack, FontIcon } from "@fluentui/react";
import { Editor } from "@tinymce/tinymce-react";
import { FC, useContext, useEffect, useState } from "react";
import { DraftFeedback } from "../../Models/Reviews/DraftFeedback";
import UserProfile from "../../Models/UserProfile";
import { store } from "../Common/GlobalStateProvider";

export interface IOtherUserFeedbackComponent {
    feedback: DraftFeedback[],
    reviewerProfiles: UserProfile[],
    onActionOrFollowUpQuickClick: (text?: string) => void,
}

export const OtherUserScribbleComponent: FC<IOtherUserFeedbackComponent> = (props: IOtherUserFeedbackComponent): JSX.Element => {

    var { appState, appdispatch } = useContext(store);
    const [otherUsersFeedback, otherUsersFeedbackSet] = useState<DraftFeedback[]>([]);
    const [reviewerPersonas, reviewerPersonasSet] = useState<IPersonaProps[]>([]);

    useEffect(() => {
        console.log("props feedback");
        console.log(props.feedback);
        if (props.feedback && props.feedback.length > 0) {
            if (appState && appState.userProfile && appState.userProfile.userPrincipalName) {
                var oF = props.feedback?.filter(x => x.reviewerUpn !== appState.userProfile?.userPrincipalName);
                if (oF && oF.length > 0) {
                    console.log("Other feedback");
                    console.log(oF);
                    otherUsersFeedbackSet(oF);
                }
            }

            if (props.reviewerProfiles && props.reviewerProfiles.length > 0) {
                var tpersonaProps: IPersonaProps[] = [];
                props.reviewerProfiles.forEach(profile => {
                    let reviewProf: IPersonaProps = {
                        id: profile.userPrincipalName,
                        text: profile.displayName,
                        imageUrl: profile.photo
                    }
                    tpersonaProps.push(reviewProf);
                });

                reviewerPersonasSet(tpersonaProps);
            }
        }
    }, [props]);
    return (
        <div className="row" title="shashank">
            {
                otherUsersFeedback && otherUsersFeedback.length > 0 &&
                otherUsersFeedback.map(x => {
                    return <div className="row">
                        {
                            x &&
                            (
                                (x.internalNotes && x.internalNotes.length > 0)
                                || (x.draftTasksAndBugs && x.draftTasksAndBugs.length > 0)
                                || (x.followupsToServiceTeam && x.followupsToServiceTeam.length > 0)
                            ) &&
                            <div className="row" key={"othFeedbac_" + x.reviewerUpn}>
                                <div>
                                    {
                                        reviewerPersonas.filter(p => p.id == x.reviewerUpn)?.map(personProps =>
                                            <div className="row" style={{ marginTop: 30 }} key={"p2_" + personProps.key}>
                                                <Separator>
                                                    <Stack horizontal>
                                                        <Persona
                                                            style={{ marginTop: 20, marginBottom: 10 }}
                                                            {...personProps}
                                                            key={"p_" + personProps.key}
                                                            title={personProps.text}
                                                            hidePersonaDetails={true}
                                                            size={PersonaSize.size32}
                                                        />
                                                        <Text style={{ margin: 5 }}>{personProps.text}</Text>
                                                    </Stack>
                                                </Separator>
                                                <hr />
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                                    <div className="col">
                                        <Label>Internal Notes</Label>
                                        {/* @ts-ignore*/}
                                        <Editor
                                            init={{
                                                skin: false,
                                                content_css: false,
                                                height: 300,
                                                menubar: false,
                                                statusbar: false,
                                                toolbar: false,
                                                table_default_attributes: {
                                                    border: '1'
                                                },

                                            }}
                                            value={x.internalNotes}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <Label>Draft Follow ups  </Label>
                                                <ul style={{ listStyleType: "none", padding: 0 }}>
                                                    {
                                                        x.followupsToServiceTeam && x.followupsToServiceTeam.length > 0 &&
                                                        x.followupsToServiceTeam.map(y => {
                                                            return <li key={"otherfeedbac_dbt_" + crypto.randomUUID()} style={{ margin: 2 }}>
                                                                <div className="d-flex">
                                                                    <Text block={false} variant="smallPlus" title={y.question} style={{ marginLeft: 5 }}>{y.question}</Text>
                                                                    <div className="ms-auto">
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="col">
                                                <Label>Draft Action Items</Label>
                                                <ul style={{ listStyleType: "none", padding: 0 }}>
                                                    {
                                                        x.draftTasksAndBugs && x.draftTasksAndBugs.length > 0 &&
                                                        x.draftTasksAndBugs.map(y => {
                                                            return <li key={"otherfeedbac_df_" + crypto.randomUUID()} style={{ margin: 2 }}>
                                                                <div className="d-flex">
                                                                    <Text block={false} variant="smallPlus" title={y.title} style={{ marginLeft: 5 }}>{y.title}</Text>
                                                                    <div className="ms-auto">
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                })
            }
        </div>
    );
}
import { IBasePickerSuggestionsProps, IPersonaProps, IPersonaStyles, ITag, ListPeoplePicker, NormalPeoplePicker, PeoplePickerItemSuggestion, PersonaSize, TagPicker } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import UserProfile from "../../Models/UserProfile";
import UserService from "../../Services/UserService";

interface IMsPeoplePickerComponent {
    id?: string,
    itemLimit: number,
    selectedUsers: UserProfile[] | undefined,
    onUserSelected: (selectedUsers: UserProfile[]) => void,
}

export const MsPeoplePickerComponent: FC<IMsPeoplePickerComponent> = (props: IMsPeoplePickerComponent): JSX.Element => {
    let userService = new UserService();
    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested Users',
        noResultsFoundText: 'No matching Users found',
    };

    const [otherMembers, otherMembersSet] = useState<IPersonaProps[]>([]);
    const [dataSuggestedMembers, dataSuggestedMembersSet] = useState<UserProfile[]>([]);
    const [dataSelectedUsers, dataSelectedUsersSet] = useState<UserProfile[]>([]);

    const personaStyles: Partial<IPersonaStyles> = {
        root: {
            height: 'auto',
        },
        secondaryText: {
            height: 'auto',
            whiteSpace: 'normal',
        },
        primaryText: {
            height: 'auto',
            whiteSpace: 'normal',
        },
    };
    const onPeopleChange = (items?: IPersonaProps[] | undefined) => {
        console.log("on change")

        if (items && items.length > 0) {
            if (items.length > dataSelectedUsers.length) {
                let newUsers = items.filter(x => !dataSelectedUsers.some(y => y.id === x.id));
                if (newUsers && newUsers.length > 0) {
                    let userAdded = dataSuggestedMembers.filter(x => x.id == newUsers[0].id);
                    props.onUserSelected([...dataSelectedUsers, userAdded[0]]);
                }
            } else {
                var newSelection = dataSelectedUsers.filter(x => items.find(y => y.id == x.id));
                console.log(newSelection);
                props.onUserSelected([...newSelection]);
            }
        }
        else {
            dataSelectedUsersSet([]);
            otherMembersSet([]);
            props.onUserSelected([]);
        }

    };
    const onMemberSelected = async (selectedItem?: IPersonaProps | undefined): Promise<IPersonaProps> => {
        // console.log("on select")
        // if (selectedItem && dataSuggestedMembers) {
        //     var selectedUser = dataSuggestedMembers.filter(x => x.id == selectedItem.id)
        //     if (selectedUser && selectedUser.length > 0 && selectedUser[0]) {
        //         var selectedUserPhoto = await userService.getUserPhotoByUpn(selectedUser[0].userPrincipalName)
        //         if (selectedUserPhoto) {
        //             selectedUser[0].photo = selectedUserPhoto;
        //             selectedItem.imageUrl = selectedUserPhoto;
        //             console.log(selectedUser);
        //         }

        //         dataSelectedUsersSet([...dataSelectedUsers, selectedUser[0]]);
        //     }
        // }
        return selectedItem!;
    }
    const onMemberFilterChanged = async (
        filterText: string,
        currentPersonas?: IPersonaProps[] | undefined,
    ): Promise<IPersonaProps[]> => {

        var users = await userService.searchUsers(filterText);
        if (users && users.length > 0) {
            var msUsers = users;
            console.log(msUsers);
            let filteredPersonas: IPersonaProps[] = [];
            msUsers.forEach(user => {
                filteredPersonas.push({
                    text: user.displayName,
                    secondaryText: user.jobTitle,
                    id: user.id,
                    size: PersonaSize.size8
                });
            });
            dataSuggestedMembersSet(msUsers);
            return filteredPersonas;
        }
        return [];
    }
    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }
    const onRenderSuggestionItem = (personaProps: IPersonaProps, suggestionsProps: IBasePickerSuggestionsProps) => {
        return (
            <PeoplePickerItemSuggestion
                personaProps={{ ...personaProps, styles: personaStyles }}
                suggestionsProps={suggestionsProps}
            />
        );
    };

    const setSelectedUsers = async () => {
        let ttags: IPersonaProps[] = [];

        var userAliases: string[] = [];
        
        if (dataSelectedUsers && dataSelectedUsers.length > 0) {

            var newUsers = dataSelectedUsers.filter(x => !otherMembers.some(y => y.id === x.id));

            if (newUsers && newUsers.length > 0) {
                newUsers.forEach(member => {
                    // var memberAlias = member.userPrincipalName.split('@')[0];
                    userAliases.push(member.userPrincipalName);
                });
            }

            if (userAliases && userAliases.length > 0) {
                console.log("call batch " + userAliases.length);
                var tuserProfiles = await userService.getUserProfilesByUPNs(userAliases);
                var otherPersona: IPersonaProps[] = [];
                if (tuserProfiles && tuserProfiles.length > 0) {
                    tuserProfiles.sort((a, b) => a.displayName.localeCompare(b.displayName)).forEach(profile => {
                        let otherProf: IPersonaProps = {
                            text: profile.displayName,
                            imageUrl: profile.photo,
                            id: profile.id,
                            key: profile.id,
                        }

                        otherPersona.push(otherProf);
                    });

                    otherMembersSet([...otherMembers ,...otherPersona]);
                }
            }
            else{
                var remainingUsers = otherMembers.filter(x => dataSelectedUsers.some(y => y.id === x.id));
                otherMembersSet(remainingUsers);
            }
        } else {
            otherMembersSet([]);
        }
    }

    useEffect(() => {
      //  console.log("MS-Picker UseEffect [props]");
       
    }, []);

    useEffect(() => {
        if (props.selectedUsers && props.selectedUsers.length > 0) {
            dataSelectedUsersSet(props.selectedUsers);
        }
        else
        {
            dataSelectedUsersSet([]);
        }
    }, [props]);

    useEffect(() => {
        setSelectedUsers();
    }, [dataSelectedUsers]);

    return (
        <NormalPeoplePicker
            onResolveSuggestions={onMemberFilterChanged}
            getTextFromItem={getTextFromItem}
            selectedItems={otherMembers}
            className={'ms-PeoplePicker'}
            pickerSuggestionsProps={pickerSuggestionsProps}
            onChange={onPeopleChange}
            selectionAriaLabel={'Selected contacts'}
            removeButtonAriaLabel={'Remove'}
            // eslint-disable-next-line react/jsx-no-bind
            onRenderSuggestionsItem={onRenderSuggestionItem}
            onItemSelected={onMemberSelected}
            itemLimit={props.itemLimit}
            removeButtonIconProps={{ iconName: 'Delete' }}
            inputProps={{
                onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onBlur called'),
                onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
                'aria-label': 'People Picker',
                id: props.id,
            }}
            resolveDelay={400}
            styles={{}}
        />
    );
}
import { ComboBox, IComboBox, IComboBoxOption, IDropdownStyles, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { ArtifactTypes } from "../../Models/TMPArtifacts/ArtifactTypes";
import { ArtifactService } from "../../Services/ArtifactService";

export interface IArtifactTypesComponent
{
    label?: string,
    placeholder?: string,
    onArtifactTypeSelected: (artifactType: ArtifactTypes) => void,
}

export const ArtifactTypesComponent: FC<IArtifactTypesComponent> = (props:IArtifactTypesComponent): JSX.Element => {
    let artifactService = new ArtifactService();
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };
    
    const [isErrorLoadingComponent, isErrorLoadingComponentSet] = useState<boolean>(false);
    const [isLoading, isLoadingSet] = useState<boolean>(false);
    const [cmbArtifactTypeOptions, cmbArtifactTypeOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbSelectedArtifactOption, cmbSelectedArtifactOptionSet] = useState<string>();
    const [dataArtifactTypes, dataArtifactTypesSet] = useState<ArtifactTypes[]>([])
    const onArtifactTypeChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            cmbSelectedArtifactOptionSet(option.key as string);
            var selectedArtifactType = dataArtifactTypes.filter(x => x.artifactTypeId == option?.key)
            if (selectedArtifactType && selectedArtifactType[0]) {
                props.onArtifactTypeSelected(selectedArtifactType[0]);
            }
        }
    };

    useEffect(()=>{
        isErrorLoadingComponentSet(false);
        loadArtifactTypes();
    },[]);

    const loadArtifactTypes = async () => {
        isLoadingSet(true);
        var response = await artifactService.getArtifactTypes();
        if (response && !response.hasErrors && response.result && response.result.length > 0)
        {
            dataArtifactTypesSet(response.result);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0){
            isErrorLoadingComponentSet(true);
        }
        else{
            isErrorLoadingComponentSet(true);
        }

        isLoadingSet(false);
    };
    useEffect(()=>{
        if (dataArtifactTypes && dataArtifactTypes.length > 0)
        {
            let tOptions: IComboBoxOption[] = [];
            dataArtifactTypes.forEach(r => {
                if (r.artifactTypeId && r.artifactType)
                {
                    tOptions.push({
                        key: r.artifactTypeId,
                        text: r.artifactType
                    });
                }
            });
            cmbArtifactTypeOptionsSet(tOptions);
        }
    },[dataArtifactTypes])

    if (isLoading)
    {
        return <Spinner size={SpinnerSize.large} title = "Loading artifact types.." />
    }

    if (isErrorLoadingComponent)
    {
        return <Text>Could not load Artifact types.</Text>
    }

    return (
        <ComboBox
            label={props.label}
            placeholder= {props.placeholder}
            options={cmbArtifactTypeOptions}
            selectedKey={cmbSelectedArtifactOption}
            styles={dropdownStyles}
            useComboBoxAsMenuWidth={true}
            onChange={onArtifactTypeChange}
            required={true}
        />
    );
}
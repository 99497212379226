import { FC, useEffect, useState } from "react";
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import HeadingComponent from "../../Components/Common/HeadingComponent";
import { UserReviewTeamsComponent } from "../../Components/Common/UserReviewTeamsComponent";
import { Team } from "../../Models/Team";
import { Checkbox, Pivot, PivotItem, PrimaryButton, Slider, TextField } from "@fluentui/react";
import AdminServices from "../../Services/AdminServices";
import { LoadingIndicator } from "../../Components/Common/LoadingIndicatorComponent";

export const AddConfigurationsPage: FC = (): JSX.Element => {

    let adminService = new AdminServices();
    
    const [displayMessages, displayMessagesSet] = useMessages();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedTeam, selectedTeamSet] = useState<Team>();
    const [teamDetails, setTeamDetails] = useState<Team>();

    useEffect(() => {
        if (selectedTeam && selectedTeam.id)
        {
            fetchData(selectedTeam.id);
        }
    }, [selectedTeam]);

    const fetchData = async(teamId: string) => {
        setIsLoading(true);
        var response = await adminService.fetchTeamMetadataById(teamId);
        if(response && response.result && !response.hasErrors)
        {
            setTeamDetails(response.result!);
        }
        else
        {
            if (response && response.businessErrors && response.businessErrors.length > 0  && response.businessErrors[0].message)
            {
                displayMessagesSet([{message: response.businessErrors[0].message, messageType: DisplayMessageType.Error}])
            }
        }
        setIsLoading(false);
    }

    const updateTeamInfo = async () => {
        setIsLoading(true);
        resetMessages();
        if (teamDetails) {
            var newTeam: Team = {
                id: teamDetails.id,
                role: teamDetails.role,
                name: teamDetails.name,
                teamAlias: teamDetails.teamAlias,
                teamDescription: teamDetails.teamDescription,
                securityGroups: teamDetails.securityGroups,
                teamMetadata: teamDetails.teamMetadata,
                responsibleFor: teamDetails.responsibleFor,
                workItemOptionsInTeamsInstance: teamDetails.workItemOptionsInTeamsInstance
            }
            var response = await adminService.updateTeamsMetadata(newTeam);
            if (response && !response.hasErrors && response.result) {
                displayMessagesSet([{ message: "Team configuration updated successfully", messageType: DisplayMessageType.Success }]);
            }
            else {
                displayMessagesSet([{ message: response?.businessErrors[0]?.message!, messageType: DisplayMessageType.Error }]);
            }
        }

        setIsLoading(false);
    }

    const resetMessages = () => {
        displayMessagesSet([]);
    }

    return (
        <div className="container">
            {isLoading && <LoadingIndicator label="Loading"/>}
            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>
            <div className="row">
                <HeadingComponent title={"Team configurations"} subtitle={"Manage the configurations per review team."} />
            </div>
            <div className="row" style={{width: 500}}>
                <UserReviewTeamsComponent
                    label="Select the Review team"
                    placeholder="Select a Review team"
                    onTeamSelected={(selectedTeam?: Team) => {
                        displayMessagesSet([]);
                        if (selectedTeam) {
                            selectedTeamSet(selectedTeam);
                        }

                    }} />
            </div>
            {selectedTeam && teamDetails && (
                <>
                <Pivot aria-label="Team Metadata" style={{padding: 10}}>
                    <PivotItem headerText="Team Metadata Configuration" itemIcon="ConfigurationSolid" itemKey="3" style={{ paddingTop: 10 }}>
                        <div className="row">
                            <div className="col" style={{ margin: 10 }}>
                                <Checkbox label="Mandate Threat Model file" name="MandateTMFile"
                                    ariaLabelledBy="MandateTMFile"
                                    checked={teamDetails?.teamMetadata?.mandateTMFile}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                        setTeamDetails(teamDetails => ({
                                            ...teamDetails!,
                                            teamMetadata: {
                                                ...teamDetails!.teamMetadata,
                                                mandateTMFile: checked
                                            }
                                        }))
                                    }} />
                            </div>
                            <div className="col" style={{ margin: 10 }}>
                                <Checkbox label="Enable Tech tags" name="enableTechTags"
                                    ariaLabelledBy="enableTechTags"
                                    checked={teamDetails?.teamMetadata?.enableTechTags}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                        setTeamDetails(teamDetails => ({
                                            ...teamDetails!,
                                            teamMetadata: {
                                                ...teamDetails!.teamMetadata,
                                                enableTechTags: checked
                                            }
                                        }))
                                    }} />
                            </div>
                            <div className="col" style={{ margin: 10 }}>
                                <Checkbox label="Enable Questionnaire" name="IsActive" ariaLabelledBy="isActive" checked={teamDetails?.teamMetadata?.enableQuestionnaire} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                    setTeamDetails(teamDetails => ({
                                        ...teamDetails!,
                                        teamMetadata: {
                                            ...teamDetails!.teamMetadata,
                                            enableQuestionnaire: checked
                                        }
                                    }))
                                }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4" style={{margin : 10}}>
                                <Slider label="Buffer days for new slots" max={30} ranged value={teamDetails.teamMetadata?.bufferDays} lowerValue={1} showValue onChange= {(range: number) => {
                                    if (range != undefined) {
                                        setTeamDetails(teamDetails => ({
                                            ...teamDetails!,
                                            teamMetadata: {
                                                ...teamDetails!.teamMetadata,
                                                bufferDays: range
                                            }
                                        }))
                                    }
                                }}/>
                            </div>
                        </div>
                    </PivotItem>
                </Pivot>
                <PrimaryButton text="Save Team details" onClick={updateTeamInfo} style={{ padding: 10 }} />
            </>
            )}
        </div>
    )
}
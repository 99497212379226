import { ApiBaseUrl } from '../authConfig';
import { BusinessError } from '../Models/common/BusinessError';
import { BusinessResult } from '../Models/common/BusinessResult';
import { ClientDateDetails } from '../Models/Reviews/ClientDateDetails';
import { Review } from '../Models/Reviews/Review';
import { ServiceTreeService } from '../Models/ServiceTreeService';
import { ServiceTreeSnapshot } from '../Models/ServiceTreeSnapshot';
import { Team } from '../Models/Team';
import { ServiceReviewTeams } from '../Models/Teams/ServiceReviewTeams';
import UserProfile from '../Models/UserProfile';
import { OneCsWorkItem } from '../Models/WorkItems/OneCsWorkItem';
import { getHeaders, getGraphHeaders } from '../Utilities/headers';
import * as http from '../Utilities/http'

export class STServices {
    getFullHistoryOfService = async (serviceId: string | undefined): Promise<BusinessResult<Review[]> | undefined> => {

        if (serviceId && serviceId.length > 0)
        {
            console.log("fetchFullHistory");
            var response = await (await http.get<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/' + serviceId + '/history', await getHeaders()))?.parsedBody;    
            return response;
        }
        
    }

    cacheUserData = async () => {
        var response = await (await http.get<BusinessResult<ServiceTreeService[]> | undefined>(ApiBaseUrl + '/api/User/data', await getHeaders()))?.parsedBody;
    }

    getServicesByNameOrId = async (filterText: string): Promise<BusinessResult<ServiceTreeService[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ServiceTreeService[]> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/name/' + filterText, await getHeaders()))?.parsedBody;
        return response;
    }

    getServicesByOrgNameOrId = async (filterText: string): Promise<BusinessResult<ServiceTreeService[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ServiceTreeService[]> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/organizationName/' + filterText, await getHeaders()))?.parsedBody;
        return response;
    }

    getServicesByDivisionNameOrId = async (filterText: string): Promise<BusinessResult<ServiceTreeService[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ServiceTreeService[]> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/divisionName/' + filterText, await getHeaders()))?.parsedBody;
        return response;
    }

    getServicesByServiceGroupNameOrId = async (filterText: string): Promise<BusinessResult<ServiceTreeService[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ServiceTreeService[]> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/serviceGroupName/' + filterText, await getHeaders()))?.parsedBody;
        return response;
    }

    getServicesByTeamGroupNameOrId = async (filterText: string): Promise<BusinessResult<ServiceTreeService[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ServiceTreeService[]> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/teamGroupName/' + filterText, await getHeaders()))?.parsedBody;
        return response;
    }

    getUserServices = async (): Promise<BusinessResult<ServiceTreeService[]>> => {
        var result = new BusinessResult<ServiceTreeService[]>();
        try {
            var response = await (await http.get<BusinessResult<ServiceTreeService[]> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/user', await getHeaders()))?.parsedBody;
            if (response !== undefined) {
                result = response;
            }
        }
        catch (error: any) {
            var businessError: BusinessError = {
                id: undefined,
                message: error.message
            }
            result.result = undefined;
            result.businessErrors.push(businessError);
        }

        return result;
    }

    getServiceByName = async (name: string): Promise<BusinessResult<ServiceTreeService[]>> => {
        var result = new BusinessResult<ServiceTreeService[]>();
        if (name && name.length >= 0) {
            try {
                if (name !== undefined || name !== '') {
                    var response = await (await http.get<BusinessResult<ServiceTreeService[]>>(ApiBaseUrl + '/api/ServiceTree/services/name/' + name, await getHeaders()))?.parsedBody;
                    if (response !== undefined) {
                        result = response;
                    }
                }
            }
            catch (error: any) {
                var businessError: BusinessError = {
                    id: undefined,
                    message: error.message
                }
                result.result = undefined;
                result.businessErrors.push(businessError);
            }
        }
        return result;
    }

    getServiceReviewerTeams = async (serviceId: string): Promise<BusinessResult<ServiceReviewTeams>> => {
        var result = new BusinessResult<ServiceReviewTeams>();
        try {
            var response = await (await http.get<BusinessResult<ServiceReviewTeams> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/' + serviceId + '/reviewteams', await getHeaders()))?.parsedBody;
            if (response !== undefined) {
                result = response;
            }
        }
        catch (error: any) {
            var businessError: BusinessError = {
                id: undefined,
                message: error.message
            }
            result.result = undefined;
            result.businessErrors.push(businessError);
        }
        return result
    }

    getWorkItemsByService = async (serviceId: string): Promise<BusinessResult<OneCsWorkItem[]>> => {
        var result = new BusinessResult<OneCsWorkItem[]>();
        try {
            var response = await (await http.get<BusinessResult<OneCsWorkItem[]>>(ApiBaseUrl + '/api/ServiceTree/services/' + serviceId + '/workitems', await getHeaders()))?.parsedBody;
            if (response !== undefined) {
                result = response;
            }
        }
        catch (error: any) {
            var businessError: BusinessError = {
                id: undefined,
                message: error.message
            }
            result.result = undefined;
            result.businessErrors.push(businessError);
        }
        return result
    }

    getUserServiceSnapShot = async (date: ClientDateDetails): Promise<BusinessResult<ServiceTreeSnapshot[]> | undefined> => {
        try {
            if(date)
        {
            var response = await (await http.post<BusinessResult<ServiceTreeSnapshot[]> | undefined>(ApiBaseUrl + '/api/ServiceTree/services/snapshot', date, await getHeaders()))?.parsedBody;
            return response;
        }
        }
        catch (error: any) {

        }

    }
}

export default STServices;
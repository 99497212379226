import { ChoiceGroup, ComboBox, IChoiceGroupOption, IComboBox, IComboBoxOption, IDropdownStyles, Label, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { ReviewTypeStrings } from "../../Models/Constants/ReviewTypeStrings";
import { ReviewType } from "../../Models/Reviews/ReviewType";
import MeetingsService from "../../Services/MeetingsService";

interface IReviewTypeComponent {
    id?: string,
    loadingText?: string,
    placeholder?: string,
    selectedTmMeetingType?: string,
    label?: string,
    showThreatModelReview: boolean,
    showSecurityConsultation: boolean,
    onReviewTypeSelected: (selectedReviewType: string) => void,
}
export const ReviewTypeComponent: FC<IReviewTypeComponent> = (props: IReviewTypeComponent): JSX.Element => {

    let reviewService: MeetingsService = new MeetingsService();
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };
    const [isLoadingData, isLoadingDataSet] = useState<boolean>(false);
    const [isComponentLoadError, isComponentLoadErrorSet] = useState<boolean>(false);
    const [cmbReviewTypeOptions, cmbReviewTypeOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbSelectedReviewTypeOption, cmbSelectedReviewTypeOptionSet] = useState<string>();
    const [dataReviewTypes, dataReviewTypesSet] = useState<ReviewType[]>([]);
    useEffect(()=>{
        let tReviewTeams: IComboBoxOption[] = [];
        if (dataReviewTypes && dataReviewTypes.length > 0)
        {
            dataReviewTypes.forEach((reviewType: ReviewType) => {
                if (reviewType.id && reviewType.name)
                {
                    tReviewTeams.push({ key: reviewType.id, text: reviewType.name});
                }
            })
            cmbReviewTypeOptionsSet(tReviewTeams);
        }
        else
        {
            cmbReviewTypeOptionsSet([]);
        }
    },[dataReviewTypes]);

    const loadRevieweTypes = async () => {
        isLoadingDataSet(true);
        var reviewTypesResponse = await reviewService.getReviewTypes();
        if (reviewTypesResponse && !reviewTypesResponse.hasErrors && reviewTypesResponse.result && reviewTypesResponse.result.length > 0) {
            dataReviewTypesSet(reviewTypesResponse.result);

            if (props.selectedTmMeetingType && props.selectedTmMeetingType.length > 0)
            {
                var selectedReviewType = reviewTypesResponse.result.filter(x => x.id == props.selectedTmMeetingType);
                if (selectedReviewType && selectedReviewType.length > 0)
                {
                    cmbSelectedReviewTypeOptionSet(selectedReviewType[0].id);
                }
            }
        }
        else {
            isComponentLoadErrorSet(true);
        }

        isLoadingDataSet(false);
    }
    const onReviewTypeChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            cmbSelectedReviewTypeOptionSet(option.key as string);
            var selectedTeam = dataReviewTypes.filter(x => x.id == option?.key)
            if (selectedTeam && selectedTeam[0] && selectedTeam[0].id && selectedTeam[0].id.length > 0) {
                props.onReviewTypeSelected(selectedTeam[0].id);
            }
        }
    };

    useEffect(()=>{
        loadRevieweTypes();
    },[]);


    if (isLoadingData)
    {
        return <div className="row">
        <Label>{props.label}</Label>
        <Spinner size={SpinnerSize.large} label = {props.loadingText ? props.loadingText : "loading the review types."} />
        </div>
    }

    if (isComponentLoadError)
    {
        return <div className="row">
        <Label>{props.label}</Label>
        <Text>Could not load the review types.</Text>
        </div>
    }

    return (
        <ComboBox
            id={props.id}
            label={props.label}
            placeholder= {props.placeholder ?? "Select Review Type"}
            options={cmbReviewTypeOptions}
            selectedKey={cmbSelectedReviewTypeOption}
            styles={dropdownStyles}
            useComboBoxAsMenuWidth={true}
            onChange={onReviewTypeChange}
        />
    );
}
import { FC } from "react";
import HeadingComponent from "./HeadingComponent";
import errorimage from '../../assets/images/ErrorPageImage.png'

interface IErrorComponent {
    errorCode?: string
}

export const ErrorComponent: FC<IErrorComponent> = (props: IErrorComponent): JSX.Element => {

    const renderSwitch = () => {
        if (props.errorCode) {
            switch (props.errorCode) {
                default:
                    return <div className="row">
                        <div className="row">
                            <div className="d-flex">
                                <div className="align-self-center">
                                    <div className="row"> </div>
                                    <div className="row"> 
                                    <HeadingComponent title="Unknown Error" subtitle="Looks like something went wrong with the page." />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

            }
        }

        return <div className="row">
            <div className="col">
                <img src={errorimage} alt="Not found error" width={500} height={500} />
            </div>
            <div className="col">
                <div className="d-flex">
                    <div className="align-self-center">
                        <HeadingComponent title="404 - Page not found" subtitle="Looks like this page doesn’t exist or can’t be found. Make sure the URL is correct." />
                    </div>
                </div>

            </div>
        </div>
    }
    
    return (
        <div className="row" style={{ height: 800 }}>
            <div className="d-flex">
                <div className="align-self-center">
                    {renderSwitch()}
                </div>
            </div>
        </div>
    );
}
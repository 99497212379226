import { InteractionRequiredAuthError } from "@azure/msal-common";
import { msalInstance } from "..";
import { graphScopes, apiScopes, defaultApiScopes } from "../authConfig";

export async function getHeaders(methodName?: string) {
    
    if (methodName && methodName.length > 0)
    {
        console.log('Called from :::' + methodName);
    }

    var token = await getAPIMsalToken();
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', 'Bearer ' + token);
    requestHeaders.set('Accept', 'application/json');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Access-Control-Allow-Origin', '*');
    requestHeaders.set('Access-Control-Allow-Credentials', 'true');
    requestHeaders.set('Origin', window.location.origin);
    requestHeaders.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_SubscriptionKey!);

    return requestHeaders;
}

export async function getMultiPartHeaders() {
    var token = await getAPIMsalToken();
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', 'Bearer ' + token);
    // requestHeaders.set('Content-Type', 'multipart/form-data');
    requestHeaders.set('Origin', window.location.origin);
    requestHeaders.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_SubscriptionKey!);

    return requestHeaders;
}

export async function getGraphHeaders(eventual:boolean = false) {
    
    var token = await getGraphMsalToken();
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', 'Bearer ' + token);
    requestHeaders.set('Accept', 'application/json');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Access-Control-Allow-Origin', '*');
    requestHeaders.set('Access-Control-Allow-Credentials', 'true');
    requestHeaders.set('Origin', window.location.origin);

    if (eventual)
    {
        requestHeaders.set('ConsistencyLevel', 'eventual');
    }

    return requestHeaders;
}

export async function getGraphMsalToken() {
    try
    {
    msalInstance.setActiveAccount(await getActiveAccount());
    var result = await msalInstance.acquireTokenSilent(graphScopes);
    return result.accessToken;
    }
    catch (err)
    {
        console.log(err + ':: graph token')
        if (err instanceof InteractionRequiredAuthError) {
            console.log(err + ':: redirect to get token')
        }
        
        //window.location.href = "/";
        localStorage.clear();
        await msalInstance.loginRedirect(graphScopes);
    }
}

export async function getAPIMsalToken() {
    try
    {
    msalInstance.setActiveAccount(await getActiveAccount());
    var result = await msalInstance.acquireTokenSilent(apiScopes);
    return result.accessToken;
    }
    catch (err)
    {
        console.log(err + ':: msal token')
        if (err instanceof InteractionRequiredAuthError) {
            console.log(err + ':: redirect to get token')
        }
        
        localStorage.clear();
        await msalInstance.loginRedirect(apiScopes);
        //throw new TokenAcquireError();
    }
}

async function getActiveAccount() {
    var accounts = msalInstance.getAllAccounts();
    var account = accounts[0];
    return account;
}
export default {getHeaders, getGraphHeaders, getMultiPartHeaders};
import { createContext, Dispatch, FC, ReactNode,  useReducer } from "react";
import { CacheResult } from "../../Models/CacheResult";
import { ServiceTreeService } from "../../Models/ServiceTreeService";
import { Team } from "../../Models/Team";
import { UserMetadata } from "../../Models/UserMetadata";
import UserProfile from "../../Models/UserProfile";

export interface IGlobalState {
    userServiceTreeServies?: ServiceTreeService[],
    serviceReaders?: ServiceTreeService[],
    userRWTeams?: Team[],
    userROTeams?: Team[],
    userMetadata?: UserMetadata,
    userProfile?: UserProfile,
    initStateLoaded: boolean
}



interface IAppStateContext {
    appState : IGlobalState,
    appdispatch: Dispatch<any>
}

const initialState: IGlobalState = {
    userMetadata: undefined,
    userServiceTreeServies:[],
    serviceReaders: [],
    userRWTeams: [],
    userROTeams: [],
    userProfile: undefined,
    initStateLoaded: false,
};

const reducer = (state: IGlobalState, action: any) => {
      switch(action.Type)
      {
        case 'userMetaData':
            var newSt = action.payload['cacheResult'] as CacheResult;
            var newState: IGlobalState= { initStateLoaded: true}
            if(newSt && newSt.userMetadata)
            {
                newState.userMetadata = newSt.userMetadata
            }

            if(newSt.serviceTreeServices && newSt.serviceTreeServices.length > 0)
            {
                newState.userServiceTreeServies = newSt.serviceTreeServices
            }
            if(newSt.serviceReaders && newSt.serviceReaders.length > 0)
            {
                newState.serviceReaders = newSt.serviceReaders
            }

            if(newSt.userRWTeams && newSt.userRWTeams.length > 0)
            {
                newState.userRWTeams = newSt.userRWTeams
            }
            if(newSt.userROTeams && newSt.userROTeams.length > 0)
            {
                newState.userROTeams = newSt.userROTeams
            }

            var newUp = action.payload['userProfile'] as UserProfile;
            if(newUp)
            {
                newState.userProfile = newUp
            }

            console.log(newState);
            return newState;
            break;
        case 'userProfile':
            var newUp = action.payload as UserProfile;
            if(newUp)
            {
               return {...state, userProfile: newUp}
            }

            break;
        default:
            break;
      }

      return state;
  }

const initiAppState: IAppStateContext = {
    appState: initialState,
    appdispatch: ()=> undefined
}

const store = createContext(initiAppState);

const GlobalStateProvider : FC<ReactNode> = ({ children }) : JSX.Element =>
{
    const [state, dispatch] = useReducer(reducer, initialState);
    
    return(
        <store.Provider value={{appState:state, appdispatch: dispatch}}>
            {children}
        </store.Provider>
    );
}

export {GlobalStateProvider, store};
import { createTheme, Icon, IIconStyles, ITheme, Label, Separator, Stack, Text } from "@fluentui/react"
import { Editor } from "@tinymce/tinymce-react"
import { FC, useContext } from "react"
import { Link } from "react-router-dom"
import { Review, } from "../../Models/Reviews/Review"
import { getDateFormat } from "../../Utilities/dateutilities"
import { CopyToClipboard } from "./CopyToClipboard"
import { store } from "./GlobalStateProvider"
import { ReviewTasksnBugsComponent } from "./ReviewTasksnBugsComponent"
import UserProfile from "../../Models/UserProfile"
import { InternalNotesComponent } from "./InternalNotesComponent"
import { IconButton } from '@fluentui/react/lib/Button';

interface IReviewDataComponent {
    review: Review,
    userProfiles?: UserProfile[],
}

export const ReviewDataComponent: FC<IReviewDataComponent> = (props: IReviewDataComponent): JSX.Element => {

    var { appState, appdispatch } = useContext(store);
    const stackTokens = { childrenGap: 10, };

    const theme: ITheme = createTheme({
        fonts: {
            medium: {
                fontFamily: 'Monaco, Menlo, Consolas',
                fontSize: '12px',
            },
        },
    });
    const iconStyles: IIconStyles = {
        root: {
            fontSize: '24px',
            height: '24px',
            width: '24px',
        },
    };

    return (
        <div className="row" id={`review-${props.review?.id}`}>
        <Separator>
            {getDateFormat(props.review.meetingsData?.dateOfReview)}
        </Separator>
        <a href="#serviceSnapShotTop" style={{ textAlign: 'right' }}>
            <IconButton iconProps={{ iconName: 'Up' }} title="Move to top" ariaLabel="Move to top" />
        </a>
                <hr style={{ marginLeft: 50, marginRight: 50 }} />
        <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
            <div className="col-4">
                <div className="row"> <Label>Service Priority </Label></div>
                <div className="row"> <Text>{props.review.service?.sdlPrioritization} </Text></div>
            </div>

            <div className="col-4" >
                <div className="row"> <Label>Review Id </Label></div>
                <div className="row">
                    <Stack horizontal tokens={stackTokens}>

                        {
                            props.review.id && props.review.service && props.review.service.serviceId ? <Link to={"/reviewdetail/" + props.review.id + "/" + props.review.service.serviceId} target="_blank" rel="noopener noreferrer"> {props.review.easyReviewId} </Link> : <Text>{props.review.easyReviewId}</Text>
                        }

                        <CopyToClipboard text={props.review?.easyReviewId} />
                    </Stack>
                </div>
            </div>

            <div className="col-4">
                <div className="row"> <Label>Security Team </Label></div>
                <div className="row"> <Text>{props.review.securityReviewTeam?.name} </Text></div>
            </div>
        </div>

        <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
            <div className="row"> <Label>Notes to service team </Label></div>
            <div className="row">
                <div style={{padding:10}}>
                    {/* @ts-ignore*/}
                <Editor
                    init={{
                        skin: false,
                        content_css: false,
                        height: 150,
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                        table_default_attributes: {
                            border: '1'
                        },

                    }}
                    value={props.review?.notesToRequestor ? props.review?.notesToRequestor : ""}
                    disabled={true}
                />
                </div>
            </div>

        </div>

        {
            appState && appState.userMetadata && (appState.userMetadata.isReviewer || appState.userMetadata?.isAdmin) &&
            <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                <InternalNotesComponent reviewerNotes={props.review.reviewerNotes} userProfiles={props.userProfiles} />
            </div>
        }

        <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
            {
                props.review.tmReviewActions && props.review.tmReviewActions.length > 0 &&
                <div className="col">
                    <Label>Tasks and Bugs</Label>
                    <ReviewTasksnBugsComponent
                        workItems={props.review.tmReviewActions} />
                </div>
            }

        </div>
    </div>)
}
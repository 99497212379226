import { CommandBarButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { CSSProperties, FC, useState } from "react";
interface ISpinnerCommandBarButton {
    id?:string,
    text: string,
    onclick: any,
    iconName: string
    executingText?: string,
    style?:CSSProperties,
}
export const SpinnerCommandBarButton: FC<ISpinnerCommandBarButton> = (props: ISpinnerCommandBarButton): JSX.Element => {
    const [isRunningEvent,isRunningEventSet] = useState<boolean>(false);
    const onclick = async() =>
    {
        isRunningEventSet(true);
        try
        {
           await props.onclick();
        }
        catch
        {

        }
        isRunningEventSet(false);
    }

    if (isRunningEvent) {
        return <Spinner size={SpinnerSize.medium} label={props.executingText} style = {props.style} />;
    }

    return (
        <CommandBarButton aria-label={props.text} id={props.id} text={props.text} onClick={onclick} style = {props.style} iconProps={{iconName: props.iconName}} />
    );
}
import { ApiBaseUrl } from '../authConfig';
import { Artifact } from '../Models/TMPArtifacts/Artifact';
import { BusinessResult } from '../Models/common/BusinessResult';
import { getHeaders, getMultiPartHeaders } from '../Utilities/headers';
import * as http from '../Utilities/http'
import { Review } from '../Models/Reviews/Review';
import { LinkedArtifact } from '../Models/TMPArtifacts/LinkedArtifact';
import { ArtifactDownload } from '../Models/TMPArtifacts/ArtifactDownload';
import { ArtifactTypes } from '../Models/TMPArtifacts/ArtifactTypes';
import { ArtifactTypeStrings } from '../Models/Constants/ArtifactTypeStrings';

export class ArtifactService {
    uploadArtifactForMeeting = async (serviceId: string, meetingId: string, files: Artifact[]): Promise<BusinessResult<LinkedArtifact[]> | undefined> => {
        if (!serviceId || !meetingId || !files || !(files.length > 0)) {
            return;
        }

        var formData = new FormData();
        formData.append('serviceId', serviceId);
        formData.append('meetingId', meetingId);

        for (var i = 0; i < files.length; i++) {
            console.log(files[i].clientId! + ' - ' + files[i].uploadedFile!.name);
            formData.append(files[i].clientId!, files[i].uploadedFile!);
        }

        const response = await fetch(new Request(ApiBaseUrl + "/api/Artifact/upload", { method: "post", body: formData, headers: await getMultiPartHeaders() }));
        var data = response.json();
        return data;
    }

    createArtifactForService = async (serviceId: string, artifact: Artifact): Promise<BusinessResult<Artifact[]> | undefined> => {

        if (!artifact
            || !artifact.artifactTitle
            || !artifact.artifactDescription) {
            return;
        }

        var formData = new FormData();
        formData.append('serviceId', serviceId);
        formData.append('artifactTitle', artifact.artifactTitle ? artifact.artifactTitle as string : "");
        formData.append('artifactDescription', artifact.artifactDescription ? artifact.artifactDescription as string : "");
        formData.append('artifactType', artifact.artifactType ? artifact.artifactType : "");

        if (artifact.artifactType == ArtifactTypeStrings.File) {
            formData.append('filedata', artifact.uploadedFile!);
        }
        else if (artifact.artifactType == ArtifactTypeStrings.HyperLink) {
            formData.append('artifactUrl', artifact.artifactUrl ? artifact.artifactUrl : "");
        }

        console.log(artifact);
        console.log("=========formdata")
        console.log(formData);
        const response = await fetch(new Request(ApiBaseUrl + "/api/Artifact/create", { method: "post", body: formData, headers: await getMultiPartHeaders() }));
        var data = response.json();
        return data;
    }

    linkArtifactForMeeting = async (serviceId: string, meetingId: string, artifactIds: string[]): Promise<BusinessResult<LinkedArtifact[]> | undefined> => {
        if (!serviceId || !meetingId || !artifactIds || !(artifactIds.length > 0)) {
            return;
        }

        var payload = {
            serviceId: serviceId,
            reviewId: meetingId,
            artifactIds: artifactIds,
        }

        var response = await (await http.post<BusinessResult<LinkedArtifact[]> | undefined>(ApiBaseUrl + '/api/Artifact/link/review', payload, await getHeaders()))?.parsedBody;
        return response;
    }

    // unlinkArtifactForMeeting = async(serviceId: string, meetingId: string, artifactId: string): Promise<BusinessResult<LinkedArtifact[]> | undefined> => {

    // }

    fetchArtifactsForDownload = async (linkedArtifacts: LinkedArtifact[]): Promise<BusinessResult<Artifact[]> | undefined> => {
        if (linkedArtifacts && linkedArtifacts.length > 0) {
            var response = await (await http.post<BusinessResult<Artifact[]> | undefined>(ApiBaseUrl + '/api/Artifact/sasUrls', linkedArtifacts, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    getArtifactSasUrl = async (artifactId: string, serviceId: string): Promise<BusinessResult<string> | undefined> => {
        if (artifactId && serviceId) {
            var response= await (await http.getFile(ApiBaseUrl + '/api/Artifact/' + artifactId + '/' + serviceId + '/sasUrl', await getHeaders()));
            //To display the success message whenever file get download and warning message when file is empty
            if (response.fileName != 'download' && response.fileByte > 0) {
                let obj = {
                    result: response.fileName,
                    hasErrors: false,
                    businessErrors: []
                }
                return obj;
            }
            else if (response.fileByte <= 0) {
                let obj = {
                    result: undefined,
                    hasErrors: true,
                    businessErrors: [{ 'id': 0, 'message': "the downloaded artifact is empty" }]

                }
                return obj;
            }
            return undefined;
            // var response = await (await http.get<BusinessResult<string> | undefined>(ApiBaseUrl + '/api/Artifact/' + artifactId + '/' + serviceId + '/sasUrl', await getHeaders()))?.parsedBody;
            // return response;
        }
    }

    getArtifactSasUrlForReview = async (artifactId: string, serviceId: string, reviewId: string): Promise<BusinessResult<string> | undefined> => {
        if (artifactId && serviceId) {
            var response = await (await http.getFile(ApiBaseUrl + '/api/Artifact/' + artifactId + '/' + serviceId + '/' + reviewId + '/sasUrl', await getHeaders()));
            //To display the success message whenever file get download and warning message when file is empty
            if (response.fileName != 'download' && response.fileByte > 0) {
                let obj = {
                    result: response.fileName,
                    hasErrors: false,
                    businessErrors: []
                }
                return obj;
            }
            else if (response.fileByte <= 0) {
                let obj = {
                    result: undefined,
                    hasErrors: true,
                    businessErrors: [{ 'id': 0, 'message': "the downloaded artifact is empty" }]

                }
                return obj;
            }
            return undefined;
            // var response = await (await http.get<BusinessResult<string> | undefined>(ApiBaseUrl + '/api/Artifact/' + artifactId + '/' + serviceId + '/' + reviewId + '/sasUrl', await getHeaders()))?.parsedBody;
            //return response;
        }
    }

    fetchArtifactsByServiceId = async (serviceId: string): Promise<BusinessResult<Artifact[]> | undefined> => {
        if (serviceId) {
            var response = await (await http.get<BusinessResult<Artifact[]> | undefined>(ApiBaseUrl + '/api/Artifact/fetchArtifactsByServiceId/' + serviceId, await getHeaders()))?.parsedBody;
            return response;
        }
        else
            return;
    }

    getArtifactTypes = async (): Promise<BusinessResult<ArtifactTypes[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ArtifactTypes[]> | undefined>(ApiBaseUrl + '/api/Artifact/types', await getHeaders()))?.parsedBody;
        return response;
    }
}
import { CommandBarButton, ITag, Stack, TagPicker, ValidationState, Text, IBasePicker, IInputProps, IBasePickerSuggestionsProps } from "@fluentui/react";
import { createRef, FC, useEffect, useState } from "react";
import { TechTag } from "../../Models/TechTag";
import MeetingsService from "../../Services/MeetingsService";

interface ITechnologyTagsComponent
{   
    placeholder?: string
    selectedTechnologies: TechTag[],
    onTechTagSelected: (selectedTags : TechTag[]) => void,
}

export const TechnologyTagsComponent: FC<ITechnologyTagsComponent> = (props: ITechnologyTagsComponent) : JSX.Element =>{
    let reviewService: MeetingsService = new MeetingsService();

    const stackTokens = { childrenGap: 10, };
    
    const loadTechnologyTags = async () => {
        var tagsResult = await reviewService.getTechTags();
        if (tagsResult && !tagsResult.hasErrors && tagsResult.result && tagsResult.result.length > 0) {
            dataTechnologyTagsSet(tagsResult.result);
        }
    }
    useEffect(()=>{
        loadTechnologyTags();
    },[]);
    useEffect(()=>{
        if (props.selectedTechnologies && props.selectedTechnologies.length > 0)
        {   
            var tags: ITag[] = [];
            props.selectedTechnologies.forEach(tag => {
                if (tag && tag.id && tag.name){
                    tags.push(
                    {
                        key: tag.id,
                        name: tag.name
                    })
                }
            });
            selectedTechnologyTagsSet(tags);
        }
        else {
            if (selectedTechnologyTags && selectedTechnologyTags.length !== 0 && props.selectedTechnologies.length === 0) {
                selectedTechnologyTagsSet([]);
            }
        }
    },[props]);

    const tagPickerRef = createRef<IBasePicker<ITag>>();
    const [dataTechnologyTags, dataTechnologyTagsSet] = useState<TechTag[]>([]);
    const [selectedTechnologyTags, selectedTechnologyTagsSet] = useState<ITag[] | undefined>([]);
    const techTagsInputProps: IInputProps = {
        placeholder: props.placeholder
    };
    const pickerTechnologySuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Select or Add new',
        noResultsFoundText: 'No results found!',
        onRenderNoResultFound: () => {
            return (
                <Stack tokens={stackTokens}>
                    <Text variant="small">Type your tag and click add or hit 'Enter'.</Text>
                    <CommandBarButton iconProps={{ iconName: 'Add' }} text="Add new tag" onClick={() => {
                        tagPickerRef.current?.completeSuggestion(true);
                    }} />
                </Stack>
            );
        }
    };
    const onResolveTechnologySuggestions = async (
        filter: string,
        selectedItems: ITag[] | undefined
    ): Promise<ITag[]> => {

        if (filter) {
            var filteredTechTags = dataTechnologyTags.filter(x => x.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
            if (filteredTechTags && filteredTechTags.length > 0) {
                var tTags: ITag[] = [];
                filteredTechTags.forEach(tag => {
                    if (tag.id && tag.name) {
                        tTags.push({
                            key: tag.id,
                            name: tag.name
                        });
                    }
                })

                console.log(tTags);
                return tTags;
            }
        }
        return [];
    };
    const onTechnologyTagChange = (items?: ITag[] | undefined) => {
        if (items && items.length > 0) {
            if (items.length > dataTechnologyTags.length) {
                let newTags = items.filter(x => !dataTechnologyTags.some(y => y.id === x.key));
                if (newTags && newTags.length > 0) {
                    let tagsAdded = dataTechnologyTags.filter(x => x.id == newTags[0].key);

                    if (dataTechnologyTags.some(x => x.id === tagsAdded[0].id)) {
                        tagsAdded[0].isNew = false;
                    }
                    else{
                        tagsAdded[0].isNew = false;
                    }
                    

                    props.onTechTagSelected([...dataTechnologyTags, tagsAdded[0]]);
                }
            } else {
                var newSelection = dataTechnologyTags.filter(x => items.find(y => y.key == x.id));
                if (newSelection.length < items.length) {
                    var newTags = items.filter(object1 => {return !newSelection.some(object2 => { return object1.key === object2.id; }); })
                    if(newTags.length > 0)
                    {
                        newTags.forEach(item => {
                            var newTag: TechTag = { id: item.key as string, name: item.name, isNew: true };
                            newSelection.push(newTag);
                        });
                    }
                }    
                props.onTechTagSelected([...newSelection]);
            }
        }
        else {
            props.onTechTagSelected([]);
            selectedTechnologyTagsSet([]);
        }
    };
    function validateTechnologyTag(input: string): ValidationState {
        if (input.length > 0) {
            return ValidationState.valid;
        } else if (input.length > 100) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    }
    const getTechnologyTagTextFromItem = (item: ITag) => item.name;
    

    return (
        <TagPicker
            aria-label="Tachnology tags"
            componentRef={tagPickerRef}
            selectedItems = {selectedTechnologyTags}
            onResolveSuggestions={onResolveTechnologySuggestions}
            getTextFromItem={getTechnologyTagTextFromItem}
            pickerSuggestionsProps={pickerTechnologySuggestionsProps}
            inputProps={techTagsInputProps}
            onChange={onTechnologyTagChange}
            createGenericItem={(input: string) => {
                console.log("--generic " + input);
                return { key: crypto.randomUUID(), name: input } as ITag;
            }}
            onValidateInput={validateTechnologyTag}
        />
    );
}
import { Announced, Checkbox, ComboBox, CommandBarButton, Dropdown, IButtonStyles, IColumn, IDropdownOption, IDropdownStyles, Label, Pivot, PivotItem, PrimaryButton, TextField } from "@fluentui/react";
import { useForceUpdate } from "@fluentui/react-hooks";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ErrorCode } from "../../Models/common/Enums";
import { Team } from "../../Models/Team";
import { OnboardNewTeamPage } from "../../Pages/OnboardNewTeam";
import AdminServices from "../../Services/AdminServices";
import { store } from "../Common/GlobalStateProvider";
import HeadingComponent from "../Common/HeadingComponent";
import { LoadingIndicator } from "../Common/LoadingIndicatorComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";
import ActionsComponent from "../Home/ActionsComponent";

interface ITeamMetadataComponentProps {
    title: string
}

export const TeamMetadataComponent: FC<ITeamMetadataComponentProps> = (props: ITeamMetadataComponentProps): JSX.Element => {
    var { appState, appdispatch } = useContext(store);
    
    const [teamsMetadata, setTeamsMetadata] = useState<Team[]>([]);
    const [selectedTeamInfo, setSelectedTeamInfo] = useState<Team>();
    const [teamNameList, setTeamNameList] = useState<IDropdownOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    let adminService = new AdminServices();
    
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };

        //get teams data
    const fetchData = async() => {
        setIsLoading(true);
        var response = await adminService.fetchTeamsMetadata();
        if(response && response.result && !response.hasErrors)
        {
            setTeamsMetadata(response.result!);
            var tempteamNameList: IDropdownOption[] = [];
            response.result?.forEach(function (teamInfo){                   
                tempteamNameList.push({key: teamInfo.name!, text: teamInfo.name!});
            })
            if(tempteamNameList?.length > 0)
            {
                setTeamNameList(tempteamNameList);
            }
        }
        else
        {
            if (response && response.businessErrors && response.businessErrors.length > 0  && response.businessErrors[0].message)
            {
                displayMessagesSet([{message: response.businessErrors[0].message, messageType: DisplayMessageType.Error}])
            }
        }
        setIsLoading(false);
    }
    
    useEffect(() => {
        if (appState.initStateLoaded && appState.userMetadata?.isAdmin) {
            setIsAdmin(true);
            fetchData();
        }
        else
        {
            displayMessagesSet([{message: "You are not authorised to view this section", messageType: DisplayMessageType.Error}]);
        }
    }, [selectedTeamInfo]);

    function findArrayElementByTitle(array: any[], title: any) {
        return array.find((element) => {
          if (element.name === title.key)
          {
            setSelectedTeamInfo(element);
            return element;
          }
        });
    }

    const [displayMessages, displayMessagesSet] = useMessages();
    
    return (
        <>
        {isLoading && <LoadingIndicator label="Loading"/>}
        <div className="row">
            <MessageComponent messages={displayMessages} />
        </div>
        <div id="temInfoContainer" style={{ padding: 10 }} >
                {isAdmin && (
                    <>
                        <div className="row">
                            <ActionsComponent title="Actions" actions={[{ title: "Onboard a new team", iconName: "Add", navigationPath: "/onboard-team" }]} />
                        </div>
                        <div className="row">
                            <HeadingComponent title="Team Metadata" />
                            <div className="col-4">
                                <ComboBox
                                    label="Select dropdown to configure the values"
                                    placeholder="Select a team"
                                    options={teamNameList}
                                    styles={dropdownStyles}
                                    onChange={(e, selectedOption) => {
                                        findArrayElementByTitle(teamsMetadata, selectedOption);
                                    }}
                                />
                            </div>
                        </div>
                        {teamsMetadata && selectedTeamInfo && (
                            <OnboardNewTeamPage teamInfo={selectedTeamInfo} secGroups={selectedTeamInfo.securityGroups} />
                        )}
                    </>
                )}
        </div>
        </>
    )
}

export default TeamMetadataComponent;
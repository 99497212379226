import { Announced, Checkbox, ComboBox, IButtonStyles, IColumn, IDropdownOption, IDropdownStyles, Label, MessageBar, MessageBarType, PrimaryButton, Text, TextField } from "@fluentui/react";
import { FC, useContext, useEffect, useRef, useState } from "react"
import { TimeZones } from "../../Models/TimeZones/TimeZones";
import AdminServices from "../../Services/AdminServices";
import { store } from "../Common/GlobalStateProvider";
import HeadingComponent from "../Common/HeadingComponent";
import { LoadingIndicator } from "../Common/LoadingIndicatorComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";
import ActionsComponent from "../Home/ActionsComponent";

interface ITimeZoneConfigurationComponentProps {
    title: string
}

export const TimeZoneConfigurationComponent: FC<ITimeZoneConfigurationComponentProps> = (props: ITimeZoneConfigurationComponentProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addTimeZone, setAddTimeZone] = useState<boolean>(false);
    const [timeZonesList, setTimeZonesList] = useState<TimeZones[]>([]);
    const [timeZoneNameList, setTimeZoneNameList] = useState<IDropdownOption[]>([]);
    const [selectedTimeZoneInfo, setSelectedTimeZoneInfo] = useState<TimeZones>();
    const timeZones = useRef<TimeZones[]>([]); 

    const [newTimeZoneName, setNewTimeZoneName] = useState<string>('');
    const [newTimeZoneId, setNewTimeZoneId] = useState<string>('');
    const [isActiveTimeZone, setIsActiveTimeZone] = useState<boolean>(false);

    var { appState, appdispatch } = useContext(store);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    
   // const [supportsDL, setSupportsDL] = useState<boolean>(false);

    const iconButtonStyles: Partial<IButtonStyles> = { root: { marginBottom: 4, paddingTop: '30px'} };
    const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 'stretch' }  };
    
    let adminService = new AdminServices();

    useEffect(() => {
        const fetchTimeZones = async () => {
            setIsLoading(true);
            var response = await adminService.fetchTimeZoneInfo();
            if(response && response.hasErrors)
            {
                displayMessagesSet([{ message: response?.businessErrors[0].message!, messageType: DisplayMessageType.Error}]);
            }   
            else if(response && response.result)
            {
                timeZones.current = response?.result!;
                setTimeZonesList(response.result!);
                var temptimezoneNameList: IDropdownOption[] = [];
                response.result?.forEach(function (timezoneInfo){                   
                    temptimezoneNameList.push({key: timezoneInfo.displayName!, text: timezoneInfo.displayName!});
                })
                if(temptimezoneNameList?.length > 0)
                {
                    setTimeZoneNameList(temptimezoneNameList);
                }
            }
            setIsLoading(false);
          };

        if (appState.initStateLoaded && appState.userMetadata?.isAdmin) {
            setIsAdmin(true);
            fetchTimeZones();
        }
        else
        {
            displayMessagesSet([{message: "You are not authorised to view this section", messageType: DisplayMessageType.Error}]);
        }
    }, [])

    const onAddTimeZone = () => {
        setAddTimeZone(true);
        toggleAddTimeZoneView(false);
    }

    const toggleAddTimeZoneView = (show: boolean) => {
        var actionsDiv = document.getElementById("addTimeZone");
        if(actionsDiv)
        {
            if(show)
                actionsDiv.style.display = 'block';
            else
                actionsDiv.style.display = 'none';
        }
    }

    const saveNewTimeZone = async () => {
        resetMessages();
        if(newTimeZoneId && newTimeZoneName)
        {
            var newTimeZone: TimeZones = {
                displayName: newTimeZoneName,
                timeZoneId: newTimeZoneId,
                isActive: isActiveTimeZone,
              //  supportsDaylightSavingTime: supportsDL
            }
            var response = await adminService.addNewTimeZone(newTimeZone);
            if(response && response.result && !response.hasErrors)
            {
                setTimeZonesList(timeZonesList => [...timeZonesList, newTimeZone ]);
                setTimeZoneNameList(timeZoneNameList => [...timeZoneNameList, {key: newTimeZone.displayName!, text: newTimeZone.displayName!} ]);
                timeZones.current.push(newTimeZone);
                displayMessagesSet([{ message: "New time zone added successfully", messageType: DisplayMessageType.Information }]);
                toggleAddTimeZoneView(false);
                setAddTimeZone(false);
            }
            else
            {
                if (response && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message)
                displayMessagesSet([{message:response.businessErrors[0].message, messageType: DisplayMessageType.Error}]);
            }
        }
        else
        {
            displayMessagesSet([{message: "Time zone name or id cannot be null", messageType: DisplayMessageType.Error}]);
        }
        
    };

    const updateTimeZones = async() => {
        setIsLoading(true);
        resetMessages();
        if(!selectedTimeZoneInfo?.displayName || !selectedTimeZoneInfo.timeZoneId)
        {
            displayMessagesSet([{message: "Please provide required information", messageType: DisplayMessageType.Error}]);
            setIsLoading(false);
            return;
        }

        var updateTimeZone : TimeZones = {
            id: selectedTimeZoneInfo.id,
            timeZoneId: selectedTimeZoneInfo.timeZoneId,
            displayName: selectedTimeZoneInfo.displayName,
            isActive: selectedTimeZoneInfo.isActive,
            //  supportsDaylightSavingTime: selectedTimeZoneInfo.supportsDaylightSavingTime
        }
        var response = await adminService.updateTimeZoneInfo(updateTimeZone);
        if(response && response.result && !response?.hasErrors)
        {
            setTimeZonesList([...timeZonesList, updateTimeZone ]);
            setSelectedTimeZoneInfo(updateTimeZone);
            timeZones.current = timeZonesList;
            displayMessagesSet([{ message: "Time zone configuration updated successfully", messageType: DisplayMessageType.Information }]);
        }
        else
        {
            if (response && response.businessErrors && response.businessErrors[0] && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
        }
        setIsLoading(false);
    }

    function findArrayElementByTitle(array: any[], title: any) {
        return array.find((element) => {
          if (element.displayName === title.key)
          {
            setSelectedTimeZoneInfo(element);
            return element;
          }
        });
    }

    const resetMessages = () => {
        displayMessagesSet([]);
    }

    const [displayMessages, displayMessagesSet] = useMessages();

    return (
        <div className="container">
            <div className="row">
            <MessageComponent messages={displayMessages} />
            </div>
            <>
            {isLoading && <LoadingIndicator label="Loading"/>}
                <div className="row">
                        <div className="row" id="addTimeZone" onClick={onAddTimeZone}>
                            <div className="col" >
                                <ActionsComponent title="Actions"  actions={[{ title: "Add New Timezone", iconName: "Add", navigationPath: "" } ]}  />
                            </div>
                        </div>
                        {addTimeZone && (
                            <>
                             <div className="row">
                                <HeadingComponent title="Add New Timezone" />
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <TextField name ="timeZoneId"
                                            aria-labelledby="timeZoneId"
                                            label="Timezone Id"
                                            value={newTimeZoneId}
                                            required = {true}
                                            onChange = {(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {
                                                setNewTimeZoneId(newValue)
                                            }}}
                                        />
                                </div>
                                <div className="col-4">
                                    <TextField name ="timeZoneName"
                                            aria-labelledby="timeZoneName"
                                            label="Display Name"
                                            value={newTimeZoneName}
                                            required = {true}
                                            onChange = {(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {
                                                setNewTimeZoneName(newValue)
                                            }}}
                                        />
                                </div>
                                <div className="col-2">
                                    <Label title="isActiveTimeZone">Is Active?</Label>
                                        <Checkbox name="isActiveTimeZone" ariaLabelledBy="isActiveTimeZone" checked={isActiveTimeZone} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setIsActiveTimeZone(checked!);
                                    }}/>
                                </div>
                            </div>
                            <div className="row">
                                <PrimaryButton text="Add" onClick={saveNewTimeZone} style={{ padding: 10, marginTop: 20, marginBlock: 20, marginLeft: 12, width: 20 }}/>
                            </div>
                            <hr/>
                            </>
                        )}
                </div>
                
                {/* <hr style={{width: '360px'}}/> */}
                {isAdmin && (
                <div className="row">
                    <ComboBox
                        label="Select dropdown to configure the values"
                        placeholder="Select a team"
                        options={timeZoneNameList}
                        styles={dropdownStyles}
                        onChange={(e, selectedOption) => {
                            findArrayElementByTitle(timeZonesList, selectedOption);
                            resetMessages();
                        }}
                    />
                </div>
                )}
                <br/>
                {isAdmin && timeZonesList && selectedTimeZoneInfo && (
                    <>
                    <div className="row">
                        <div className="col-2">
                            <Label title="TimeZone Id">TimeZone id</Label>
                            <Text>{selectedTimeZoneInfo?.timeZoneId}</Text>
                        </div>
                        <div className="col-4">
                        <Label title="Display Name">Display name</Label>
                            <Text>{selectedTimeZoneInfo?.displayName}</Text>
                        </div>
                        <div className="col-2">
                            <Label title="IsActive">Is active?</Label>
                                <Checkbox name="isActive" ariaLabelledBy="isActive" checked={selectedTimeZoneInfo?.isActive} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                    setSelectedTimeZoneInfo(selectedTimeZoneInfo => ({
                                        ...selectedTimeZoneInfo,
                                        isActive: checked
                                    }))}}
                                />
                        </div>
                        <div className="col">
                            <Label title="supportsDaylightSavingTime">Supports daylight saving time?</Label>
                                <Text>{selectedTimeZoneInfo.supportsDaylightSavingTime == true ? "Yes" : "No"}</Text>
                        </div>
                    </div>
                    <div className="row">
                        <PrimaryButton text="Save" onClick={updateTimeZones}  style={{ padding: 10, marginTop: 20, marginLeft: 10, width: 20 }}/>
                    </div>
                    </>
                )}
            </>
        </div>
    )
}
import { InteractionStatus } from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Component, FC, useEffect } from "react";
import { defaultApiScopes, uiDefaultScopes, apiScopes, graphScopes } from "../authConfig";
export const MsalRdirectLoginComponent: FC = (): JSX.Element => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    useEffect(()=> {

        // if (accounts.length === 0 && inProgress === 'none') {
        //     instance.loginRedirect(apiScopes);
        // }

        if(!isAuthenticated && InteractionStatus.None === inProgress)
            instance.loginRedirect(apiScopes).catch(e => 
                {
                    console.log(e);
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(apiScopes);
                      }
                });
    }, []);

    return(
        <></>
    );
}

export default MsalRdirectLoginComponent;
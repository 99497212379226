import { ConstrainMode, DetailsList, DetailsListLayoutMode, FontIcon, IColumn, SelectionMode, Stack, StackItem, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClientDateDetails } from "../../Models/Reviews/ClientDateDetails";
import { Review } from "../../Models/Reviews/Review";
import MeetingsService from "../../Services/MeetingsService";
import { getDateFormat, getTimeFormat } from "../../Utilities/dateutilities";
import HeadingComponent from '../Common/HeadingComponent'
import { LoadingIndicator } from "../Common/LoadingIndicatorComponent";
import { MeetingTimeCalloutComponent } from "../Common/MeetingTimeCalloutComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";

interface IUpComingReviewsComponentProps {
    title: string,
    subtitle?: string
}

export const UpComingReviewsComponent: FC<IUpComingReviewsComponentProps> = (props: IUpComingReviewsComponentProps): JSX.Element => {
    let meetingsService = new MeetingsService();
    const [minWidth] = useState(160);
    const stackTokens = { childrenGap: 10, };

    const [isLoading, isLoadingSet] = useState<boolean>(false);
    const [isNoReviewsFound, isNoReviewsFoundSet] = useState<boolean>(false);

    const [dataUpComingMeetings, dataUpComingMeetingsSet] = useState<Review[]>([]);
    const [pastMeetingsColumns, pastMeetingsColumnsSet] = useState<IColumn[]>(
        [
            {
                key: 'ReviewId',
                name: 'Review Id',
                minWidth: 150
            },
            {
                key: 'ServiceName',
                name: 'Service Name',
                minWidth: 200
            },
            {
                key: 'ReviewType',
                name: 'Review Type',
                minWidth: 120
            },
            {
                key: 'MeetingDate',
                name: 'Meeting Date Time',
                minWidth: 200
            },
            {
                key: 'SecurityTeam',
                name: 'Security Team',
                minWidth: 200
            },
            {
                key: 'QuickActions',
                name: 'Actions ',
                minWidth: minWidth
            },
        ]
    );

    const loadReviews = async () => {
        isLoadingSet(true);
        var d = new Date();
        const clientDate: ClientDateDetails = {
            clientDateTime: d,
            clientOffsetMinutes: d.getTimezoneOffset(),
        };
        var result = await meetingsService.reviewerUpcomingMeetings(clientDate);

        if (result && !result.hasErrors && result.result && result.result.length > 0) {
            const distantFuture = new Date(8640000000000000);
            var meetingData = result.result.sort((a, b) => {
                let dateA: Date = a.meetingsData?.dateOfReview ? new Date(a.meetingsData.dateOfReview) : distantFuture;
                let dateB: Date = b.meetingsData?.dateOfReview ? new Date(b.meetingsData.dateOfReview) : distantFuture;
                return dateA.getTime() - dateB.getTime();
            });
            var meetingDataWithLocalTime = await meetingsService.loadLocalTimeForReviews(meetingData);
            dataUpComingMeetingsSet(meetingDataWithLocalTime);
        }
        else if (result && !result.hasErrors && result.result && result.result.length == 0) {
            isNoReviewsFoundSet(true);
        }
        else {
            if (result && result.hasErrors && result.businessErrors && result.businessErrors.length > 0 && result.businessErrors[0].message) {
                displayMessagesSet([{ message: result.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "Could not fetch review(s).", messageType: DisplayMessageType.Error }]);
            }
        }

        isLoadingSet(false);
    }

    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var currentReviewItem = item as Review;
        if (column && currentReviewItem) {
            switch (column!.key) {
                case 'ServiceName':
                    return <div title={currentReviewItem.service?.serviceName}>{currentReviewItem.service?.serviceName}</div>;
                case 'ReviewId':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            {currentReviewItem.id && currentReviewItem.id.length > 0 &&
                                currentReviewItem.service && currentReviewItem.service.serviceId &&
                                <Link to={"/reviewdetail/" + currentReviewItem.id + "/" + currentReviewItem.service?.serviceId}>{currentReviewItem.easyReviewId}</Link>
                            }
                        </Stack>
                    </div>;
                case 'ReviewType':
                    return <div title={currentReviewItem.reviewType}>{currentReviewItem.reviewType}</div>;
                case 'MeetingDate':
                    return <Stack horizontal tokens={stackTokens}>
                        <StackItem>
                            {getDateFormat(currentReviewItem.meetingsData?.browserLocalTime) + '-' + getTimeFormat(currentReviewItem.meetingsData?.browserLocalTime)}
                        </StackItem>
                        <StackItem>
                        <MeetingTimeCalloutComponent meetingData={currentReviewItem.meetingsData} />
                        </StackItem>
                    </Stack>;
                case 'SecurityTeam':
                    return <div title={currentReviewItem.securityReviewTeam?.name}>{currentReviewItem.securityReviewTeam?.name}</div>;
                case 'QuickActions':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            {currentReviewItem.id && currentReviewItem.id.length > 0 &&
                                currentReviewItem.service && currentReviewItem.service.serviceId &&
                                <Link to={"/reviewdetail/" + currentReviewItem.id + "/" + currentReviewItem.service?.serviceId} target="_blank" rel="noopener noreferrer"><FontIcon iconName="PreviewLink" title="View Meeting" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} /></Link>
                            }
                        </Stack>
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    function reset() {
        dataUpComingMeetingsSet([]);
        isNoReviewsFoundSet(false);
        isLoadingSet(false);
        displayMessagesSet([]);
    }

    useEffect(() => {
        reset();
        loadReviews();

    }, []);

    const [displayMessages, displayMessagesSet] = useMessages();

    return (
        <>
            <div className="container" style={{ padding: 10 }} >
                <div className="row">
                    <div className="d-flex">
                        <div className="me-auto">
                            <HeadingComponent title={props.title} subtitle={props.subtitle} />
                        </div>
                        <Stack horizontal tokens={stackTokens} style={{ cursor: 'pointer', color: 'blue', marginTop: 20, marginRight: 140 }}
                            onClick={() => {
                                reset();
                                loadReviews();
                            }}>
                            <FontIcon iconName="Refresh" title="Refresh" />
                            <Text variant="medium">Refresh</Text>
                        </Stack>
                    </div>
                </div>
                {isLoading && <LoadingIndicator label="Loading" />}
                <div className="row">
                    <MessageComponent messages={displayMessages} />
                </div>
                <div className="row">
                    {
                        isNoReviewsFound ? <Text>No Review(s) Found</Text> :
                            <DetailsList
                                items={dataUpComingMeetings}
                                columns={pastMeetingsColumns}
                                onRenderItemColumn={_renderItemColumn}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                constrainMode={ConstrainMode.horizontalConstrained}
                            />
                    }
                </div>
            </div>
        </>
    );
}

export default UpComingReviewsComponent;
import { addDays, addYears, DatePicker, DefaultButton, defaultDatePickerStrings, Label, Stack, TextField } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { SearchFilter } from "../../Models/Search/SearchFilter";
import { TeamsType } from "../../Models/Search/TeamsType";
import ServiceTreeService from "../../Models/ServiceTreeService";
import { Team } from "../../Models/Team";
import { TechTag } from "../../Models/TechTag";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";
import { ReviewTypeMultiSelectComponent } from "../Common/ReviewTypeMultiSelectComponent";
import { ServiceReviewTeamsComponent } from "../Common/ServiceReviewTeamsComponent";
import { ServiceTreeServicePickerComponent } from "../Common/ServiceTreeServicePickerComponent";
import { SpinnerPrimaryButton } from "../Common/SpinnerPrimaryButton";
import { TeamsListMultiSelectComponent } from "../Common/TeamsListMultiSelectComponent";
import { UserMultiReviewTeamsComponent } from "../Common/UserMultiReviewTeamsComponent";
import { ReviewStatesMultiSelectComponent } from "./ReviewStatesMultiSelectComponent";

interface ISearchComponent {
    id?: string,
    DisplayTeamsType: TeamsType,
    onSearchClicked: (searchCriterial: SearchFilter) => void,
    userSelectedTeam?: Team,
}

export const SearchComponent: FC<ISearchComponent> = (props: ISearchComponent): JSX.Element => {

    const today: Date = new Date(new Date());
    const minDate = addDays(today, +1);
    const maxDate = addYears(today, 5);

    const [dataSelectedServiceTreeService, dataSelectedServiceTreeServiceSet] = useState<ServiceTreeService[]>([]);
    const [dataSelectedTeams, dataSelectedTeamsSet] = useState<Team[]>([]);
    const [dataSelectedReviewStates, dataSelectedReviewStatesSet] = useState<string[]>([]);
    const [easyReviewId, easyReviewIdSet] = useState<string>();
    const [dataTechnologyTags, dataTechnologyTagsSet] = useState<TechTag[]>([]);
    const [dataReviewTypes, dataReviewTypesSet] = useState<string[]>([]);

    const [startDate, startDateSet] = useState<Date | undefined>();
    const [endDate, endDateSet] = useState<Date | undefined>();

    const [displayMessages, displayMessagesSet] = useMessages();
    
    useEffect(() => {
        if (props.userSelectedTeam && props.userSelectedTeam.id)
        {
            dataSelectedTeamsSet([{id: props.userSelectedTeam.id}])
        }
    },[props.userSelectedTeam])
    
    const resetFields = () => {
        startDateSet(undefined);
        endDateSet(undefined);
        dataTechnologyTagsSet([]);
        easyReviewIdSet("");
        dataSelectedReviewStatesSet([]);
        dataSelectedTeamsSet([]);
        dataSelectedServiceTreeServiceSet([]);
        dataReviewTypesSet([]);
        displayMessagesSet([])
    }

    const onSearchButtonClicked = () => {
        if ((easyReviewId == undefined || easyReviewId == "") && startDate == undefined && endDate == undefined && dataSelectedTeams.length == 0 && dataSelectedReviewStates.length == 0 && dataSelectedServiceTreeService.length == 0 && dataTechnologyTags.length == 0 && dataReviewTypes.length == 0)
        {
            displayMessagesSet([{ message: "Please select valid filters for search.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var searchCriteria: SearchFilter = {
            easyReviewId: easyReviewId,
            endDate: endDate,
            startDate: startDate,
            reviewStatuses: dataSelectedReviewStates,
            serviceNames: dataSelectedServiceTreeService,
            teams: dataSelectedTeams,
            technologyTags: dataTechnologyTags,
            reviewTypes: dataReviewTypes
        };

        props.onSearchClicked(searchCriteria);
        displayMessagesSet([]);
    }

    return (
    
        <div className="container">
        <div className="row">
            <MessageComponent messages={displayMessages} />
        </div>

    <div className="row">
        <div className="row">
            <div className="col">
                <div className="row">
                    <Label>Service Name(s)</Label>
                </div>
                <div className="row">
                    <ServiceTreeServicePickerComponent
                        itemLimit={50}
                        id={props.id + "_servicepicker"}
                        placeholder="Search by service names"
                        SelectedServices={dataSelectedServiceTreeService ? [...dataSelectedServiceTreeService] : []}
                        onServiceSelected={(selectedServices) => {
                            if (selectedServices && selectedServices.length > 0) {
                                dataSelectedServiceTreeServiceSet(selectedServices);
                            }
                            else {
                                dataSelectedServiceTreeServiceSet([]);
                            }
                        }} />
                </div>

            </div>
            <div className="col">
                {
                    props.DisplayTeamsType == TeamsType.UserTeams &&
                    <UserMultiReviewTeamsComponent
                        id={props.id + "_userreviewteamsselector"}
                        label="Review team(s)"
                        placeholder="Search by review teams"
                        selectedTeamIds= {dataSelectedTeams}
                        onTeamsSelected={(selectedTeams: Team[]) => {
                            if (selectedTeams && selectedTeams.length > 0) {
                                dataSelectedTeamsSet(selectedTeams);
                            }
                            else {
                                dataSelectedTeamsSet([]);
                            }

                        }} />
                }
                {
                    props.DisplayTeamsType == TeamsType.AllTeams &&
                    <TeamsListMultiSelectComponent
                        id={props.id + "_allreviewteamsselector"}
                        label="Review team(s)"
                        placeholder="Search by review teams"
                        selectedTeamIds={dataSelectedTeams}
                        onTeamsSelected={(selectedTeams: Team[]) => {
                            if (selectedTeams && selectedTeams.length > 0) {
                                dataSelectedTeamsSet(selectedTeams);
                            }
                            else {
                                dataSelectedTeamsSet([]);
                            }

                        }} />
                }
            </div>
            <div className="col">
                <ReviewStatesMultiSelectComponent
                    id={props.id + "_reviewstatesselector"}
                    label="Review State(s)"
                    placeholder="Search by review states"
                    selectedReviewStates={dataSelectedReviewStates}
                    onReviewStateSelected={(selectedStates: string[]) => {
                        if (selectedStates && selectedStates.length > 0) {
                            dataSelectedReviewStatesSet(selectedStates);
                        } else {
                            dataSelectedReviewStatesSet([]);
                        }
                    }} />
            </div>
        </div>
        <div className="row">

            <div className="col">
                <TextField
                    id={props.id + "_reviewId"}
                    label="Review Id"
                    placeholder="Search by review id"
                    value={easyReviewId}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        if (newValue != undefined) { easyReviewIdSet(newValue) }
                    }}>

                </TextField>
            </div>
            <div className="col">
                <div className="row">
                    <div className="col">
                        <DatePicker
                            id={props.id + "_startDate"}
                            label="Start Date"
                            value={startDate}
                            placeholder="Select a start slot date for the review"
                            ariaLabel="Select a start date"
                            onSelectDate= {startDateSet as (date: Date | undefined | null) => void }
                            strings={defaultDatePickerStrings}
                        />
                    </div>
                    <div className="col">
                        <DatePicker
                            id={props.id + "_endDate"}
                            label="End Date"
                            value={endDate}
                            placeholder="Select a end slot date for the review"
                            ariaLabel="Select a end date"
                            onSelectDate={endDateSet as (date: Date | undefined | null) => void }
                            minDate={startDate}
                            strings={defaultDatePickerStrings}
                        />
                    </div>
                </div>
            </div>
            <div className="col">
                <Label>Review Type(s)</Label>
                <ReviewTypeMultiSelectComponent 
                    id={props.id + "_reviewTypeSelector"}
                    selectedReviewTypes={dataReviewTypes} 
                    placeholder="Search by review types"
                    onReviewTypeSelected={(selectedReviewTypes: string[]) => {
                        if (selectedReviewTypes && selectedReviewTypes.length > 0)
                        {
                            dataReviewTypesSet(selectedReviewTypes);
                        }
                        else
                        {
                            dataReviewTypesSet([]);
                        }
                    } 
                }/>
            </div>
        </div>
        <div className="row">
            <div className="d-flex" style={{ marginTop: 20 }}>
                <Stack className="mx-auto" horizontal tokens={{ childrenGap: 10 }}>
                    <SpinnerPrimaryButton id={props.id + "_searchButton"} text="Search" executingText="searching" onclick={async () => {await onSearchButtonClicked();}} />
                    <DefaultButton text="Clear" onClick={resetFields} />
                </Stack>
            </div>
        </div>
    </div>
    </div>
    )
}
import React, { useEffect, useState } from "react";
import { FC } from "react";
import {Pivot, PivotItem} from "@fluentui/react";
import {DesignQuestionnairePage}  from "./DesignQuestionnaire";
import {PreviewQuestionnaire} from "./PreviewQuestionnaire";
import { UserReviewTeamsComponent } from "../../Components/Common/UserReviewTeamsComponent";
import { Team } from "../../Models/Team";
import { QuestionnaireService } from "../../Services/QuestionnaireService";
import { LoadingIndicator } from "../../Components/Common/LoadingIndicatorComponent";
import HeadingComponent from "../../Components/Common/HeadingComponent";
import { Questions } from "../../Models/Questionnaire/Question";

export const QuestionnaireHomePage: FC = (): JSX.Element => {

    let queService = new QuestionnaireService();
    
    const [dataSelectedTeam, setDataSelectedTeam] = useState<Team>();
    const [teamQuestionsList, setTeamQuestionsList] = useState<Questions[]>([]);
    const [questionnaireId, setQuestionnaireId] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if(dataSelectedTeam && dataSelectedTeam.id)
        {
            fetchQuestionnaire(dataSelectedTeam.id);
        }
    }, [dataSelectedTeam]);

    const fetchQuestionnaire = async(teamId: string) => {
        setIsLoading(true);
        var queData = await queService.fetchQuestionnaire(teamId);
        if (queData && !queData.hasErrors && queData.result && queData.result.id && queData.result.questions)
        {
            setQuestionnaireId(queData.result.id);
            setTeamQuestionsList(queData.result.questions);
            setIsLoading(false);
        }
        else
        {
           setQuestionnaireId("");
            setTeamQuestionsList([]);
            setIsLoading(false);
        }
    }

    return (
        <div className="container" style={{ padding: 10 }}>
            <div className="row">
                <HeadingComponent title="Service Questionnaire" subtitle="Design questionnaire for your service." />
            </div>
            <div className="row" style={{width: 300, paddingBottom: 20}}>
                <UserReviewTeamsComponent
                    label="Review Team"
                    placeholder="Select a Review team"
                    onTeamSelected={(selectedTeam?: Team) => {
                        if (selectedTeam) {
                            setDataSelectedTeam(selectedTeam);
                        }
                }} />
            </div>
            {isLoading && <LoadingIndicator label="Loading"/>}
            {dataSelectedTeam && (
            <div className="row">
                <Pivot aria-label="Count and Icon Pivot Example" style={{ minHeight: 1076 }}  >
                    <PivotItem headerText="Design" itemIcon="SurveyQuestions">
                        <DesignQuestionnairePage questionsList={teamQuestionsList} questionnaireId={questionnaireId} teamId= {dataSelectedTeam.id} onQuestionsUpdated={(questions: Questions[]) => {
                                    setTeamQuestionsList(questions);
                                }}/>
                    </PivotItem>
                    <PivotItem headerText="Preview" itemIcon="Preview">
                        <br/>
                        <PreviewQuestionnaire isPreview={true} isDesign={false} isUserView={false} questionsList={teamQuestionsList}/>
                    </PivotItem>
                </Pivot>
            </div>)}
        </div>
    )
}
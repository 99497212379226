import { ApiBaseUrl } from "../authConfig";
import { ReviewCategory } from "../Models/Admin/ReviewCategory";
import { BusinessError } from "../Models/common/BusinessError";
import { BusinessResult } from "../Models/common/BusinessResult";
import { getHeaders } from "../Utilities/headers";
import { Team } from "../Models/Team";
import * as http from '../Utilities/http'
import { TimeZones } from "../Models/TimeZones/TimeZones";
import { Collection } from "typescript";
import { GraphSecurityGroup } from "../Models/Graph/Groups/GraphSecurityGroup";
import { TimeOff } from "../Models/Reviews/TimeOff";
import { Schedule } from "../Models/Reviews/Schedule";
import { Review } from "../Models/Reviews/Review";
import { PortalConfiguration } from "../Models/Admin/PortalConfiguration";
import { AllowedApps } from "../Models/Admin/AllowedApps";
import { AppXDiv } from "../Models/Admin/AppXDiv";
import { ServiceReader } from "../Models/ServiceReader";

export class AdminServices{
    validateTimeOffs = async (timeOff: TimeOff): Promise<BusinessResult<Review[]> | undefined> => {
        var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Admin/timeoff/validate', timeOff, await getHeaders()))?.parsedBody;
        return response;
    }   

    addScheduleToTeam = async (schedule: Schedule): Promise<BusinessResult<Schedule> | undefined> => {
        var response = await (await http.post<BusinessResult<Schedule> | undefined>(ApiBaseUrl + '/api/Admin/schedule/add', schedule, await getHeaders()))?.parsedBody;
        return response
    }

    addTimeOffs = async (timeOff: TimeOff): Promise<BusinessResult<TimeOff> | undefined> => {
        var response = await (await http.post<BusinessResult<TimeOff> | undefined>(ApiBaseUrl + '/api/Reviews/timeoff/add', timeOff, await getHeaders()))?.parsedBody;
        return response;
    }

    getActiveTimeOffs = async (teamId: string): Promise<BusinessResult<TimeOff[]> | undefined> => {
        var response = await (await http.get<BusinessResult<TimeOff[]> | undefined>(ApiBaseUrl + '/api/Admin/timeoffs/' + teamId, await getHeaders()))?.parsedBody;
        return response
    }

    getActiveSchedules = async (teamId: string): Promise<BusinessResult<Schedule[]> | undefined> => {
        var response = await (await http.get<BusinessResult<Schedule[]> | undefined>(ApiBaseUrl + '/api/Admin/schedules/' + teamId, await getHeaders()))?.parsedBody;
        return response
    }
    
    getCategory = async (): Promise<BusinessResult<ReviewCategory> | undefined> =>{
        var response = await (await http.get<BusinessResult<ReviewCategory> | undefined>(ApiBaseUrl + '/api/Admin/getCategory/', await getHeaders()))?.parsedBody;
        return response;
    }

    insertUpdateCategory = async(categoryList: ReviewCategory): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.post<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Admin/insertUpdateCategory/', categoryList, await getHeaders()))?.parsedBody;
        return response;
    }

    fetchTeamMetadataById = async (teamId: string): Promise<BusinessResult<Team> | undefined> =>{
        var response = await (await http.get<BusinessResult<Team> | undefined>(ApiBaseUrl + '/api/Admin/teamMetadata/' + teamId, await getHeaders()))?.parsedBody;
        return response;
    }

    fetchTeamsMetadata = async (): Promise<BusinessResult<Team[]> | undefined> =>{
        var response = await (await http.get<BusinessResult<Team[]> | undefined>(ApiBaseUrl + '/api/Admin/fetchTeamsInfo', await getHeaders()))?.parsedBody;
        return response;
    }

    onboardNewTeam = async (teamInfo : Team): Promise<BusinessResult<Team> | undefined> => {
        var response = await (await http.post<BusinessResult<Team> | undefined>(ApiBaseUrl + '/api/Admin/onboardNewTeam', teamInfo, await getHeaders()))?.parsedBody;
        return response;
    }

    updateTeamsMetadata = async (teamInfo : Team): Promise<BusinessResult<Team> | undefined> => {
        var response = await (await http.post<BusinessResult<Team> | undefined>(ApiBaseUrl + '/api/Admin/updateTeamsInfo', teamInfo, await getHeaders()))?.parsedBody;
        return response;
    }

    fetchTimeZoneInfo = async() : Promise<BusinessResult<TimeZones[]> | undefined> => {
        var response = await (await http.get<BusinessResult<TimeZones[]> | undefined>(ApiBaseUrl + '/api/Admin/fetchAllTimeZones', await getHeaders()))?.parsedBody;
        return response;
    }

    addNewTimeZone = async(timeZoneInfo: TimeZones) : Promise<BusinessResult<TimeZones> | undefined> => {
        var response = await (await http.post<BusinessResult<TimeZones> | undefined>(ApiBaseUrl + '/api/Admin/addTimeZone', timeZoneInfo, await getHeaders()))?.parsedBody;
        return response;
    }

    updateTimeZoneInfo = async(timeZoneInfo: TimeZones) : Promise<BusinessResult<TimeZones> | undefined> => {
        var response = await (await http.post<BusinessResult<TimeZones> | undefined>(ApiBaseUrl + '/api/Admin/updateTimeZones', timeZoneInfo, await getHeaders()))?.parsedBody;
        return response;
    }

    getSecurityGroup = async(groupName: string): Promise<BusinessResult<GraphSecurityGroup> | undefined> => {
        var response = await (await http.get<BusinessResult<GraphSecurityGroup> | undefined>(ApiBaseUrl + '/api/Admin/getSecurityGroup/' + groupName, await getHeaders()))?.parsedBody;
        return response;
    }

    fetchPortalConfig = async (): Promise<BusinessResult<PortalConfiguration> | undefined> => {
        var response = await (await http.get<BusinessResult<PortalConfiguration> | undefined>(ApiBaseUrl + '/api/Reviews/fetchPortalConfig' ,await getHeaders()))?.parsedBody;
        return response;
    }

    savePortalConfig = async (portalConfig: PortalConfiguration): Promise<BusinessResult<PortalConfiguration> | undefined> => {
        var response = await (await http.post<BusinessResult<PortalConfiguration> | undefined>(ApiBaseUrl + '/api/Admin/updatePortalConfig', portalConfig ,await getHeaders()))?.parsedBody;
        return response;
    }

    getAllowedApps = async (): Promise<BusinessResult<AllowedApps[]> | undefined> => {
        var response = await (await http.get<BusinessResult<AllowedApps[]> | undefined>(ApiBaseUrl + '/api/Admin/endpoints/allowedapps' ,await getHeaders()))?.parsedBody;
        return response;
    }

    addAllowedApps = async (appId:string): Promise<BusinessResult<boolean> | undefined> => {
        let app: AppXDiv = {
            appId: appId
        };
        
        var response = await (await http.put<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Admin/endpoints/allowedapps', app ,await getHeaders()))?.parsedBody;
        return response;
    }

    addXDiv = async (appId:string, xDivId: string, allServices:boolean): Promise<BusinessResult<boolean> | undefined> => {
        let app: AppXDiv = {
            appId: appId,
            xDivId: xDivId,
            allowAllApps: allServices
        };
        
        var response = await (await http.put<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Admin/endpoints/addxdiv', app ,await getHeaders()))?.parsedBody;
        return response;
    }

    removeXDiv = async (appId:string, xDivId: string): Promise<BusinessResult<boolean> | undefined> => {
        let app: AppXDiv = {
            appId: appId,
            xDivId: xDivId
        };
        
        var response = await (await http.removeWithBody<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Admin/endpoints/removexdiv', app ,await getHeaders()))?.parsedBody;
        return response;
    }

    removeAppId =async (appId:string): Promise<BusinessResult<boolean> | undefined> => {
        let app: AppXDiv = {
            appId: appId
        };
        
        var response = await (await http.removeWithBody<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Admin/endpoints/removeappid', app ,await getHeaders()))?.parsedBody;        
        return response;
    }

    getServiceReaders = async (): Promise<BusinessResult<ServiceReader[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ServiceReader[]> | undefined>(ApiBaseUrl + '/api/Admin/accessManager/getservicereaders' ,await getHeaders()))?.parsedBody;
        return response;
    }

    addServiceReader = async (serviceReaders:ServiceReader): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.put<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Admin/accessManager/addservicereader', serviceReaders ,await getHeaders()))?.parsedBody;
        return response;
    }

    removeServiceReader =async (serviceReaders:ServiceReader): Promise<BusinessResult<boolean> | undefined> => {      
        var response = await (await http.removeWithBody<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Admin/accessManager/deleteservicereader', serviceReaders ,await getHeaders()))?.parsedBody;        
        return response;
    }
}

export default AdminServices;
import { Icon } from "@fluentui/react";
import { FC } from "react";
import { Link } from "react-router-dom";

interface IActionTileComponentProps {
    id: string,
    title: string,
    iconName: string,
    onClick?: any,
    navigationPath?:string
}

export const ActionTileComponent: FC<IActionTileComponentProps> = (props: IActionTileComponentProps): JSX.Element => {
    return (
        <>
            <Link id={props.id} to={props.navigationPath!} className="notextdecoration">
                <div className="actiontile" onClick={props.onClick}>
                    <div className="row">
                        <Icon iconName={props.iconName} style={{ fontSize: 40, color: 'blue' }} />
                        <p style={{ fontSize: 14, color: 'blue' }}>{props.title} </p>
                    </div>
                </div>
            </Link>
        </>);
}

export default ActionTileComponent;
import { Announced, ComboBox, DocumentCard, DocumentCardActivity, DocumentCardDetails, DocumentCardImage, DocumentCardTitle, FontIcon, IColumn, IComboBox, IComboBoxOption, IconButton, IDocumentCardActivityPerson, IDocumentCardStyles, IDropdownStyles, IIconProps, ImageFit, Label, Stack, StackItem, TextField, Text, Panel, PanelType, DefaultButton, CommandBarButton, CommandBar, DocumentCardActions } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { profile } from "console";
import { FC, useContext, useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { ArtifactTypesComponent } from "../../Components/Common/ArtifactTypesComponent";
import DetailsListCustom from "../../Components/Common/DetailsListCustomComponent";
import { FileTypeLogoComponent } from "../../Components/Common/FileTypeLogoComponent";
import { store } from "../../Components/Common/GlobalStateProvider";
import HeadingComponent from "../../Components/Common/HeadingComponent";
import { LoadingIndicator } from "../../Components/Common/LoadingIndicatorComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import { SpinnerPrimaryButton } from "../../Components/Common/SpinnerPrimaryButton";
import { UserServicesComponent } from "../../Components/Common/UserServicesComponent";
import { ErrorCode } from "../../Models/common/Enums";
import { ArtifactTypeStrings } from "../../Models/Constants/ArtifactTypeStrings";
import { ServiceTreeService } from "../../Models/ServiceTreeService";
import { Artifact } from "../../Models/TMPArtifacts/Artifact";
import { ArtifactDownload } from "../../Models/TMPArtifacts/ArtifactDownload";
import { ArtifactTypes } from "../../Models/TMPArtifacts/ArtifactTypes";
import UserProfile from "../../Models/UserProfile";
import { ArtifactService } from "../../Services/ArtifactService";
import STServices from "../../Services/STServices";
import UserService from "../../Services/UserService";
import { getDateFormat } from "../../Utilities/dateutilities";
import { isEmptyOrSpaces } from "../../Utilities/textUtilities";

export const ViewArtifactsPage: FC = (): JSX.Element => {
    let artifactService = new ArtifactService();
    let userService = new UserService();

    const stackTokens = { childrenGap: 10, };
    const cardStyles: IDocumentCardStyles = {
        root:
        {
            display: 'inline-block',
            marginRight: 20,
            marginBottom: 20,
            width: 300,
        },
    };

    useEffect(() => {
    }, []);

    const [loadingText, loadingTextSet] = useState<string>();

    var { appState, appdispatch } = useContext(store);

    const [listOfArtifacts, listOfArtifactsSet] = useState<Artifact[]>([]);
    const [isLoading, isLoadingSet] = useState<boolean>(false);
    const [isServiceOwner,isServiceOwnerSet]= useState<boolean>(false);
    const [selectedServiceTreeService, selectedServiceTreeServiceSet] = useState<ServiceTreeService>();
    useEffect(() => {
        if (selectedServiceTreeService && selectedServiceTreeService.serviceId) {
        var serviceData = appState.userServiceTreeServies?.find(obj => obj.serviceId === selectedServiceTreeService.serviceId)
            if(serviceData){
                isServiceOwnerSet(true)
            }
            else{
                isServiceOwnerSet(false)
             }
          loadArtifactsForDownload(selectedServiceTreeService.serviceId);
        }
    }, [selectedServiceTreeService]);
    const loadUserPersonas = async (userAliases: string[]): Promise<UserProfile[] | undefined> => {
        var userProfiles = await userService.getUserProfiles(userAliases);
        return userProfiles;
    }
    const loadArtifactsForDownload = async (serviceId: string | undefined) => {
        displayMessagesSet([]);
        listOfArtifactsSet([]);
        isLoadingSet(true);
        var response = await artifactService.fetchArtifactsByServiceId(serviceId!);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            listOfArtifactsSet(response.result);
            if (response.result && response.result.length > 0) {
                var uniqueAliases = response.result.filter(x => x && x.uploadedBy).map(item => item.uploadedBy && item.uploadedBy.split('@')[0]).filter((value, index, self) => self.indexOf(value) === index) as string[];
                var userProfiles = await loadUserPersonas(uniqueAliases);
                if (userProfiles && userProfiles.length > 0) {
                    response.result.forEach(artifact => {
                        var profile = userProfiles!.filter(x => x.userPrincipalName === artifact.uploadedBy)
                        if (profile && profile.length > 0) {
                            artifact.persona = {
                                name: profile[0].displayName ?? '',
                                profileImageSrc: profile[0].photo ?? '',
                            }
                        }
                    });
                }

                listOfArtifactsSet(response.result);
            }
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0){
            displayMessagesSet([{ message: "There are no artifacts for the selected service.", messageType: DisplayMessageType.Information }]);
        }
        else
        {
            if (response?.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message)
            {
                displayMessagesSet([{message: response.businessErrors[0].message, messageType: DisplayMessageType.Error}]);
            }
            else {
                displayMessagesSet([{ message: "The artifacts could not be loaded.", messageType: DisplayMessageType.Error }]);
            }
        }    
        isLoadingSet(false);
    }
    const resetValues = () => {
        artifactFileSet(undefined);
        addArtifactTypeSet(undefined);
        artifactTitleSet(undefined);
        artifactDescriptionSet(undefined)
        artifactUrlSet(undefined);
    }

    const [isAddArtifactModalOpen, { setTrue: showAddArtifactModal, setFalse: hideAddArtifactModal }] = useBoolean(false);
    useEffect(()=>{
        displayAddArtifactPanelMessagesSet([]);
        resetValues();

    },[isAddArtifactModalOpen]);
    const onAddArtifactFooterContent = () => {
        return (
            <Stack horizontal tokens={stackTokens}>
                <SpinnerPrimaryButton text="Save" onclick={onSaveAddArtifact} executingText="Saving the artifact details" />
                <DefaultButton onClick={() => { resetValues(); hideAddArtifactModal(); }}>Cancel</DefaultButton>
            </Stack>
        );
    }
    const onSaveAddArtifact = async () => {
        displayAddArtifactPanelMessagesSet([]);
        if (isEmptyOrSpaces(artifactTitle))
        {
            displayAddArtifactPanelMessagesSet([{ message: "Please enter artifact title.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (isEmptyOrSpaces(artifactDescription))
        {
            displayAddArtifactPanelMessagesSet([{ message: "Please enter artifact description.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (isEmptyOrSpaces(addArtifactType?.artifactTypeId))
        {
            displayAddArtifactPanelMessagesSet([{ message: "Please select artifact type.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (isEmptyOrSpaces(selectedServiceTreeService?.serviceId))
        {
            displayAddArtifactPanelMessagesSet([{ message: "Please selected service could not be determined.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (ArtifactTypeStrings.HyperLink === addArtifactType?.artifactTypeId && isEmptyOrSpaces(artifactUrl))
        {
            displayAddArtifactPanelMessagesSet([{ message: "Please enter a url.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (ArtifactTypeStrings.HyperLink === addArtifactType?.artifactTypeId 
            && !isEmptyOrSpaces(artifactUrl)
            && !(artifactUrl?.startsWith('https://')))
        {
            displayAddArtifactPanelMessagesSet([{ message: "Url must begin with http:// or https://", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (ArtifactTypeStrings.File === addArtifactType?.artifactTypeId 
            && artifactFile === undefined)
        {
            displayAddArtifactPanelMessagesSet([{ message: "Please add a file using Upload file button.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (selectedServiceTreeService && selectedServiceTreeService.serviceId) {
            let artifact: Artifact = {
                artifactTitle: artifactTitle,
                artifactDescription: artifactDescription,
                artifactType: addArtifactType?.artifactTypeId,
                serviceId: selectedServiceTreeService.serviceId,
            };

            if (addArtifactType?.artifactTypeId == ArtifactTypeStrings.File) {
                artifact.uploadedFile = artifactFile;
            }

            if (addArtifactType?.artifactTypeId == ArtifactTypeStrings.HyperLink) {
                artifact.artifactUrl = artifactUrl;
            }


            var response = await artifactService.createArtifactForService(selectedServiceTreeService.serviceId, artifact);
            if (response && !response.hasErrors && response.result && response.result.length > 0) {

                displayMessagesSet([{ message: "The artifact has been created.", messageType: DisplayMessageType.Success }]);
                hideAddArtifactModal();
                loadArtifactsForDownload(selectedServiceTreeService.serviceId);
            }
            else {
                if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                    displayAddArtifactPanelMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                }
                else {
                    displayAddArtifactPanelMessagesSet([{ message: "The artifact could not be created.", messageType: DisplayMessageType.Error }]);
                }
            }
        }
        else {
            displayAddArtifactPanelMessagesSet([{ message: "The service configuration could not be determined.", messageType: DisplayMessageType.Error }]);
        }
    }

    const [addArtifactType, addArtifactTypeSet] = useState<ArtifactTypes>();
    const [artifactTitle, artifactTitleSet] = useState<string>();
    const onartifacTitleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        artifactTitleSet(newValue);
    }
    const [artifactDescription, artifactDescriptionSet] = useState<string>();
    const onArtifactDescriptionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        artifactDescriptionSet(newValue);
    }
    const [artifactUrl, artifactUrlSet] = useState<string>();
    const [artifactFile, artifactFileSet] = useState<File>();
    const onArtifactUrlChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        artifactUrlSet(newValue);
    }
    const fileRef = useRef<HTMLInputElement>(null);
    const handleChange = async (event: any) => {

        if (event.target.files && event.target.files.length > 0) {
            artifactFileSet(event.target.files[0]);
        }
        else {
            artifactFileSet(undefined);
        }
    }

    const navigate = useNavigate();
    const onArtifactClick = async (artifact: Artifact) => {
        if (artifact.artifactType == ArtifactTypeStrings.HyperLink) {
            displayMessagesSet([{ message: "The requested page is loaded successfuly.", messageType: DisplayMessageType.Success }]);
            window.open(artifact.artifactUrl, '_blank', 'noopener noreferrer')
        }
        
        if (artifact.artifactType == ArtifactTypeStrings.File) {
            loadingTextSet("Fetching the file for download.")
            isLoadingSet(true);
            if (artifact.artifactId && artifact.serviceId)
            {
                var response = await artifactService.getArtifactSasUrl(artifact.artifactId, artifact.serviceId);
                if (response && !response.hasErrors && response.result && response.result.length > 0)
                {
                    displayMessagesSet([{ message: "The artifact is downloaded.", messageType: DisplayMessageType.Success }]);
                   // window.open(response.result)
                }
                else {
                    if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                        displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                    }
                    else {
                        displayMessagesSet([{ message: "The artifact could not be downloaded.", messageType: DisplayMessageType.Error }]);
                    }
                }    
            }

            isLoadingSet(false);
            loadingTextSet(undefined);
        }
    }

    const [displayMessages, displayMessagesSet] = useMessages();
    const [displayAddArtifactPanelMessages, displayAddArtifactPanelMessagesSet] = useMessages();

    return (
        <>
            <div className="container" style={{ padding: 10 }}>
            <div className="row">
            <MessageComponent messages={displayMessages} />
            </div>
            
                <div className="row">
                    <div className="d-flex" style={{ margin: 0, padding: 0 }}>
                        <div className="me-auto">
                            <HeadingComponent title="Artifacts" subtitle="Add and view your security artifacts here." />
                        </div>
                        {selectedServiceTreeService && selectedServiceTreeService.serviceId && isServiceOwner &&
                            <div className="ms-auto" style={{ marginTop: 40 }}>
                                <Stack horizontal tokens={stackTokens} tabIndex={0} style={{ cursor: 'pointer', color: 'blue' }}>
                                    <Link to={""}  onClick={() => {showAddArtifactModal();}} style={{textDecoration: "none"}}>
                                        <FontIcon iconName="Add" title="Add Artifact" />
                                        <Text variant="medium">  Add Artifact</Text>
                                    </Link>
                                </Stack>
                            </div>
                        }
                    </div>

                </div>
                <div className="row">
                    <div className="col">
                        {
                            <UserServicesComponent
                            label="Select service to view Artifact(s)"
                            addOther={false}
                            showP3P4ServicesOnly={false}
                            showAllServices = {true}
                            onServiceSelected={(selectedServices?: ServiceTreeService | undefined) => {
                                console.log("selectedservice", selectedServices);
                                if(selectedServices == undefined)
                                    {
                                        listOfArtifactsSet([])
                                    }
                                    else
                                    {
                                        selectedServiceTreeServiceSet(selectedServices);
                                    }
                                }
                            } />
                        }
                    </div>
                </div>

                {isLoading && <LoadingIndicator label={loadingText ? loadingText : "Loading"} />}
                {listOfArtifacts && listOfArtifacts.length > 0 && (
                    <div className="row" style={{ marginTop: 20 }}>
                        <div className="d-flex flex-wrap">
                            {
                                listOfArtifacts.map(artifact => {
                                    return <DocumentCard
                                        styles={cardStyles}
                                        onClick={() => {
                                            //onArtifactClick(artifact) 
                                        }}
                                    >
                                        <div className="row" style={{ marginLeft: 5, marginRight: 5 }}>
                                            <div className="d-flex">
                                                <Stack horizontal tokens={stackTokens}>
                                                    <FileTypeLogoComponent
                                                        artifactType={artifact.artifactType as string}
                                                        fileName={artifact.fileName}
                                                    />
                                                    <Text variant="mediumPlus" >{artifact.artifactTitle} </Text>

                                                </Stack>
                                            </div>
                                        </div>

                                        {
                                            artifact.artifactDescription &&
                                            <div className="row" style={{ marginLeft: 5, marginRight: 5 }}>
                                                <Text variant="small">{artifact.artifactDescription} </Text>
                                            </div>
                                        }
                                        <DocumentCardActivity activity={'uploaded on: ' + getDateFormat(artifact.uploadedOn)} people={artifact.persona ? [artifact.persona] : []} />

                                        <div className="d-flex" style={{ padding: 10, color: 'rgb(0, 120, 212)' }}>
                                            <Stack tokens={stackTokens}>
                                                {
                                                    artifact.artifactType == ArtifactTypeStrings.File &&
                                                    <FontIcon iconName="CloudDownload" title={artifact.artifactUrl} onClick={
                                                        (e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onArtifactClick(artifact);
                                                        }
                                                    } />
                                                }

                                                {
                                                    artifact.artifactType == ArtifactTypeStrings.HyperLink &&
                                                    <FontIcon iconName="Link" title={artifact.artifactUrl} onClick={
                                                        (e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onArtifactClick(artifact);
                                                        }
                                                    } />
                                                }
                                            </Stack>

                                            {/* <div className="ms-auto" title="Linked reviews count">
                                                {
                                                    artifact.artifactLinks && artifact.artifactLinks?.length > 0 &&
                                                    <Stack horizontal tokens={stackTokens}>

                                                        <FontIcon iconName="PageLink" />
                                                        {artifact.artifactLinks?.length}

                                                    </Stack>
                                                }
                                            </div> */}
                                        </div>

                                    </DocumentCard>
                                })
                            }
                        </div>
                    </div>
                ) 
            }
            </div>

            {/* The Add artifact panel */}
            <Panel
                type={PanelType.medium}
                isOpen={isAddArtifactModalOpen}
                onDismiss={() => { resetValues(); hideAddArtifactModal(); }}
                closeButtonAriaLabel = "Close"
                headerText={"Add artifacts to service " + selectedServiceTreeService?.serviceName}
                onRenderFooterContent={onAddArtifactFooterContent}
            >
                <div className="row">
                    <MessageComponent messages={displayAddArtifactPanelMessages} />
                </div>
                <div className="row">
                    <TextField
                        styles={{
                        }}
                        required={true}
                        multiline={false}
                        label="Title"
                        name="Artifact Title"
                        value={artifactTitle}
                        onChange={onartifacTitleChange} />
                </div>

                <div className="row">
                    <TextField
                        styles={{
                        }}
                        required={true}
                        multiline={true}
                        label="Description"
                        name="Artifact Description"
                        value={artifactDescription}
                        onChange={onArtifactDescriptionChange} />
                </div>

                <div className="row">
                    <ArtifactTypesComponent
                        placeholder="Select type of artifact"
                        label="Artifact Type"
                        onArtifactTypeSelected={(artifactType: ArtifactTypes) => {
                            addArtifactTypeSet(artifactType);
                        }
                        } />
                </div>
                {
                    addArtifactType && addArtifactType.artifactTypeId && addArtifactType.artifactTypeId == ArtifactTypeStrings.HyperLink &&
                    <div className="row">
                        <div className="row">
                            <TextField
                                styles={{

                                }}
                                multiline={true}
                                label="URL"
                                name="url"
                                value={artifactUrl}
                                required={true}
                                onChange={onArtifactUrlChange} />
                        </div>


                    </div>
                }

                {
                    addArtifactType && addArtifactType.artifactTypeId && addArtifactType.artifactTypeId == ArtifactTypeStrings.File &&
                    <div className="row">
                        <div className="d-flex" style={{ marginTop: 20 }}>
                            <CommandBarButton iconProps={{ iconName: 'Upload' }} text="Upload file" onClick={() => {
                                if (fileRef && fileRef.current) {
                                    fileRef.current.click();
                                }
                            }}>

                            </CommandBarButton>
                            {artifactFile?.name && (
                                <div className="row" style={{ margin: 5}}>
                                    <Stack horizontal tokens={stackTokens}>
                                        <FileTypeLogoComponent
                                            artifactType={addArtifactType.artifactTypeId as string}
                                            fileName={artifactFile?.name}/>
                                        <Text variant="medium" >{artifactFile?.name} </Text>
                                    </Stack>
                                </div>
                            )}
                        </div>
                        <input id="upload" name="upload" type="file" ref={fileRef} onChange={handleChange} hidden accept=".tm7,.tm8,.msg, .xlsx, .csv, .pdf,.doc,.docx,.jpg,.png,.jpeg,.ppt,.pptx,.vsd,.vsdx" />
                    </div>
                }

            </Panel>
        </>
    )
}

export default ViewArtifactsPage;

import { Stack } from "@fluentui/react";
import { FC, useEffect } from "react";
import ActionTileComponent from '../Common/ActionTileComponent'
import HeadingComponent from '../Common/HeadingComponent'

interface IActions {
    title: string,
    iconName: string,
    navigationPath: string
}

interface IActionsComponentProps {
    title: string,
    subtitle?: string,
    actions?: IActions[]
}

export const ActionsComponent: FC<IActionsComponentProps> = (props: IActionsComponentProps): JSX.Element => {

    const stackTokens = { childrenGap: 10, };
    useEffect(() => {

    }, []);

    return (
        <>
            <div className="container" style={{ padding: 10 }}>
                <div className="row">
                    <HeadingComponent title={props.title} subtitle={props.subtitle} />
                </div>
                <div className="row ">
                    <Stack horizontal tokens={stackTokens}>
                        {
                            props.actions?.map((action, index, arr) => { return <ActionTileComponent key={"actions_" + index.toString()} id={index.toString()} title= {action.title}  iconName={action.iconName}  navigationPath={action.navigationPath} /> })
                        }
                        {/* <ActionTileComponent title="Schedule a TM review"  iconName="Add"  navigationPath="/create-schedule"/>
                        <ActionTileComponent title="Record adhoc TM review"  iconName="PeopleExternalShare"  navigationPath="/create-schedule"/> */}
                    </Stack>
                </div>
            </div>
        </>
    );
}

export default ActionsComponent;

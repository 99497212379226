import { FontIcon, Label, Panel, PanelType, Spinner, SpinnerSize, Stack, StackItem, Sticky, Text } from "@fluentui/react";
import { Component, FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserProfileComponent } from './UserProfileComponent';
import { useBoolean } from "@fluentui/react-hooks";
import { SearchBox, ISearchBoxStyles } from '@fluentui/react/lib/SearchBox';
import { IIconProps } from '@fluentui/react/lib/Icon';
import HelpHyperlinksComponent from "../Common/HelpHyperlinksComponent";
import { SearchService } from "../../Services/SearchService";
import { UserSearch } from "../../Models/Search/UserSearch";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";
import { ServiceReviewHistoryComponent } from "../Common/ServiceReviewHistoryComponent";
import ServiceTreeService from "../../Models/ServiceTreeService";
import { SearchComponent } from "../Search/SearchComponent";
import { TeamsType } from "../../Models/Search/TeamsType";
import { Review } from "../../Models/Reviews/Review";
import { SearchPage } from "../../Pages/SearchPage";
import { store } from "../Common/GlobalStateProvider";
import {
      initializeInAppFeedback,
      openFeedback,
      FeedbackInitData,
      FeedbackAuthenticationType,
      IFeedbackInitOptions,
      FeedbackPolicyValue
    } from "@ms/inapp-feedback-loader";
import { TenantId } from "../../authConfig";

export const TopBarComponent: FC = (): JSX.Element => {
    let searchService = new SearchService();
    const stackTokens = { childrenGap: 10, };
    const [isHelpModalOpen, { setTrue: showHelpModalModal, setFalse: hideHelpModalModal }] = useBoolean(false);
    const [displayMessages, displayMessagesSet] = useMessages();
    // const [searchInput, setSearchInput] = useState<any>();
    const [showSearchOutput, setShowSearchOutput]= useState<boolean>(false);
    const [loadOcvFeedback, setLoadOcvFeedback]= useState<boolean>(true);
    const [userSearch, setUserSearch] = useState<UserSearch>();
    const [selectedService, setSelectedService] = useState<ServiceTreeService>();
    const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 500 } };
    const filterIcon: IIconProps = { iconName: 'Filter' };

    const [isSearching, isSearchingSet] = useState<boolean>(false);
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    
    var { appState, appdispatch } = useContext(store);

    useEffect(() => {
         const loadOCV = async() => {
           const hostName: string = 'ThreatModelingPortal'
           const language: string = 'en'
           let inAppFeedbackRef = document.getElementById('inAppFeedbackRef-id')
        
            if (inAppFeedbackRef && hostName && language) {
               const feedbackInfo: FeedbackInitData = {
                hostName: hostName,
                locale: language,
                inAppFeedbackRef: inAppFeedbackRef
              }
              await initializeInAppFeedback(feedbackInfo)
            }
         }
         loadOCV();
        
     }, [])

    const onHelpNavigationContent = () => {
        return (
            <div className="row" style={{ marginRight: 10, marginTop: 10 }}>
                <div className="d-flex">
                    <Stack horizontal tokens={stackTokens} className="ms-auto">
                        <FontIcon iconName="Cancel" onClick={hideHelpModalModal} style={{ cursor: 'pointer' }} />
                    </Stack>
                </div>
            </div>
        );
    }

    const onUserSearch = async(searchInput: any) => {
        reset();
        setShowSearchOutput(true);
        isSearchingSet(true);
        var result = await searchService.searchByUserInput(searchInput);
        if (result && !result.hasErrors && ((result.result?.reviewTeams != null && result.result.reviewTeams.length > 0) || (result.result?.services != null && result.result.services.length > 0) || (result.result?.reviews != null && result.result.reviews.length > 0))) {
            setUserSearch(result.result);
        }
        else if (result && result.hasErrors && result.businessErrors && result.businessErrors.length > 0 && result.businessErrors[0].message) {
            displayMessagesSet([{ message: result.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
        }
        else
        {
            displayMessagesSet([{ message: "Could not fetch search results.", messageType: DisplayMessageType.Error }]);
        }
        isSearchingSet(false);
    }

    function reset() {
        //setSearchInput(undefined);
        setUserSearch(undefined);
        setShowSearchOutput(false);
        displayMessagesSet([]);
        isSearchingSet(false);
    }

      let sampleFeedbackOptions: IFeedbackInitOptions = {
          appId: Number(process.env.REACT_APP_OCVAppID!),
          clientName: 'ThreatModelingPortal',
          authenticationType: FeedbackAuthenticationType.AAD,
          isProduction: process.env.REACT_APP_OCVProductionEnvironment === 'false' ? false : true,
          userId: 'a:' + appState.userProfile?.id,
          tenantId: TenantId,  
          feedbackConfig: {
              isEmailCollectionEnabled: true,
              isFileUploadEnabled: true,
              isScreenshotEnabled: true,
              isScreenRecordingEnabled: true,
              //isShareContextDataEnabled: true,
              isDisplayed: true,
              complianceChecks: {
                  //connectedExperiences: FeedbackPolicyValue.Enabled,
                  policyAllowContact: FeedbackPolicyValue.Enabled,
                  policyAllowContent: FeedbackPolicyValue.Enabled,
                  policyAllowFeedback: FeedbackPolicyValue.Enabled,
                  policyAllowScreenshot: FeedbackPolicyValue.Enabled,
                  //policyAllowSurvey: FeedbackPolicyValue.Enabled,
                  policyEmailCollectionDefault: FeedbackPolicyValue.Enabled,
                  policyScreenshotDefault: FeedbackPolicyValue.Enabled,
                  //policyContentSamplesDefault: FeedbackPolicyValue.Enabled
              }, // complianceChecks is required for AAD users. The host app needs to query OCPS API to get the compliance checks and pass on to the feedback module
              email: appState.userProfile?.mail,
          },
          themeOptions: { baseTheme: 'WindowsLight' } // The host app needs to set their own theme options. The values are for representation only
      }
     console.log("ocv isprod", sampleFeedbackOptions.isProduction);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary justify-content-between" style={{ height: 48, position: 'fixed', top: 0, left: 0, width: '100%' }}>
            <div id="inAppFeedbackRef-id"></div>
                <div className="row flex-fill">
                    <div className="col-4 d-flex">
                        <Link className="navbar-brand ms-2" to="/">Threat Modeling Portal</Link>
                    </div>
                    <div className="col-4" style={{marginTop: 4, position: 'relative'}}>
                        {/* <form className="d-flex"> */}
                            <SearchBox
                                styles={searchBoxStyles}
                                placeholder="Search for reviews, services or teams by name or id."
                                aria-label="Search for reviews, services or teams by name or id."
                                onEscape={ev => setShowSearchOutput(false)}
                                onClear={ev => setShowSearchOutput(false)}
                                onChange={(_, newValue) => console.log('SearchBox onChange fired: ' + newValue)}
                                onSearch={newValue => onUserSearch(newValue)}
                                // iconProps={filterIcon}
                            />
                            {/* <input className="form-control" type="search Service, ServiceId, Reviewer team" placeholder="Search" aria-label="Search" /> */}
                        {/* </form> */}
                    </div>
                    <div className="col-4 d-flex">

                        <div className="ms-auto">
                            <Stack horizontal>
                                  <StackItem>
                                    <FontIcon style={{ color: 'white', marginRight: 20, marginTop: 8, cursor: 'pointer' }} iconName="Feedback" title="Feedback" onClick={() => {openFeedback(sampleFeedbackOptions) }} />
                                  </StackItem> 
                                <StackItem>
                                    <FontIcon style={{ color: 'white', marginRight: 20, marginTop: 8, cursor: 'pointer' }} iconName="Help" title="Help and Support" onClick={showHelpModalModal} />
                                </StackItem>
                                <StackItem>
                                    <UserProfileComponent />
                                </StackItem>
                            </Stack>
                        </div>
                    </div>
                </div>
                {/* <div className="flex-grow-2">
                <a className="navbar-brand ms-4" href="/">Threat Modeling Portal</a>
                </div>
                <div className="flex-grow-1">
                <form className="d-flex">
                    <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                </form>
                </div>
                <div className="flex-grow-2">
                <button className="btn btn-outline-success" type="submit">Search</button>
                </div>  */}
            </nav>

            {showSearchOutput && (
                <div className="container" style={{position: "absolute", top: 40, left: 0, right: 20, width: 500, display: 'block', border: '1px solid black', backgroundColor: 'white'}}>
                <div className="row">
                    <div className="d-flex">
                        <div className="me-auto">
                            {isSearching && 
                                <div className="row">
                                    <Spinner size={SpinnerSize.large} title="Searching" label="searching" />
                                </div>
                            }
                            <div className="row">
                                <MessageComponent messages={displayMessages} />
                            </div>
                            <Stack horizontal>
                                <StackItem>
                                    <Label>Reviews</Label>
                                </StackItem>
                                <StackItem style={{marginLeft: 300}}>
                                    <Label>{userSearch?.reviews?.length == undefined ? 0 : userSearch?.reviews?.length}</Label>
                                </StackItem>
                            </Stack>
                            {userSearch?.reviews && userSearch.reviews.length > 0 && userSearch.reviews.map(x => {
                                return (
                                    <div className="row">
                                        <Link to={"/reviewdetail/" + x.id + "/" + x.service?.serviceId} onClick={() => {reset();}} >{x.easyReviewId}</Link>
                                    </div>
                                )})}
                            <hr/>
                            <Stack horizontal>
                                <StackItem>
                                    <Label>Services</Label>
                                </StackItem>
                                <StackItem style={{marginLeft: 300}}>
                                    <Label>{userSearch?.services?.length == undefined ? 0 : userSearch?.services?.length}</Label>
                                </StackItem>
                            </Stack>
                            {userSearch?.services && userSearch.services.length > 0 && userSearch.services.map(x => {
                                return (
                                    <div className="row">
                                        <Link onClick={() => { setSelectedService(x); openPanel(); reset(); } } to={""}>{x.serviceName}</Link>
                                    </div>
                                )})}
                            <hr/>
                            <Stack horizontal>
                                <StackItem>
                                    <Label>Teams</Label>
                                </StackItem>
                                <StackItem style={{marginLeft: 312}}>
                                    <Label>{userSearch?.reviewTeams?.length == undefined ? 0 : userSearch?.reviewTeams?.length}</Label>
                                </StackItem>
                            </Stack>
                            {userSearch?.reviewTeams && userSearch.reviewTeams.length > 0 && userSearch.reviewTeams.map(x => {
                                return (
                                    <div className="row">
                                      {(() => {
                                            if (appState.userMetadata && appState.userMetadata?.isReviewer || appState.userMetadata?.isAdmin){
                                                return (
                                                    <Link onClick={() => {reset()}} to={"/search/" + x.id}>{x.name}</Link>
                                                )
                                            }
                                            else if (appState.userMetadata && appState.userMetadata?.isAuditor){
                                                return (
                                                    <Link onClick={() => {reset()}} to={"/auditor/" + x.id}>{x.name}</Link>
                                                )
                                            }
                                            return null;
                                          })()
                                      }
                                    </div>
                                  )})}
                            <hr/>
                            {/* <Stack horizontal>
                                <StackItem>
                                    <Label>Users</Label>
                                </StackItem>
                                <StackItem style={{marginLeft: 315}}>
                                    <Label>{userSearch?.users?.length == undefined ? 0 : userSearch?.users?.length}</Label>
                                </StackItem>
                            </Stack>
                            <hr/> */}
                        </div>
                        </div>
                </div>
                </div>
            )}

            <Panel
                type={PanelType.large}
                isOpen={isOpen}
                onDismiss={dismissPanel}
                closeButtonAriaLabel="Close">
                <ServiceReviewHistoryComponent
                        serviceId={selectedService?.serviceId}
                        serviceName={selectedService?.serviceName} />
            </Panel>

            <Panel
                type={PanelType.medium}
                isOpen={isHelpModalOpen}
                onDismiss={hideHelpModalModal}
                closeButtonAriaLabel="Close"
                headerText={"Help and Support"}
                hasCloseButton={false}
                onRenderNavigationContent={onHelpNavigationContent}
            >
                <div className="row">
                    <HelpHyperlinksComponent
                        title="Support Resources"
                        hyperlinks={[
                            { title: "Privacy Statement", url: "https://go.microsoft.com/fwlink/?LinkId=518021", icon: "UserOptional" },
                            { title: "TMP documentation", url: "http://aka.ms/tmpdocs", icon: "Documentation" },
                            { title: "SDL", url: "https://eng.ms/docs/microsoft-security/security/azure-security/cloudai-security-fundamentals-engineering/sdl-modernization", icon: "Documentation" },
                        ]}/>
                </div>
                
                <div className="row">
                    <HelpHyperlinksComponent
                        title="Contacts"
                        hyperlinks={[
                            { title: "Azure SDL help (azsdlhelp@microsoft.com)", url: "mailto:azsdlhelp@microsoft.com", icon: "EditMail", description: "For questions about the SDL process, SDL requirements, SDL tools" },
                            { title: "Azure Risk Management (azurerisk@microsoft.com)", url: "mailto:azurerisk@microsoft.com", icon: "EditMail", description: "For getting Exceptions"  },
                            { title: "TM Portal Team (tmpt@microsoft.com)", url: "mailto:tmpt@microsoft.com", icon: "EditMail", description: "For issues related to portal"  },
                        ]}/>
                </div>

            </Panel>
        </>
    );
}

export default TopBarComponent;
import { ComboBox, Dropdown, IComboBox, IComboBoxOption, IDropdownOption, Spinner, SpinnerSize } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { Team } from "../../Models/Team";
import AdminServices from "../../Services/AdminServices";

interface ITeamsListDropdownComponent{
    label?: string,
    placeholder?: string,
    onTeamSelected: (selectedTeam?: Team) => void,
}

export const TeamsListDropdownComponent : FC<ITeamsListDropdownComponent> = (props:ITeamsListDropdownComponent): JSX.Element => {

    const [isLoading, isLoadingSet] = useState<boolean>(false);
    const [teams, teamsSet] = useState<Team[]>([]);
    const [teamNameOptions, teamNameOptionsSet] = useState<IComboBoxOption[]>([]);
    const [comboBoxSelectedTeamOption, comboBoxSelectedTeamOptionSet] = useState<string>();

    let adminService = new AdminServices();
    
    useEffect(()=>{
    },[]);
    useEffect(()=>{
        if (teams == undefined || teams.length == 0)
        {
            fetchTeams();
        }
    },[props]);
    useEffect(()=>{
        var tOptions: IDropdownOption[] = [];
        if (teams && teams.length > 0)
        {
            teams.forEach(x => {
                if (x.id && x.name)
                {
                    tOptions.push({
                        key: x.id,
                        text: x.name
                    });
                }
            });

            teamNameOptionsSet(tOptions);
        }

    },[teams]);

    const fetchTeams = async() => {
        isLoadingSet(true);
        var response = await adminService.fetchTeamsMetadata();
        if(response && response.result && !response.hasErrors && response.result.length > 0)
        {
            teamsSet(response.result);
        }

        isLoadingSet(false);
    }
    const onTeamChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            comboBoxSelectedTeamOptionSet(option.key as string);
            var selectedTeam = teams.filter(x => x.id == option.key);
            if (selectedTeam && selectedTeam.length > 0 && selectedTeam[0])
            {
                props.onTeamSelected(selectedTeam[0]);
            }
        }
    };

    if (isLoading)
    {
        return <Spinner size={SpinnerSize.large} title="loading" />
    }

    return (<ComboBox
        label={props.label ? props.label : "Select Team"}
        placeholder={props.placeholder ? props.placeholder : "Select Team"}
        options={teamNameOptions}
        selectedKey= {comboBoxSelectedTeamOption}
        onChange={onTeamChange}
    />);
}
import { FC } from "react";
import { useParams } from "react-router-dom";
import errorimage from '../../assets/images/ErrorPageImage.png'
import unknownError from '../../assets/images/ErrorPageImage.png'
import { ErrorComponent } from "../../Components/Common/ErrorComponent";
import HeadingComponent from "../../Components/Common/HeadingComponent";

export const ErrorPage: FC = (): JSX.Element => {

    let { errorCode } = useParams();
    return (
        <div className="row" style={{ height: 800 }}>
            <div className="d-flex">
                <div className="align-self-center">
                    <ErrorComponent errorCode={errorCode} />
                </div>
            </div>

        </div>
    );
}
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage'
import ReviewerPage from './Pages/ReviewerPage'
import AdminDashboardHomePage from './Pages/AdminDashboardHomePage'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { graphConfig, graphScopes } from "./authConfig";
import TopBarComponent from './Components/Menubars/TopBarComponent';
import MsalRdirectLoginComponent from './Components/MsalRdirectLoginComponent'
import { Panel, PanelType } from '@fluentui/react';
import SideBarComponent from './Components/Menubars/SideBarComponent';
import { SplashPage } from './Pages/SplashPage';
import UserService from './Services/UserService';
import { GlobalStateProvider, store, } from './Components/Common/GlobalStateProvider';
import { isatty } from 'tty';
import { CacheResult } from './Models/CacheResult';
import { Layout } from './Components/Layout';
import { msalInstance } from '.';
import { AddSchedulesPage } from './Pages/Reviewers/AddSchedulesPage';
import { OnboardNewTeamPage } from './Pages/OnboardNewTeam';
import { ReviewDetailsPage } from './Pages/ReviewDetailsPage';
import { ViewArtifactsPage } from './Pages/Artifacts/ViewArtifactsPage';
import { SearchPage } from './Pages/SearchPage';
import { AdhocReviewPage } from './Pages/ReviewPages/AdhocReviewPage';
import { SelfAttestReviewPage } from './Pages/ReviewPages/SelfAttestReviewPage';
import { ScheduleReviewPage } from './Pages/ReviewPages/ScheduleReviewPage';
import { CustomizeMeetingMailPage } from './Pages/Reviewers/CustomizeMeetingMailPage';
import { QuestionnaireHomePage } from './Pages/Questionnaire/QuestionnaireHome';
import { AdminSchedulesPage } from './Pages/Admin/AdminSchedulesPage';
import { ErrorPage } from './Pages/Errors/ErrorPage';
import { AuditorPage } from './Pages/Auditor/AuditorPage';
import ErrorBoundary from './Pages/Errors/ErrorBoundary';
import { AppIdXdivMappingsPage } from './Pages/Admin/AppIdXdivMappingsPage';
import { AddConfigurationsPage } from './Pages/Reviewers/AddConfigurationsPage';


export const App: FC = (): JSX.Element => {

  let userService: UserService = new UserService();
  const { appState, appdispatch } = useContext(store);
  const [userDataLoaded, userDataLoadedSet] = useState<boolean>(false);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isUserAuthenticated, isUserAuthenticatedSet] = useState<boolean>(false);
  // No Dependencies = onload of widget

  useEffect(()=>{
    const loadUserData = async () => {
      try {

        var response = await userService.cacheUserData();
        var user = await userService.getUserProfile();
        if (response && user) {
          userDataLoadedSet(true);
          appdispatch({ Type: 'userMetaData', payload: { 'cacheResult': response, 'userProfile': user } });
        }

      }
      catch
      {

      }
      userDataLoadedSet(true);
    };

    if (!appState.initStateLoaded) {
      loadUserData();
    }
  },[]);

  if (!appState.initStateLoaded) {

    return <SplashPage />
  }

  
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/'
              element={
                <Layout>
                  <HomePage />
                </Layout>} />
            <Route path='/create-schedule' element={<Layout> <ScheduleReviewPage /> </Layout>} />
            <Route path='/adhocreview' element={<Layout> <AdhocReviewPage /> </Layout>} />
            <Route path='/selfattest' element={<Layout> <SelfAttestReviewPage /> </Layout>} />
            <Route path='/reviewer' element={<Layout> <ReviewerPage /> </Layout>} />
            <Route path='/artifacts' element={<Layout> <ViewArtifactsPage/> </Layout>} />
            <Route path='/search' element={<Layout> <SearchPage/> </Layout>} />
            <Route path='/search/:teamId' element={<Layout> <SearchPage/> </Layout>} />
            <Route path='/reviewdetail/:reviewId/:serviceId' element={<Layout> <ReviewDetailsPage /> </Layout>} />
            <Route path='/reviewdetail/:reviewId' element={<Layout> <ReviewDetailsPage /> </Layout>} />
            <Route path='/Service/ReviewDetails' element={<Layout> <ReviewDetailsPage /> </Layout>} />
            <Route path='/admin' element={<Layout> <AdminDashboardHomePage /> </Layout>} />
            <Route path='/admin/apps' element={<Layout> <AppIdXdivMappingsPage /> </Layout>} />
            <Route path='/question' element={<Layout> <QuestionnaireHomePage /> </Layout>} />
            <Route path='/configurations' element={<Layout> <AddConfigurationsPage /> </Layout>} />
            <Route path='/AddSchedulesPage' element={<Layout> <AddSchedulesPage /> </Layout>} />
            <Route path='/onboard-team' element={<Layout> <OnboardNewTeamPage /> </Layout>} />
            <Route path='/emailcustomize' element={<Layout> <CustomizeMeetingMailPage /> </Layout>} />
            <Route path='/adminschedule' element={<Layout> <AdminSchedulesPage /> </Layout>} />
            <Route path='/auditor' element={<Layout> <AuditorPage /> </Layout>} />
            <Route path='/auditor/:teamId' element={<Layout> <AuditorPage/> </Layout>} />
            <Route path = '*' element={<Layout><ErrorPage /></Layout>} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;


import { ComboBox, IComboBox, IComboBoxOption, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react"
import { TMPTimeZone } from "../../Models/TimeZones/TmpTimeZone";
import MeetingsService from "../../Services/MeetingsService";

interface ITimeZoneDropdownComponent {
    id?: string,
    label?: string,
    placeholder?: string,
    filterIds?: string[],
    selectedTimeZoneId?: string,
    onTimeZoneSelected: (selectedTimeZone?: TMPTimeZone) => void,
}

export const TimeZoneDropdownComponent : FC<ITimeZoneDropdownComponent> = (props: ITimeZoneDropdownComponent): JSX.Element => {
    let reviewService = new MeetingsService();

    const [isComponentLoadError, isComponentLoadErrorSet] = useState<boolean>(false);
    const [dataTimeZones, dataTimeZonesSet] = useState<TMPTimeZone[]>([]);
    const [comboBoxTZOptions, comboBoxTZOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbSelectedTZOption, cmbSelectedTZOptionSet] = useState<string>();
    const loadTimeZones = async () => {
        var tmpTimeZones = await reviewService.getTmpTimeZones();
        if (tmpTimeZones && !tmpTimeZones.hasErrors && tmpTimeZones.result && tmpTimeZones.result?.length > 0) {
            dataTimeZonesSet(tmpTimeZones.result);
        }
        else{
            isComponentLoadErrorSet(true);
        }
    };

    const onTimeZoneChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            cmbSelectedTZOptionSet(option.key as string);
            var selectedTimeZone = dataTimeZones.filter(x => x.id == option.key);
            if (selectedTimeZone && selectedTimeZone.length > 0 && selectedTimeZone[0])
            {
                props.onTimeZoneSelected(selectedTimeZone[0]);
            }
            else {
                props.onTimeZoneSelected(undefined);
            }
        }
    };

    useEffect(()=>{
        isComponentLoadErrorSet(false);
        loadTimeZones();
    }, []);

    useEffect(()=>{
        if (dataTimeZones && dataTimeZones.length > 0){
            var tOptions: IComboBoxOption[] = [];
            dataTimeZones.forEach(tx => {
                if (tx.id && tx.displayName)
                {
                    tOptions.push({
                        key: tx.id,
                        text: tx.displayName
                    });
                }
            })

            if (props.filterIds && props.filterIds.length > 0)
            {
                tOptions = tOptions.filter(x => props.filterIds?.some(y => y == x.key))
            }

            comboBoxTZOptionsSet(tOptions);

            if (props.selectedTimeZoneId)
            {
                cmbSelectedTZOptionSet(props.selectedTimeZoneId as string);

                var selectedTimeZone = dataTimeZones.filter(x => x.id == props.selectedTimeZoneId);
                if (selectedTimeZone && selectedTimeZone.length > 0 && selectedTimeZone[0])
                {
                    props.onTimeZoneSelected(selectedTimeZone[0]);
                }
                else {
                    props.onTimeZoneSelected(undefined);
                }
            }
        }

        
    },[dataTimeZones])

    if (isComponentLoadError){
        return <Text>Could not load the Timezone(s).</Text>
    }

    return (
        <ComboBox
            id={props.id}
            label={props.label}
            placeholder={props.placeholder}
            selectedKey = {cmbSelectedTZOption}
            useComboBoxAsMenuWidth={true}
            options={comboBoxTZOptions}
            onChange={onTimeZoneChange} />
    );
}
import { FC } from "react";

interface IHeadingComponentProps {
    title: string,
    subtitle?: string,
}

export const HeadingComponent: FC<IHeadingComponentProps> = (props: IHeadingComponentProps): JSX.Element => {

    return (
        <>
            <div style={{marginTop:10, marginBottom:10}}> 
                <div className="row text-start">
                    <h5>{props.title}</h5>
                </div>
                { props.subtitle &&
                <div className="row text-start">
                    <p>{props.subtitle}</p>
                </div>
                }
            </div>
        </>
    );
}

export default HeadingComponent;
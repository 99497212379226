import { Announced, MessageBar, MessageBarType } from "@fluentui/react";
import { FC, useCallback, useEffect, useState } from "react";

export enum DisplayMessageType {
    Error,
    Warning,
    Information,
    Success
  }

export interface DisplayMessage
{
    id?: string,
    message: string,
    messageType: DisplayMessageType
}

interface IMessageComponent {
    messages?: DisplayMessage[];
}

export const useMessages = () =>
{
    const [messages, messagesSet] = useState<DisplayMessage[]>([]);

    const setMessages = (messages : DisplayMessage[]) => {
        messagesSet(messages);
    }

    return [messages, setMessages] as const;
}

function getMessageBarType(messageType : DisplayMessageType)
{
    switch(messageType)
    {
        case DisplayMessageType.Error : return MessageBarType.error;
        case DisplayMessageType.Warning : return MessageBarType.warning;
        case DisplayMessageType.Information : return MessageBarType.info;
        case DisplayMessageType.Success : return MessageBarType.success;
        default : return MessageBarType.info;
    }
}

export const MessageComponent: FC<IMessageComponent> = (props: IMessageComponent): JSX.Element => {

    const [messages, messagesSet] = useState<DisplayMessage[]>([]);
    useEffect(()=>{
        if (props.messages && props.messages.length > 0)
        {
            let tmsgs : DisplayMessage[] = [];
            props.messages.forEach(x =>
                {
                    if (x.id)
                    {
                        tmsgs.push(x);
                    }
                    else {
                        let y: DisplayMessage = {
                            id: crypto.randomUUID(),
                            message: x.message,
                            messageType: x.messageType,
                        };
                        tmsgs.push(y);
                    }
                })
            messagesSet(tmsgs);
        }
        else
        {
            messagesSet([]);
        }
    },[props])
    const onDismissClick = (id?: string) =>{
        if (id)
        {
            let remainingMessages = messages.filter(x => x.id != id);
            messagesSet(remainingMessages);
        }
    }

    return (
        <>
        {messages && messages.length > 0 && (
        <div className="container">
            {
                messages.map(msg => {
                    return msg && msg.message && <div className="row" style={{marginTop:3, marginBottom: 3}}>
                        <Announced message={msg.message}/>
                        <MessageBar
                            messageBarType={getMessageBarType(msg.messageType)}
                            isMultiline={false}
                            onDismiss={()=>{ onDismissClick(msg.id)}}
                            dismissButtonAriaLabel="Close"
                        >
                            {msg.message}
                        </MessageBar>
                    </div>
                })
            }
        </div>)}
    </>);
}
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { DetailsList, IColumn, SelectionMode, Separator, DetailsListLayoutMode } from '@fluentui/react';
import { Review } from '../../Models/Reviews/Review';
import { getDateFormat } from '../../Utilities/dateutilities';
import {ReviewDataComponent} from './ReviewDataComponent';
import DetailsListCustom from "./DetailsListCustomComponent";




interface IReviewDataComponent {
  review: Review[];
}

const ServiceReviewHistoryIndex: FC<IReviewDataComponent> = (props: IReviewDataComponent): JSX.Element => {
  const [tableData, setTableData] = useState<Review[]>([]);
  const [selectedReview, setReviewByDate] = useState<Review>({} as Review);

  

  useEffect(() => {
    const fetchData = async () =>{
      if (props.review && props.review.length > 0) {
      setTableData(props.review);
    }
  };
  fetchData();
  }, [props.review]);


  const onRenderDate = (item: Review): JSX.Element => {
    return (
      <a href={`#review-${item?.id}`}>
        {getDateFormat(item.meetingsData?.dateOfReview)}
      </a>
    );
  };

  const columns: IColumn[] = [
    {
      key: 'date',
      name: 'Date',
      fieldName: 'dateOfReview',
      minWidth: 80,
      maxWidth: 100,
      isResizable: false,
      onRender: onRenderDate,
    },
    {
      key: 'reviewId',
      name: 'Review ID',
      fieldName: 'easyReviewId',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'table-securityReview',
      name: 'Security Review Team',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Review) => item.securityReviewTeam?.name || '-',
    },
  ];

  return (
    <div style={{height: '300px',width :'500px', overflow: 'auto' }}>
      <DetailsList
      items={tableData}
      columns={columns}
      compact={true}
      selectionMode={ SelectionMode.none }
      selectionPreservedOnEmptyClick={true}
      enterModalSelectionOnTouch={false}
      layoutMode={DetailsListLayoutMode.justified}
      />
    </div>
  );
};

export default ServiceReviewHistoryIndex;

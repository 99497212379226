import { ComboBox, IComboBox, IComboBoxOption, IDropdownStyles } from "@fluentui/react"
import { FC, useEffect, useState } from "react"
import { Team } from "../../Models/Team"
import AdminServices from "../../Services/AdminServices";

interface ITeamsListMultiSelectComponent {
    id?: string,
    label?: string,
    placeholder?: string,
    selectedTeamIds: Team[],
    onTeamsSelected: (selectedTeam: Team[]) => void,
}

export const TeamsListMultiSelectComponent: FC<ITeamsListMultiSelectComponent> = (props: ITeamsListMultiSelectComponent): JSX.Element => {

    let adminService = new AdminServices();
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };
    const [isLoading, isLoadingSet] = useState<boolean>(false);

    const [cmbUserTeamsOptions, cmbUserTeamsOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbUserSelectedTeamKeys, cmbUserSelectedTeamKeysSet] = useState<string[]>([]);
    const [teams, teamsSet] = useState<Team[]>([]);
    useEffect(() => {
        var selectedTeams = teams?.filter(x => cmbUserSelectedTeamKeys.some(y => y == x.id));
        if (selectedTeams && selectedTeams.length > 0) {
            props.onTeamsSelected(selectedTeams);
        }
        else {
            props.onTeamsSelected([]);
        }

    }, [cmbUserSelectedTeamKeys]);

    const onReviewTeamChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            let selected = option.selected;
            if (selected) {
                cmbUserSelectedTeamKeysSet([...cmbUserSelectedTeamKeys, option.key as string])
            }
            else {
                var remaining = cmbUserSelectedTeamKeys.filter(x => x !== option.key);
                cmbUserSelectedTeamKeysSet(remaining);
            }


        }
    };

    const fetchTeams = async () => {
        isLoadingSet(true);
        var response = await adminService.fetchTeamsMetadata();
        if (response && response.result && !response.hasErrors && response.result.length > 0) {
            teamsSet(response.result);
        }
        else {
            teamsSet([]);
        }

        isLoadingSet(false);
    }
    useEffect(() => {
        if (teams == undefined || teams.length == 0) {
            fetchTeams();
        }
    }, []);
    useEffect(() => {
        if (props.selectedTeamIds && props.selectedTeamIds.length > 0) {

            if (props.selectedTeamIds.length !== cmbUserSelectedTeamKeys.length) {
                let deletedItems = cmbUserSelectedTeamKeys.filter(x => !props.selectedTeamIds.some(y => y.id === x));
                let newItems = props.selectedTeamIds.filter(x => !cmbUserSelectedTeamKeys.some(y => y === x.id));
                let existingItems = props.selectedTeamIds.filter(x => cmbUserSelectedTeamKeys.some(y => y === x.id));

                var presentItems = [...existingItems, ...newItems];
                let t: string[] = [];
                presentItems.forEach(x => {
                    if (x.id && x.id.length > 0) {
                        t.push(x.id);
                    }
                });
                cmbUserSelectedTeamKeysSet(t);
            }
        }
        else {
            if (cmbUserSelectedTeamKeys.length !== 0 && props.selectedTeamIds.length === 0) {
                cmbUserSelectedTeamKeysSet([]);
            }
        }
    }, [props])
    useEffect(() => {
        var tOptions: IComboBoxOption[] = [];
        if (teams && teams.length > 0) {
            teams.forEach(x => {
                if (x.id && x.name) {
                    tOptions.push({
                        key: x.id,
                        text: x.name
                    });
                }
            });

            cmbUserTeamsOptionsSet(tOptions);
        }

    }, [teams]);

    return (<ComboBox
        label={props.label}
        placeholder={props.placeholder}
        selectedKey={cmbUserSelectedTeamKeys}
        styles={dropdownStyles}
        multiSelect={true}
        onChange={onReviewTeamChange}
        useComboBoxAsMenuWidth={true}
        options={cmbUserTeamsOptions} />)
}
import { DetailsHeader, Separator, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { Review } from "../../Models/Reviews/Review";
import MeetingsService from "../../Services/MeetingsService";
import STServices from "../../Services/STServices";
import HeadingComponent from "./HeadingComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "./MessageComponent";
import { ReviewDataComponent } from "./ReviewDataComponent";
import UserProfile from "../../Models/UserProfile";
import UserService from "../../Services/UserService";
import ServiceReviewHistoryIndex from "./ServiceReviewHistoryIndex";
import { getDateFormat } from "../../Utilities/dateutilities";

interface IServiceHistoryComponent {
    serviceId?: string,
    serviceName?: string
}

export const ServiceReviewHistoryComponent: FC<IServiceHistoryComponent> = (props: IServiceHistoryComponent): JSX.Element => {

    let stService: STServices = new STServices();
    let userService: UserService = new UserService();

    const [isComponentLoadError, isComponentLoadErrorSet] = useState<boolean>(false);
    const [noHistoryFound, noHistoryFoundSet] = useState<boolean>(false);
    const [isLoadingHistory, isLoadingHistorySet] = useState<boolean>(false);
    const [serviceId, serviceIdSet] = useState<string>();
    const [reviews, reviewsSet] = useState<Review[]>([]);
    const [userProfiles, userProfilesSet] = useState<UserProfile[]>([]);

    useEffect(() => {
        let userUpns: string[] | undefined = [];
        if (reviews && reviews.length > 0) {
            let tuserUpns = reviews?.map(x => x.reviewers && x.reviewers.map(y =>y.userPrincipalName)).flat();
            tuserUpns.forEach(e => {
                if (e && e.length > 0){
                    userUpns?.push(e);
                }
            });
            
            if (userUpns && userUpns.length > 0) {
                loadUserProfiles(userUpns);
            }
        }
    }, [reviews]);

    const [displayMessages, displayMessagesSet] = useMessages();

    const loadUserProfiles = async (userUpns: string[]) => {
       let uProfiles =  await userService.getUserProfilesByUPNs(userUpns);
         if (uProfiles && uProfiles.length > 0) {
            userProfilesSet(uProfiles);
         }
    }
    const loadServiceHistory = async (serviceId: string) => {
        isLoadingHistorySet(true);
        var response = await stService.getFullHistoryOfService(serviceId);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            const distantFuture = new Date(8640000000000000);
            reviewsSet(response.result.sort((a, b) => {
                let dateA: Date = a.meetingsData?.dateOfReview ? new Date(a.meetingsData.dateOfReview) : distantFuture;
                let dateB: Date = b.meetingsData?.dateOfReview ? new Date(b.meetingsData.dateOfReview) : distantFuture;
                return dateB.getTime() - dateA.getTime();
            }));   
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            noHistoryFoundSet(true);
        }
        else if (response && response.hasErrors && response.businessErrors && response.businessErrors[0] && response.businessErrors[0].message) {
            displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
        }
        else {
            isComponentLoadErrorSet(true);
        }
        isLoadingHistorySet(false);
    }

    useEffect(() => {
        isComponentLoadErrorSet(false);
        noHistoryFoundSet(false);
        if (props.serviceId && props.serviceId.length > 0 && props.serviceId !== serviceId) {
            serviceIdSet(props.serviceId);
        }
    }, [props]);

    useEffect(() => {
        if (serviceId && serviceId.length > 0) {
            loadServiceHistory(serviceId);
        }
    }, [serviceId])

    if (isLoadingHistory) {
        return <Spinner size={SpinnerSize.large} label="Loading the service snapshot" />
    }

    if (noHistoryFound) {
        return <Text>There are no review(s) to display the snapshot.</Text>
    }

    if (isComponentLoadError) {
        return <Text>Could not load the history.</Text>
    }

    return (<div className="container" id= "serviceSnapShotTop">
        <div className="row">
            <HeadingComponent title={props.serviceName ?? ""} subtitle={"The history of the service"} />
        </div> 
        <div className="row">
            <MessageComponent messages={displayMessages} />
        </div>
        <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 'auto'}} >
            <ServiceReviewHistoryIndex review ={reviews} />
        </div>
        <Separator></Separator>
        <div className = "row" style={{ display: 'flex', justifyContent: 'center' }}>
            <HeadingComponent title = "Review Details"  />
        </div>
        {
            reviews && reviews.length > 0 &&
            reviews.map(review => {
                return <div className="row" style={{ marginTop: 20, }}>
                <ReviewDataComponent review={review} userProfiles={userProfiles} />
                </div>
            })
        }
        
    </div>);
}
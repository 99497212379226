import { FC, useEffect, useState } from "react";
import HeadingComponent from "../../Components/Common/HeadingComponent";
import { CommandBarButton, Stack, TextField, Text, FontIcon, Checkbox, Spinner, SpinnerSize } from "@fluentui/react";
import { MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import AdminServices from "../../Services/AdminServices";
import { AllowedApps } from "../../Models/Admin/AllowedApps";
import { SpinnerCommandBarButton } from "../../Components/Common/SpinnerCommandBarButton";
import { XDivTeams } from "../../Models/Admin/XDivTeams";

export const AppIdXdivMappingsPage: FC = (): JSX.Element => {
    var adminService = new AdminServices();
    const stackTokens = { childrenGap: 10, };
    const [appId, appIdSet] = useState<string>('');
    const [xdivId, xdivIdSet] = useState<string>('');

    const [selectedAppId, selectedAppIdSet] = useState<string>();
    useEffect(() => {
        var selectedApp = appIds.filter(x => x.appId === selectedAppId);
        if (selectedApp && selectedApp.length > 0 && selectedApp[0] && selectedApp[0].allowedXDivTeams) {
            xdivIdsSet(selectedApp[0].allowedXDivTeams)
        }
    }, [selectedAppId])

    const [appIds, appIdsSet] = useState<AllowedApps[]>([]);
    const [xdivIds, xdivIdsSet] = useState<XDivTeams[]>([]);

    const [xdivDeleteInProgress, xdivDeleteInProgressSet] = useState<boolean>(false);
    const [appIdDeleteInProgress, appIdDeleteInProgressSet] = useState<boolean>(false);
    const [allowAllServices, allowAllServicesSet] = useState<boolean>(false);
    const [displayMessages, displayMessagesSet] = useMessages();

    useEffect(() => {
        loadApps();
    }, [])

    const loadApps = async () => {
        var response = await adminService.getAllowedApps();
        if (response && response.result && !response.hasErrors) {
            appIdsSet(response.result);
        }
    }
    const onAllServicesAllowedChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (checked) {
            allowAllServicesSet(checked);
        }
        else {
            allowAllServicesSet(false);
        }
    };

    const onAppIdAdd = async () => {
        if (appId != undefined && appId != '') {
            let app: AllowedApps = {
                appId: appId,
            }
            var response = await adminService.addAllowedApps(appId);
            if (response && response.result && !response.hasErrors) {
                appIdsSet([...appIds, app]);
                appIdSet('');
            }

        }
    }
    const onAppIdDelete = async (appId?: string) => {
        if (appId && appId.length > 0) {
            appIdDeleteInProgressSet(true);
            var response = await adminService.removeAppId(appId);
            if (response && response.result && !response.hasErrors) {
                var remaininApps = appIds.filter((app) => app.appId !== appId);
                appIdsSet(remaininApps);
                xdivIdsSet([]);
                selectedAppIdSet(undefined);
            }

            appIdDeleteInProgressSet(false);
        }
    }

    const onXdivIdAdd = async (allServices:boolean) => {

        if (xdivId != undefined && xdivId != '' && selectedAppId != undefined && selectedAppId != '') {
            var response = await adminService.addXDiv(selectedAppId, xdivId, allServices);
            if (response && response.result && !response.hasErrors) {
                let xdiv: XDivTeams = {
                    xDivId: xdivId
                };
                xdivIdsSet([...xdivIds, xdiv]);

                xdivIdSet('');
            }
        }
    }
    const onXdivIdDelete = async (appId?: string, xdivId?: string) => {

        if (appId && appId.length > 0 && xdivId && xdivId.length > 0) {
            xdivDeleteInProgressSet(true);

            var response = await adminService.removeXDiv(appId, xdivId);
            if (response && response.result && !response.hasErrors) {
                var remaininXDivs = xdivIds.filter((xdiv) => xdiv.xDivId != xdivId);
                xdivIdsSet(remaininXDivs);
            }

            xdivDeleteInProgressSet(false);
        }
    }

    return (
        <div className="container" >
            <div className="row">
                <HeadingComponent title="Allowed APP Id(s)" subtitle="The app id to xdiv permission mapping." />
            </div>
            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>
            <div className="row" >
                <div className="col-6" >
                    <div className="row">
                        <Stack horizontal tokens={stackTokens}>
                            <TextField label="AppId" placeholder="AppId" className="form-control input-lg border-0"
                                value={appId}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                    if (newValue != undefined) {
                                        appIdSet(newValue);
                                    }
                                }}
                                style={{ border: 'none' }} />
                            <SpinnerCommandBarButton iconName="Add" text="" style={{ marginTop: 25 }} onclick={onAppIdAdd} />
                        </Stack>
                    </div>
                    <div className="row">
                        {
                            appIds.map((app, index) => {
                                return (
                                    <div className="row" style={{ margin: 10, padding: 10, cursor: 'pointer' }} >
                                        <Stack horizontal tokens={stackTokens}>
                                            <Text>{app.appId} </Text>
                                            {!appIdDeleteInProgress &&
                                                <FontIcon iconName="Delete" onClick={(e) => { onAppIdDelete(app.appId) }} />
                                            }
                                            {
                                                appIdDeleteInProgress &&
                                                <Spinner size={SpinnerSize.small} label="deleting.." />
                                            }
                                            <FontIcon iconName="ArrowUpRight8" onClick={() => { selectedAppIdSet(app.appId) }} />
                                        </Stack>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {
                    selectedAppId !== undefined &&
                    <div className="col-6">
                        <div className="row">
                            <HeadingComponent title="" subtitle={"The app id to xdiv permission mapping for appId: " + selectedAppId} />
                        </div>
                        <div className="row">
                            <div className="row">
                                <Stack horizontal tokens={stackTokens}>
                                    <Checkbox label="Allow all services." checked={allowAllServices} onChange={onAllServicesAllowedChange} />
                                    { allowAllServices && <FontIcon iconName="save" onClick={()=> {onXdivIdAdd(true)}}/> }
                                </Stack>
                            </div>

                        </div>
                        {
                            !allowAllServices &&
                            <div className="row">
                                <div className="row">
                                    <Stack horizontal tokens={stackTokens}>
                                        <TextField label="XdivId"
                                            placeholder="Enter the xdiv GUID"
                                            className="form-control input-lg border-0"
                                            style={{ border: 'none' }}
                                            value={xdivId}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    xdivIdSet(newValue);
                                                }
                                            }} />
                                        <SpinnerCommandBarButton iconName="Add" text="" style={{ marginTop: 25 }} onclick={onXdivIdAdd} />
                                    </Stack>
                                </div>
                                <div className="row">
                                    {selectedAppId &&
                                        xdivIds.map((xdivId, index) => {
                                            return (
                                                <div className="row" style={{ margin: 10, padding: 10, cursor: 'pointer' }} onClick={() => { }}>
                                                    <Stack horizontal tokens={stackTokens}>
                                                        <Text>{xdivId.xDivId} </Text>
                                                        {!xdivDeleteInProgress &&
                                                            <FontIcon iconName="Delete" onClick={() => { onXdivIdDelete(selectedAppId, xdivId.xDivId) }} />
                                                        }
                                                        {
                                                            xdivDeleteInProgress &&
                                                            <Spinner size={SpinnerSize.small} label="deleting.." />
                                                        }
                                                    </Stack>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
import { Callout, FontIcon, Link, Text } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { FC } from "react";
import { MeetingsData } from "../../Models/Reviews/MeetingsData";
import { Slot } from "../../Models/Reviews/Slot";
import { getDateFormat, getTimeFormat } from "../../Utilities/dateutilities";

interface IMeetingTimeCalloutComponent {
    meetingData?: MeetingsData,
    scheduleName?: string
}

export const MeetingTimeCalloutComponent: FC<IMeetingTimeCalloutComponent> = (props: IMeetingTimeCalloutComponent): JSX.Element => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    if (!props.meetingData) {
        return (<></>);
    }

    return (
        <div>
            <Link to={""} onClick={toggleIsCalloutVisible}><FontIcon aria-label="Info" iconName="Info" color="blue" style={{ cursor: "pointer", color: 'blue' }} id={buttonId} /></Link>
            {
                isCalloutVisible &&
                <Callout
                    role="dialog"
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <Text block variant="small" >
                        You are seeing the local time as per your browser. 
                    </Text>
                    <Text block variant="small" >
                        The meeting was originally posted at 
                    </Text>
                    
                    <Text block variant="small" >
                        <b> {getDateFormat(props.meetingData?.dateOfReview) + ' ' + getTimeFormat(props.meetingData?.dateOfReview)}</b>
                    </Text>
                    <Text block variant="small" >
                        in <b> {props.meetingData?.timeZone?.displayName} </b> timezone.
                    </Text>
                    {
                        props.scheduleName && props.scheduleName.length > 0 &&
                        <Text block variant="small" >
                            under schedule : {props.scheduleName}.
                        </Text>
                    }
                </Callout>
            }
        </div>
    );
}
import { ComboBox, IComboBox, IComboBoxOption, IDropdownStyles } from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { Team } from "../../Models/Team";
import { store } from "./GlobalStateProvider";

interface IUserMultiReviewTeamsComponent {
    id?: string,
    label?: string,
    placeholder?: string,
    selectedTeamIds?: Team[],
    onTeamsSelected: (selectedTeam: Team[]) => void,
}

export const UserMultiReviewTeamsComponent: FC<IUserMultiReviewTeamsComponent> = (props: IUserMultiReviewTeamsComponent): JSX.Element => {
    var { appState, appdispatch } = useContext(store);
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };
    
    const [cmbUserTeamsOptions, cmbUserTeamsOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbUserSelectedTeamKeys, cmbUserSelectedTeamKeysSet] = useState<string[]>([]);
    const [allReviewTeams, allReviewTeamsSet] = useState<Team[]>([]);

    useEffect(() => {
        if (appState.initStateLoaded && appState.userRWTeams && appState.userRWTeams.length > 0)
        {
            var allReviewTeams: Team[] = [];
            if (appState.userMetadata?.isReviewReader)
            {
                if (appState.userROTeams && appState.userRWTeams.length > 0)
                {
                    allReviewTeams = [...appState.userRWTeams, ...appState.userROTeams];
                    allReviewTeams = allReviewTeams.filter((value, index, self) => index === self.findIndex((t) => (t['id'] === value['id'])))
                    allReviewTeamsSet(allReviewTeams.sort((e1, e2) => e1.name!.toLowerCase().localeCompare(e2.name!.toLowerCase())));
                }
            }
            else
            {
                allReviewTeamsSet(appState.userRWTeams);
            }
        }
    }, [props]);
    
    useEffect(()=>{
        var selectedTeams = allReviewTeams?.filter(x => cmbUserSelectedTeamKeys.some(y => y == x.id));
        if (selectedTeams && selectedTeams.length > 0)
        {
            props.onTeamsSelected(selectedTeams);
        }
        else {
            props.onTeamsSelected([]);
        }

    },[cmbUserSelectedTeamKeys]);

    const onReviewTeamChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            let selected = option.selected;
            if (selected)
            {
                cmbUserSelectedTeamKeysSet([...cmbUserSelectedTeamKeys, option.key as string])
            }
            else {
                var remaining = cmbUserSelectedTeamKeys.filter(x => x !== option.key);
                cmbUserSelectedTeamKeysSet(remaining);
            }

            
        }
    };

    useEffect(() => {
        if (props.selectedTeamIds && props.selectedTeamIds.length > 0) {

            if (props.selectedTeamIds.length !== cmbUserSelectedTeamKeys.length) {
                let deletedItems = cmbUserSelectedTeamKeys.filter(x => !props.selectedTeamIds!.some(y => y.id === x));
                let newItems = props.selectedTeamIds.filter(x => !cmbUserSelectedTeamKeys.some(y => y === x.id));
                let existingItems = props.selectedTeamIds.filter(x => cmbUserSelectedTeamKeys.some(y => y === x.id));

                var presentItems = [...existingItems, ...newItems];
                let t: string[] = [];
                presentItems.forEach(x => {
                    if (x.id && x.id.length > 0) {
                        t.push(x.id);
                    }
                });
                cmbUserSelectedTeamKeysSet(t);
            }
        }
        else {
            if (cmbUserSelectedTeamKeys.length !== 0 && props.selectedTeamIds && props.selectedTeamIds.length === 0) {
                cmbUserSelectedTeamKeysSet([]);
            }
        }
    },[props]);

    useEffect(()=>{
        if(allReviewTeams && allReviewTeams.length > 0)
        {
            var tTeamOptions: IComboBoxOption[] = [];

            allReviewTeams.forEach(team => {
                if (team.id && team.name)
                {
                    tTeamOptions.push({
                        key: team.id,
                        text: team.name,
                    });
                }
            })
            cmbUserTeamsOptionsSet(tTeamOptions);
        }
        
    },[allReviewTeams]);
    
    return (<ComboBox
        id={props.id}
        label={props.label}
        placeholder={props.placeholder}
        selectedKey={cmbUserSelectedTeamKeys}
        styles={dropdownStyles}
        multiSelect={true}
        onChange={onReviewTeamChange}
        useComboBoxAsMenuWidth={true}
        options={cmbUserTeamsOptions} />)
}
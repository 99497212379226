import { FC } from "react";
import { FontIcon, Panel, PanelType, Stack, StackItem, Sticky, Text } from "@fluentui/react";

interface HyperLinksData {
    title: string,
    url: string,
    icon: string,
    description?: string
}

interface IHelpHyperlinksComponent {
    title: string,
    hyperlinks: HyperLinksData[],
}

export const HelpHyperlinksComponent: FC<IHelpHyperlinksComponent> = (props: IHelpHyperlinksComponent): JSX.Element => {
    return (<div className="row">
        <div className="row">
            <Text variant="medium" style={{ fontWeight: 500, marginTop: 10 }}>{props.title}</Text>
        </div>
        <div className="row" style={{ marginTop: 10 }}>
            {
                props.hyperlinks.map((hyperlink: HyperLinksData) => {
                    return (
                        <div className="row">
                            <Stack tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <FontIcon iconName={hyperlink.icon} style={{ marginTop: 5, fontSize: 15 }} />
                                        <a title={hyperlink.title} href={hyperlink.url} target="_blank" rel="noreferrer" style={{ marginLeft: 10 }}>{hyperlink.title} <FontIcon iconName="OpenInNewWindow" style={{ paddingTop: 5 }} />
                                        </a>

                                    </Stack>
                                </StackItem>
                                {
                                    hyperlink.description &&
                                    <StackItem style={{marginLeft:25}}>
                                        <Text variant="small" style={{ marginTop: 2 }} block nowrap>{hyperlink.description}</Text>
                                    </StackItem>
                                }
                            </Stack>
                        </div>
                    )

                })
            }
        </div>
    </div>);
}

export default HelpHyperlinksComponent;
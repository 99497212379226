import { Spinner, SpinnerSize } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import STServices from "../Services/STServices";
import UserService from "../Services/UserService";
import { useNavigate } from 'react-router';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import MsalRdirectLoginComponent from "../Components/MsalRdirectLoginComponent";

export const SplashPage: FC = (): JSX.Element => {
    return(
        <>
        
        <div className="container">
            <div style={{alignContent:'center', minHeight:800}}>
            <Spinner  size={SpinnerSize.large} label="Loading things up.."/>
            </div>
        </div>
        </>
    );
}
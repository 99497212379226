import { ComboBox, IComboBox, IComboBoxOption, IDropdownStyles, Label, SelectableOptionMenuItemType, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import ServiceTreeService from "../../Models/ServiceTreeService";
import { Team } from "../../Models/Team";
import STServices from "../../Services/STServices";

interface IServiceReviewTeamsComponent {
    id?: string,
    label: string,
    service?: ServiceTreeService,
    placeholder: string,
    loadingText?: string,
    selectedReviewTeamId?: string,
    onReviewTeamSelected: (selectedTeam?: Team) => void,
}
export const ServiceReviewTeamsComponent: FC<IServiceReviewTeamsComponent> = (props: IServiceReviewTeamsComponent): JSX.Element => {
    let sTServices: STServices = new STServices();
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };

    const [isComponentLoadError, isComponentLoadErrorSet] = useState<boolean>(false);
    const [isLoadingTeamsData, isLoadingTeamsDataSet] = useState<boolean>(false);
    const [service, serviceSet] = useState<ServiceTreeService>();
    const [cmbSecurityReviewTeamOptions, cmbSecurityReviewTeamOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbSelectedSecurityReviewTeamOption, cmbSelectedSecurityReviewTeamOptionSet] = useState<string>();
    const [dataSecurityReviewAllTeams, dataSecurityReviewAllTeamsSet] = useState<Team[]>([]);
    const [dataSecurityServiceReviewTeams, dataSecurityServiceReviewTeamsSet] = useState<Team[]>([]);

    const loadReviewerTeamsFor = async (serviceId: string) => {
        isLoadingTeamsDataSet(true);
        var userReviewTeams = await sTServices.getServiceReviewerTeams(serviceId);
        if (userReviewTeams && !userReviewTeams.hasErrors && userReviewTeams.result) {
            if (userReviewTeams.result.allTeams && userReviewTeams.result.allTeams.length > 0) {
                dataSecurityReviewAllTeamsSet(userReviewTeams.result.allTeams);
            }

            if (userReviewTeams.result.serviceReviewTeams && userReviewTeams.result.serviceReviewTeams.length > 0) {
                dataSecurityServiceReviewTeamsSet(userReviewTeams.result.serviceReviewTeams);
            }
            else
            {
                dataSecurityServiceReviewTeamsSet([]);
            }
        }
        else {
            isComponentLoadErrorSet(true);
        }

        isLoadingTeamsDataSet(false);
    };
    useEffect(() => {
        isComponentLoadErrorSet(false);
        if (props.selectedReviewTeamId)
        {
            cmbSelectedSecurityReviewTeamOptionSet(props.selectedReviewTeamId);
        }

        if (props.service && props.service.serviceId && props.service.serviceId !== service?.serviceId) {
            serviceSet(props.service);
            loadReviewerTeamsFor(props.service.serviceId);
        }

        

    }, [props])
    useEffect(() => {
        cmbSecurityReviewTeamOptionsSet([]);
        let sReviewTeams: IComboBoxOption[] = [];

        if (dataSecurityServiceReviewTeams && dataSecurityServiceReviewTeams.length > 0) {
            sReviewTeams.push({ key: 'ResponsibleTeams', text: 'Review teams responsible for the service.', itemType: SelectableOptionMenuItemType.Header });
            dataSecurityServiceReviewTeams.sort((a, b) => a.name!.localeCompare(b.name!)).forEach(x => {
                if (x.id && x.name) {
                    sReviewTeams.push(
                        {
                            key: x.id,
                            text: x.name,
                        }
                    );
                }
            });


        }

        sReviewTeams.push({ key: 'AllTeams', text: 'All Review teams.', itemType: SelectableOptionMenuItemType.Header });
        dataSecurityReviewAllTeams.sort((a, b) => a.name!.localeCompare(b.name!)).forEach(x => {
            if (x.id && x.name) {
                sReviewTeams.push(
                    {
                        key: x.id,
                        text: x.name,
                    }
                );
            }
        });

        cmbSecurityReviewTeamOptionsSet(sReviewTeams);
        if (dataSecurityServiceReviewTeams && dataSecurityServiceReviewTeams.length == 1) {
            cmbSelectedSecurityReviewTeamOptionSet(dataSecurityServiceReviewTeams[0].id);
            props.onReviewTeamSelected(dataSecurityServiceReviewTeams[0]);
        }
        else
        {
            console.log("else in useEffect")
            cmbSelectedSecurityReviewTeamOptionSet("");
            props.onReviewTeamSelected(undefined);
        }
        // cmbSecurityReviewTeamOptionsSet(sReviewTeams.sort((a, b) => a.text.localeCompare(b.text)));
    }, [dataSecurityReviewAllTeams, dataSecurityServiceReviewTeams])
    const onSecurityTeamChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            cmbSelectedSecurityReviewTeamOptionSet(option.key as string);
            var selectedTeam = dataSecurityReviewAllTeams.filter(x => x.id == option?.key)
            if (selectedTeam && selectedTeam[0]) {
                props.onReviewTeamSelected(selectedTeam[0]);
            }
        }
    };

    if (isLoadingTeamsData)
    {
        return <div className="row">
        <Label>{props.label}</Label>
        <Spinner size={SpinnerSize.large} label = {props.loadingText ? props.loadingText : "loading the review teams."} />
        </div>
    }

    if (isComponentLoadError)
    {
        return <div className="row">
        <Label>{props.label}</Label>
        <Text>Could not load the review teams.</Text>
        </div>
    }

    return (
        <ComboBox
            id={props.id}
            label={props.label}
            placeholder= {props.placeholder}
            options={cmbSecurityReviewTeamOptions}
            selectedKey={cmbSelectedSecurityReviewTeamOption}
            styles={dropdownStyles}
            useComboBoxAsMenuWidth={true}
            onChange={onSecurityTeamChange}
        />
    );
}
import { addDays, Checkbox, DatePicker, defaultDatePickerStrings, Label, Stack, StackItem, Text } from "@fluentui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeadingComponent from "../../Components/Common/HeadingComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import { ServiceMemberPeoplePickerComponent } from "../../Components/Common/ServiceMemberPeoplePickerComponent";
import { SpinnerPrimaryButton } from "../../Components/Common/SpinnerPrimaryButton";
import { TimeDropDownComponent } from "../../Components/Common/TimeDropDownComponent";
import { TimeZoneDropdownComponent } from "../../Components/Common/TimeZoneDropdownComponent";
import { UserServicesComponent } from "../../Components/Common/UserServicesComponent";
import { SelfAttestMeeting } from "../../Models/Reviews/SelfAttestMeeting";
import ServiceTreeService from "../../Models/ServiceTreeService";
import { MeetingTime } from "../../Models/TimeZones/MeetingTime";
import { TMPTimeZone } from "../../Models/TimeZones/TmpTimeZone";
import UserProfile from "../../Models/UserProfile";
import MeetingsService from "../../Services/MeetingsService";

export const SelfAttestReviewPage: FC = (): JSX.Element => {
    let reviewService = new MeetingsService();

    const navigate = useNavigate();

    const [isNextButtonAllowed, isNextButtonAllowedSet] = useState<boolean>(true);

    const stackTokens = { childrenGap: 10, };
    const today: Date = new Date(new Date());
    const yesterday = addDays(today, -1);
    const [dataSelectedServiceTreeService, dataSelectedServiceTreeServiceSet] = useState<ServiceTreeService>();
    useEffect(() => {
        isNextButtonAllowedSet(true);
        displayMessagesSet([]);
        if (dataSelectedServiceTreeService && dataSelectedServiceTreeService.serviceId && dataSelectedServiceTreeService.serviceId.length > 0) {
            validateP3Message(dataSelectedServiceTreeService.serviceId);
        }
    }, [dataSelectedServiceTreeService])
    const validateP3Message = async (serviceId: string) => {
        var response = await reviewService.CanServiceSelfAttest(serviceId);
        if (response && !response.hasErrors) {

            if (!response.result) {
                isNextButtonAllowedSet(false);
                displayMessagesSet([{ message: "Could not determine the Eligibility of the service for self attest.", messageType: DisplayMessageType.Error }]);
            }
        } else {
            isNextButtonAllowedSet(false);
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
            }
        }
    }

    const [dataOtherUsers, dataOtherUsersSet] = useState<UserProfile[]>([]);
    const [selectedDate, selectedDateSet] = useState<Date | undefined>(yesterday);
    const [reviewTimeZone, reviewTimeZoneSet] = useState<TMPTimeZone>();
    const [reviewTime, reviewTimeSet] = useState<MeetingTime>();

    const onNextClick = async () => {
        // await new Promise(r => setTimeout(r, 2000));
        displayMessagesSet([]);
        if (dataSelectedServiceTreeService === undefined || dataSelectedServiceTreeService.serviceId === undefined || dataSelectedServiceTreeService.serviceId.length == 0) {
            displayMessagesSet([{ message: "Please select a Service", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (selectedDate === undefined) {
            displayMessagesSet([{ message: "Please enter the Date of Review", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (reviewTimeZone === undefined || reviewTimeZone.id === undefined || reviewTimeZone.id.length === 0) {
            displayMessagesSet([{ message: "Please select Timezone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (reviewTime === undefined || reviewTime.id === undefined || reviewTime.id.length === 0) {
            displayMessagesSet([{ message: "Please select Time", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (selectedDate) {
            var d = new Date(selectedDate);
            var selfAttestReview: SelfAttestMeeting = {
                service: dataSelectedServiceTreeService,
                otherTeamMembers: dataOtherUsers,
                dateOfReview: selectedDate,
                timeZoneOfMeeting: reviewTimeZone,
                timeOfMeeting: reviewTime,
                clientOffsetMinutes: d.getTimezoneOffset(),
            }

            var response = await reviewService.recordSelfAttestReview(selfAttestReview);
            if (response && !response.hasErrors && response.result) {
                navigate("/reviewdetail/" + response.result.id + "/" + response.result.service?.serviceId)
            } else {
                if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                    displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                }
                else {
                    displayMessagesSet([{ message: "The self attest could not be recorded.", messageType: DisplayMessageType.Error }]);
                }
            }
        }
        else {
            displayMessagesSet([{ message: "Please select a valid date", messageType: DisplayMessageType.Warning }]);
        }
    }

    const [displayMessages, displayMessagesSet] = useMessages();

    return (
        <div className="container" style={{ padding: 10 }}>
            <div className="row">
                <HeadingComponent title="Self Attest" subtitle="Review P3/P4 services in your team and log the issues found." />
            </div>
            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <UserServicesComponent
                            id = "self_attest_userP3P4services"
                            label="Service"
                            addOther={false}
                            placeholder="Select a service"
                            showP3P4ServicesOnly={true}
                            showAllServices={false}
                            onServiceSelected={(selectedServices?: ServiceTreeService | undefined) => {
                                dataSelectedServiceTreeServiceSet(selectedServices);
                            }
                            } />

                    </div>
                    <div className="row">
                        <ServiceMemberPeoplePickerComponent
                            id="self_attest_ServiceMembers"
                            itemLimit={50}
                            serviceData={dataSelectedServiceTreeService}
                            onUserSelected={(usersSelected: UserProfile[]) => {
                                dataOtherUsersSet(usersSelected);
                            }} />
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <Label>
                            Date of Review
                        </Label>
                        <DatePicker
                            id="self_attest_reviewDate"
                            value={selectedDate}
                            onSelectDate={selectedDateSet as (date: Date | null | undefined) => void}
                            placeholder="Select of review"
                            ariaLabel="Select date of review"
                            maxDate={yesterday}
                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultDatePickerStrings}
                        />
                    </div>

                    <div className="row">
                        <div className="row">
                            <div className="col">
                                <TimeZoneDropdownComponent
                                    id="self_attest_timezone"
                                    label="Select timezone."
                                    placeholder="Select timezone."
                                    onTimeZoneSelected={((selectedTimeZone?: TMPTimeZone) => {
                                        if (selectedTimeZone) {
                                            reviewTimeZoneSet(selectedTimeZone);
                                        }
                                    })} />

                            </div>
                            <div className="col">
                                <TimeDropDownComponent
                                    id="self_attest_time"
                                    label="Select time of meeting"
                                    placeholder="Select time of meeting"
                                    onTimeSelected={(selectedMeetingTime: MeetingTime) => {
                                        reviewTimeSet(selectedMeetingTime);
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="d-flex" style={{ margin: 20, padding: 10 }}>
                    <div className="mx-auto">
                        <Stack horizontal tokens={stackTokens}>
                            { isNextButtonAllowed &&
                                <StackItem>
                                    <SpinnerPrimaryButton id="self_attest_recordbutton" onclick={onNextClick} text="Record a self attested review" executingText="Saving the review information" />
                                </StackItem>
                            }
                        </Stack>
                    </div>
                </div>
            </div>
        </div>);
}
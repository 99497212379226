import { Announced, ChoiceGroup, FontIcon, IChoiceGroupOption, IconButton, mergeStyles, PrimaryButton, Text, TextField, Toggle } from "@fluentui/react"
import { FC, useEffect, useState } from "react"
import { LoadingIndicator } from "../../Components/Common/LoadingIndicatorComponent"
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent"
import { ErrorCode } from "../../Models/common/Enums"
import { AnswerChoices } from "../../Models/Questionnaire/AnswerChoices"
import { Questions } from "../../Models/Questionnaire/Question"
import { QuestionType } from "../../Models/Questionnaire/QuestionType"

interface IPreviewQuestionnaire {
    questionsList?: Questions[],
    isUserView: boolean,
    isPreview: boolean,
    isDesign: boolean,
    onDeleteQuestion?: (updatedQuestion: Questions) => void;
    onEditQuestion?: (updatedQuesiton: Questions, itemProperty: string, itemValue: string) => void;
    onToggleQuestion?: (updatedQuestion: Questions, checked: boolean) => void;
    onEditChoices?: (itemKey: string, itemValue: string) => void;
    onSelectChoice?: (updatedQuesiton: Questions, selectedOption: IChoiceGroupOption) => void;
}

export const PreviewQuestionnaire: FC<IPreviewQuestionnaire> = (props: IPreviewQuestionnaire): JSX.Element => {

    const [teamQuestionsList, setTeamQuestionsList] = useState<Questions[]>();
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const optionRootClass = mergeStyles({ display: 'flex', alignItems: 'center' });
    var options: IChoiceGroupOption[] = [
        { key: "choice_", text: '', ariaLabel: 'choice', styles: { field: { marginRight: "15px" } }, onRenderField: (properties, render) => {
            return (
              <div className={optionRootClass}>
                {render!(properties)}
                <TextField aria-label={properties?.key} name="option1" value={properties?.text == "" ? "" : properties?.text} disabled= {props.isPreview} placeholder="Add option text" onChange = {(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {props.onEditChoices!(properties?.key!, newValue!) }}}/>
                <IconButton aria-label="Delete" className="btn btn-primary" iconProps={{ iconName: 'Delete' }} style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent'}} onClick={() => deleteChoices(properties?.key)}/>
              </div>
            );}
        }
    ];
    
    useEffect(() => {
        displayMessagesSet([]);
        if(props.questionsList && props.questionsList.length > 0)
        {
            setTeamQuestionsList(props.questionsList);
        }
        else
        {
            setTeamQuestionsList(undefined);
            displayMessagesSet([{message: "No data found", messageType: DisplayMessageType.Error}]);
            setIsLoading(false);
        }
    }, [props.questionsList])

    const deleteChoices = (id?: string) : void => {
        var tempQuestionsList = teamQuestionsList;
        if(tempQuestionsList && tempQuestionsList.length > 0)
        {
            var qId = id?.split('_')[2];
            var qIndex = tempQuestionsList.findIndex(x => x.id == qId);
            if(qIndex != -1)
            {
                const qData = tempQuestionsList.slice();
                const qItem = { ...qData[qIndex] };
                var choiceId = qItem.answerChoices?.findIndex(x => x.choiceKey == id);
                if(choiceId != -1)
                {
                    qItem.answerChoices?.splice(+choiceId!, 1);
                    qData[qIndex] = qItem;
                    tempQuestionsList = qData;
                    setTeamQuestionsList(tempQuestionsList);
                }
            }
        }
    }

    const loadOptions = (item?: Questions) : IChoiceGroupOption[] => {
        var temp: IChoiceGroupOption[] = [];
        if(item?.answerChoices && item.answerChoices.length > 0)
        {
            item.answerChoices.map(x => {
                var tempq: IChoiceGroupOption = { key: x.choiceKey!, text: '', ariaLabel: x.choiceKey!, styles: { field: { marginRight: "15px" } }, onRenderField: (properties, render) => {
                    return (
                      <div className={optionRootClass}>
                        {render!(properties)}
                        <div className="row">
                            {props.isDesign && ( 
                                <div className="col" style={{width: 1000}}>
                                    <TextField aria-label={x.choiceText} name="option1" id={x.choiceKey!} value={x.choiceText} disabled= {props.isPreview} placeholder="Add option text" onChange = {(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {props.onEditChoices!(x.choiceKey!, newValue!) }}}/>
                                </div>
                            )}
                            {!props.isDesign && (
                            <Text aria-label={x.choiceText}>{x.choiceText}</Text>
                            )}
                            
                            {!props.isPreview && props.isDesign && (
                            <div className="col-2">
                                <IconButton aria-label="Delete" className="btn btn-primary" iconProps={{ iconName: 'Delete' }} style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent'}} onClick={() => deleteChoices(x?.choiceKey)}/>
                            </div>
                            )}
                        </div>
                      </div>
                    );}
                }
                temp.push(tempq);
            })
        }
        return temp;
    }
    const renderOptions = (item?: Questions): IChoiceGroupOption[] => {
        if(options && options.length > 0)
        {
            options.map( x => {
                var choiceId = crypto.randomUUID();
                x.key += choiceId + "_" + item?.id;
                var temp: AnswerChoices = {choiceKey: x.key, choiceText: x.text};
                item?.answerChoices?.push(temp);
            })
        }
        return options;
    }

    const addNewChoice = (item: Questions): IChoiceGroupOption[] => {
        var temp: IChoiceGroupOption[] = [];
        if(item.answerChoices && item.answerChoices.length > 0)
        {
            var guid = crypto.randomUUID();
            var choiceId = "choice_" + guid + "_" + item.id;
            var temp1: AnswerChoices = {choiceKey: choiceId, choiceText: ''};
            item.answerChoices.push(temp1);

            var tempQuestionsList = teamQuestionsList;
            if (tempQuestionsList && tempQuestionsList.length > 0) {
                const idx = tempQuestionsList.findIndex(x => x.id == item.id);
                const nextData = tempQuestionsList.slice();
                const nextItem = { ...nextData[idx] };
                nextItem.answerChoices = item.answerChoices;
                nextData[idx] = nextItem;
                tempQuestionsList = nextData;
                setTeamQuestionsList(tempQuestionsList);
            }
        }
        return temp;
    }

    const [displayMessages, displayMessagesSet] = useMessages();
    
    return (
        <div className="container">
            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>
            {isLoading && <LoadingIndicator label="Loading"/>}
            
            <br/>
            {teamQuestionsList && teamQuestionsList.length > 0 && teamQuestionsList.map(item => (
            <div className="row" style={{paddingTop: 20, paddingLeft: 20, paddingRight: 20, paddingBottom: 10, backgroundColor: "rgb(211, 211, 211)",  marginBottom: 20, boxShadow: "0px 5px 8px 2px rgb(192,192,192)"}}>
                <div className="row">
                    <div className="col-4" style={{width: 50, paddingLeft: 10, paddingBottom: 10}}>
                    { 
                        props.isDesign ? <TextField name="questionNumber" id={"queNumber_" + item.id} disabled={props.isPreview || props.isUserView} value={item.questionNumber.toString()} aria-label={item.questionNumber.toString()} placeholder={item.questionNumber.toString()} style={{borderRadius: 5}}  onChange = {props.onEditQuestion ? (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {props.onEditQuestion!(item, "questionNumber", newValue!) }}: undefined}/>
                        : <Text>{item.questionNumber}</Text>
                    }
                    </div>
                    <div className="col-4" style= {{border:"none !important", background:"transparent !important", width: 1000, paddingLeft: 10, paddingBottom: 10}}>
                    {
                        props.isDesign ? <TextField required={item.isRequiredQuestion} id={"question_" + item.id} disabled={props.isPreview || props.isUserView} name="question" value={item.questionText} aria-label={item.questionNumber.toString()} placeholder="Add a new question" style={{border:"none !important", background:"transparent !important", color:"black",borderRadius: 5}} onChange = {props.onEditQuestion ? (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {props.onEditQuestion!(item, "question", newValue!) }}: undefined}/>
                        : <><Text>{item.questionText} </Text> {item.isRequiredQuestion && ( <sup style= {{color: 'red'}}>*</sup> )}</>
                    }
                    </div>
                    {!props.isPreview && props.isDesign && (
                        <div className="col-4" style={{width: 50, paddingLeft: 10, paddingBottom: 10}}>
                            <FontIcon aria-label="Delete" iconName={"Delete"} id={"delete_" + item.id} style={{}} onClick={props.onDeleteQuestion ? (e) => props.onDeleteQuestion!(item): undefined}/>
                        </div>
                    )}
                </div>
                {item.questionType == QuestionType.Choice && (
                    <div className="row">
                        <div className="col-4" style={{width: 50, paddingLeft: 10, paddingBottom: 10}}>
                        </div>
                        <div className="col-4" style={{width: 1100, paddingLeft: 10, paddingBottom: 10}}>
                            <ChoiceGroup name="choices" disabled={props.isPreview} onChange= {props.onSelectChoice ? (ev?: React.FormEvent<HTMLInputElement | HTMLElement> | undefined, option?: IChoiceGroupOption | undefined) => {if(option != undefined) {props.onSelectChoice!(item, option) }} : undefined}
                               options = {item.answerChoices && item.answerChoices.length > 0 ? loadOptions(item) : renderOptions(item)}
                                selectedKey= {props.isUserView ? item.selectedAnswerChoice : ""}
                                //options={optionsType} 
                            />
                        </div>
                        {!props.isPreview && props.isDesign && (
                            <div className="col-4">
                                <PrimaryButton aria-label="Add an option" className="btn btn-primary" iconProps={{ iconName: 'Add' }} text="Add an option" style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent', marginLeft: 35}} onClick= {() => addNewChoice(item)} />
                            </div>
                        )}
                    </div>
                )}
                {item.questionType == QuestionType.FreeText && (
                    <div className="row">
                        <div className="col-4" style={{width: 50, paddingLeft: 10, paddingBottom: 10}}>
                        </div>
                        <div className="col-8" style={{width: 1000, paddingLeft: 10, paddingBottom: 10}}>
                            <TextField aria-label={item.answerText} name="answer" id={"answer_" + item.id} disabled={props.isDesign || props.isPreview} multiline={true} value={(props.isUserView) ? item.answerText: ""} placeholder="Add answer" style={{borderRadius: 5}} onChange = {props.onEditQuestion ? (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {props.onEditQuestion!(item, "answer", newValue!) }} : undefined}/>
                        </div>
                    </div>
                )}
                {item.questionType == QuestionType.FreeTextWithChoice && (
                    <div className="row">
                        <div className="col-4" style={{width: 50, paddingLeft: 10, paddingBottom: 10}}>
                        </div>
                        <div className="col-4" style={{width: 1000, paddingLeft: 10, paddingBottom: 10}}>
                            <ChoiceGroup name="choices" disabled={props.isPreview}
                            options={(item.answerChoices && item.answerChoices?.length > 0) ? loadOptions(item): renderOptions(item)}
                            selectedKey= {props.isUserView ? item.selectedAnswerChoice : ""}
                            onChange= {props.onSelectChoice ? (ev?: React.FormEvent<HTMLInputElement | HTMLElement> | undefined, option?: IChoiceGroupOption | undefined) => {if(option) {props.onSelectChoice!(item, option) }} : undefined}
                            />
                        </div>
                        {!props.isPreview && props.isDesign && (
                            <div className="col-4">
                                <PrimaryButton aria-label="Add an option" className="btn btn-primary" iconProps={{ iconName: 'Add' }} text="Add an option" style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent', marginLeft: 35}} onClick= {() => addNewChoice(item)} />
                            </div>
                        )}
                        <div className="col-4" style={{width: 1000, paddingLeft: 10, paddingBottom: 10, marginLeft: 50}}>
                            <TextField aria-label={item.answerText} name="answer" id={"answerChoice_" + item.id} disabled={props.isDesign || props.isPreview} multiline={true} value={props.isDesign ? "" : item.answerText} placeholder="Add answer" style={{borderRadius: 5}} onChange = {props.onEditQuestion ? (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {props.onEditQuestion!(item, "answer", newValue!) }} : undefined}/>
                        </div>
                    </div>
                )}
                {!props.isPreview && props.isDesign && (
                    <div className="row">
                    <div className="col-4" style={{width: 50, paddingLeft: 10, paddingBottom: 10}}>
                    </div>
                    <div className="col-4">
                        <Toggle checked={item.isRequiredQuestion} onText="Required" id={"toggle_" + item.id}  offText="Not required" role="checkbox" onChange={props.onToggleQuestion ? (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {props.onToggleQuestion!(item, checked!) } : undefined}/>
                    </div>
                </div>
                )}
                <br/>
            </div>
            ))}
        </div>
    )
}
import { ApiBaseUrl } from "../authConfig";
import { BusinessResult } from "../Models/common/BusinessResult";
import { Questions } from "../Models/Questionnaire/Question";
import { Questionnaire } from "../Models/Questionnaire/Questionnaire";
import { getHeaders } from "../Utilities/headers";
import * as http from '../Utilities/http'

export class QuestionnaireService {

    saveQuestionnaire = async (questionnaireData: Questionnaire): Promise<BusinessResult<Questionnaire> | undefined> => {
        if (questionnaireData)
        {
            var response = await (await http.post<BusinessResult<Questionnaire> | undefined>(ApiBaseUrl + '/api/Questionnaire/savequestion', questionnaireData, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    fetchQuestionnaire = async (teamId: string): Promise<BusinessResult<Questionnaire> | undefined> => {
        var response = await (await http.get<BusinessResult<Questionnaire> | undefined>(ApiBaseUrl + '/api/Questionnaire/fetchquestion/' + teamId, await getHeaders()))?.parsedBody;
        if (!response?.hasErrors) {
            return response;
        }
    }

    deleteQuestionnaire = async (teamId: string, questionnaireId: string): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.remove<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Questionnaire/deletequestion/' + teamId + '/' + questionnaireId, await getHeaders()))?.parsedBody;
        return response;
    }
}
import { Announced, Checkbox, ChoiceGroup, CommandBarButton, IBasePickerSuggestionsProps, IButtonStyles, IChoiceGroupOption, IColumn, IconButton, ITag, ITextFieldStyles, Label, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, Stack, StackItem, TagPicker, TextField, Text, FontIcon } from "@fluentui/react";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import DetailsListCustom from "../Components/Common/DetailsListCustomComponent";
import { store } from "../Components/Common/GlobalStateProvider";
import { LoadingIndicator } from "../Components/Common/LoadingIndicatorComponent";
import { SecurityGroups } from "../Models/SecurityGroups";
import { ErrorCode } from "../Models/common/Enums";
import { Team } from "../Models/Team";
import AdminServices from "../Services/AdminServices";
import UserService from "../Services/UserService";
import STServices from "../Services/STServices";
import { ServiceTreeService } from "../Models/ServiceTreeService";
import { DisplayMessageType, MessageComponent, useMessages } from "../Components/Common/MessageComponent";
import HeadingComponent from "../Components/Common/HeadingComponent";

interface IOnboardNewTeamPageProps {
    teamInfo?: Team;
    secGroups?: SecurityGroups[];
}

export const OnboardNewTeamPage: FC<IOnboardNewTeamPageProps> = (props: IOnboardNewTeamPageProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pivotIndex, setPivotIndex] = useState<number>(0);

    const [addSecurityGroup, setAddSecurityGroup] = useState<boolean>(false);
    const [addResponsibleFor, setAddResponsibleFor] = useState<boolean>(false);
    const secGroups = useRef<SecurityGroups[]>([]);
    const services = useRef<ServiceTreeService[]>([]);
    const serviceGroups = useRef<ServiceTreeService[]>([]);
    const teamGroups = useRef<ServiceTreeService[]>([]);
    const [suggestedServiceTreeServices, setSuggestedServiceTreeServices] = useState<ServiceTreeService[]>([]);
    const [selectedServiceTreeObject, setSelectedServiceTreeObject] = useState<ServiceTreeService>();
    const [dataSelectedServiceTreeService, dataSelectedServiceTreeServiceSet] = useState<ServiceTreeService[]>([]);
    const loggedUserAlias = useRef<string>('');
    let adminService = new AdminServices();
    let userService = new UserService();
    let sTServices = new STServices();
    var { appState, appdispatch } = useContext(store);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [teamDetails, setTeamDetails] = useState<Team>();
    const [isNewTeamOnboarding, setIsNewTeamOnboarding] = useState<boolean>(false);
    const [newSecurityGroupName, setNewSecurityGroupName] = useState<string>('');
    // const [isActiveSecurityGroup, setIsActiveSecurityGroup] = useState<boolean>(false);
    // const [isDeletedSecurityGroup, setIsDeletedSecurityGroup] = useState<boolean>(false);
    const [isROSecurityGroup, setIsROSecurityGroup] = useState<boolean>(false);
    const [isRWSecurityGroup, setIsRWSecurityGroup] = useState<boolean>(false);

    let tempTeamArr: Team = {
        id: '',
        name: '',
        teamAlias: '',
        teamDescription: '',
        securityGroups: [],
        teamMetadata: {
            adoInstance: '',
            allowSC: false,
            allowTM: false,
            allowTented: false,
            ccEmails: false,
            ccMeetings: false,
            requireST: false,
            witTags: '',
            calendarId: '',
            calendarName: '',
            isActive: false,
            // isDeleted: false,
            modifiedBy: '',
            modifiedOn: '',
            sendWeeklyEmail: false,
            mandateTMFile: true,
            enableQuestionnaire: false,
            enableServiceInteractions: false,
            enableTechTags: false,
            isDefaultTeamForSelfAttest: true,
        },
        responsibleFor: {
            xDivServiceTreeIds: [],
        }
    }
    useEffect(() => {
        console.log("::::::::::::props");
        console.log(props);
        if (!props.teamInfo) {
            setTeamDetails(tempTeamArr);
            setIsNewTeamOnboarding(true);
        }
        else {
            setTeamDetails(props.teamInfo!);
            secGroups.current = [...props.secGroups!];
            if (props?.teamInfo?.responsibleFor?.xDivServiceTreeIds && props.teamInfo.responsibleFor.xDivServiceTreeIds.length > 0) {
                console.log(props.teamInfo.responsibleFor.xDivServiceTreeIds);
                responsibleForGuidsSet(props.teamInfo.responsibleFor.xDivServiceTreeIds);
            }
            else {
                console.log("no responsible");
                responsibleForGuidsSet([]);
            }
            setIsNewTeamOnboarding(false);
            resetMessages();
        }
    }, [props.teamInfo]);

    useEffect(() => {
        const loadUserProfile = async () => {
            setIsLoading(true);
            var user = await userService.getUserProfile();
            if (user)
                loggedUserAlias.current = user.userPrincipalName;
            setIsLoading(false);
        };

        if (appState.initStateLoaded && appState.userMetadata?.isAdmin) {
            setIsAdmin(true);
            if (!appState.userProfile) {
                loadUserProfile();
            }
            else {
                loggedUserAlias.current = appState.userProfile.userPrincipalName;
            }
        }
    }, []);



    const securityGroupColumnsList: IColumn[] = [
        {
            key: 'name',
            name: 'Group Name',
            fieldName: 'name',
            minWidth: 50,
            maxWidth: 350,
            isRowHeader: true,
            data: 'string',
            isResizable: true,
            onRender: (item: SecurityGroups) => {
                return (
                    <Text>{item.name}</Text>
                );
            }
        },
        // {
        //     key: 'isActive',
        //     name: 'Is Active?',
        //     fieldName: 'isActive',
        //     minWidth: 50,
        //     maxWidth: 200,
        //     isRowHeader: true,
        //     data: 'boolean',
        //     isResizable: true,
        //     onRender: (item: SecurityGroups) => {
        //         return (
        //             <Checkbox checked={item.isActive} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        //                 item.isActive = checked;
        //                 updateCategory(item);
        //             }} />
        //         );
        //     }
        // },
        // {
        //     key: 'isDeleted',
        //     name: 'Is Deleted?',
        //     fieldName: 'isDeleted',
        //     minWidth: 50,
        //     maxWidth: 200,
        //     isRowHeader: true,
        //     data: 'boolean',
        //     isResizable: true,
        //     onRender: (item: SecurityGroups) => {
        //         return (
        //             <Checkbox checked={item.isDeleted} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        //                 item.isDeleted = checked;
        //                 updateCategory(item);
        //             }} />
        //         );
        //     }
        // },
        {
            key: 'isReadOnlyGroup',
            name: 'Read Only?',
            fieldName: 'isReadOnlyGroup',
            minWidth: 50,
            maxWidth: 200,
            isRowHeader: true,
            data: 'boolean',
            isResizable: true,
            onRender: (item: SecurityGroups) => {
                return (
                    <Checkbox aria-label="Read Only" label= " " checked={item.isReadOnlyGroup} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                        item.isReadOnlyGroup = checked;
                        updateCategory(item);
                    }} />
                );
            }
        },
        {
            key: 'isReadWriteGroup',
            name: 'Read Write?',
            fieldName: 'isReadWriteGroup',
            minWidth: 50,
            maxWidth: 200,
            isRowHeader: true,
            data: 'boolean',
            isResizable: true,
            onRender: (item: SecurityGroups) => {
                return (
                    <Checkbox aria-label="Read Write" label= " " checked={item.isReadWriteGroup} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                        item.isReadWriteGroup = checked;
                        updateCategory(item);
                    }} />
                );
            }
        },
        {
            key: 'Delete',
            name: 'Delete',
            minWidth: 50,
            onRender: (item: SecurityGroups) => {
                return (
                    <FontIcon style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} iconName="Delete" onClick={() => removeSecurityGroups(item)} />
                );
            }
        }
    ];

    const iconButtonStyles: Partial<IButtonStyles> = { root: { marginBottom: 4, paddingTop: '30px' } };

    const responsibleForType: IChoiceGroupOption[] = [
        { key: '0', text: 'Services', styles: { field: { marginRight: "15px" } } },
        { key: '1', text: 'Service Groups', styles: { field: { marginRight: "15px" } } },
        { key: '2', text: 'Team Groups', styles: { field: { marginRight: "15px" } } },
    ];

    const removeSecurityGroups = (item: SecurityGroups) => {
        var idx = secGroups.current.findIndex(x => x.aadObjectId == item.aadObjectId);
        if (idx != -1) {
            secGroups.current.splice(idx, 1);
            setTeamDetails(teamDetails => ({
                ...teamDetails!,
                securityGroups: secGroups.current
            }))
        }
    }

    const onboardNewTeam = async () => {
        setIsLoading(true);
        resetMessages();
        if (teamDetails) {
            if (!teamDetails.name || !teamDetails.teamDescription || !teamDetails.teamMetadata?.calendarId || !teamDetails.teamMetadata?.calendarName) {
                displayMessagesSet([{ message: "Please provide required information", messageType: DisplayMessageType.Error }]);
                setIsLoading(false);
                return;
            }
            if (teamDetails.workItemOptionsInTeamsInstance) {
                if (!((teamDetails.workItemOptionsInTeamsInstance?.accountName && teamDetails.workItemOptionsInTeamsInstance?.accountName !== '') && (teamDetails.workItemOptionsInTeamsInstance?.accountUrl && teamDetails.workItemOptionsInTeamsInstance?.accountUrl !== '')
                    && (teamDetails.workItemOptionsInTeamsInstance?.areaPath && teamDetails.workItemOptionsInTeamsInstance?.areaPath !== '') && (teamDetails.workItemOptionsInTeamsInstance?.iterationPath && teamDetails.workItemOptionsInTeamsInstance?.iterationPath !== '')
                    && (teamDetails.workItemOptionsInTeamsInstance?.patTokenVaultUrl && teamDetails.workItemOptionsInTeamsInstance?.patTokenVaultUrl !== '') && (teamDetails.workItemOptionsInTeamsInstance?.project && teamDetails.workItemOptionsInTeamsInstance?.project !== '') && (teamDetails.workItemOptionsInTeamsInstance?.workItemType && teamDetails.workItemOptionsInTeamsInstance?.workItemType !== ''))) {
                    displayMessagesSet([{ message: "Please fill all fields with valid data for VSO information or keep all empty.", messageType: DisplayMessageType.Error }]);
                    setIsLoading(false);
                    return;
                }
            }

            var newTeam: Team = {
                id: teamDetails.id,
                role: teamDetails.role,
                name: teamDetails.name,
                teamAlias: teamDetails.teamAlias,
                teamDescription: teamDetails.teamDescription,
                securityGroups: teamDetails.securityGroups,
                teamMetadata: teamDetails.teamMetadata,
                responsibleFor: teamDetails.responsibleFor,
                workItemOptionsInTeamsInstance: teamDetails.workItemOptionsInTeamsInstance
            }
            var response = await adminService.onboardNewTeam(newTeam);
            if (response && !response.hasErrors && response.result) {
                displayMessagesSet([{ message: "Team onboarded successfully", messageType: DisplayMessageType.Success }]);
            }
            else {
                displayMessagesSet([{ message: response?.businessErrors[0]?.message!, messageType: DisplayMessageType.Error }]);
            }
        }

        setIsLoading(false);
    }

    const updateTeamInfo = async () => {
        setIsLoading(true);
        resetMessages();
        if (teamDetails) {
            if (!teamDetails.name || !teamDetails.teamDescription || !teamDetails.teamMetadata?.calendarId || !teamDetails.teamMetadata?.calendarName) {
                displayMessagesSet([{ message: "Please provide required information", messageType: DisplayMessageType.Error }]);
            }
            if (teamDetails.workItemOptionsInTeamsInstance) {
                if (!((teamDetails.workItemOptionsInTeamsInstance?.accountName && teamDetails.workItemOptionsInTeamsInstance?.accountName !== '') && (teamDetails.workItemOptionsInTeamsInstance?.accountUrl && teamDetails.workItemOptionsInTeamsInstance?.accountUrl !== '')
                    && (teamDetails.workItemOptionsInTeamsInstance?.areaPath && teamDetails.workItemOptionsInTeamsInstance?.areaPath !== '') && (teamDetails.workItemOptionsInTeamsInstance?.iterationPath && teamDetails.workItemOptionsInTeamsInstance?.iterationPath !== '')
                    && (teamDetails.workItemOptionsInTeamsInstance?.patTokenVaultUrl && teamDetails.workItemOptionsInTeamsInstance?.patTokenVaultUrl !== '') && (teamDetails.workItemOptionsInTeamsInstance?.project && teamDetails.workItemOptionsInTeamsInstance?.project !== '') && (teamDetails.workItemOptionsInTeamsInstance?.workItemType && teamDetails.workItemOptionsInTeamsInstance?.workItemType !== ''))) {
                    displayMessagesSet([{ message: "Please fill all fields with valid data for VSO information or keep all empty.", messageType: DisplayMessageType.Error }]);
                    setIsLoading(false);
                    return;
                }
            }
            var newTeam: Team = {
                id: teamDetails.id,
                role: teamDetails.role,
                name: teamDetails.name,
                teamAlias: teamDetails.teamAlias,
                teamDescription: teamDetails.teamDescription,
                securityGroups: teamDetails.securityGroups,
                teamMetadata: teamDetails.teamMetadata,
                responsibleFor: teamDetails.responsibleFor,
                workItemOptionsInTeamsInstance: teamDetails.workItemOptionsInTeamsInstance
            }
            var response = await adminService.updateTeamsMetadata(newTeam);
            if (response && !response.hasErrors && response.result) {
                displayMessagesSet([{ message: "Team configuration updated successfully", messageType: DisplayMessageType.Success }]);
            }
            else {
                displayMessagesSet([{ message: response?.businessErrors[0]?.message!, messageType: DisplayMessageType.Error }]);
            }
        }

        setIsLoading(false);
    }

    const onFirstPivotNextClick = async () => {
        setIsLoading(true);

        if (!teamDetails) {
            alert('no data');
        }
        else {
            if (!teamDetails.name || !teamDetails.teamDescription || !teamDetails.teamMetadata?.calendarId || !teamDetails.teamMetadata?.calendarName) {
                displayMessagesSet([{ message: "Please provide required information", messageType: DisplayMessageType.Error }]);
            }
            else {
                setPivotIndex(pivotIndex + 1);
            }
        }
        setIsLoading(false);
    }

    const updateCategory = (item: SecurityGroups) => {
        const idx = secGroups.current.findIndex(x => x.aadObjectId == item.aadObjectId);
        if (idx != -1) {
            const nextData = secGroups.current.slice();
            const nextItem = { ...nextData[idx] };

            nextItem.modifiedBy = loggedUserAlias.current;
            nextItem.modifiedOn = new Date().toISOString();
            nextData[idx] = nextItem;
            secGroups.current = nextData;
            setTeamDetails(teamDetails => ({
                ...teamDetails!,
                securityGroups: secGroups.current
            }))
        }
    }

    const onAddSecurityGroup = () => {

        setAddSecurityGroup(true);
        toggleSecurityGroupView(false);
    }

    const saveNewSecurityGroup = async () => {
        resetMessages();

        if (newSecurityGroupName == "") {
            displayMessagesSet([{ message: "Security group name cannot be null", messageType: DisplayMessageType.Error }]);
            return;
        }

        const inputGroup = await adminService.getSecurityGroup(newSecurityGroupName);

        if (inputGroup?.result && !inputGroup.hasErrors) {
            var newSecGroup: SecurityGroups = {
                aadObjectId: inputGroup.result.id,
                name: inputGroup.result.displayName,
                isReadOnlyGroup: isROSecurityGroup,
                isReadWriteGroup: isRWSecurityGroup,
                createdBy: loggedUserAlias.current,
                createdOn: new Date().toISOString()
            }
            secGroups.current.push(newSecGroup);
            setTeamDetails(teamDetails => ({
                ...teamDetails!,
                securityGroups: secGroups.current
            }));

            setAddSecurityGroup(false);
            toggleSecurityGroupView(true);
            setNewSecurityGroupName('');
            // setIsActiveSecurityGroup(false);
            // setIsDeletedSecurityGroup(false);
            setIsRWSecurityGroup(false);
            setIsROSecurityGroup(false);
        }
        else {
            displayMessagesSet([{ message: "Security group name is invalid", messageType: DisplayMessageType.Error }]);
        }
    };

    const toggleSecurityGroupView = (show: boolean) => {
        var actionsDiv = document.getElementById("addSecurityGroup");
        if (actionsDiv) {
            if (show)
                actionsDiv.style.display = 'block';
            else
                actionsDiv.style.display = 'none';
        }
    }

    const resetMessages = () => {
        displayMessagesSet([]);
    }

    const [displayMessages, displayMessagesSet] = useMessages();

    const stackTokens = { childrenGap: 10, };

    const [responsibleForGuids, responsibleForGuidsSet] = useState<string[]>([]);
    useEffect(() => {
        if (responsibleForGuids && responsibleForGuids.length > 0) {
            setTeamDetails(teamDetails => ({
                ...teamDetails!,
                responsibleFor: {
                    xDivServiceTreeIds: responsibleForGuids
                }
            }));
        }
        else {
            setTeamDetails(teamDetails => ({
                ...teamDetails!,
                responsibleFor: {
                    xDivServiceTreeIds: []
                }
            }));
        }
    }, [responsibleForGuids]);
    const [responsibleForGuid, responsibleForGuidSet] = useState<string>();
    const onresponsibleForGuidChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        responsibleForGuidSet(newValue);
    }
    const addresponsibleForGuid = (text?: string) => {
        var xdivIds = teamDetails?.responsibleFor?.xDivServiceTreeIds;
        if (xdivIds && xdivIds.length > 0 && text && text.trim().length > 0) {
            responsibleForGuidsSet([...xdivIds, text])
        }
        else {
            if (text && text.trim().length > 0) {
                responsibleForGuidsSet([text]);
            }
        }

        responsibleForGuidSet("");
    }
    const onresponsibleForGuidDelete = (id?: string) => {
        var remainingGuids = responsibleForGuids.filter(x => x !== id);
        responsibleForGuidsSet(remainingGuids);
    }

    return (
        <div className="container">
            <>
                <div className="row">
                    <MessageComponent messages={displayMessages} />
                </div>
                {isLoading && <LoadingIndicator label="Loading" />}
                {!props.teamInfo && (
                    <div className="row">
                        <HeadingComponent title="Onboard a New Team" />
                    </div>)}
                {isAdmin && (
                    <>
                        <Pivot aria-label="Team Metadata Info" style={{ paddingTop: 10 }}>
                            <PivotItem headerText="Team Metadata" itemIcon="ContactInfo" itemKey="01" style={{ paddingTop: 10 }}>
                                <div className="row">
                                    <div className="col">
                                        <TextField
                                            name="name"
                                            aria-labelledby="TeamName"
                                            label="Team Name"
                                            value={teamDetails?.name}
                                            required={true}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        name: newValue
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <TextField
                                            name="teamAlias"
                                            aria-labelledby="TeamAlias"
                                            label="Team Alias"
                                            value={teamDetails?.teamAlias}
                                            required={true}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        teamAlias: newValue
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TextField
                                            name="calendarName"
                                            aria-labelledby="CalendarName"
                                            label="Calendar Name"
                                            value={teamDetails?.teamMetadata?.calendarName}
                                            required={true}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        teamMetadata: {
                                                            ...teamDetails!.teamMetadata,
                                                            calendarName: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <TextField
                                            name="calendarId"
                                            aria-labelledby="CalendarId"
                                            label="Calendar Id"
                                            value={teamDetails?.teamMetadata?.calendarId}
                                            required={true}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        teamMetadata: {
                                                            ...teamDetails!.teamMetadata,
                                                            calendarId: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <TextField
                                            name="adoInstance"
                                            aria-labelledby="ADOInstance"
                                            label="ADO Instance"
                                            value={teamDetails?.teamMetadata?.adoInstance}
                                            required={true}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        teamMetadata: {
                                                            ...teamDetails!.teamMetadata,
                                                            adoInstance: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Security Group Configuration" itemIcon="LaptopSecure" itemKey="2">
                                <div className="row">
                                    <div className="col">
                                        <div className="row" id="addSecurityGroup">
                                            <div className="col">
                                                <CommandBarButton
                                                    className="ms-auto"
                                                    id="btnAddSecurityGroup"
                                                    iconProps={{ iconName: "Add" }}
                                                    text="Add  Security Group"
                                                    ariaLabel="Add Security Group"
                                                    styles={iconButtonStyles}
                                                    onClick={onAddSecurityGroup}
                                                />
                                            </div>
                                        </div>
                                        {addSecurityGroup && (
                                            <>
                                                {/* <div className="row">
                                        <DetailsListCustom columns= {securityGroupColumnsList} data= {tempTeamArr.securityGroups}/>
                                    </div> */}
                                                <div className="row">
                                                    <div className="col-4">
                                                        <TextField name="securityGroupName"
                                                            aria-labelledby="securityGroupName"
                                                            label="Group Name"
                                                            value={newSecurityGroupName}
                                                            required={true}
                                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                                if (newValue != undefined) {
                                                                    setNewSecurityGroupName(newValue)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <Label>Read Only?</Label>
                                                            <Checkbox label=" " name="isROSecurityGroup" aria-label="isROSecurityGroup" checked={isROSecurityGroup} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                                setIsROSecurityGroup(checked!);
                                                        }}/>
                                                    </div>
                                                    <div className="col-2">
                                                        <Label>Read Write?</Label>
                                                            <Checkbox label=" " name="isRWSecurityGroup" aria-label="isRWSecurityGroup" checked={isRWSecurityGroup} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                                setIsRWSecurityGroup(checked!);
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <PrimaryButton text="Add" onClick={saveNewSecurityGroup} style={{ padding: 10, margin: 20, width: 20 }} />
                                                </div>
                                            </>
                                        )}
                                        <hr style={{ width: '360px' }} />
                                    </div>
                                    {teamDetails && teamDetails.securityGroups && teamDetails.securityGroups.length > 0 && (
                                        <div className="row">
                                            <DetailsListCustom columns={securityGroupColumnsList} data={teamDetails.securityGroups} />
                                        </div>
                                    )}
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Team Metadata Configuration" itemIcon="ConfigurationSolid" itemKey="3" style={{ paddingTop: 10 }}>
                                <div className="row">
                                    <div className="col">
                                        <TextField
                                            name="teamDescription"
                                            aria-labelledby="TeamDescription"
                                            label="Team Description"
                                            value={teamDetails?.teamDescription}
                                            required={true}
                                            multiline={true}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        teamDescription: newValue
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <TextField
                                            name="witTags"
                                            aria-labelledby="witTags"
                                            label="Workitem Tags"
                                            value={teamDetails?.teamMetadata?.witTags}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        teamMetadata: {
                                                            ...teamDetails!.teamMetadata,
                                                            witTags: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="CC Team DL On Emails" name="ccEmails" ariaLabelledBy="ccEmails" checked={teamDetails?.teamMetadata?.ccEmails} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    ccEmails: checked
                                                }
                                            }))
                                        }} />
                                    </div>
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="CC Team DL On Meetings" name="ccMeetings" ariaLabelledBy="ccMeetings" checked={teamDetails?.teamMetadata?.ccMeetings} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    ccMeetings: checked
                                                }
                                            }))
                                        }} />
                                    </div>
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Allow Threat Model Meetings" name="allowTM" ariaLabelledBy="allowTM" checked={teamDetails?.teamMetadata?.allowTM} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    allowTM: checked
                                                }
                                            }))
                                        }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Allow Security Consultations" name="allowSC" ariaLabelledBy="allowSC" checked={teamDetails?.teamMetadata?.allowSC} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    allowSC: checked
                                                }
                                            }))
                                        }} />
                                    </div>
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Allow Tented Reviews" name="allowTented" ariaLabelledBy="allowTented" checked={teamDetails?.teamMetadata?.allowTented} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    allowTented: checked
                                                }
                                            }))
                                        }} />
                                    </div>
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Require ServiceTree" name="requireST" ariaLabelledBy="requireST" checked={teamDetails?.teamMetadata?.requireST} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    requireST: checked
                                                }
                                            }))

                                        }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Is Active" name="IsActive" ariaLabelledBy="isActive" checked={teamDetails?.teamMetadata?.isActive} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    isActive: checked
                                                }
                                            }))
                                        }} />
                                    </div>
                                    {/* <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Enable Questionnaire" name="IsActive" ariaLabelledBy="isActive" checked={teamDetails?.teamMetadata?.enableQuestionnaire} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    enableQuestionnaire: checked
                                                }
                                            }))
                                        }} />
                                    </div> */}
                                    {/* <div className="col">
                                <Label title="IsDeleted">Is Deleted?</Label>
                                    <Checkbox name="IsDeleted" ariaLabelledBy="isDeleted" checked={teamDetails?.teamMetadata.isDeleted} onChange= {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                             setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    isDeleted: checked
                                            }}))
                                        }} />
                            </div> */}
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Require Weekly Report" name="sendWeeklyEmail" ariaLabelledBy="sendWeeklyEmail" checked={teamDetails?.teamMetadata?.sendWeeklyEmail} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            setTeamDetails(teamDetails => ({
                                                ...teamDetails!,
                                                teamMetadata: {
                                                    ...teamDetails!.teamMetadata,
                                                    sendWeeklyEmail: checked
                                                }
                                            }))
                                        }} />
                                    </div>
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Enable Service Interactions" name="enableServiceInteractions"
                                            ariaLabelledBy="enableServiceInteractions"
                                            checked={teamDetails?.teamMetadata?.enableServiceInteractions}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                setTeamDetails(teamDetails => ({
                                                    ...teamDetails!,
                                                    teamMetadata: {
                                                        ...teamDetails!.teamMetadata,
                                                        enableServiceInteractions: checked
                                                    }
                                                }))
                                            }} />
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Mandate Threat Model file" name="MandateTMFile"
                                            ariaLabelledBy="MandateTMFile"
                                            checked={teamDetails?.teamMetadata?.mandateTMFile}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                setTeamDetails(teamDetails => ({
                                                    ...teamDetails!,
                                                    teamMetadata: {
                                                        ...teamDetails!.teamMetadata,
                                                        mandateTMFile: checked
                                                    }
                                                }))
                                            }} />
                                    </div> */}
                                    
                                    {/* <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Enable Tech tags" name="enableTechTags"
                                            ariaLabelledBy="enableTechTags"
                                            checked={teamDetails?.teamMetadata?.enableTechTags}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                setTeamDetails(teamDetails => ({
                                                    ...teamDetails!,
                                                    teamMetadata: {
                                                        ...teamDetails!.teamMetadata,
                                                        enableTechTags: checked
                                                    }
                                                }))
                                            }} />
                                    </div> */}

                                </div>
                                <div className="row">
                                    <div className="col" style={{ margin: 10 }}>
                                        <Checkbox label="Is Default team for SelfAttest Reviews" name="defSelfAttest"
                                            ariaLabelledBy="default team for self attest."
                                            checked={teamDetails?.teamMetadata?.isDefaultTeamForSelfAttest}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                console.log(checked);   
                                                setTeamDetails(teamDetails => ({
                                                    ...teamDetails!,
                                                    teamMetadata: {
                                                        ...teamDetails!.teamMetadata,
                                                        isDefaultTeamForSelfAttest: checked
                                                    }
                                                }))
                                            }} />
                                    </div>
                                    <div className="col">
                                        
                                    </div>
                                    <div className="col">

                                    </div>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Responsible For" itemIcon="ContactInfo" itemKey="04">
                                <div className="row">
                                    <div className="col">
                                        <TextField
                                            value={responsibleForGuid}
                                            placeholder="Add the ThreatModeling XDiv id and enter"
                                            onChange={onresponsibleForGuidChange}
                                            iconProps={{ iconName: 'Add', style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => { addresponsibleForGuid(responsibleForGuid) } }}
                                            onKeyDown={(e) => { if (e.key == 'Enter') { addresponsibleForGuid(responsibleForGuid) } }} />

                                    </div>
                                    <div className="row">
                                        {
                                            responsibleForGuids && responsibleForGuids.length > 0 &&
                                            responsibleForGuids.map(x => {
                                                return <div className="row" style={{ marginTop: 10 }}>
                                                    <div className="col">
                                                        <div className="d-flex">
                                                            <Text id={x}>{x}</Text>
                                                            <Stack tokens={stackTokens} horizontal className="ms-auto">
                                                                <FontIcon iconName="Delete" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} onClick={() => { onresponsibleForGuidDelete(x) }} />
                                                            </Stack>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="ADO Configuration" itemIcon="VSTSAltLogo1" itemKey="05">
                                <div className="row">
                                    <div className="col">
                                        <TextField
                                            name="vstsAccountName"
                                            aria-labelledby="VstsAccountName"
                                            label="Account Name"
                                            value={teamDetails?.workItemOptionsInTeamsInstance?.accountName}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        workItemOptionsInTeamsInstance: {
                                                            ...teamDetails!.workItemOptionsInTeamsInstance,
                                                            accountName: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <TextField
                                            name="vstsAccountUrl"
                                            aria-labelledby="vstsAccountUrl"
                                            label="Account URL"
                                            value={teamDetails?.workItemOptionsInTeamsInstance?.accountUrl}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        workItemOptionsInTeamsInstance: {
                                                            ...teamDetails!.workItemOptionsInTeamsInstance,
                                                            accountUrl: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TextField
                                            name="projectName"
                                            aria-labelledby="projectName"
                                            label="Project Name"
                                            value={teamDetails?.workItemOptionsInTeamsInstance?.project}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        workItemOptionsInTeamsInstance: {
                                                            ...teamDetails!.workItemOptionsInTeamsInstance,
                                                            project: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <TextField
                                            name="workItemType"
                                            aria-labelledby="workItemType"
                                            label="Workitem Type"
                                            value={teamDetails?.workItemOptionsInTeamsInstance?.workItemType}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        workItemOptionsInTeamsInstance: {
                                                            ...teamDetails!.workItemOptionsInTeamsInstance,
                                                            workItemType: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TextField
                                            name="areaPath"
                                            aria-labelledby="areaPath"
                                            label="Area Path"
                                            value={teamDetails?.workItemOptionsInTeamsInstance?.areaPath}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        workItemOptionsInTeamsInstance: {
                                                            ...teamDetails!.workItemOptionsInTeamsInstance,
                                                            areaPath: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <TextField
                                            name="iterationPath"
                                            aria-labelledby="iterationPath"
                                            label="Iteration Path"
                                            value={teamDetails?.workItemOptionsInTeamsInstance?.iterationPath}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        workItemOptionsInTeamsInstance: {
                                                            ...teamDetails!.workItemOptionsInTeamsInstance,
                                                            iterationPath: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <TextField
                                            name="patTokenVaultUrl"
                                            aria-labelledby="patTokenVaultUrl"
                                            label="PAT Token URL"
                                            value={teamDetails?.workItemOptionsInTeamsInstance?.patTokenVaultUrl}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                if (newValue != undefined) {
                                                    setTeamDetails(teamDetails => ({
                                                        ...teamDetails!,
                                                        workItemOptionsInTeamsInstance: {
                                                            ...teamDetails!.workItemOptionsInTeamsInstance,
                                                            patTokenVaultUrl: newValue
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </PivotItem>
                        </Pivot>
                        <br />
                        {isNewTeamOnboarding ? <PrimaryButton text="Onboard Team" onClick={onboardNewTeam} style={{ padding: 10 }} /> :
                            <PrimaryButton text="Save Team details" onClick={updateTeamInfo} style={{ padding: 10 }} />}
                    </>
                )}
            </>
        </div>
    )
}
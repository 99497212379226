import { Component, FC, useContext, useEffect, useState } from "react";
import { store } from "../Components/Common/GlobalStateProvider";
import { DisplayMessageType, MessageComponent, useMessages } from "../Components/Common/MessageComponent";
import ActionsComponent from '../Components/Home/ActionsComponent'
import PendingReviewsComponent from "../Components/Home/PendingReviewsComponent";
import ReviewsComponent from '../Components/Home/ReviewsComponent'
import ServiceSnapshotComponent from '../Components/Home/ServiceSnapshotComponent'
import AdminServices from "../Services/AdminServices";
import STServices from "../Services/STServices";

export const HomePage: FC = (): JSX.Element => {
    let sTServices: STServices = new STServices();  
    let adminService = new AdminServices();

    var { appState, appdispatch } = useContext(store);
    const [userActions, userActionsSet] = useState<any[]>([]);
    const [displayMessages, displayMessagesSet] = useMessages();

      // No Dependencies = onload of widget
      useEffect(()=>{
        
        var tActions:any[] = [];

        tActions.push({
            iconName: "Add",
            title: "Schedule a Review",
            navigationPath: "/create-schedule"
        });

        if (appState.initStateLoaded)
        {
            if (appState.userMetadata?.isReviewer)
            {
                tActions.push({
                    iconName: "AddFriend",
                    title: "Record Adhoc Review",
                    navigationPath: "/adhocreview"
                });
            }

            if(appState.userServiceTreeServies?.some(svc => svc.sdlPrioritization === "Priority 4" || svc.sdlPrioritization === "Priority 3"))
            {
                tActions.push({
                    iconName: "AddGroup",
                    title: "Self Attest a TM Review",
                    navigationPath: "/selfattest"
                });
            }
        }
        
        userActionsSet(tActions);
    }, []);

    const fetchPortalConfig = async () => {
        var response = await adminService.fetchPortalConfig();
        if (response && !response.hasErrors && response.result) {
            if (response.result.homeScreenMessage)
            {
                displayMessagesSet([{message: response.result.homeScreenMessage, messageType: DisplayMessageType.Warning}]);
                return;
            }
        }
    }

    useEffect(() => {
        fetchPortalConfig();
    }, []);

    return (
        <>
            <div className="row" >
                <MessageComponent messages={displayMessages} />    
            </div>

            <div className="row mb-5" >
                <ActionsComponent title="Actions" 
                    actions={userActions}  
                    />
            </div>

            <div className="row mb-5" >
                <PendingReviewsComponent title="In-Progress reviews" />
            </div>

            <div className="row mb-5" >
                <ReviewsComponent title="Upcoming reviews" />
            </div>

            <div className="row mb-5">
                <ServiceSnapshotComponent title="My services" />
            </div>
        </>
    );
}

export default HomePage;
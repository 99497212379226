import { ComboBox, IComboBox, IComboBoxOption, IDropdownStyles } from "@fluentui/react";
import { FC, useEffect, useState } from "react"
import { ReviewStatusStrings } from "../../Models/Constants/ReviewStatusStrings";

interface IReviewStatesComponent {
    id?: string,
    label?: string,
    placeholder?: string,
    selectedReviewStates: string[],
    onReviewStateSelected: (selectedStates: string[]) => void,
}

export const ReviewStatesMultiSelectComponent: FC<IReviewStatesComponent> = (props: IReviewStatesComponent): JSX.Element => {
    useEffect(() => {
        if (props.selectedReviewStates && props.selectedReviewStates.length > 0) {
            if (props.selectedReviewStates.length !== cmbUserSelectedStateKeys.length) {
                let deletedItems = cmbUserSelectedStateKeys.filter(x => !props.selectedReviewStates.some(y => y === x));
                let newItems = props.selectedReviewStates.filter(x => !cmbUserSelectedStateKeys.some(y => y === x));
                let existingItems = props.selectedReviewStates.filter(x => cmbUserSelectedStateKeys.some(y => y === x));
                cmbUserSelectedStateKeysSet([...existingItems, ...newItems]);
            }
        }
        else {
            if (cmbUserSelectedStateKeys.length !== 0 && props.selectedReviewStates.length === 0) {
                cmbUserSelectedStateKeysSet([]);
            }
        }
    }, [props]);

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };
    const [cmbReviewOptions, cmbReviewoptionsSet] = useState<IComboBoxOption[]>([
        {
            key: ReviewStatusStrings.New,
            text: ReviewStatusStrings.New
        },
        {
            key: ReviewStatusStrings.InProgress,
            text: ReviewStatusStrings.InProgress
        },
        {
            key: ReviewStatusStrings.Completed,
            text: ReviewStatusStrings.Completed
        },
        {
            key: ReviewStatusStrings.NoShow,
            text: ReviewStatusStrings.NoShow
        },
        {
            key: ReviewStatusStrings.ReOpened,
            text: ReviewStatusStrings.ReOpened
        },
        {
            key: ReviewStatusStrings.Abandoned,
            text: ReviewStatusStrings.Abandoned
        }
    ]);
    const [cmbUserSelectedStateKeys, cmbUserSelectedStateKeysSet] = useState<string[]>([]);
    useEffect(() => {
        if (cmbUserSelectedStateKeys && cmbUserSelectedStateKeys.length > 0) {
            props.onReviewStateSelected(cmbUserSelectedStateKeys);
        }
        else {
            props.onReviewStateSelected([]);
        }

    }, [cmbUserSelectedStateKeys]);
    const onReviewStateChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            let selected = option.selected;
            if (selected) {
                cmbUserSelectedStateKeysSet([...cmbUserSelectedStateKeys, option.key as string])
            }
            else {
                var remaining = cmbUserSelectedStateKeys.filter(x => x !== option.key);
                cmbUserSelectedStateKeysSet(remaining);
            }


        }
    };

    return (<ComboBox
        label={props.label}
        placeholder={props.placeholder}
        selectedKey={cmbUserSelectedStateKeys}
        styles={dropdownStyles}
        multiSelect={true}
        onChange={onReviewStateChange}
        useComboBoxAsMenuWidth={true}
        options={cmbReviewOptions} />)
}
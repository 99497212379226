import { addDays, addMonths, addYears, buildColumns, Checkbox, ComboBox, ConstrainMode, DatePicker, DefaultButton, DetailsList, DetailsListLayoutMode, FontIcon, FontWeights, getTheme, IButtonStyles, IColumn, IComboBox, IComboBoxOption, IconButton, IIconProps, Label, mergeStyleSets, Modal, noWrap, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SelectionMode, SpinButton, Stack, StackItem, TextField, Text, Dropdown } from "@fluentui/react";
import { useBoolean, useId, useRefEffect } from "@fluentui/react-hooks";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { start } from "repl";
import { TeamsListDropdownComponent } from "../../Components/Admin/TeamsListDropdownComponent";
import { store } from "../../Components/Common/GlobalStateProvider";
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import { SpinnerPrimaryButton } from "../../Components/Common/SpinnerPrimaryButton";
import { TimeDropDownComponent } from "../../Components/Common/TimeDropDownComponent";
import { TimeZoneDropdownComponent } from "../../Components/Common/TimeZoneDropdownComponent";
import { UserReviewTeamsComponent } from "../../Components/Common/UserReviewTeamsComponent";
import { Review } from "../../Models/Reviews/Review";
import { Schedule } from "../../Models/Reviews/Schedule";
import { TimeOff } from "../../Models/Reviews/TimeOff";
import { Team } from "../../Models/Team";
import { MeetingTime } from "../../Models/TimeZones/MeetingTime";
import { TMPTimeZone } from "../../Models/TimeZones/TmpTimeZone";
import AdminServices from "../../Services/AdminServices";
import MeetingsService from "../../Services/MeetingsService";
import { getDateFormat, getDateTimeFromDateAndTimeAMPM, getTimeFormat } from "../../Utilities/dateutilities";
import { isEmptyOrSpaces } from "../../Utilities/textUtilities";

export const AdminSchedulesPage: FC = (): JSX.Element => {

    let adminService: AdminServices = new AdminServices();

    const buttonStyles = { root: { marginRight: 8 } };

    const today: Date = new Date(new Date());
    const minDate = addDays(today, +1);
    const maxDate = addYears(today, 5);
    const stackTokens = { childrenGap: 10, };

    const [slotName, slotNameSet] = useState<string>();
    const [startDate, startDateSet] = useState<Date | undefined>();
    const [endDate, endDateSet] = useState<Date | undefined>();

    const [isMonChecked, isMonCheckedSet] = useState<boolean>(true);
    const [isTueChecked, isTueCheckedSet] = useState<boolean>(true);
    const [isWedChecked, isWedCheckedSet] = useState<boolean>(true);
    const [isThuChecked, isThuCheckedSet] = useState<boolean>(true);
    const [isFriChecked, isFriCheckedSet] = useState<boolean>(false);

    const [is30MinSlot, is30MinSlotSet] = useState<boolean>(false);
    const [is1hrMinSlot, is1hrMinSlotSet] = useState<boolean>(true);

    const [dataSelectedTime, dataSelectedTimeSet] = useState<MeetingTime>();
    const [dataSelectedTimeZone, dataSelectedTimeZoneSet] = useState<TMPTimeZone>();
    const [dataSelectedReviewTeam, dataSelectedReviewTeamSet] = useState<Team>();

    const theme = getTheme();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);


    /* Time off modal  */
    const [timeOffStartDate, timeOffStartDateSet] = useState<Date | undefined>();
    const [timeOffEndDate, timeOffEndDateSet] = useState<Date | undefined>();

    const [dataTeamSelectedTimeOffTzs, dataTeamSelectedTimeOffTzsSet] = useState<TMPTimeZone>();

    const [isTimeOffModalOpen, { setTrue: showTimeOffModal, setFalse: hideTimeOffModal }] = useBoolean(false);

    const [timeOffReason, timeOffReasonSet] = useState<string>();

    const [timeOffSlots, timeOffSlotsSet] = useState<Schedule[]>([]);
    const [selectedTimeOffSlots, selectedTimeOffSlotsSet] = useState<Schedule[]>([]);
    /* Time off modal  */

    const [validTimeZoneIds, validTimeZoneIdsSet] = useState<string[]>([]);

    /*Schedules display*/
    const [dataTeamSchedules, dataTeamSchedulesSet] = useState<Schedule[]>([]);
    const [dataTeamTimeOffs, dataTeamTimeOffsSet] = useState<TimeOff[]>([]);
    const [dataSelectedScheduleForEdit, dataSelectedScheduleForEditSet] = useState<Schedule>();
    const [minWidth] = useState(160);
    const [teamScheduleColumns, teamScheduleColumnsSet] = useState<IColumn[]>(
        [
            {
                key: 'SlotName',
                name: 'Slot Name',
                minWidth: 120
            },
            {
                key: 'StartDate',
                name: 'Start Date',
                minWidth: 70
            },
            {
                key: 'EndDate',
                name: 'End Date',
                minWidth: 60
            },
            {
                key: 'StartTime',
                name: 'Start Time',
                minWidth: 80
            },
            {
                key: 'Duration',
                name: 'Duration',
                minWidth: 60
            },
            {
                key: 'ActiveWeekDays',
                name: 'Active Week Days',
                minWidth: 150,
            },
            {
                key: 'TimeZone',
                name: 'Time Zone',
                minWidth: 300
            },
            {
                key: 'QuickActions',
                name: 'Actions ',
                minWidth: 100
            },
        ]
    );
    const [teamTimeOffColumns, teamTimeOffColumnsSet] = useState<IColumn[]>(
        [
            {
                key: 'StartDate',
                name: 'Start Date',
                minWidth: 120
            },
            {
                key: 'EndDate',
                name: 'End Date',
                minWidth: 70
            },
            {
                key: 'TimeOffReason',
                name: 'Reason',
                minWidth: 200
            },
            {
                key: 'TimeZone',
                name: 'Time Zone',
                minWidth: 300
            },
            {
                key: 'QuickActions',
                name: 'Actions ',
                minWidth: 100
            },
        ]
    );

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',

        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const titleId = useId('title');

    const loadSchedulesForTeamAsAdmin = async (teamId: string) => {
        var response = await adminService.getActiveSchedules(teamId);

        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            dataTeamSchedulesSet(response.result);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            displayMessagesSet([{ message: "No Schedules found for selected team", messageType: DisplayMessageType.Information }]);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The schedules could not be loaded.", messageType: DisplayMessageType.Error }]);
            }
        }
    }
    const loadTimeOffsForTeamAsAdmin = async (teamId: string) => {
        var response = await adminService.getActiveTimeOffs(teamId);

        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            dataTeamTimeOffsSet(response.result);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            displayMessagesSet([{ message: "No Time Offs found for selected team", messageType: DisplayMessageType.Information }]);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The time Offs could not be loaded.", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    function reset() {
        displayMessagesSet([]);
        displayAddSchedulePanelMessagesSet([]);
        displayAddTimeOffPanelMessagesSet([]);
        displayEditSchedulePanelMessagesSet([]);
    }

    useEffect(() => {
    }, []);
    useEffect(() => {
        if (dataSelectedReviewTeam && dataSelectedReviewTeam.id) {
            loadSchedulesForTeamAsAdmin(dataSelectedReviewTeam.id);
            loadTimeOffsForTeamAsAdmin(dataSelectedReviewTeam.id);
        }
    }, [dataSelectedReviewTeam]);
    useEffect(() => {
        is1hrMinSlotSet(!is30MinSlot);
    }, [is30MinSlot]);
    useEffect(() => {
        is30MinSlotSet(!is1hrMinSlot);
    }, [is1hrMinSlot]);
    useEffect(() => {
        if (dataTeamSelectedTimeOffTzs && dataTeamSelectedTimeOffTzs.id) {
            var shortlistedTzSchedules = dataTeamSchedules.filter(x => x.tmpTimeZone?.id == dataTeamSelectedTimeOffTzs.id);
            if (shortlistedTzSchedules && shortlistedTzSchedules.length > 0) {
                timeOffSlotsSet(shortlistedTzSchedules);
            }
            else {
                timeOffSlotsSet([]);
            }
        }
    }, [dataTeamSelectedTimeOffTzs]);
    useEffect(() => {
        var shortlistedTzs = dataTeamSchedules.map(x => x.tmpTimeZone?.id !== undefined ? x.tmpTimeZone.id : "").filter((v, i, a) => a.indexOf(v) === i);
        if (shortlistedTzs && shortlistedTzs.length > 0) {
            validTimeZoneIdsSet(shortlistedTzs);
        }
    }, [dataTeamSchedules])

    const onMonChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isMonCheckedSet(checked as boolean);
        },
        [],
    );

    const onTueChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isTueCheckedSet(checked as boolean);
        },
        [],
    );

    const onWedChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isWedCheckedSet(checked as boolean);
        },
        [],
    );

    const onThuChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isThuCheckedSet(checked as boolean);
        },
        [],
    );

    const onFriChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isFriCheckedSet(checked as boolean);
        },
        [],
    );

    const on30MinSlotCheck = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            is30MinSlotSet(checked as boolean);
        },
        [],
    );

    const on1hrSlotCheck = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            is1hrMinSlotSet(checked as boolean);
        },
        [],
    );

    const AddScheduleToTeam = async () => {
        displayAddSchedulePanelMessagesSet([]);

        let offset = new Date().getTimezoneOffset();

        if (slotName === undefined || slotName.length === 0) {
            displayAddSchedulePanelMessagesSet([{ message: "Please enter a slot name.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var isEmpty = isEmptyOrSpaces(slotName);
        if (isEmpty) {
            displayAddSchedulePanelMessagesSet([{ message: "The slot name must be valid and not white spaces.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined) {
            displayAddSchedulePanelMessagesSet([{ message: "The team configuration could not be determined", messageType: DisplayMessageType.Error }]);
            return;
        }

        if (startDate === undefined || endDate === undefined) {
            displayAddSchedulePanelMessagesSet([{ message: "The start date or end date cannot be null", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedTimeZone === undefined || dataSelectedTimeZone.id === undefined || dataSelectedTimeZone.id.length === 0) {
            displayAddSchedulePanelMessagesSet([{ message: "Please select Timezone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedTime === undefined || dataSelectedTime.id === undefined || dataSelectedTime.id.length === 0) {
            displayAddSchedulePanelMessagesSet([{ message: "Please select start time", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (selectedSlotVisibility === undefined || selectedSlotVisibility === null)
        {
            displayEditSchedulePanelMessagesSet([{ message: "Please select slot visibility", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var createSchedule: Schedule = {
            team: dataSelectedReviewTeam,
            name: slotName,
            startDate: startDate,
            endDate: endDate,
            isMondayActive: isMonChecked,
            isTuedayActive: isTueChecked,
            isWeddayActive: isWedChecked,
            isThudayActive: isThuChecked,
            isFridayActive: isFriChecked,
            slotStart: dataSelectedTime,
            is1HourSlot: is1hrMinSlot,
            is30MinSlot: is30MinSlot,
            tmpTimeZone: dataSelectedTimeZone,
            clientOffsetMinutes: offset,
            visibleDays: selectedSlotVisibility
        };



        var response = await adminService.addScheduleToTeam(createSchedule);
        if (response && !response.hasErrors && response.result) {
            dataTeamSchedulesSet([...dataTeamSchedules, response.result]);
        }
        else {
            dataTeamSchedulesSet([]);
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The schedule could not be added", messageType: DisplayMessageType.Error }]);
            }
        }

        hideModal();
    }

    const onSlotNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        slotNameSet(newValue);
    }

    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var currentScheduleItem = item as Schedule;
        if (column && currentScheduleItem) {
            switch (column!.key) {
                case 'SlotName':
                    return <div>{currentScheduleItem.name}</div>;
                case 'StartDate':
                    return <div>{currentScheduleItem.startDateStr}</div>;
                case 'EndDate':
                    return <div>{currentScheduleItem.endDateStr}</div>;
                case 'StartTime':
                    return <div><Stack horizontal tokens={stackTokens}>{currentScheduleItem.slotStart?.timeAMPM} </Stack></div>;
                case 'Duration':
                    return <div>{currentScheduleItem.is1HourSlot ? "1 hr" : "30 min"}</div>
                case 'ActiveWeekDays':
                    return <div>
                        <Stack tokens={stackTokens} horizontal>
                            <StackItem>
                                <div className={currentScheduleItem.isMondayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isMondayActive ? "Active" : "Inactive"}>M</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isTuedayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isTuedayActive ? "Active" : "Inactive"}>T</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isWeddayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isWeddayActive ? "Active" : "Inactive"}>W</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isThudayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isThudayActive ? "Active" : "Inactive"}>T</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isFridayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isFridayActive ? "Active" : "Inactive"}>F</div>
                            </StackItem>
                        </Stack>
                    </div>;
                case 'TimeZone':
                    return <div>{currentScheduleItem.tmpTimeZone?.displayName}</div>;
                case 'QuickActions':
                    return <div>
                        {/* <Stack horizontal tokens={stackTokens}>
                            <FontIcon iconName="Edit"
                                style={{ fontSize: 14, color: "blue", cursor: 'pointer' }}
                                onClick={() => { reset(); reviewsTobeRescheduledSet([]); dataSelectedScheduleForEditSet(currentScheduleItem); showSlotEditModal(); }} />
                        </Stack> */}
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    function _renderTimeOffColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var currentScheduleItem = item as TimeOff;
        if (column && currentScheduleItem) {
            switch (column!.key) {
                case 'TimeOffReason':
                    return <div title={currentScheduleItem.timeOffReason}>{currentScheduleItem.timeOffReason}</div>;
                case 'StartDate':
                    return <div>{currentScheduleItem.startDateStr}</div>;
                case 'EndDate':
                    return <div>{currentScheduleItem.endDateStr}</div>;
                case 'TimeZone':
                    return <div>{currentScheduleItem.tmpTimeZone?.displayName}</div>;
                case 'QuickActions':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            {/* <FontIcon iconName="Delete"
                                style={{ fontSize: 14, color: "blue", cursor: 'pointer' }}
                                onClick={() => { reset(); DeleteTimeOff(dataSelectedReviewTeam?.id , currentScheduleItem.id) }} /> */}
                        </Stack>
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    const onTimeOffReasonChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        timeOffReasonSet(newValue);
    }

    const ValidateTimeOffToSlots = async () => {
        reviewsTobeRescheduledSet([]);
        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined) {
            displayAddTimeOffPanelMessagesSet([{ message: "The team configuration could not be determined", messageType: DisplayMessageType.Error }]);
            return;
        }

        if (dataTeamSelectedTimeOffTzs === undefined || dataTeamSelectedTimeOffTzs.id === undefined || dataTeamSelectedTimeOffTzs.id.length === 0) {
            displayAddTimeOffPanelMessagesSet([{ message: "Please select a time zone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (timeOffStartDate === undefined || timeOffEndDate === undefined) {
            displayAddTimeOffPanelMessagesSet([{ message: "The start date or end date cannot be null", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (timeOffReason === undefined || timeOffReason.length === 0) {
            displayAddTimeOffPanelMessagesSet([{ message: "Please enter a time off reason.", messageType: DisplayMessageType.Warning }]);
            return;
        }


        let offset = new Date().getTimezoneOffset();

        if (applyTimeOffToAllSlots) {
            selectedTimeOffSlotsSet([]);
        }

        var timeOff: TimeOff = {
            startDate: timeOffStartDate,
            endDate: timeOffEndDate,
            clientOffsetMinutes: offset,
            team: dataSelectedReviewTeam,
            timeOffReason: timeOffReason,
            selectedSlots: selectedTimeOffSlots,
            tmpTimeZone: dataTeamSelectedTimeOffTzs
        }

        var response = await adminService.validateTimeOffs(timeOff);
        if (response && !response.hasErrors && response.result && response.result?.length > 0) {
            reviewsTobeRescheduledSet(response.result);
        }
        else if (response && !response.hasErrors && response.result?.length == 0) {
            enableAddTimeOffSet(true);
            displayAddTimeOffPanelMessagesSet([{ message: "The validation succeeded. Please click \"Add Timeoff\". to save", messageType: DisplayMessageType.Information }]);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayAddTimeOffPanelMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayAddTimeOffPanelMessagesSet([{ message: "The review details could not be updated", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const AddTimeOffToSlots = async () => {
        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined) {
            displayAddTimeOffPanelMessagesSet([{ message: "The team configuration could not be determined", messageType: DisplayMessageType.Error }]);
            return;
        }

        if (dataTeamSelectedTimeOffTzs === undefined || dataTeamSelectedTimeOffTzs.id === undefined || dataTeamSelectedTimeOffTzs.id.length === 0) {
            displayAddTimeOffPanelMessagesSet([{ message: "Please select a time zone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (timeOffStartDate === undefined || timeOffEndDate === undefined) {
            displayAddTimeOffPanelMessagesSet([{ message: "The start date or end date cannot be null", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (timeOffReason === undefined || timeOffReason.length === 0) {
            displayAddTimeOffPanelMessagesSet([{ message: "Please enter a time off reason.", messageType: DisplayMessageType.Warning }]);
            return;
        }


        let offset = new Date().getTimezoneOffset();

        if (applyTimeOffToAllSlots) {
            selectedTimeOffSlotsSet([]);
        }

        var timeOff: TimeOff = {
            startDate: timeOffStartDate,
            endDate: timeOffEndDate,
            clientOffsetMinutes: offset,
            team: dataSelectedReviewTeam,
            timeOffReason: timeOffReason,
            selectedSlots: selectedTimeOffSlots,
            tmpTimeZone: dataTeamSelectedTimeOffTzs
        }

        var response = await adminService.addTimeOffs(timeOff);
        if (response && !response.hasErrors && response.result) {
            dataTeamTimeOffsSet([...dataTeamTimeOffs, response.result]);
            hideTimeOffModal();

            displayMessagesSet([{ message: "Successfully added the time off.", messageType: DisplayMessageType.Success }]);
        } else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The review details could not be updated", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const OnSlotSelectedForTimeOff = async (slot: Schedule) => {
        if (slot && slot.id) {
            selectedTimeOffSlotsSet([...selectedTimeOffSlots, slot]);

            applyTimeOffToAllSlotsSet(false);
        }
    }

    const onRenderScheduleFooterContent = () => (
        <Stack horizontal tokens={stackTokens}>
            <SpinnerPrimaryButton text="Add Slot" executingText="Saving Schedule Information" onclick={AddScheduleToTeam} />
            <DefaultButton onClick={hideModal}>Cancel</DefaultButton>
        </Stack>
    );

    const [enableAddTimeOff, enableAddTimeOffSet] = useState<boolean>(false);
    const onRenderTimeOffFooterContent = () => (
        <Stack horizontal tokens={stackTokens}>
            {

                !enableAddTimeOff ? <SpinnerPrimaryButton text="Validate Add TimeOff" executingText="Validating the Time off Information" onclick={ValidateTimeOffToSlots} />
                    : <SpinnerPrimaryButton text="Add TimeOff" executingText="Saving the Addon Information" onclick={AddTimeOffToSlots} />

            }
            <DefaultButton onClick={hideTimeOffModal}>Cancel</DefaultButton>
        </Stack>
    );

    const [displayMessages, displayMessagesSet] = useMessages();
    const [displayAddSchedulePanelMessages, displayAddSchedulePanelMessagesSet] = useMessages();
    const [displayAddTimeOffPanelMessages, displayAddTimeOffPanelMessagesSet] = useMessages();
    const [displayEditSchedulePanelMessages, displayEditSchedulePanelMessagesSet] = useMessages();

    const [isSlotEditModalOpen, { setTrue: showSlotEditModal, setFalse: hideSlotEditModal }] = useBoolean(false);

    useEffect(() => {
        if (dataSelectedScheduleForEdit && dataSelectedScheduleForEdit.startDate && dataSelectedScheduleForEdit.endDate) {
            slotNameSet(dataSelectedScheduleForEdit.name);
            startDateSet(new Date(dataSelectedScheduleForEdit.startDate));
            endDateSet(new Date(dataSelectedScheduleForEdit.endDate));
            isMonCheckedSet(dataSelectedScheduleForEdit.isMondayActive ?? false);
            isTueCheckedSet(dataSelectedScheduleForEdit.isTuedayActive ?? false);
            isWedCheckedSet(dataSelectedScheduleForEdit.isWeddayActive ?? false);
            isThuCheckedSet(dataSelectedScheduleForEdit.isThudayActive ?? false);
            isFriCheckedSet(dataSelectedScheduleForEdit.isFridayActive ?? false);
            selectedTimeZoneIdSet(dataSelectedScheduleForEdit.tmpTimeZone?.id);
            selectedTimeIdSet(dataSelectedScheduleForEdit.slotStart?.id);
            is1hrMinSlotSet(dataSelectedScheduleForEdit.is1HourSlot ?? true);
            is30MinSlotSet(dataSelectedScheduleForEdit.is30MinSlot ?? false);

            if (new Date(dataSelectedScheduleForEdit.startDate) < new Date()) {
                startDateEditDisabledSet(true);
            }
        }
        else {
            slotNameSet(undefined);
            startDateSet(undefined);
            endDateSet(undefined);
            isMonCheckedSet(true);
            isTueCheckedSet(true);
            isWedCheckedSet(true);
            isThuCheckedSet(true);
            isFriCheckedSet(false);
            selectedTimeZoneIdSet(undefined);
            selectedTimeIdSet(undefined);
            is1hrMinSlotSet(true);
            is30MinSlotSet(false);
        }
    }, [dataSelectedScheduleForEdit])
    const [selectedTimeZoneId, selectedTimeZoneIdSet] = useState<string>();
    const [selectedTimeId, selectedTimeIdSet] = useState<string>();
    const [startDateEditDisabled, startDateEditDisabledSet] = useState<boolean>(false);

    const [reviewsTobeRescheduled, reviewsTobeRescheduledSet] = useState<Review[]>([]);
    const [enableEditSchedule, enableEditScheduleSet] = useState<boolean>(false);

    const [applyTimeOffToAllSlots, applyTimeOffToAllSlotsSet] = useState<boolean>(true);
    const OnSelectAllSlots = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        if (isChecked && isChecked === true) {
            applyTimeOffToAllSlotsSet(true);
        }
        else {

            applyTimeOffToAllSlotsSet(false);
        }
    }
    useEffect(() => {
        if (selectedTimeOffSlots && selectedTimeOffSlots.length > 0) {
            enableAddTimeOffSet(true);
        }
    }, [selectedTimeOffSlots]);

    const visibilityOptions: IComboBoxOption[] = [
        { key: 7, text: '7 Days (1 week)' },
        { key: 15, text: '15 Days (~2 weeks)' },
        { key: 30, text: '30 Days (~1 month)' },
        { key: 45, text: '45 Days (~1.5 months)' },
        { key: 60, text: '60 Days (~2 months)' },
        // { key: 75, text: '75 Days (~2.5 months)' },
        // { key: 90, text: '90 Days (~3 months)' },
        // { key: 120, text: '120 Days (~4 months)' },
      ];
    const [selectedSlotVisibility,selectedSlotVisibilitySet] = useState<number>();
    const onVisibilityChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            selectedSlotVisibilitySet(option.key as number);
        }
    };

    return (<>
        <div className="container">
            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>

            <div className="row">
                <div className="d-flex">
                    <Stack horizontal tokens={stackTokens} className="me-auto">
                        <TeamsListDropdownComponent
                            label="Select your Review Team"
                            // selectedTeamId={dataSelectedReviewTeam?.id}
                            onTeamSelected={(selectedTeam?: Team) => {
                                reset();
                                dataTeamSchedulesSet([]);
                                dataTeamTimeOffsSet([]);
                                if (selectedTeam) {
                                    dataSelectedReviewTeamSet(selectedTeam);
                                }
                                else {
                                    dataSelectedReviewTeamSet(undefined);
                                    displayMessagesSet([{ message: "The team configuration could not be read.", messageType: DisplayMessageType.Error }]);
                                }
                            }
                            }

                        />

                    </Stack>
                    {
                        dataSelectedReviewTeam && dataSelectedReviewTeam.id &&
                        <Stack horizontal tokens={stackTokens} className="ms-auto" style={{ marginTop: 20, marginRight: 20 }}>
                            <Link to="#" onClick={() => { reset(); reviewsTobeRescheduledSet([]); showModal(); }} ><FontIcon iconName="Add" /> Add Slot</Link>
                            <Link to="#" onClick={() => { reset(); reviewsTobeRescheduledSet([]); enableAddTimeOffSet(false); applyTimeOffToAllSlotsSet(true); showTimeOffModal(); }} ><FontIcon iconName="Snooze" /> Add Time off</Link>
                        </Stack>
                    }
                </div>
            </div>
            { dataSelectedReviewTeam && dataSelectedReviewTeam.id &&
            <Pivot aria-label="Scheduling for the reviewers" >
                <PivotItem headerText="Schedules" itemIcon="AccountManagement" >
                    <div className="row">
                        {dataTeamSchedules && dataTeamSchedules.length > 0 &&
                            <DetailsList
                                items={dataTeamSchedules}
                                columns={teamScheduleColumns}
                                onRenderItemColumn={_renderItemColumn}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                constrainMode={ConstrainMode.horizontalConstrained}
                            />
                        }
                    </div>
                </PivotItem>
                <PivotItem headerText="Time Offs" itemIcon="Snooze">
                <div className="row">
                        {dataTeamTimeOffs && dataTeamTimeOffs.length > 0 &&
                            <DetailsList
                                items={dataTeamTimeOffs}
                                columns={teamTimeOffColumns}
                                onRenderItemColumn={_renderTimeOffColumn}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                constrainMode={ConstrainMode.horizontalConstrained}
                            />
                        }
                    </div>
                </PivotItem>
            </Pivot>
            }
        </div>

        {/* Add schedule panel */}
        <Panel
            type={PanelType.medium}
            isOpen={isModalOpen}
            onDismiss={hideModal}
            closeButtonAriaLabel = "Close"
            headerText={"Create a slot for " + dataSelectedReviewTeam?.name}
            onRenderFooterContent={onRenderScheduleFooterContent}
        >

            <div className="row">
                <p>Each slot corresponds to one Calendar invite / team's meeting, between start to end time time per day.</p>
            </div>

            <div className="row">
                <MessageComponent messages={displayAddSchedulePanelMessages} />
            </div>

            <div className="row">
                <TextField label="Slot Name" placeholder="Enter a friendly name for the slot" value={slotName} onChange={onSlotNameChange} />
            </div>

            <div className="row">
                <DatePicker
                    label="Start Date"
                    placeholder="Select a start date for the series"
                    ariaLabel="Select a start date"
                    onSelectDate={startDateSet as (date?: Date | undefined | null) => void}
                    minDate={minDate}
                    maxDate={maxDate}
                    allowTextInput
                />
            </div>
            <div className="row">
                <DatePicker
                    label="End Date"
                    placeholder="Select a end date for the series"
                    ariaLabel="Select a end date"
                    onSelectDate={endDateSet as (date?: Date | undefined | null) => void}
                    minDate={minDate}
                    maxDate={maxDate}
                    allowTextInput
                />
            </div>
            <div className="row">
                <div className="col">
                    <Label>Active Days</Label>
                    <Stack tokens={stackTokens} horizontal style={{ marginTop: 0 }} >
                        <Checkbox label="Mon" checked={isMonChecked} onChange={onMonChange} />
                        <Checkbox label="Tue" checked={isTueChecked} onChange={onTueChange} />
                        <Checkbox label="Wed" checked={isWedChecked} onChange={onWedChange} />
                        <Checkbox label="Thu" checked={isThuChecked} onChange={onThuChange} />
                        <Checkbox label="Fri" checked={isFriChecked} onChange={onFriChange} />
                    </Stack>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Label title="Time Zone">Time Zone</Label>
                    <TimeZoneDropdownComponent
                        onTimeZoneSelected={(selectedTimeZone?: TMPTimeZone) => {
                            if (selectedTimeZone) {
                                dataSelectedTimeZoneSet(selectedTimeZone);
                            }
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Label>Start Time</Label>
                    <TimeDropDownComponent
                        onTimeSelected={(selectedTime: MeetingTime) => {
                            dataSelectedTimeSet(selectedTime);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="row">
                    <Label> Slot Span </Label>
                </div>
                <div className="row">
                    <Stack tokens={stackTokens} horizontal style={{ marginTop: 0 }} >
                        <Checkbox label="1 hour" checked={is1hrMinSlot} onChange={on1hrSlotCheck} />
                        <Checkbox label="30 min" checked={is30MinSlot} onChange={on30MinSlotCheck} />
                    </Stack>
                </div>
            </div>
            
            <div className="row">
                <div className="row">
                    <Label> Free Slot visibility </Label>
                </div>
                <div className="row">
                   <ComboBox 
                    selectedKey= {selectedSlotVisibility}
                    onChange={onVisibilityChanged}
                    placeholder={"Number of days in schedule service teams can see slots as Available from today."}
                    options={visibilityOptions}
                    useComboBoxAsMenuWidth={true}/>
                </div>
            </div>

        </Panel>

        {/* Add time off panel */}
        <Panel
            type={PanelType.medium}
            isOpen={isTimeOffModalOpen}
            onDismiss={hideTimeOffModal}
            closeButtonAriaLabel = "Close"
            headerText={"Create Time Off for " + dataSelectedReviewTeam?.name}
            onRenderFooterContent={onRenderTimeOffFooterContent}
        >

            <div className="row">
                <p>Each slot corresponds to one Calendar invite / team's meeting, between start to end time time per day.</p>
            </div>

            <div className="row">
                <MessageComponent messages={displayAddTimeOffPanelMessages} />
            </div>

            <div className="row">
                <TimeZoneDropdownComponent
                    label="Select Timezone for the time off."
                    placeholder="Select Timezone for the time off."
                    onTimeZoneSelected={(selectedTimeZone?: TMPTimeZone) => {
                        if (selectedTimeZone) {
                            dataTeamSelectedTimeOffTzsSet(selectedTimeZone);
                        }
                    }}
                />
            </div>
            <div className="row">
                <DatePicker
                    label="Start Date"
                    placeholder="Select a start date for the time off"
                    ariaLabel="Select a start date"
                    onSelectDate={timeOffStartDateSet as (date?: Date | undefined | null) => void}
                    minDate={minDate}
                    maxDate={maxDate}
                    allowTextInput
                />
            </div>
            <div className="row">
                <DatePicker
                    label="End Date"
                    placeholder="Select a end date for the series"
                    ariaLabel="Select a end date"
                    onSelectDate={timeOffEndDateSet as (date?: Date | undefined | null) => void}
                    minDate={minDate}
                    maxDate={maxDate}
                    allowTextInput
                />
            </div>

            <div className="row">
                <TextField value={timeOffReason} onChange={onTimeOffReasonChange} label="Time off reason" placeholder="Enter a reason for hitting the snooze." />
            </div>
            {
                timeOffSlots && timeOffSlots.length > 0 &&
                <div className="row">

                    <Label>Select the schedule(s) to apply the Time Off</Label>
                    <Stack tokens={stackTokens}>
                        <Stack.Item>
                            <Checkbox label={"Apply to all slots (Active and Created in future)"} checked={applyTimeOffToAllSlots} onChange={OnSelectAllSlots} />
                        </Stack.Item>
                        <Stack.Item>
                            <Text>or choose specific Slots</Text>
                        </Stack.Item>
                        {
                            timeOffSlots.map(x => {
                                return (<>
                                    <Stack.Item>
                                        <Checkbox label={x.name} onChange={() => { OnSlotSelectedForTimeOff(x) }} />
                                    </Stack.Item>
                                </>)
                            })
                        }
                    </Stack>
                </div>
            }

            {
                reviewsTobeRescheduled && reviewsTobeRescheduled.length > 0 &&
                <div className="row">
                    <div className="col">
                        <Label>These reviews have to be rescheduled or cancelled before proceeding with Time Off as these are booked slots in the time zone.</Label>
                        {
                            reviewsTobeRescheduled.map(r => {
                                return <div className="row">
                                    <div className="col">
                                        <Link to={"/reviewdetail/" + r.id + "/" + r.service?.serviceId} target="_blank">{r.easyReviewId}</Link>
                                    </div>
                                    <div className="col">
                                        {getDateFormat(r.meetingsData?.dateOfReview)} {getTimeFormat(r.meetingsData?.dateOfReview)}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            }

        </Panel>
    </>);
}
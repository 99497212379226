import { Checkbox, FontIcon, IPersonaProps, Persona, PersonaSize, Separator, Stack, Text } from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react"
import { DraftFeedback } from "../../Models/Reviews/DraftFeedback";
import { FollowUps } from "../../Models/Reviews/FollowUps";
import UserProfile from "../../Models/UserProfile";
import { isEmptyOrSpaces } from "../../Utilities/textUtilities";
import { store } from "../Common/GlobalStateProvider";

export interface IReviewerFollowUpsComponent {
    items: FollowUps[],
    reviewerProfiles: UserProfile[],
    onFollowUpsSelected?: (selectedFollowUps?: FollowUps[]) => void,
    onFollowupEditClick?: (editTasksAndBugs?: FollowUps) => void,
    onFollowupDeleteClick?: (deleteTaskAndBug?: FollowUps) => void,
}

export const ReviewerDraftFollowUpsComponent: FC<IReviewerFollowUpsComponent> = (props: IReviewerFollowUpsComponent): JSX.Element => {

    const stackTokens = { childrenGap: 10, };
    var { appState, appdispatch } = useContext(store);

    const [draftfollowUptoServiceTeam, draftfollowUptoServiceTeamSet] = useState<FollowUps[]>([]);
    const [reviewerPersonas, reviewerPersonasSet] = useState<IPersonaProps[]>([]);

    const [selectedFollowUpsToServiceTeam, selectedFollowUpsToServiceTeamSet] = useState<FollowUps[]>([]);
    useEffect(() => {
        if (selectedFollowUpsToServiceTeam && selectedFollowUpsToServiceTeam.length > 0 && props.onFollowUpsSelected) {
            props.onFollowUpsSelected(selectedFollowUpsToServiceTeam);
        }
        else {
            if (props.onFollowUpsSelected) {
                props.onFollowUpsSelected([]);
            }
        }
    }, [selectedFollowUpsToServiceTeam]);

    useEffect(() => {
        if (props.items && props.items.length > 0) {
            draftfollowUptoServiceTeamSet(props.items);

            if (props.reviewerProfiles && props.reviewerProfiles.length > 0) {
                var tpersonaProps: IPersonaProps[] = [];
                props.reviewerProfiles.forEach(profile => {
                    let reviewProf: IPersonaProps = {
                        id: profile.userPrincipalName,
                        text: profile.displayName,
                        imageUrl: profile.photo
                    }
                    tpersonaProps.push(reviewProf);
                });

                reviewerPersonasSet(tpersonaProps);
            }
        }
        else {
            draftfollowUptoServiceTeamSet([]);
        }
    }, [props]);

    const [isFollowUpChecked, isFollowUpCheckedSet] = useState<boolean>(false);
    const followUpSelection = (followUp: FollowUps) => {
        var remainingSelectedFollowups = selectedFollowUpsToServiceTeam?.filter(x => x.id !== followUp.id);
        followUp.isSelected = !followUp.isSelected;
        if (followUp.isSelected) {
            selectedFollowUpsToServiceTeamSet([...remainingSelectedFollowups, followUp])
        }
        else {
            selectedFollowUpsToServiceTeamSet([...remainingSelectedFollowups])
        }
    };

    const onEditClicked = (f: FollowUps) => {
        if (props.onFollowupEditClick) {
            props.onFollowupEditClick(f);
        }
    }
    const onDeleteClicked = (f: FollowUps) => {
        var remainingTaksOrBugs = draftfollowUptoServiceTeam.filter(x => x.id !== f.id);
        draftfollowUptoServiceTeamSet(remainingTaksOrBugs)
        if (props.onFollowupDeleteClick) {
            props.onFollowupDeleteClick(f);
        }
    }

    return (
        <div className="row">
            {
                draftfollowUptoServiceTeam && draftfollowUptoServiceTeam?.map(followUptoServiceTeam =>
                    <div className="row" style={{ marginTop: 10 }}>
                        <div className="d-flex">
                            <Stack horizontal tokens={stackTokens}>
                                <Checkbox checked={followUptoServiceTeam.isSelected} onChange={() => { followUpSelection(followUptoServiceTeam) }} />
                                <Text>{followUptoServiceTeam.question}</Text>
                            </Stack>
                            <Stack className="ms-auto" horizontal tokens={stackTokens}>

                                {
                                    (followUptoServiceTeam.answer === undefined || followUptoServiceTeam.answer?.length === 0 || isEmptyOrSpaces(followUptoServiceTeam.answer)) &&
                                    <Stack tokens={stackTokens} horizontal className="ms-auto" style={{ marginTop: 5 }}>
                                        <div>
                                            {
                                                reviewerPersonas.filter(p => p.id == followUptoServiceTeam.createdBy)?.map(personProps =>
                                                    <Stack horizontal tokens={stackTokens}>
                                                        <Persona
                                                            {...personProps}
                                                            key={"fst_" + personProps.key}
                                                            title={personProps.text}
                                                            hidePersonaDetails={true}
                                                            size={PersonaSize.size24}
                                                        />
                                                        {/* <Text style={{ margin: 5 }}>{personProps.text}</Text> */}
                                                    </Stack>

                                                )
                                            }
                                        </div>
                                        {
                                            followUptoServiceTeam.createdBy === appState.userProfile?.userPrincipalName ? <FontIcon iconName="Edit" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} onClick={(e) => { onEditClicked(followUptoServiceTeam); e.stopPropagation(); }} />
                                                : <FontIcon iconName="Copy" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} title="Clone and make changes"
                                                    onClick={(e) => {
                                                        onEditClicked(followUptoServiceTeam);
                                                        e.stopPropagation();
                                                    }} />
                                        }
                                        {followUptoServiceTeam.createdBy === appState.userProfile?.userPrincipalName &&
                                            <FontIcon iconName="Delete" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} onClick={() => { onDeleteClicked(followUptoServiceTeam)}} />
                                        }
                                    </Stack>
                                }
                            </Stack>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
import { Button, DefaultButton, FontIcon, Icon, IconButton, IIconProps, Panel, PanelType } from "@fluentui/react"
import { FC, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { store } from "../Common/GlobalStateProvider"
import SideBarMenuItemComponent from '../Common/SideBarMenuItemComponent'

interface ISideBarComponentProps {
    IsAdmin: boolean,
    IsReviewer: boolean,
    IsAuditor: boolean,
    IsReviewReader: boolean,
    isServiceReader: boolean
}
export const SideBarComponent: FC<ISideBarComponentProps> = (props): JSX.Element => {
    const [isMinimized, isMinimizedSet] = useState<boolean>(true);
    const [menuClassName, menuClassNameSet] = useState<string>("flex-shrink-0 p-3 bg-white app-menu");
    var { appState, appdispatch } = useContext(store);
    
    const menu = [
        {
            id: 'leftmenu_home',
            title: 'Home',
            iconName: 'Home',
            navigationPath: '/',
            isVisible: true
        },
        {
            id: 'leftmenu_artifacts',
            title: 'Artifacts',
            iconName: 'DocumentSet',
            navigationPath: '/artifacts',
            isVisible: ((appState?.userServiceTreeServies?.length !== undefined && appState?.userServiceTreeServies?.length > 0) || (props.isServiceReader)) ? true : false
        },
        {
            id: 'leftmenu_reviewersearch',
            title: 'Search',
            iconName: 'Search',
            navigationPath: '/search',
            isVisible: (props.IsReviewer || props.IsReviewReader)
        },
        {
            id: 'leftmenu_reviewer',
            title: 'Reviewer',
            iconName: 'UserEvent',
            navigationPath: '/reviewer',
            subItems: [
                {
                    id: 'leftmenu_reviewer_schedules',
                    title: 'Schedules',
                    iconName: 'Calendar',
                    navigationPath: '/AddSchedulesPage',
                    isVisible: props.IsReviewer
                },
                {
                    id: 'leftmenu_reviewer_emailcontent',
                    title: 'EmailContent',
                    iconName: 'EditMail',
                    navigationPath: '/emailcustomize',
                    isVisible: props.IsReviewer
                },
                {
                    id: 'leftmenu_reviewer_questionnaire',
                    title: 'Questionnaire',
                    iconName: 'SurveyQuestions',
                    navigationPath: '/question',
                    isVisible: props.IsReviewer
                },
                {
                    id: 'leftmenu_reviewer_configurations',
                    title: 'Configurations',
                    iconName: 'ConfigurationSolid',
                    navigationPath: '/configurations',
                    isVisible: props.IsReviewer
                }
            ],
            isVisible: (props.IsReviewer || props.IsReviewReader)
        },
        {
            id: 'leftmenu_auditorsearch',
            title: 'Auditor',
            iconName: 'ProfileSearch',
            navigationPath: '/auditor',
            isVisible: props.IsAuditor
        },
        {
            id: 'leftmenu_admin',
            title: 'Admin',
            iconName: 'Admin',
            navigationPath: '/admin',
            isVisible: props.IsAdmin,
            subItems: [
                {
                    id: 'leftmenu_admin_appids',
                    title: 'API App(s)',
                    iconName: 'AzureAPIManagement',
                    navigationPath: '/admin/apps',
                    isVisible: props.IsAdmin
                }
            ]
        }
    ]

    function open() {
        isMinimizedSet(!isMinimized);
        if (isMinimized) {
            menuClassNameSet("flex-shrink-0 p-3 bg-white app-menu")
        }
        else {
            menuClassNameSet("flex-shrink-0 p-3 bg-white app-menu-open")
        }
    }

    function close() {
        isMinimizedSet(true);
    }

    return (
        // className={menuClassName}
        <div style=
            {{
                width: !isMinimized ? 250 : 80,
                border: 'solid rgba(0, 0, 0, 0)', 
                boxShadow: '5px 49px 2px 0px rgb(0 0 0 / 12%)',
                // boxShadow: 'rgb(0 0 0 / 0%) 0px 25.6px 57.6px 0px, rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px',
                minWidth:80,
                paddingLeft:20,
                paddingRight:0,
                transition: '0.2s',
                height:'100%',
                position: 'fixed',
                left: 0,
                top:0,
                paddingTop:0,
                zIndex:999,
            }} >
           <div style={{height:48}}></div>
           <div style={{background:'white', height:'100%', paddingRight:20}}>
            <ul className="list-unstyled ps-0" style={{paddingLeft:30 , background:'white'}}>
                <li className="mb-2">
                    <div onClick={open} >
                        <SideBarMenuItemComponent title="" iconName="AlignJustify" navigationPath="#" isMinimized={isMinimized} close={close} />
                    </div>
                </li>
                {
                    menu.map(function (item, index) {
                        if (item.isVisible) {
                            return <li key={"main_" + index} className="mb-2"><SideBarMenuItemComponent id={item.id} title={item.title} iconName={item.iconName} navigationPath={item.navigationPath} items={item.subItems} isMinimized={isMinimized} close={close} /></li>
                        }
                    })
                }
            </ul>
            </div>
        </div>
    );
}

export default SideBarComponent
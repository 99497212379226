import { Spinner, SpinnerSize, Stack, Toggle, Text } from "@fluentui/react";
import { FC, useEffect, useRef, useState } from "react";

interface ICountdownTimer {
    seconds: number,
    callback: any,
    coundowntext: string,
    callbackExecutingText: string,
    label: string
}
export const CountdownTimer: FC<ICountdownTimer> = (props: ICountdownTimer): JSX.Element => {

    const Ref = useRef<any>(null);
    const stackTokens = { childrenGap: 10, };
    const [timerSeconds, timerSecondsSet] = useState<number>(0);
    const [iscallbackRunning, iscallbackRunningSet] = useState<boolean>(false);
    
    const [isAutoSaveOn, isAutoSaveOnSet] = useState<boolean>(false);
    useEffect(()=>{
        if (isAutoSaveOn)
        {
            timerSecondsSet(props.seconds);
        }
    }, [isAutoSaveOn]);

    function onAutoSaveChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (checked) {
            isAutoSaveOnSet(true);
            createTimer();
        }
        else {
            isAutoSaveOnSet(false);
            clearTimer();
        }
    }

    function createTimer() {
        if (isAutoSaveOn) {
            const timerId = setInterval(() => tick(), 1000);
            Ref.current = timerId;
        }
    }
    function clearTimer() {
        if (Ref.current) {
            clearInterval(Ref.current);
        }

    }
    const tick = () => {
        timerSecondsSet(timerSeconds - 1);
    }

    const callCallback = async () => {
        iscallbackRunningSet(true);
        await props.callback();
        iscallbackRunningSet(false);
        timerSecondsSet(props.seconds);
    }
    useEffect(() => {
        if (timerSeconds == 1) {
            callCallback();
        }
    }, [timerSeconds]);

    useEffect(() => {
        createTimer();
        return () => clearTimer();
    });

    return (<>
        
        <Stack horizontal tokens={stackTokens}>
        
            {
                iscallbackRunning && isAutoSaveOn &&
                <Spinner size={SpinnerSize.medium} label={props.callbackExecutingText} title={props.callbackExecutingText} />
            }
            {
                !iscallbackRunning && isAutoSaveOn &&
                <Text style={{ marginTop: 10 }}>{props.coundowntext} {timerSeconds} seconds</Text>
            }
            
            <Toggle label={props.label} inlineLabel defaultChecked onText="On" offText="Off" onChange={onAutoSaveChange} checked={isAutoSaveOn} />
        </Stack>
        
    </>);
}
import { CommandBarButton, getPropsWithDefaults, IButtonStyles, ITextFieldProps, Label, Stack } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FC, useEffect, useState } from "react";
import ServiceTreeService from "../../Models/ServiceTreeService";
import UserProfile from "../../Models/UserProfile";
import UserService from "../../Services/UserService";
import { MsPeoplePickerComponent } from "./MsPeoplePickerComponent";

interface IServiceMemberPeoplePickerComponent {
    id?: string
    serviceId?: string
    serviceData?: ServiceTreeService,
    itemLimit: number,
    label?: string,
    labelServiceTreeMemberButton?: string,
    onUserSelected: (selectedUsers: UserProfile[]) => void,
}

export const ServiceMemberPeoplePickerComponent: FC<IServiceMemberPeoplePickerComponent> = (props: IServiceMemberPeoplePickerComponent): JSX.Element => {
    let userService: UserService = new UserService();
    const customLabelId = useId('customlabel');
    const [otherUsers, otherUsersSet] = useState<UserProfile[]>([]);
    const [serviceTreeService, serviceTreeServiceSet] = useState<ServiceTreeService>();


    useEffect(() => {
    }, [])
    useEffect(() => {
        if (props.serviceData) {
            console.log("Found service tree data");
            serviceTreeServiceSet(props.serviceData);
        }
        
    }, [props])

    useEffect(() => {
        if(!props.serviceData)
        {
            serviceTreeServiceSet(undefined);
            otherUsersSet([]);
            props.onUserSelected([]);
        }
    }, [props.serviceData]);

    const onAddServiceTreeMembers = async () => {
        var devOwnerAliases: string[] = [];
        var pmOwnerAliases: string[] = [];
        var adminAliases: string[] = [];

        if (serviceTreeService?.devOwners) {
            devOwnerAliases = serviceTreeService.devOwners.split(';');
        }

        if (serviceTreeService?.pMOwners) {
            pmOwnerAliases = serviceTreeService.pMOwners.split(';');
        }

        if (serviceTreeService?.admins) {
            adminAliases = serviceTreeService.admins.split(';');
        }

        var aliasesArray = [...devOwnerAliases, ...pmOwnerAliases, ...adminAliases];
        if (aliasesArray && aliasesArray.length > 0) {
            var response = await userService.getUserProfiles(aliasesArray);
            if (response && response.length > 0) {
                otherUsersSet([...otherUsers, ...response]);
                props.onUserSelected([...otherUsers, ...response]);
            }
        }
    }
    const OtherSerivceTreeMembersLabel = (props: ITextFieldProps): JSX.Element => {

        const iconButtonStyles: Partial<IButtonStyles> = { root: { marginBottom: 0 } };
        return (
            <>
                <Stack horizontal style={{}} >
                    <Label id={props.id}>{props.label}</Label>
                    <CommandBarButton
                        className="ms-auto"
                        id="AddOtherServiceTreeMembers"
                        iconProps={{ iconName: "Add" }}
                        text={"Add Service Tree members"}
                        styles={iconButtonStyles}
                        onClick={onAddServiceTreeMembers}
                        disabled = {serviceTreeService ? false : true}
                    />
                </Stack>
            </>
        );
    }

    return (<div className="col">
        <OtherSerivceTreeMembersLabel id={customLabelId} label={props.label ? props.label : " Other Team Members"} />
        {otherUsers && (<MsPeoplePickerComponent
            id={props.id}
            itemLimit={props.itemLimit}
            selectedUsers={otherUsers}
            onUserSelected={(selectedUsers: UserProfile[]) => {
                if (selectedUsers && selectedUsers.length > 0) {
                    otherUsersSet(selectedUsers);
                    props.onUserSelected(selectedUsers);
                }
                else {
                    otherUsersSet([]);
                    props.onUserSelected([]);
                }
            }} /> )}

    </div>);
}
import { Checkbox, CommandBarButton, DefaultButton, FontIcon, Label, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { ArtifactTypeStrings } from "../../Models/Constants/ArtifactTypeStrings";
import { Artifact } from "../../Models/TMPArtifacts/Artifact";
import { ArtifactDownload } from "../../Models/TMPArtifacts/ArtifactDownload";
import { LinkedArtifact } from "../../Models/TMPArtifacts/LinkedArtifact";
import { ArtifactService } from "../../Services/ArtifactService";
import { htmlEscape } from "../../Utilities/textUtilities";
import { FileTypeLogoComponent } from "./FileTypeLogoComponent";
import { store } from "./GlobalStateProvider";
import { DisplayMessageType, MessageComponent, useMessages } from "./MessageComponent";

interface IUploadOrLinkArtifactsComponent {
    id?: string
    reviewId: string,
    serviceId: string,
    serviceName: string,
    onArtifactUploaded: (selectedServcices: LinkedArtifact[]) => void,
    onArtifactLinked: (selectedServcices: LinkedArtifact[]) => void,
}

// Place the widget in a column always.
export const UploadOrLinkArtifactsComponent: FC<IUploadOrLinkArtifactsComponent> = (props: IUploadOrLinkArtifactsComponent): JSX.Element => {
    let artifactService: ArtifactService = new ArtifactService();
    var { appState, appdispatch } = useContext(store);
    
    useEffect(() => {
        linkedServiceArtifactsSet([]);
    }, []);

    const stackTokens = { childrenGap: 10, };

    const [artifacts, artifactsSet] = useState<Artifact[]>([]);
    const fileRef = useRef<HTMLInputElement>(null);
    const fileRefOther = useRef<HTMLInputElement>(null);
    const handleChange = async (event: any) => {
        displayMessagesSet([]);
        isUploadInProgressSet(true);
        var tFiles: Artifact[] = [];

        var fileSizes = 0;
        if (event.target.files.length > 0) {
            for (var i = 0; i < event.target.files.length; i++) {
                fileSizes = fileSizes + event.target.files[i].size;
            }

            if (fileSizes > 25 * 1024 * 1024) {
                console.log(fileSizes);
                displayMessagesSet([{ message: "The total upload size cannot exceed 25MB at one go", messageType: DisplayMessageType.Warning }]);
                isUploadInProgressSet(false);
                return;
            }
        }
        console.log(event.target.files)
        console.log(event);
        for (var i = 0; i < event.target.files.length; i++) {
            tFiles.push({
                clientId: crypto.randomUUID(),
                reviewId: props.reviewId,
                artifactname: event.target.files[i].name,
                artifactType: ArtifactTypeStrings.File,
                serviceId: props.serviceId,
                uploadedFile: event.target.files[i]
            });
        }

        var uploadResult = await artifactService.uploadArtifactForMeeting(props.serviceId, props.reviewId, tFiles);
        if (uploadResult && !uploadResult.hasErrors && uploadResult.result && uploadResult.result.length > 0) {
            props.onArtifactUploaded(uploadResult.result);
        } else if (uploadResult && uploadResult.hasErrors && uploadResult.businessErrors && uploadResult.businessErrors.length > 0 && uploadResult.businessErrors[0].message) {
            displayMessagesSet([{ message: uploadResult.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
        } else {
            displayMessagesSet([{ message: "Error occurred while uploading", messageType: DisplayMessageType.Error }]);
        }



        isUploadInProgressSet(false);
    };
    const onLinkArtifactsClick = async () => {
        displayMessagesSet([]);
        var linkArtifacts = linkedServiceArtifacts.map(x => x.artifactId) as string[];
        var linkResult = await artifactService.linkArtifactForMeeting(props.serviceId, props.reviewId, linkArtifacts);
        console.log(linkResult);
        if (linkResult && !linkResult.hasErrors && linkResult.result && linkResult.result.length > 0) {
            console.log("in component");
            console.log(linkResult.result);
            props.onArtifactLinked(linkResult.result);
        } else if (linkResult && linkResult.hasErrors && linkResult.businessErrors && linkResult.businessErrors.length > 0 && linkResult.businessErrors[0].message) {
            displayMessagesSet([{ message: linkResult.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
        }
        else {
            displayMessagesSet([{ message: "Error occurred while linking the file", messageType: DisplayMessageType.Error }]);
        }

        linkedServiceArtifactsSet([]);
    }

    const [serviceArtifacts, serviceArtifactsSet] = useState<ArtifactDownload[]>([]);
    const [isLinkArtifactModalOpen, { setTrue: showArtifactLinkModal, setFalse: hideArtifactLinkModal }] = useBoolean(false);
    const onRenderScheduleFooterContent = () => (
        <Stack horizontal tokens={stackTokens}>
            <PrimaryButton id={props.id + "_linkButton"} text="Link" onClick={() => {
                onLinkArtifactsClick();
                hideArtifactLinkModal();
            }} />
            <DefaultButton onClick={hideArtifactLinkModal}>Cancel</DefaultButton>
        </Stack>
    );
    const loadArtifactsForService = async () => {
        isLoadingArtifactsSet(true);
        serviceArtifactsSet([]);
        if (props.serviceId) {
            var response = await artifactService.fetchArtifactsByServiceId(props.serviceId);
            if (response && !response.hasErrors && response.result && response.result.length > 0) {
                serviceArtifactsSet(response.result);
            }
            else if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            } else {
                displayMessagesSet([{ message: "Error occurred fetching the artifacts.", messageType: DisplayMessageType.Error }]);
            }
        }

        isLoadingArtifactsSet(false);
    }
    const [isLoadingArtifacts, isLoadingArtifactsSet] = useState<boolean>(false);
    const [linkedServiceArtifacts, linkedServiceArtifactsSet] = useState<ArtifactDownload[]>([]);
    const onChangeLinkedArtifacts = async (artifact?: ArtifactDownload, checked?: boolean) => {
        if (checked && artifact && artifact.artifactId) {

            linkedServiceArtifactsSet([...linkedServiceArtifacts, artifact]);
        }
        else {
            if (artifact && artifact.artifactId) {

                console.log(linkedServiceArtifacts);
                var remainingArtifacts = linkedServiceArtifacts.filter(x => x.artifactId != artifact.artifactId);
                console.log(remainingArtifacts);
                linkedServiceArtifactsSet([...remainingArtifacts]);
            }
        }
    }
    const [isUploadInProgress, isUploadInProgressSet] = useState<boolean>(false);
    const [isLinkingInProgress, isLinkingInProgressSet] = useState<boolean>(false);

    const [displayMessages, displayMessagesSet] = useMessages();

    return (<>

        <div className="row">
            <Stack horizontal>
                <StackItem>
                    <CommandBarButton id={props.id + "_uploadTMFile"} iconProps={{ iconName: 'Upload' }} text="Upload TM file" onClick={() => {
                        if (fileRef && fileRef.current) {
                            fileRef.current.click();
                        }
                    }}>

                    </CommandBarButton>
                    <input id="upload" name="upload" type="file" ref={fileRef} onChange={handleChange} hidden multiple accept=".tm7,.tm8" />
                </StackItem>
                <StackItem>
                    <CommandBarButton id={props.id + "_uploadOtherFile"} iconProps={{ iconName: 'Upload' }} text="Upload other artifacts" onClick={() => {
                        if (fileRefOther && fileRefOther.current) {
                            fileRefOther.current.click();
                        }

                    }} />
                    <input id="uploadOther" name="uploadOther" type="file" ref={fileRefOther} onChange={handleChange} hidden multiple accept=".msg, .xlsx, .csv, .pdf,.doc,.docx,.jpg,.png,.jpeg,.ppt,.pptx,.vsd,.vsdx,.md" />
                </StackItem>
                { 
                appState.userServiceTreeServies && appState.userServiceTreeServies.some(x => x.serviceId === props.serviceId) &&
                <StackItem>
                    <CommandBarButton id={props.id + "_linkArtifacts"} iconProps={{ iconName: 'Link' }} text="Link Security Artifact" onClick={() => { loadArtifactsForService(); showArtifactLinkModal(); }} />
                </StackItem>
                }
            </Stack>
        </div>
        {
            (isUploadInProgress || isLinkingInProgress) &&
            <div className="row">
                <Spinner label="Uploading artifacts" size={SpinnerSize.small} />
            </div>
        }

        {
            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>
        }


        {/* The link artifact modal */}
        <Panel
            id={props.id + "_linkArtifactPanel"}
            type={PanelType.medium}
            isOpen={isLinkArtifactModalOpen}
            onDismiss={hideArtifactLinkModal}
            closeButtonAriaLabel = "Close"
            headerText={"Artifacts"}
            onRenderFooterContent={onRenderScheduleFooterContent}
        >

            <div className="row">
                <p>Select from existing artifacts in service {props.serviceName}.</p>
            </div>

            <div className="row" style={{ maxHeight: 1000, overflowY: 'scroll' }}>
                {
                    isLoadingArtifacts &&
                    <div className="row">
                        <Spinner label="Loading artifacts" size={SpinnerSize.large} />
                    </div>
                }
                {
                    !isLoadingArtifacts &&
                    <div className="row" >
                        {
                            serviceArtifacts.map(artifact => {
                                return <>
                                    <div className="row" style={{ marginTop: 5 }} key={"artifact_" + artifact.artifactId}>
                                        <Stack horizontal>
                                        <Checkbox onChange=
                                            {(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => { onChangeLinkedArtifacts(artifact, checked) }} />
                                        <FileTypeLogoComponent
                                            artifactType={artifact.artifactType as string}
                                            fileName={artifact.fileName}
                                        />
                                        <Text style={{marginLeft: 3}} title={artifact.artifactType == ArtifactTypeStrings.File ?  artifact.fileName : artifact.artifactUrl}>{artifact.artifactTitle}</Text>
                                        </Stack>
                                    </div>
                                </>
                            })
                        }
                    </div>
                }
            </div>
        </Panel>
    </>);
}

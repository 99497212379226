import { FontIcon } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { ArtifactTypeStrings } from "../../Models/Constants/ArtifactTypeStrings";

interface IFileTypeLogoComponent
{
    artifactType: string,
    fileName?: string,
}

export const FileTypeLogoComponent: FC<IFileTypeLogoComponent> = (props:IFileTypeLogoComponent) : JSX.Element => {
    
    const [fileExtension, fileExtensionSet] = useState<string>();

    useEffect(()=>{
    },[]);

    useEffect(()=>{
        if (props.fileName)
        {
            fileExtensionSet(props.fileName.substring(props.fileName.lastIndexOf('.')+1, props.fileName.length).toLocaleLowerCase() || props.fileName.toLocaleLowerCase());
        }
    },[props]);

    if (props.artifactType == ArtifactTypeStrings.HyperLink)
    {
        return <FontIcon iconName="Link" />
    }

    // .tm7,.tm8,.pdf,.doc,.docx,.jpg,.png,.jpeg,.ppt,.pptx,.vsd,.vsdx
    if (props.artifactType == ArtifactTypeStrings.File)
    {

        if (fileExtension == "tm7" || fileExtension == "tm8")
        {
            return <FontIcon iconName="AuthenticatorApp" />
        }

        if (fileExtension == "xlsx" || fileExtension == "csv")
        {
            return <FontIcon iconName="ExcelDocument" />
        }

        if (fileExtension == "msg")
        {
            return <FontIcon iconName="Mail" />
        }

        if (fileExtension == "pdf")
        {
            return <FontIcon iconName="PDF" />
        }

        if (fileExtension == "doc" || fileExtension == "docx")
        {
            return <FontIcon iconName="WordDocument" />
        }

        if (fileExtension == "jpg" || fileExtension == "png" || fileExtension == "jpeg")
        {
            return <FontIcon iconName="ImageSearch" />
        }

        if (fileExtension == "ppt" || fileExtension == "pptx")
        {
            return <FontIcon iconName="PowerPointDocument" />
        }

        if (fileExtension == "vsd" || fileExtension == "vsdx")
        {
            return <FontIcon iconName="VisioDocument" />
        }

        if (fileExtension == "zip")
        {
            return <FontIcon iconName="ZipFolder" />
        }

        return <FontIcon iconName="TextDocument" />
    }

    return (<>
    </>);
}
import { IBasePickerSuggestionsProps, ITag, TagPicker } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { ServiceTreeService } from "../../Models/ServiceTreeService";
import STServices from "../../Services/STServices";

interface IServiceTreeServicePickerComponent {
    id?: string,
    itemLimit: number,
    placeholder?: string,
    onServiceSelected: (selectedServices: ServiceTreeService[]) => void,
    SelectedServices?: ServiceTreeService[],
}

export const ServiceTreeServicePickerComponent: FC<IServiceTreeServicePickerComponent> = (props: IServiceTreeServicePickerComponent): JSX.Element => {
    let stService = new STServices();

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested Services',
        noResultsFoundText: 'No matching ServiceTree Service found',
    };

    const [dataSuggestedServiceTreeServices, dataSuggestedServiceTreeServicesSet] = useState<ServiceTreeService[]>([]);
    const [dataSelectedServiceTreeService, dataSelectedServiceTreeServiceSet] = useState<ServiceTreeService[]>([]);
    const [tSelectedService, tSelectedServiceSet] = useState<ServiceTreeService>();
    const [selectedService, selectedServiceSet] = useState<ITag[]>([]);
    const serviceChangeSuggestedTags = async (filter: string, selectedItems?: ITag[] | undefined): Promise<ITag[]> => {
        var stServices = await stService.getServicesByNameOrId(filter);
        var serviceTreeSuggestions: ITag[] = [];
        dataSuggestedServiceTreeServicesSet([]);
        if (stServices && !stServices.hasErrors && stServices.result) {
            stServices.result.forEach(service => {
                if (service.serviceId && service.serviceName) {
                    serviceTreeSuggestions.push({
                        key: service.serviceId,
                        name: service.serviceName
                    });
                }
            });

            dataSuggestedServiceTreeServicesSet(stServices.result);
        }
        return serviceTreeSuggestions;
    };

    const onServiceChanged = (items?: ITag[] | undefined) => {
        console.log('changed');

        if (items && items.length > 0) {
            if (items.length > dataSelectedServiceTreeService.length) {
                let newItems = items.filter(x => !dataSelectedServiceTreeService.some(y => y.serviceId === x.key));
                if (newItems && newItems.length > 0) {
                    let serviceTreeItemAdded = dataSuggestedServiceTreeServices.filter(x => x.serviceId == newItems[0].key);
                    // dataSelectedServiceTreeServiceSet([...dataSelectedServiceTreeService, serviceTreeItemAdded[0]]);
                    props.onServiceSelected([...dataSelectedServiceTreeService, serviceTreeItemAdded[0]]);
                }

            } else {
                console.log("item removed");
                var newSelection = dataSelectedServiceTreeService.filter(x => items.find(y => y.key == x.serviceId));
                // dataSelectedServiceTreeServiceSet([...newSelection]);
                props.onServiceSelected([...newSelection]);
            }


        }
        else {
            dataSelectedServiceTreeServiceSet([]);
            props.onServiceSelected([]);

        }
    }

    const onServiceTreeServiceSelected = (selectedItem?: ITag | undefined): ITag => {
        console.log('selected');
        // if (selectedItem) {
        //     var sService = dataSuggestedServiceTreeServices.filter(x => x.serviceId === selectedItem.key);
        //     if (sService && sService.length > 0 && sService[0]) {
        //         {
        //         }
        //     }
        // }

        return selectedItem!;
    }

    const setSelectedServices = () => {
        let ttags: ITag[] = [];
        if (dataSelectedServiceTreeService && dataSelectedServiceTreeService.length > 0) {
            dataSelectedServiceTreeService?.forEach(x => {
                if (x && x.serviceId && x.serviceName) {
                    ttags.push(
                        {
                            key: x.serviceId,
                            name: x.serviceName
                        });
                }
            });

            selectedServiceSet(ttags);
        }
        else {
            selectedServiceSet([]);
        }
    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (props.SelectedServices && props.SelectedServices.length > 0) {
            var newServices = props.SelectedServices.filter(x => !dataSelectedServiceTreeService.some(y => y.serviceId === x.serviceId))
            if (newServices && newServices.length > 0) {
                dataSelectedServiceTreeServiceSet([...dataSelectedServiceTreeService, ...newServices]);
            }
            else {
                dataSelectedServiceTreeServiceSet([...props.SelectedServices]);
            }

        }
        else {
            dataSelectedServiceTreeServiceSet([]);
        }
    }, [props]);

    useEffect(() => {
        setSelectedServices();
    }, [dataSelectedServiceTreeService]);

    return (
        <TagPicker
            aria-label="Service picker"
            removeButtonAriaLabel="Remove"
            selectionAriaLabel="Selected service"
            resolveDelay={400}
            onResolveSuggestions={serviceChangeSuggestedTags}
            selectedItems={selectedService}
            onChange={onServiceChanged}
            onItemSelected={onServiceTreeServiceSelected}
            itemLimit={props.itemLimit}
            pickerSuggestionsProps={pickerSuggestionsProps}
            inputProps={{
                id: props.id,
                placeholder: props.placeholder
            }}
        />
    );
}
import { FC, useEffect, useState } from "react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { ReviewType } from "../../Models/Reviews/ReviewType";
import MeetingsService from "../../Services/MeetingsService";
import { CommandBarButton, ConstrainMode, DefaultButton, DetailsList, DetailsListLayoutMode, FontIcon, IColumn, Label, Panel, PanelType, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, Text, TextField, Toggle } from "@fluentui/react";
import HeadingComponent from "../Common/HeadingComponent";
import { MessageComponent } from "../Common/MessageComponent";
import { SpinnerPrimaryButton } from "../Common/SpinnerPrimaryButton";

interface IReviewTypeEditComponent {
    label?: string,
    loadingText?: string,
}
export const ReviewTypeEditComponent: FC<IReviewTypeEditComponent> = (props: IReviewTypeEditComponent): JSX.Element => {

    let reviewService: MeetingsService = new MeetingsService();
    const stackTokens = { childrenGap: 10, };
    const [isLoadingData, isLoadingDataSet] = useState<boolean>(false);
    const [isComponentLoadError, isComponentLoadErrorSet] = useState<boolean>(false);
    const [dataReviewTypes, dataReviewTypesSet] = useState<ReviewType[]>([]);

    const [reviewTypeId, reviewTypeIdSet] = useState<string>();
    const [reviewTypeIsActive, reviewTypeIsActiveSet] = useState<boolean>();
    const [reviewTypeText, reviewTypeTextSet] = useState<string>();
    const onReviewTypeTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        reviewTypeTextSet(newValue);
    }

    const [workItemsColumns, workItemsColumnsSet] = useState<IColumn[]>([
        {
            key: 'ReviewType',
            name: 'Review Type',
            minWidth: 500
        },
        {
            key: 'IsActive',
            name: 'Is Active',
            minWidth: 100
        },
        {
            key: 'QuickActions',
            name: 'Actions ',
            minWidth: 100
        }
    ]);
    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var reviewTaskTypeItem = item as ReviewType;
        if (column && reviewTaskTypeItem) {
            switch (column!.key) {
                case 'ReviewType':
                    return <Text> {reviewTaskTypeItem.name}</Text>;
                case 'IsActive':
                    return <Text> {reviewTaskTypeItem.isActive ? "Yes" : "No"}</Text>;
                case 'QuickActions':
                    return <Stack horizontal tokens={stackTokens}>
                        <Stack.Item>
                            <FontIcon iconName="Edit" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} title="Edit"
                                onClick={(e) => {
                                    onEditClicked(reviewTaskTypeItem);
                                    e.stopPropagation();
                                }} />
                        </Stack.Item>
                    </Stack>
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    const loadRevieweTypes = async () => {
        isLoadingDataSet(true);
        var reviewTypesResponse = await reviewService.getReviewTypes();
        if (reviewTypesResponse && !reviewTypesResponse.hasErrors && reviewTypesResponse.result && reviewTypesResponse.result.length > 0) {
            dataReviewTypesSet(reviewTypesResponse.result);
        }
        else {
            isComponentLoadErrorSet(true);
        }

        isLoadingDataSet(false);
    }

    const onEditClicked = async (wkItem: ReviewType) => {
        showEditModal();
        reviewTypeIdSet(wkItem.id);
        reviewTypeIsActiveSet(wkItem.isActive);
        reviewTypeTextSet(wkItem.name);
    }

    const [isAddModalOpen, { setTrue: showAddModal, setFalse: hideAddModal }] = useBoolean(false);
    useEffect(() => {
        if (!isAddModalOpen) {
            clearReviewType();
        }
    }, [isAddModalOpen])

    const [isEditModalOpen, { setTrue: showEditModal, setFalse: hideEditModal }] = useBoolean(false);
    useEffect(() => {
        if (!isEditModalOpen) {
            clearReviewType();
        }
    }, [isEditModalOpen])

    function clearReviewType() {
        reviewTypeIdSet("");
        reviewTypeIsActiveSet(false);
        reviewTypeTextSet("");
    }

    useEffect(() => {
        loadRevieweTypes();
    }, []);

    const createReviewType = async () => {
        let rType: ReviewType = {
            isActive: reviewTypeIsActive ?? false,
            name: reviewTypeText,
        };
        var response = await reviewService.createReviewType(rType);
        if (response && !response.hasErrors && response.result) {
            hideAddModal();
            dataReviewTypesSet(response.result);
        }
        else {

        }
    }


    const editReviewType = async () => {
        let rType: ReviewType = {
            id: reviewTypeId,
            isActive: reviewTypeIsActive ?? false,
            name: reviewTypeText,
        };
        var response = await reviewService.editReviewType(rType);
        if (response && !response.hasErrors && response.result) {
            hideEditModal();
            dataReviewTypesSet(response.result);
        }
        else {

        }
    }

    const onReviewTypeAddFooterContent = () => {
        return (
            <Stack horizontal tokens={stackTokens}>
                <SpinnerPrimaryButton text="Add" onclick={createReviewType} executingText="Add the review type" />
                <DefaultButton onClick={hideAddModal}>Cancel</DefaultButton>
            </Stack>
        );
    }

    const onReviewTypeEditFooterContent = () => {
        return (
            <Stack horizontal tokens={stackTokens}>
                <SpinnerPrimaryButton text="Save" onclick={editReviewType} executingText="Editing the review type" />
                <DefaultButton onClick={hideEditModal}>Cancel</DefaultButton>
            </Stack>
        );
    }

    function onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (checked != undefined) {
            reviewTypeIsActiveSet(checked);
        }
    }

    if (isLoadingData) {
        return <div className="row">
            <Label>{props.label}</Label>
            <Spinner size={SpinnerSize.large} label={props.loadingText ? props.loadingText : "loading the review types."} />
        </div>
    }

    if (isComponentLoadError) {
        return <div className="row">
            <Label>{props.label}</Label>
            <Text>Could not load the review types.</Text>
        </div>
    }

    return (<>
        <div className="row">
            <div className="row">
                <div className="d-flex">
                    <Stack horizontal tokens={stackTokens}>
                        <HeadingComponent title="Review Types" />
                    </Stack>

                    <Stack horizontal tokens={stackTokens} className="ms-auto" style={{ marginRight: 500 }}>
                        <StackItem>
                            <CommandBarButton id={"btnAddbugorTask"} iconProps={{ iconName: 'Add' }} text="Add Review Type" onClick={() => {
                                showAddModal();
                            }} />
                        </StackItem>
                    </Stack>
                </div>
            </div>
            <div className="row">
                <DetailsList
                    items={dataReviewTypes}
                    columns={workItemsColumns}
                    onRenderItemColumn={_renderItemColumn}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.horizontalConstrained}
                />
            </div>
        </div>

        {/* Add panel */}
        <Panel
            type={PanelType.medium}
            isOpen={isAddModalOpen}
            onDismiss={hideAddModal}
            closeButtonAriaLabel="Close"
            headerText={"Add Review Type"}
            onRenderFooterContent={onReviewTypeAddFooterContent}
        >

            <div className="row">
                <TextField
                    multiline={false}
                    required={true}
                    label={"Review Type"}
                    placeholder="Add a new review type"
                    name="deleteConfitmation"
                    value={reviewTypeText}
                    onChange={onReviewTypeTextChange}
                />
            </div>
            <div className="row">
                <Toggle label="Is Active" checked={reviewTypeIsActive} onChange={onChange} />
            </div>
        </Panel>

        {/* Edit panel */}
        <Panel
            type={PanelType.medium}
            isOpen={isEditModalOpen}
            onDismiss={hideEditModal}
            closeButtonAriaLabel="Close"
            headerText={"Edit Review Type"}
            onRenderFooterContent={onReviewTypeEditFooterContent}
        >

            <div className="row">
                <TextField
                    multiline={false}
                    required={true}
                    label={"Review Type"}
                    placeholder="Edit the review type"
                    name="editConfitmation"
                    value={reviewTypeText}
                    onChange={onReviewTypeTextChange}
                />

            </div>

            <div className="row">
                <Toggle label="Is Active" checked={reviewTypeIsActive} onChange={onChange} />
            </div>

        </Panel>
    </>
    )
}

import { ConstrainMode, DetailsList, DetailsListLayoutMode, FontIcon, IColumn, IPersonaProps, Label, Panel, PanelType, Persona, PersonaSize, SelectionMode, Stack, StackItem, Text, TextField } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FC, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ClientDateDetails } from "../../Models/Reviews/ClientDateDetails";
import { Review } from "../../Models/Reviews/Review";
import { ServiceTreeSnapshot } from "../../Models/ServiceTreeSnapshot";
import { ArtifactDownload } from "../../Models/TMPArtifacts/ArtifactDownload";
import { LinkedArtifact } from "../../Models/TMPArtifacts/LinkedArtifact";
import UserProfile from "../../Models/UserProfile";
import { ReviewWorkItem } from "../../Models/WorkItems/ReviewWorkItem";
import { ArtifactService } from "../../Services/ArtifactService";
import STServices from "../../Services/STServices";
import UserService from "../../Services/UserService";
import { getDateFormat } from "../../Utilities/dateutilities";
import { store } from "../Common/GlobalStateProvider";
import HeadingComponent from '../Common/HeadingComponent'
import { LoadingIndicator } from "../Common/LoadingIndicatorComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";
import { ServiceReviewHistoryComponent } from "../Common/ServiceReviewHistoryComponent";

interface IServiceSnapshotComponentProps {
    title: string,
    subtitle?: string
}
export const ServiceSnapshotComponent: FC<IServiceSnapshotComponentProps> = (props: IServiceSnapshotComponentProps): JSX.Element => {
    let stServices = new STServices();
    let userService = new UserService();
    let artifactService = new ArtifactService();
    const today: Date = new Date(new Date());

    const [minWidth] = useState(160);
    const stackTokens = { childrenGap: 10, };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataServiceSnapshots, dataServiceSnapshotsSet] = useState<ServiceTreeSnapshot[]>([]);
    const [dataSelectedServiceSnapshot, dataSelectedServiceSnapshotSet] = useState<ServiceTreeSnapshot>();
    const [serviceSnapshotsColumns, serviceSnapshotsColumnsSet] = useState<IColumn[]>(
        [
            {
                key: 'ServiceName',
                name: 'Service Name',
                minWidth: 300
            },
            {
                key: 'ServiceId',
                name: 'Service Id',
                minWidth: 250
            },
            {
                key: 'LastTMReview',
                name: 'Last Completed Review',
                minWidth: 150
            },
            {
                key: 'NextReviewDate',
                name: 'Next Review Date',
                minWidth: 150
            },
            // {
            //     key: 'OpenTasksCount',
            //     name: 'Open Tasks Count',
            //     minWidth: 50
            // },
            // {
            //     key: 'OpenBugCount',
            //     name: 'Open Bug Count',
            //     minWidth: 50
            // },
            {
                key: 'QuickActions',
                name: 'Actions ',
                minWidth: minWidth
            },
        ]
    );

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [displayMessages, displayMessagesSet] = useMessages();
    const [isNoServiceFound, isNoServiceFoundSet] = useState<boolean>(false);

    const loadSnapshot = async () => {
        setIsLoading(true);
        var d = new Date();
        const upcomingReviews: ClientDateDetails = {
            clientDateTime: d,
            clientOffsetMinutes: d.getTimezoneOffset(),
        };
        var result = await stServices.getUserServiceSnapShot(upcomingReviews);
        if (result && !result.hasErrors && result.result && result.result.length > 0) {
            dataServiceSnapshotsSet(result.result);
        }
        else if (result && !result.hasErrors && result.result && result.result.length == 0) {
            isNoServiceFoundSet(true);
        }
        else {
            if (result && result.hasErrors && result.businessErrors && result.businessErrors.length > 0 && result.businessErrors[0].message) 
            { 
                displayMessagesSet([{message: result.businessErrors[0].message, messageType: DisplayMessageType.Error}]);
            }
            else
            {
                displayMessagesSet([{message: "Could not fetch your service(s).", messageType: DisplayMessageType.Error}]);
            }
        }
        setIsLoading(false);
    }

    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var currentSnapshotItem = item as ServiceTreeSnapshot;
        if (column && currentSnapshotItem) {
            switch (column!.key) {
                case 'ServiceId':
                    return <div title={currentSnapshotItem.serviceId}>{currentSnapshotItem.serviceId}</div>;
                case 'ServiceName':
                    return <div>
                    <Stack horizontal tokens={stackTokens}>
                        {/* <FontIcon iconName="FullHistory" title="FullHistory" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} onClick={() => { dataSelectedServiceSnapshotSet(currentSnapshotItem); openPanel(); }} /> */}
                        <Link onClick={() => { dataSelectedServiceSnapshotSet(currentSnapshotItem); openPanel(); } } to={""}>{currentSnapshotItem.serviceName}</Link>
                    </Stack>
                </div>;
                case 'ServicePriority':
                        return <div title={currentSnapshotItem.serviceName}>{currentSnapshotItem.serviceName}</div>;
                case 'LastTMReview':
                    return <div title={getDateFormat(currentSnapshotItem.lastTMReview)}>{getDateFormat(currentSnapshotItem.lastTMReview)}</div>;
                case 'NextReviewDate':
                    return <div title={getDateFormat(currentSnapshotItem.nextReviewDate)}>{getDateFormat(currentSnapshotItem.nextReviewDate)}</div>;
                case 'OpenTasksCount':
                    return <div title={currentSnapshotItem.openTasksCount?.toString()}>{currentSnapshotItem.openTasksCount}</div>;
                case 'OpenBugCount':
                    return <div title={currentSnapshotItem.openBugCount?.toString()}>{currentSnapshotItem.openBugCount}</div>;
                case 'QuickActions':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            {/* <FontIcon iconName="FullHistory" title="FullHistory" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} onClick={() => { dataSelectedServiceSnapshotSet(currentSnapshotItem); openPanel(); }} /> */}
                            <Link onClick={() => { dataSelectedServiceSnapshotSet(currentSnapshotItem); openPanel(); } } to={""}><FontIcon iconName="FullHistory" title="FullHistory" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }}/></Link>
                        </Stack>
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    useEffect(() => {
        isNoServiceFoundSet(false);
        displayMessagesSet([]);
        loadSnapshot();
    }, []);


    return (
        <>
            
            <div className="container" style={{ padding: 10 }} >
                <div className="row">
                    <HeadingComponent title={props.title} subtitle={props.subtitle} />
                </div>
                {isLoading && <LoadingIndicator label="Loading" />}
                <div className="row">
                    <MessageComponent messages={displayMessages} />
                </div>
                <div className="row">
                    {
                        isNoServiceFound ? <Text>No Services found to display.</Text> :
                        <DetailsList
                            items={dataServiceSnapshots}
                            columns={serviceSnapshotsColumns}
                            onRenderItemColumn={_renderItemColumn}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            constrainMode={ConstrainMode.horizontalConstrained}
                        />
                    }
                </div>
            </div>

            <Panel
                type={PanelType.large}
                isOpen={isOpen}
                onDismiss={dismissPanel}
                closeButtonAriaLabel="Close"
            >
                <ServiceReviewHistoryComponent
                        serviceId={dataSelectedServiceSnapshot?.serviceId}
                        serviceName={dataSelectedServiceSnapshot?.serviceName} />
            </Panel>
        </>
    );
}

export default ServiceSnapshotComponent;

import { TextField,Text } from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { JsxEmit } from "typescript";
import { FollowUps } from "../../Models/Reviews/FollowUps";
import { store } from "../Common/GlobalStateProvider";
import { SpinnerPrimaryButton } from "../Common/SpinnerPrimaryButton";

export interface IFollowUpCreateComponent {
    placeholderText?: string,
    onFollowupToServiceTeamCreated: (followup?: FollowUps) => void,
    showButtonInline?: boolean,
    selectedFollowupItem?: FollowUps,
}

export const FollowUpCreateComponent: FC<IFollowUpCreateComponent> = (props: IFollowUpCreateComponent): JSX.Element => {

    var { appState, appdispatch } = useContext(store);

    const [referenceId, referenceIdSet] = useState<string>();
    const [createdBy, createdBySet] = useState<string>();
    const [followUpTextToServiceTeam, followUpTextToServiceTeamSet] = useState<string>();
    const onfollowUpTextToServiceTeamChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        followUpTextToServiceTeamSet(newValue);
    }

    const onFollowUpCreate = (followUpText: string | undefined) => {
        if (followUpText && followUpText.trim().length > 0) {
            let refId = crypto.randomUUID();
            if (referenceId)
            {
                refId = referenceId;
            }

            let wCreatedBy = appState?.userProfile?.userPrincipalName;
            if (createdBy)
            {
                wCreatedBy = createdBy
            }

            var followUp: FollowUps =
            {
                id: refId,
                question: followUpText,
                createdBy: wCreatedBy,
                modifiedBy: appState?.userProfile?.userPrincipalName,
            };
            
            props.onFollowupToServiceTeamCreated(followUp);
            followUpTextToServiceTeamSet("");
        }
    }

    useEffect(() => {
        if (props.selectedFollowupItem?.question) {
            followUpTextToServiceTeamSet(props.selectedFollowupItem.question)
        }   

        if (props.selectedFollowupItem?.id) {
            referenceIdSet(props.selectedFollowupItem.id)
        }
        else {
            referenceIdSet(crypto.randomUUID());
        }

        if (props.selectedFollowupItem?.createdBy){
            createdBySet(appState.userProfile?.userPrincipalName);
            if (props.selectedFollowupItem?.createdBy !== appState.userProfile?.userPrincipalName)
            {
                referenceIdSet(crypto.randomUUID());
            }
        }
    }, [props]);

    return (
        <>
        <div className="d-flex">
            <TextField
                className="flex-fill"
                value={followUpTextToServiceTeam}
                placeholder={props.placeholderText ?? "Hit enter to create draft followup to service team."}
                onChange={onfollowUpTextToServiceTeamChange}
                iconProps={{ iconName: 'Add', style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => { onFollowUpCreate(followUpTextToServiceTeam) } }}
                onKeyDown={(e) => { if (e.key == 'Enter') { onFollowUpCreate(followUpTextToServiceTeam) } }} />
            <div className="ms-auto">
                <SpinnerPrimaryButton text={"Add to draft followups"} onclick={() => { onFollowUpCreate(followUpTextToServiceTeam) }} style={{ marginLeft: 10 }} />
                <br></br>
                <Text variant="small" >(Only Assigned Reviewers can save the Followups)</Text>
            </div>
        </div>
        </>
    )

}
import { ApiBaseUrl } from "../authConfig";
import { BusinessResult } from "../Models/common/BusinessResult";
import { Review } from "../Models/Reviews/Review";
import { ReviewSlot } from "../Models/Reviews/ReviewSlot";
import { Schedule } from "../Models/Reviews/Schedule";
import { Slot } from "../Models/Reviews/Slot";
import { TimeOff } from "../Models/Reviews/TimeOff";
import { ClientDateDetails } from "../Models/Reviews/ClientDateDetails";
import { MeetingTime } from "../Models/TimeZones/MeetingTime";
import { TMPTimeZone } from "../Models/TimeZones/TmpTimeZone";
import { getHeaders } from "../Utilities/headers";
import * as http from '../Utilities/http'
import { WeaknessType } from "../Models/WeaknessType";
import { OneCsWorkItem } from "../Models/WorkItems/OneCsWorkItem";
import { UpdateReviewWorkItem } from "../Models/WorkItems/UpdateReviewWorkItem";
import { TechTag } from "../Models/TechTag";
import { ReviewStatus } from "../Models/Reviews/ReviewStatus";
import { TimeZoneConversionDTO } from "../Models/TimeZones/TimeZoneConversionDTO";
import ReviewDetailUpdate from "../Models/Reviews/ReviewDetailUpdate";
import { RescheduleSlot } from "../Models/Reviews/RescheduleSlot";
import { ExistingReviewIds } from "../Models/Reviews/ExistingReviewIds";
import { AdhocMeeting } from "../Models/Reviews/AdhocMeeting";
import { SelfAttestMeeting } from "../Models/Reviews/SelfAttestMeeting";
import { ScheduleMeetingCreate } from "../Models/Reviews/ScheduleMeetingCreate";
import { ScheduleMeetingMetadataUpdate } from "../Models/Reviews/ScheduleMeetingMetadataUpdate";
import { MeetingsData } from "../Models/Reviews/MeetingsData";
import { meetingDataToLocalTime } from "../Utilities/dateutilities";
import UserProfile from "../Models/UserProfile";
import { Questions } from "../Models/Questionnaire/Question";
import { QuestionResponse } from "../Models/Reviews/QuestionResponses";
import { RescheduleAdhoc } from "../Models/Reviews/RescheduleAdhoc";
import { FollowUps } from "../Models/Reviews/FollowUps";
import { TeamMetadata } from "../Models/TeamMetadata";
import { DraftFeedback } from "../Models/Reviews/DraftFeedback";
import { PublishTasksAndBugs } from "../Models/Reviews/PublishTasksAndBugs";
import { PublishFollowups } from "../Models/Reviews/PublishFollowups";
import { ReviewWorkItem } from "../Models/WorkItems/ReviewWorkItem";
import { ReviewType } from "../Models/Reviews/ReviewType";

export class MeetingsService {
    saveDraftWorkItem = async (wkItem: ReviewWorkItem, reviewId: string, serviceId: string) : Promise<BusinessResult<ReviewWorkItem> | undefined> => {
        var response = await (await http.post<BusinessResult<ReviewWorkItem> | undefined>(ApiBaseUrl + '/api/Reviews/draft/'+ reviewId +'/' + serviceId, wkItem, await getHeaders()))?.parsedBody;
        return response;
    }
    deleteDraftWorkItem = async (referenceId: string, reviewId: string, serviceId: string) : Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.remove<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Reviews/draft/'+ referenceId + '/'+ reviewId +'/' + serviceId, await getHeaders()))?.parsedBody;
        return response;
    } 
    publishFollowups = async (data: PublishFollowups): Promise<BusinessResult<Review> | undefined> => {
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/feedback/publishfollowups', data, await getHeaders()))?.parsedBody;
        return response;
    }
    publishTasksAndBugs = async (data: PublishTasksAndBugs): Promise<BusinessResult<Review> | undefined> => {
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/feedback/publishTasksnBugs', data, await getHeaders()))?.parsedBody;
        return response;
    }

    saveFeedback = async (feedback: DraftFeedback): Promise<BusinessResult<DraftFeedback[]> | undefined> => {
        var response = await (await http.post<BusinessResult<DraftFeedback[]> | undefined>(ApiBaseUrl + '/api/Reviews/feedback', feedback, await getHeaders()))?.parsedBody;
        return response;
    }

    getReviewersFeedback = async (reviewId: string, serviceId: string): Promise<BusinessResult<DraftFeedback[]> | undefined> => {
        var response = await (await http.get<BusinessResult<DraftFeedback[]> | undefined>(ApiBaseUrl + '/api/Reviews/feedback/' + reviewId + '/' + serviceId , await getHeaders()))?.parsedBody;
        return response;
    }

    getTeamMetaData = async (teamId: string) : Promise<BusinessResult<TeamMetadata> | undefined> => {
        var response = await (await http.get<BusinessResult<TeamMetadata> | undefined>(ApiBaseUrl + '/api/Reviews/teams/' + teamId , await getHeaders()))?.parsedBody;
        return response;
    }

    recordSelfAttestReview = async (selfAttestReview: SelfAttestMeeting): Promise<BusinessResult<Review> | undefined> => {
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/selfattest', selfAttestReview, await getHeaders()))?.parsedBody;
        return response;
    }

    recordAdhocReview = async (adhocReview: AdhocMeeting): Promise<BusinessResult<Review> | undefined> => {
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/adhoc', adhocReview, await getHeaders()))?.parsedBody;
        return response;
    }

    createScheduleMeeting = async (scheduleRdeview: ScheduleMeetingCreate): Promise<BusinessResult<Review> | undefined> => {
        var meetingId;
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/createscheduled', scheduleRdeview, await getHeaders()))?.parsedBody;
        return response;
    }

    updateScheduleMeetingMetadata = async (updatedReviewMetadata: ScheduleMeetingMetadataUpdate): Promise<BusinessResult<Review> | undefined> => {
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/schedulemetadata', updatedReviewMetadata, await getHeaders()))?.parsedBody;
        return response;
    }

    fetchEmailContent = async (teamId: string): Promise<BusinessResult<string> | undefined> => {
        var response = await (await http.get<BusinessResult<string> | undefined>(ApiBaseUrl + '/api/Reviews/teams/' + teamId + '/mailmessage', await getHeaders()))?.parsedBody;
        return response;
    }

    saveEmailContent = async (teamId: string, emailContent: string): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.post<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Reviews/teams/' + teamId + '/mailmessage', emailContent, await getHeaders()))?.parsedBody;
        return response;
    }

    CanServiceSelfAttest = async (serviceId: string): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.get<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Reviews/canselfattest/' + serviceId, await getHeaders()))?.parsedBody;
        return response;
    }

    deleteReview = async (reviewId: string, serviceId: string): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.remove<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Reviews/' + reviewId + '/' + serviceId, await getHeaders()))?.parsedBody;
        return response;
    }
    getExistingReviewIds = async (serviceId: string, securityTeamId: string): Promise<BusinessResult<ExistingReviewIds[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ExistingReviewIds[]> | undefined>(ApiBaseUrl + '/api/Reviews/existing/' + serviceId + '/' + securityTeamId, await getHeaders()))?.parsedBody;
        return response;
    }
    updateReviewDetail = async (updateReviewDetailObj: ReviewDetailUpdate) => {
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/edit/reviewdetail', updateReviewDetailObj, await getHeaders()))?.parsedBody;
        return response;
    }
    saveReviewerUpdates = async (reviewerUpdates: ReviewStatus): Promise<BusinessResult<Review> | undefined> => {
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/reviewerupdate', reviewerUpdates, await getHeaders()))?.parsedBody;
        return response;
    }

    getReview = async (reviewId: string, serviceId: string): Promise<BusinessResult<Review> | undefined> => {
        var response = await (await http.get<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/' + reviewId + '/' + serviceId, await getHeaders()))?.parsedBody;
        return response;
    }

    getReviewById = async (reviewId: string): Promise<BusinessResult<Review> | undefined> => {
        var response = await (await http.get<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/' + reviewId, await getHeaders()))?.parsedBody;

        if (response && response.result && response.result.id && response.result.service && response.result.service.serviceId)
        {
            window.open("/reviewdetail/"+response.result.id+'/'+response.result.service.serviceId, "_self");
        }
        return response;
    }

    addScheduleToTeam = async (schedule: Schedule): Promise<BusinessResult<Schedule> | undefined> => {
        var response = await (await http.post<BusinessResult<Schedule> | undefined>(ApiBaseUrl + '/api/Reviews/schedule/add', schedule, await getHeaders()))?.parsedBody;
        return response
    }

    validateEditScheduleToTeam = async (editSchedule: Schedule): Promise<BusinessResult<Review[]> | undefined> => {
        var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Reviews/schedule/validateedit', editSchedule, await getHeaders()))?.parsedBody;
        return response;
    }

    saveEditScheduleToTeam = async (editSchedule: Schedule): Promise<BusinessResult<Schedule> | undefined> => {
        var response = await (await http.post<BusinessResult<Schedule> | undefined>(ApiBaseUrl + '/api/Reviews/schedule/saveedit', editSchedule, await getHeaders()))?.parsedBody;
        return response;
    }

    validateDeleteScheduleToTeam =async (scheduleId:string, teamId: string): Promise<BusinessResult<Review[]> | undefined> => {
        var response = await (await http.get<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Reviews/schedule/validatedelete/' + scheduleId + '/' + teamId, await getHeaders()))?.parsedBody;
        return response
    }

    deleteScheduleToTeam = async (scheduleId:string, teamId: string): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.remove<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Reviews/schedule/delete/' + scheduleId + '/' + teamId, await getHeaders()))?.parsedBody;
        return response
    }

    addTimeOffs = async (timeOff: TimeOff): Promise<BusinessResult<TimeOff> | undefined> => {
        var response = await (await http.post<BusinessResult<TimeOff> | undefined>(ApiBaseUrl + '/api/Reviews/timeoff/add', timeOff, await getHeaders()))?.parsedBody;
        return response;
    }
    
    validateTimeOffs = async (timeOff: TimeOff): Promise<BusinessResult<Review[]> | undefined> => {
        var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Reviews/timeoff/validate', timeOff, await getHeaders()))?.parsedBody;
        return response;
    }   

    getActiveSchedules = async (teamId: string): Promise<BusinessResult<Schedule[]> | undefined> => {
        var response = await (await http.get<BusinessResult<Schedule[]> | undefined>(ApiBaseUrl + '/api/Reviews/schedules/' + teamId, await getHeaders()))?.parsedBody;
        return response
    }

    getActiveTimeOffs = async (teamId: string): Promise<BusinessResult<TimeOff[]> | undefined> => {
        var response = await (await http.get<BusinessResult<TimeOff[]> | undefined>(ApiBaseUrl + '/api/Reviews/timeoffs/' + teamId, await getHeaders()))?.parsedBody;
        return response
    }

    deleteTimeOff = async (teamId: string, timeOffId: string): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.remove<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Reviews/timeoffs/' + teamId+ '/' + timeOffId, await getHeaders()))?.parsedBody;
        return response
    }

    getAvailableSlots = async (teamId: string): Promise<BusinessResult<Slot[]> | undefined> => {
        var response = await (await http.get<BusinessResult<Slot[]> | undefined>(ApiBaseUrl + '/api/Reviews/slots/available/' + teamId, await getHeaders()))?.parsedBody;
        return response;
    }

    convertTimeZoneToLocal = async (scheduledTimeToLocalTime: TimeZoneConversionDTO[]): Promise<TimeZoneConversionDTO[] | undefined> => {
        var response = await (await http.post<TimeZoneConversionDTO[] | undefined>(ApiBaseUrl + '/api/Reviews/converttimezone', scheduledTimeToLocalTime, await getHeaders()))?.parsedBody;
        return response;
    }



    getTmpTimeZones = async (): Promise<BusinessResult<TMPTimeZone[]> | undefined> => {
        var response = await (await http.get<BusinessResult<TMPTimeZone[]>>(ApiBaseUrl + '/api/Reviews/timezones', await getHeaders()))?.parsedBody;
        return response;
    }

    getMeetingTimes = async (): Promise<BusinessResult<MeetingTime[]> | undefined> => {
        var response = await (await http.get<BusinessResult<MeetingTime[]>>(ApiBaseUrl + '/api/Reviews/meetingtimes', await getHeaders()))?.parsedBody;
        return response;
    }

    reScheduleMeeting = async (rescheduleSlot: RescheduleSlot): Promise<BusinessResult<Review> | undefined> => {
        if (rescheduleSlot) {
            var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/changeslot', rescheduleSlot, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    rescheduleToAdhocMeeting = async (rescheduleAdhoc: RescheduleAdhoc): Promise<BusinessResult<Review> | undefined> => {
        if (rescheduleAdhoc) {
            var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/changeslot/adhoc', rescheduleAdhoc, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    upComingMeetings = async (upcoming: ClientDateDetails): Promise<BusinessResult<Review[]> | undefined> => {
        if (upcoming) {
            var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Reviews/upcoming', upcoming, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    pendingMeetings = async (upcoming: ClientDateDetails): Promise<BusinessResult<Review[]> | undefined> => {
        if (upcoming) {
            var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Reviews/pending', upcoming, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    reviewerUpcomingMeetings = async (clientDate: ClientDateDetails): Promise<BusinessResult<Review[]> | undefined> => {
        if (clientDate) {
            var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Reviews/upcoming/reviewer', clientDate, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    reviewerPendingMeetings = async (clientDate: ClientDateDetails): Promise<BusinessResult<Review[]> | undefined> => {
        if (clientDate) {
            var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Reviews/pending/reviewer', clientDate, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    reviewerUnAssignedMeetings = async (clientDate: ClientDateDetails): Promise<BusinessResult<Review[]> | undefined> => {
        if (clientDate) {
            var response = await (await http.post<BusinessResult<Review[]> | undefined>(ApiBaseUrl + '/api/Reviews/unassigned/reviewer', clientDate, await getHeaders()))?.parsedBody;
            return response;
        }
    }

    getWeaknesses = async (): Promise<BusinessResult<WeaknessType[]> | undefined> => {
        var response = await (await http.get<BusinessResult<WeaknessType[]>>(ApiBaseUrl + '/api/Reviews/weaknesses', await getHeaders()))?.parsedBody;
        return response;
    }

    getReviewTypes = async (): Promise<BusinessResult<ReviewType[]> | undefined> => {
        var response = await (await http.get<BusinessResult<ReviewType[]>>(ApiBaseUrl + '/api/Reviews/types', await getHeaders()))?.parsedBody;
        return response;
    }

    editReviewType = async (type: ReviewType): Promise<BusinessResult<ReviewType[]> | undefined> => {
        var response = await (await http.post<BusinessResult<ReviewType[]>>(ApiBaseUrl + '/api/Reviews/types/edit', type, await getHeaders()))?.parsedBody;
        return response;
    }

    createReviewType = async (type: ReviewType): Promise<BusinessResult<ReviewType[]> | undefined> => {
        var response = await (await http.post<BusinessResult<ReviewType[]>>(ApiBaseUrl + '/api/Reviews/types', type, await getHeaders()))?.parsedBody;
        return response;
    }

    getTechTags = async (): Promise<BusinessResult<TechTag[]> | undefined> => {
        var response = await (await http.get<BusinessResult<TechTag[]>>(ApiBaseUrl + '/api/Reviews/techtags', await getHeaders()))?.parsedBody;
        return response;
    }

    loadLocalTimeForReviews = async (reviews: Review[]): Promise<Review[]> => {
        if (reviews && reviews.length > 0) {
            var tmeetings: MeetingsData[] = [];
            reviews.forEach(x => {
                if (x && x.meetingsData) {
                    x.meetingsData.clientReferenceId = crypto.randomUUID();
                    tmeetings.push(x.meetingsData);
                }

                if (x && x.additionalAdhocMeetings && x.additionalAdhocMeetings.length > 0)
                {
                    x.additionalAdhocMeetings.forEach(y => {
                        if (y)
                        {
                            y.clientReferenceId = crypto.randomUUID();
                            tmeetings.push(y);
                        }
                    })
                }
            });

            var localTimeData = await meetingDataToLocalTime(tmeetings);
            reviews.forEach(x => {
                var tData = localTimeData.filter(z => z.clientReferenceId == x.meetingsData?.clientReferenceId);
                if (tData && tData.length > 0 && tData[0]) {
                    x.meetingsData = tData[0];
                }

                if (x && x.additionalAdhocMeetings && x.additionalAdhocMeetings.length > 0)
                {
                    x.additionalAdhocMeetings.forEach( p=> {
                        var additionalData = localTimeData.filter(q => q.clientReferenceId == p.clientReferenceId);
                        if (additionalData && additionalData.length > 0 && additionalData[0])
                        {
                            p = additionalData[0];
                        }
                    })
                }
                
            });

            console.log(reviews);
        }

        return reviews;
    }

    quickAssignReviewer = async (reviewId: string, serviceId: string, reviewers: UserProfile[]): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.post<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Reviews/quick/assignreviewer/' + reviewId + '/' + serviceId, reviewers, await getHeaders()))?.parsedBody;
        return response;
    }

    quickCompleteReview = async (reviewId: string, serviceId: string): Promise<BusinessResult<boolean> | undefined> => {
        var response = await (await http.post<BusinessResult<boolean> | undefined>(ApiBaseUrl + '/api/Reviews/quick/complete/' + reviewId + '/' + serviceId, "" ,await getHeaders()))?.parsedBody;
        return response;
    }

    recordQuestionResponses = async(questionResponses: QuestionResponse) : Promise<BusinessResult<Review> | undefined> => {
        if(questionResponses)
        {
            var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/questionResponses', questionResponses, await getHeaders()))?.parsedBody;
            return response;  
        }
    }

    addFollowUp = async (reviewId: string | undefined, serviceId: string | undefined, followUpId: string, answer: string, eTag?: string) : Promise<BusinessResult<Review> | undefined> => {
        var followUp: FollowUps = {
            id: followUpId,
            answer: answer,
            eTag: eTag,
        }
        
        var response = await (await http.post<BusinessResult<Review> | undefined>(ApiBaseUrl + '/api/Reviews/followup/' + reviewId + '/' + serviceId, followUp ,await getHeaders()))?.parsedBody;
        return response;
    }
}

export default MeetingsService;
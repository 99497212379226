import * as React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

interface ILoadingIndicatorProps{
    label: string;
}

export const LoadingIndicator: React.FC<ILoadingIndicatorProps> = (props: ILoadingIndicatorProps): JSX.Element => {
    let labelForIndicator: string = props.label === '' ? 'Loading' : props.label;
    return (
        <>
            <div className="loadingDiv"></div>
            <Spinner
                className="loading"
                ariaLive="polite"
                size={SpinnerSize?.large}
                label= {labelForIndicator}
                role="progressbar"
                aria-label={labelForIndicator}
            />
        </>
    );
};

import { ConstrainMode, Text, DetailsList, DetailsListLayoutMode, FontIcon, IColumn, SelectionMode, Stack, StackItem, SpinButton, Spinner, SpinnerSize } from "@fluentui/react"
import { FC, useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import HeadingComponent from "../Components/Common/HeadingComponent"
import { MeetingTimeCalloutComponent } from "../Components/Common/MeetingTimeCalloutComponent"
import { DisplayMessageType, MessageComponent, useMessages } from "../Components/Common/MessageComponent"
import { SearchComponent } from "../Components/Search/SearchComponent"
import { Review } from "../Models/Reviews/Review"
import { SearchData } from "../Models/Search/SearchData"
import { SearchFilter } from "../Models/Search/SearchFilter"
import { TeamsType } from "../Models/Search/TeamsType"
import { SearchService } from "../Services/SearchService"
import { getDateFormat, getTimeFormat } from "../Utilities/dateutilities"
import { Team } from "../Models/Team"
import { store } from "../Components/Common/GlobalStateProvider"

export const SearchPage: FC = (): JSX.Element => {
    let searchService = new SearchService();
    const [reviewerData, reviewerDataSet] = useState<Review[]>([]);
    const [displayMessages, displayMessagesSet] = useMessages();
    const [searchIndex, searchIndexSet] = useState<number>(0);
    const stackTokens = { childrenGap: 10, };
    const [searchCriteria, searchCriteriaSet] = useState<SearchFilter>();
    var { appState, appdispatch } = useContext(store);
    let { teamId } = useParams();

    const [moreResultsPresent, moreResultsPresentSet] = useState<boolean>(true);

    const [isSearching, isSearchingSet] = useState<boolean>(false);
    const [isLoadingMore, isLoadingMoreSet] = useState<boolean>(false);

    useEffect(() => {
        if (appState.initStateLoaded)
        {
            if ((appState.userMetadata?.isReviewer || appState.userMetadata?.isReviewReader) && teamId)
            {
                var selectedTeam: Team[] = [{id: teamId}];
                var criteria: SearchFilter = {
                    teams: selectedTeam,
                    serviceNames: [],
                    easyReviewId: '',
                    endDate: undefined,
                    startDate: undefined,
                    reviewStatuses: [],
                    technologyTags: [],
                    reviewTypes: []
                }
                search(criteria);
            }
        }
    }, [teamId])

    const search = async (criteria: SearchFilter) => {
        isSearchingSet(true);
        reviewerDataSet([]);
        moreResultsPresentSet(true);
        displayMessagesSet([]);
        searchCriteriaSet(criteria);
        var response = await searchService.searchByReviewer(criteria, 0);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            searchIndexSet(1);
            reviewerDataSet(response.result);
            if (response.result.length < 10) {
                moreResultsPresentSet(false);
            }
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            displayMessagesSet([{ message: "No results found for the search criteria.", messageType: DisplayMessageType.Warning }]);
            reviewerDataSet([]);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The search did not succeed", messageType: DisplayMessageType.Error }]);
            }
        }

        isSearchingSet(false);
    }

    const loadMore = async (criteria: SearchFilter, index: number) => {
        searchIndexSet(index);
        isLoadingMoreSet(true);
        var response = await searchService.searchByReviewer(criteria, searchIndex);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            searchIndexSet(searchIndex + 1);
            reviewerDataSet([...reviewerData, ...response.result]);
            if (response.result.length < 10) {
                moreResultsPresentSet(false);
            }
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            if (reviewerData && reviewerData.length > 0) {
                displayMessagesSet([{ message: "You have reached the end of search results.", messageType: DisplayMessageType.Information }]);
            }
            else {
                displayMessagesSet([{ message: "No results found for the search criteria.", messageType: DisplayMessageType.Warning }]);
            }
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The search did not succeed", messageType: DisplayMessageType.Error }]);
            }
        }
        isLoadingMoreSet(false);
    }

    const [minWidth] = useState(160);
    const [auditorMeetingsColumns, auditorMeetingsColumnsSet] = useState<IColumn[]>(
        [
            {
                key: 'ServiceName',
                name: 'Service Name',
                minWidth: 150
            },
            {
                key: 'ReviewId',
                name: 'Review Id',
                minWidth: 100
            },
            {
                key: 'ReviewType',
                name: 'Review Type',
                minWidth: 120
            },
            {
                key: 'ReviewStatus',
                name: 'Status',
                minWidth: 80
            },
            {
                key: 'MeetingDate',
                name: 'Review Date',
                minWidth: 100
            },
            {
                key: 'SecurityTeam',
                name: 'Security Team',
                minWidth: 150
            },
            {
                key: 'TechnologyTags',
                name: 'Technology Tags',
                minWidth: 100
            },
            {
                key: 'QuickActions',
                name: 'Actions ',
                minWidth: minWidth
            },
        ]
    );
    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var currentReviewItem = item as Review;
        if (column && currentReviewItem) {
            switch (column!.key) {
                case 'ServiceName':
                    return <div id={"servicename_result" + index } title={currentReviewItem.service?.serviceName}>{currentReviewItem.service?.serviceName}</div>;
                case 'ReviewId':
                    return <div title={currentReviewItem.easyReviewId}>{currentReviewItem.easyReviewId}</div>;
                case 'ReviewType':
                    return <div title={currentReviewItem.reviewType}>{currentReviewItem.reviewType}</div>;
                case 'ReviewStatus':
                    return <div title={currentReviewItem.reviewStatus}>{currentReviewItem.reviewStatus}</div>;
                case 'MeetingDate':
                    return <Stack horizontal tokens={stackTokens}>
                        <StackItem>
                            {getDateFormat(currentReviewItem.meetingsData?.dateOfReview)}
                        </StackItem>
                    </Stack>
                case 'SecurityTeam':
                    return <div title={currentReviewItem.securityReviewTeam?.name}>{currentReviewItem.securityReviewTeam?.name}</div>;
                case 'TechnologyTags':
                    return <div className="d-flex">
                        {currentReviewItem?.technologyTags?.map(x => { return <div title={x.name}> {x.name}, </div> })}
                    </div>
                case 'QuickActions':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            {currentReviewItem.id && currentReviewItem.id.length > 0 &&
                                currentReviewItem.service && currentReviewItem.service.serviceId &&
                                <Link to={"/reviewdetail/" + currentReviewItem.id + "/" + currentReviewItem.service?.serviceId} target="_blank" rel="noopener noreferrer"><FontIcon iconName="PreviewLink" title="View Meeting" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} /></Link>
                            }
                        </Stack>
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    return (<div className="container">
        <div className="row">
            <MessageComponent messages={displayMessages} />
        </div>

        <div className="row">
            <HeadingComponent title="Search Reviews" subtitle="Search for reviews within your review teams."></HeadingComponent>
        </div>

        <div className="row">
            <SearchComponent
                id="search_reviewer"
                DisplayTeamsType={TeamsType.UserTeams}
                userSelectedTeam={{id: teamId? teamId: undefined}}
                onSearchClicked={async (searchFilter) => {
                    displayMessagesSet([]);
                    searchIndexSet(0);
                    reviewerDataSet([]);
                    console.log(searchFilter);
                    await search(searchFilter);
                }}
            />
        </div>

        {isSearching &&
            <div className="row">
                <Spinner size={SpinnerSize.large} title="Searching" label="searching" />
            </div>
        }

        { reviewerData && reviewerData.length > 0 &&
            <div className="row">
                <DetailsList
                    items={reviewerData}
                    columns={auditorMeetingsColumns}
                    onRenderItemColumn={_renderItemColumn}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.horizontalConstrained}

                />
            </div>
        }
        <div className="row">
            {reviewerData && reviewerData.length > 0 && moreResultsPresent && !isLoadingMore &&
                <div className="d-flex">
                    <div className="mx-auto">
                        <Link to={""} onClick={() => { loadMore(searchCriteria!, searchIndex) }} > load more </Link>
                    </div>
                </div>
            }
            {
                isLoadingMore &&
                <Spinner size={SpinnerSize.large} title="Loading" label="loading" />
            }
        </div>
    </div>)
}
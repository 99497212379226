import { FC, useEffect, useState } from "react";
import UpComingReviewsComponent from '../Components/Reviewer/UpComingReviewsComponent'
import PastReviewsComponent from '../Components/Reviewer/PastReviewsComponent'
import { UnAssignedReviewsComponent } from "../Components/Reviewer/UnAssignedReviewsComponent";
import AdminServices from "../Services/AdminServices";
import { DisplayMessageType, MessageComponent, useMessages } from "../Components/Common/MessageComponent";

export const ReviewerPage: FC = (): JSX.Element => {
    let adminService = new AdminServices();
    const [displayMessages, displayMessagesSet] = useMessages();

    const fetchPortalConfig = async () => {
        var response = await adminService.fetchPortalConfig();
        if (response && !response.hasErrors && response.result) {
            if (response.result.reviewerScreenMessage)
            {
                displayMessagesSet([{message: response.result.reviewerScreenMessage, messageType: DisplayMessageType.Warning}]);
                return;
            }
        }
    };

    useEffect(() => {
        fetchPortalConfig();
    }, []);

    return (
        <>
            <div className="row" >
                <MessageComponent messages={displayMessages} />    
            </div>

            <div className="row" >
                <PastReviewsComponent title="In-Progress Reviews" subtitle="Your in-progress reviews with the app team(s). " />
            </div>

            <div className="row" >
                <UpComingReviewsComponent title="Upcoming Reviews" subtitle="Your scheduled reviews with the app team(s) " />
            </div>

            <div className="row" >
                <UnAssignedReviewsComponent title="UnAssigned Reviews" subtitle="These scheduled reviews do not have a reviewer assigned" />
            </div>
        </>
    );
}

export default ReviewerPage;
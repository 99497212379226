import { ConstrainMode, DefaultButton, DetailsList, DetailsListLayoutMode, FontIcon, IColumn, Label, Panel, PanelType, SelectionMode, Stack, StackItem, Text, TextField } from "@fluentui/react";
import { useBoolean, useId, useRefEffect } from "@fluentui/react-hooks";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClientDateDetails } from "../../Models/Reviews/ClientDateDetails";
import { Review } from "../../Models/Reviews/Review";
import UserProfile from "../../Models/UserProfile";
import MeetingsService from "../../Services/MeetingsService";
import { getDateFormat, getTimeFormat } from "../../Utilities/dateutilities";
import HeadingComponent from "../Common/HeadingComponent";
import { LoadingIndicator } from "../Common/LoadingIndicatorComponent";
import { MeetingTimeCalloutComponent } from "../Common/MeetingTimeCalloutComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";
import { MsPeoplePickerComponent } from "../Common/MsPeoplePickerComponent";
import { SpinnerPrimaryButton } from "../Common/SpinnerPrimaryButton";
import UserProfileComponent from "../Menubars/UserProfileComponent";

interface IUnAssignedReviewsComponent {
    title: string,
    subtitle?: string
}

export const UnAssignedReviewsComponent: FC<IUnAssignedReviewsComponent> = (props: IUnAssignedReviewsComponent) => {
    let meetingsService = new MeetingsService();
    const [minWidth] = useState(160);
    const stackTokens = { childrenGap: 10, };
    const [dataUnAssignedMeetings, dataUnAssignedMeetingsSet] = useState<Review[]>([]);

    const [isLoading, isLoadingSet] = useState<boolean>(false);
    const [isNoReviewsFound, isNoReviewsFoundSet] = useState<boolean>(false);

    const [unAssignedMeetingsColumns, unAssignedMeetingsColumnsSet] = useState<IColumn[]>(
        [
            {
                key: 'ReviewId',
                name: 'Review Id',
                minWidth: 150
            },
            {
                key: 'ServiceName',
                name: 'Service Name',
                minWidth: 200
            },
            {
                key: 'ReviewType',
                name: 'Review Type',
                minWidth: 120
            },
            {
                key: 'MeetingDate',
                name: 'Meeting Date Time',
                minWidth: 200
            },
            {
                key: 'SecurityTeam',
                name: 'Security Team',
                minWidth: 200
            },
            {
                key: 'QuickActions',
                name: 'Actions ',
                minWidth: minWidth
            },
        ]
    );

    const loadReviews = async () => {
        isLoadingSet(true);
        var d = new Date();
        const clientDate: ClientDateDetails = {
            clientDateTime: d,
            clientOffsetMinutes: d.getTimezoneOffset(),
        };
        var result = await meetingsService.reviewerUnAssignedMeetings(clientDate);

        if (result && !result.hasErrors && result.result && result.result.length > 0) {
            const distantFuture = new Date(8640000000000000);
            var meetingData = result.result.sort((a, b) => {
                let dateA: Date = a.meetingsData?.dateOfReview ? new Date(a.meetingsData.dateOfReview) : distantFuture;
                let dateB: Date = b.meetingsData?.dateOfReview ? new Date(b.meetingsData.dateOfReview) : distantFuture;
                return dateA.getTime() - dateB.getTime();
            });
            var meetingDataWithLocalTime = await meetingsService.loadLocalTimeForReviews(meetingData);
            dataUnAssignedMeetingsSet(meetingDataWithLocalTime);
        }
        else if (result && !result.hasErrors && result.result && result.result.length == 0) {
            isNoReviewsFoundSet(true);
        }
        else {
            if (result && result.hasErrors && result.businessErrors && result.businessErrors.length > 0 && result.businessErrors[0].message) {
                displayMessagesSet([{ message: result.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "Could not fetch review(s).", messageType: DisplayMessageType.Error }]);
            }
        }

        isLoadingSet(false)
    }

    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var currentReviewItem = item as Review;
        if (column && currentReviewItem) {
            switch (column!.key) {
                case 'ServiceName':
                    return <div title={currentReviewItem.service?.serviceName}>{currentReviewItem.service?.serviceName}</div>;
                case 'ReviewId':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            {currentReviewItem.id && currentReviewItem.id.length > 0 &&
                                currentReviewItem.service && currentReviewItem.service.serviceId &&
                                <Link to={"/reviewdetail/" + currentReviewItem.id + "/" + currentReviewItem.service?.serviceId}>{currentReviewItem.easyReviewId}</Link>
                            }
                        </Stack>
                    </div>
                case 'ReviewType':
                    return <div title={currentReviewItem.reviewType}>{currentReviewItem.reviewType}</div>;
                case 'MeetingDate':
                    return <Stack horizontal tokens={stackTokens}>
                        <StackItem>
                            {getDateFormat(currentReviewItem.meetingsData?.browserLocalTime) + '-' + getTimeFormat(currentReviewItem.meetingsData?.browserLocalTime)}
                        </StackItem>
                        <StackItem>
                            <MeetingTimeCalloutComponent meetingData={currentReviewItem.meetingsData} />
                        </StackItem>
                    </Stack>;

                case 'SecurityTeam':
                    return <div title={currentReviewItem.securityReviewTeam?.name}>{currentReviewItem.securityReviewTeam?.name}</div>;
                case 'QuickActions':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            {currentReviewItem.id && currentReviewItem.id.length > 0 &&
                                currentReviewItem.service && currentReviewItem.service.serviceId &&
                                <Link to={"/reviewdetail/" + currentReviewItem.id + "/" + currentReviewItem.service?.serviceId} target="_blank" rel="noopener noreferrer"><FontIcon iconName="PreviewLink" title="View Meeting" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} /></Link>
                            }
                            {
                                (currentReviewItem.meetingsData) &&
                                <FontIcon iconName="TestUserSolid" title="Assign Reviewer" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }}
                                    onClick={() => {
                                        displayQuickAssignMessagesSet([]);
                                        currentReviewSet(currentReviewItem)
                                        showQuickAssignReviewerModal();
                                    }} />
                            }
                        </Stack>
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    function reset() {
        dataUnAssignedMeetingsSet([]);
        isNoReviewsFoundSet(false);
        isLoadingSet(false);
        displayMessagesSet([]);
        displayQuickAssignMessagesSet([]);
    }

    useEffect(() => {
        reset();
        loadReviews();
    }, []);

    const [isQuickAssignReviewerModalOpen, { setTrue: showQuickAssignReviewerModal, setFalse: hideQuickAssignReviewerModal }] = useBoolean(false);
    const [currentReview, currentReviewSet] = useState<Review>();
    useEffect(() => {
        if (currentReview && currentReview.reviewers && currentReview.reviewers.length > 0) {
            reviewersSet(currentReview.reviewers);
        }
    }, [currentReview]);

    const onRenderQuickAssignReviewerFooterContent = () => (
        <Stack horizontal tokens={stackTokens}>
            <SpinnerPrimaryButton text="Assign Reviewer(s)" executingText="Assigning reviewers" onclick={AssignReviewers} />
            <DefaultButton onClick={() => { hideQuickAssignReviewerModal(); reviewersSet([]); }}>Cancel</DefaultButton>
        </Stack>
    );

    const [reviewers, reviewersSet] = useState<UserProfile[]>([]);

    const AssignReviewers = async () => {
        if (currentReview?.id && currentReview.service && currentReview.service.serviceId && reviewers && reviewers.length > 0) {
            var response = await meetingsService.quickAssignReviewer(currentReview?.id, currentReview?.service?.serviceId, reviewers);
            if (response && !response.hasErrors && response.result) {
                dataUnAssignedMeetingsSet(dataUnAssignedMeetings.filter(x => !(x.id == currentReview.id && x.service?.serviceId == currentReview.service?.serviceId)))
                hideQuickAssignReviewerModal();
            }
            else {
                if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                    displayQuickAssignMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                }
                else {
                    displayQuickAssignMessagesSet([{ message: "Could not assign the review.", messageType: DisplayMessageType.Error }]);
                }
            }
        }
        else {
            if (reviewers === undefined || reviewers.length == 0)
            {
                displayQuickAssignMessagesSet([{message: "Reviewers cannot be empty", messageType: DisplayMessageType.Warning}]);
                return;
            }

            displayQuickAssignMessagesSet([{message: "Could not determine the review for assigning reviewer.", messageType: DisplayMessageType.Error}]);
        }
    }

    const [displayMessages, displayMessagesSet] = useMessages();
    const [displayQuickAssignMessages, displayQuickAssignMessagesSet] = useMessages();

    return (
        <>
            <div className="container" style={{ padding: 10 }} >
                <div className="row">
                    <div className="d-flex">
                        <div className="me-auto">
                            <HeadingComponent title={props.title} subtitle={props.subtitle} />
                        </div>
                        <Stack horizontal tokens={stackTokens} style={{ cursor: 'pointer', color: 'blue', marginTop: 20, marginRight: 140 }}
                            onClick={() => {
                                reset();
                                loadReviews();
                            }}>
                            <FontIcon iconName="Refresh" title="Refresh" />
                            <Text variant="medium">Refresh</Text>
                        </Stack>
                    </div>
                </div>
                {isLoading && <LoadingIndicator label="Loading" />}
                <div className="row">
                    <MessageComponent messages={displayMessages} />
                </div>
                <div className="row">
                    {
                        isNoReviewsFound ? <Text>No Review(s) Found</Text> :
                            <DetailsList
                                items={dataUnAssignedMeetings}
                                columns={unAssignedMeetingsColumns}
                                onRenderItemColumn={_renderItemColumn}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                constrainMode={ConstrainMode.horizontalConstrained}
                            />
                    }
                </div>
            </div>

            <Panel
                type={PanelType.medium}
                isOpen={isQuickAssignReviewerModalOpen}
                onDismiss={hideQuickAssignReviewerModal}
                closeButtonAriaLabel = "Close"
                headerText={"Quick Assign - " + currentReview?.easyReviewId}
                onRenderFooterContent={onRenderQuickAssignReviewerFooterContent}
            >
                {currentReview &&
                    <div className="container">
                        <div className="row">
                            <MessageComponent messages={displayQuickAssignMessages} />
                        </div>
                        <div className="row">
                            <Label>Reviewers</Label>
                            <MsPeoplePickerComponent
                                itemLimit={10}
                                selectedUsers={reviewers}
                                onUserSelected={(selectedUsers: UserProfile[]) => {
                                    reviewersSet(selectedUsers);
                                }}
                            />
                        </div>
                        
                        <div className="row">
                            <Label>Review Type</Label>
                            {
                                currentReview.reviewType ?
                                    <Text>  {currentReview.reviewType} </Text> : <Text>Review type not found.</Text>
                            }
                        </div>

                        <div className="row">
                            <Label>Technology Tags</Label>
                            <ul style={{ listStyleType: "none", marginLeft: 5 }}>
                                {
                                    currentReview.technologyTags && currentReview.technologyTags.length > 0 ?
                                        currentReview.technologyTags.map(x => {
                                            return <li key={crypto.randomUUID()} style={{ margin: 2 }}>
                                                <div className="d-flex">
                                                    <Text block={false} variant="smallPlus" title={x.name} style={{ marginLeft: 5 }}>{x.name}</Text>
                                                </div>
                                            </li>
                                        })
                                        :
                                        <li style={{ margin: 0 }}><Text style={{ marginLeft: 0 }}>No technology tags found</Text></li>
                                }
                            </ul>
                        </div>

                        <div className="row">
                            <Label>Additional Request data</Label>
                            {
                                currentReview.additionalDetails ?
                                    <Text>  {currentReview.additionalDetails} </Text> : <Text>No additional details found.</Text>
                            }
                        </div>


                    </div>
                }
            </Panel>
        </>
    );
}
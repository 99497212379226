import { count } from 'console';
import { ApiBaseUrl, msalConfig, TenantId } from '../authConfig';
import { CacheResult } from '../Models/CacheResult';
import { BusinessResult } from '../Models/common/BusinessResult';
import { KeyValyePair } from '../Models/common/Dictionary';
import { BatchRequest } from '../Models/Graph/Batch/BatchRequest';
import { BatchResponse } from '../Models/Graph/Batch/BatchResponse';
import { GraphRequest } from '../Models/Graph/Batch/GraphRequest';
import UserProfile from '../Models/UserProfile';
import { UserProfileSearch } from '../Models/UserProfileSearch';
import { getGraphHeaders, getHeaders } from '../Utilities/headers';
import * as http from '../Utilities/http'

export class UserService {

    getUserProfile = async (): Promise<UserProfile | undefined> => {
        const response = await (await http.get<UserProfile>('https://graph.microsoft.com/v1.0/me', await getGraphHeaders()))?.parsedBody;
        const photo64String = await http.getBase64StringFromBinary('https://graph.microsoft.com/v1.0/me/photo/$value', await getGraphHeaders());
        if (response != null || response !== undefined) {
            response.photo = 'data:image/png;base64,' + photo64String;
        }
        return response;
    }

    getUserProfileByUpn = async (upn: string): Promise<UserProfile | undefined> => {
        try {
            const response = await (await http.get<UserProfile>('https://graph.microsoft.com/v1.0/' + TenantId + "/users/" + upn, await getGraphHeaders()))?.parsedBody;
            if (response) {
                return response;
            }
        }
        catch {

        }
        return undefined;
    }

    getUserPhotoByUpn = async (upn: string): Promise<string | undefined> => {
        try {
            const photo64String = await http.getBase64StringFromBinary('https://graph.microsoft.com/v1.0/' + TenantId + "/users/" + upn + '/photo/$value', await getGraphHeaders());
            if (photo64String) {
                return 'data:image/png;base64,' + photo64String;
            }
        }
        catch {

        }
        return undefined;
    }

    searchUsers = async (searchTerm: string): Promise<UserProfile[]> => {
        if (searchTerm && searchTerm.length > 0) {
            //const resp = await (await http.get<UserProfileSearch | undefined>("https://graph.microsoft.com/v1.0/" + TenantId + "/users?$filter=startswith(displayName,'" + searchTerm + "')", await getGraphHeaders(true)))?.parsedBody;
            const resp = await (await http.get<BusinessResult<UserProfile[]> | undefined>(ApiBaseUrl + '/api/User/search/' + searchTerm, await getHeaders()))?.parsedBody;
            if (resp && !resp.hasErrors && resp.result && resp.result.length > 0) {
                resp.result.forEach(x => x.photo = undefined);
                return resp.result;
            }
        }
        return [];
    }

    cacheUserData = async (): Promise<CacheResult | undefined> => {
        var response = await (await http.get<BusinessResult<CacheResult>>(ApiBaseUrl + '/api/User/data', await getHeaders('cacheUserData')))?.parsedBody;
        if (!response?.hasErrors) {
            return response?.result;
        }
    }

    getUserProfiles = async (userAliases: string[]) => {
        var retUserProfiles: UserProfile[] = [];
        let distintAliases = userAliases.filter((item, i, ar) => ar.indexOf(item) === i);
        var userProfiles = await this.batchUserProfile(distintAliases);
        var userPhotos = await this.batchUserPhotos(distintAliases);

        userProfiles.forEach(profile => {

            var usrPhoto = userPhotos?.filter(x => x.key == profile.key);
            if (usrPhoto && usrPhoto.length > 0 && userPhotos[0] && usrPhoto[0].value && profile.value) {
                profile.value.photo = usrPhoto[0].value;
            }
            retUserProfiles.push(profile.value);
        });
        return retUserProfiles;
    }

    getUserProfilesByUPNs = async (upns: string[]) => {
        var retUserProfiles: UserProfile[] = [];
        let distintUPNs = upns.filter((item, i, ar) => ar.indexOf(item) === i);
        var userProfiles = await this.batchUserProfileByUpn(distintUPNs);
        var userPhotos = await this.batchUserPhotosByUpn(distintUPNs);

        userProfiles.forEach(profile => {

            var usrPhoto = userPhotos?.filter(x => x.key == profile.key);
            if (usrPhoto && usrPhoto.length > 0 && userPhotos[0] && usrPhoto[0].value && profile.value) {
                profile.value.photo = usrPhoto[0].value;
            }
            retUserProfiles.push(profile.value);
        });
        return retUserProfiles;
    }

    // limitation of batch is 20 requests in one go but internet search says 15 is a sweet spot.
    private batchUserProfile = async (userAliases: string[]): Promise<KeyValyePair<UserProfile>[]> => {
        var dict: KeyValyePair<UserProfile>[] = [];
        var userProfiles: UserProfile[] = [];
        var pageSize: number = 15;
        var counter: number = 0;

        do {
            var batchRequestBody: BatchRequest = {
            };

            var brs = [];
            var numberOfElements = pageSize;

            if (counter + pageSize > userAliases.length) {
                numberOfElements = userAliases.length - counter;
            }

            for (var i = counter; i < counter + numberOfElements; i++) {
                var gr: GraphRequest = {
                    id: i,
                    url: '/users/' + userAliases[i].toLowerCase() + '@microsoft.com',
                    method: 'GET'
                }
                brs.push(gr);
            }

            batchRequestBody = {
                requests: brs
            }

            var response = await (await http.post<BatchResponse>("https://graph.microsoft.com/v1.0/$batch", batchRequestBody, await getGraphHeaders()))?.parsedBody;
            if (response && response.responses && response.responses.length > 0) {
                response.responses.forEach(response => {
                    if (response.status == '200') {
                        var index = parseInt(response.id!)
                        var keyAlias = userAliases[index] as string;
                        dict.push({
                            key: keyAlias,
                            value: response.body as UserProfile
                        })
                        // userProfiles.push(response.body as UserProfile)
                    }
                })
            }

            counter = counter + pageSize
        } while (userAliases.length > counter);

        return dict;
    }

    private batchUserPhotos = async (userAliases: string[]): Promise<KeyValyePair<string>[]> => {
        var userPhotos: KeyValyePair<string>[] = [];
        var pageSize: number = 15;
        var counter: number = 0;

        do {
            var batchRequestBody: BatchRequest = {
            };

            var brs = [];
            var numberOfElements = pageSize;

            if (counter + pageSize > userAliases.length) {
                numberOfElements = userAliases.length - counter;
            }

            for (var i = counter; i < counter + numberOfElements; i++) {
                var gr: GraphRequest = {
                    id: i,
                    url: '/users/' + userAliases[i].toLowerCase() + '@microsoft.com/photo/$value',
                    method: 'GET'
                }
                brs.push(gr);
            }

            batchRequestBody = {
                requests: brs
            }

            var response = await (await http.post<BatchResponse>("https://graph.microsoft.com/v1.0/$batch", batchRequestBody, await getGraphHeaders()))?.parsedBody;
            if (response && response.responses && response.responses.length > 0) {
                response.responses.forEach(response => {
                    if (response.status == '200') {
                        var photo = 'data:image/png;base64,' + response.body as string
                        var index = parseInt(response.id!)
                        var keyAlias = userAliases[index] as string;
                        userPhotos.push({
                            key: keyAlias,
                            value: photo
                        })
                    }
                })
            }

            counter = counter + pageSize
        } while (userAliases.length > counter);

        return userPhotos;
    }

    private batchUserProfileByUpn = async (upns: string[]): Promise<KeyValyePair<UserProfile>[]> => {
        var dict: KeyValyePair<UserProfile>[] = [];
        var userProfiles: UserProfile[] = [];
        var pageSize: number = 15;
        var counter: number = 0;

        do {
            var batchRequestBody: BatchRequest = {
            };

            var brs = [];
            var numberOfElements = pageSize;

            if (counter + pageSize > upns.length) {
                numberOfElements = upns.length - counter;
            }

            for (var i = counter; i < counter + numberOfElements; i++) {
                var gr: GraphRequest = {
                    id: i,
                    url: '/users/' + upns[i].toLowerCase(),
                    method: 'GET'
                }
                brs.push(gr);
            }

            batchRequestBody = {
                requests: brs
            }

            var response = await (await http.post<BatchResponse>("https://graph.microsoft.com/v1.0/$batch", batchRequestBody, await getGraphHeaders()))?.parsedBody;
            if (response && response.responses && response.responses.length > 0) {
                response.responses.forEach(response => {
                    if (response.status == '200') {
                        var index = parseInt(response.id!)
                        var keyAlias = upns[index] as string;
                        dict.push({
                            key: keyAlias,
                            value: response.body as UserProfile
                        })
                        // userProfiles.push(response.body as UserProfile)
                    }
                })
            }

            counter = counter + pageSize
        } while (upns.length > counter);

        return dict;
    }

    private batchUserPhotosByUpn = async (upns: string[]): Promise<KeyValyePair<string>[]> => {
        var userPhotos: KeyValyePair<string>[] = [];
        var pageSize: number = 15;
        var counter: number = 0;

        do {
            var batchRequestBody: BatchRequest = {
            };

            var brs = [];
            var numberOfElements = pageSize;

            if (counter + pageSize > upns.length) {
                numberOfElements = upns.length - counter;
            }

            for (var i = counter; i < counter + numberOfElements; i++) {
                var gr: GraphRequest = {
                    id: i,
                    url: '/users/' + upns[i].toLowerCase() + '/photo/$value',
                    method: 'GET'
                }
                brs.push(gr);
            }

            batchRequestBody = {
                requests: brs
            }

            var response = await (await http.post<BatchResponse>("https://graph.microsoft.com/v1.0/$batch", batchRequestBody, await getGraphHeaders()))?.parsedBody;
            if (response && response.responses && response.responses.length > 0) {
                response.responses.forEach(response => {
                    if (response.status == '200') {
                        var photo = 'data:image/png;base64,' + response.body as string
                        var index = parseInt(response.id!)
                        var keyAlias = upns[index] as string;
                        userPhotos.push({
                            key: keyAlias,
                            value: photo
                        })
                    }
                })
            }

            counter = counter + pageSize
        } while (upns.length > counter);

        return userPhotos;
    }
}

export default UserService;
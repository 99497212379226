import { DateTime } from "luxon";
import { MeetingsData } from "../Models/Reviews/MeetingsData";
import { TimeZoneConversionDTO } from "../Models/TimeZones/TimeZoneConversionDTO";
import MeetingsService from "../Services/MeetingsService";

export function getDateFormat(date?: Date): string {
    if(date)
    {
        var nonNullDate = new Date(date);
        var day = nonNullDate.getDate();
        var month = getMonthName(nonNullDate.getMonth());
        var year = nonNullDate.getFullYear();

        return day + '-' + month + '-' + year
    }
    return '';
}

export function getTimeFormat(date?: Date): string {
    var isPM: boolean = false;
    var minutesStr = '';
    if(date)
    {
        var nonNullDate = new Date(date);
        var clientOffSet = nonNullDate.getTimezoneOffset();
        
        var hours = nonNullDate.getHours();
        var minutes = nonNullDate.getMinutes();

        if (hours > 11)
        {
            isPM = true;
        }

        if (hours > 12)
        {
            hours = hours - 12;
            
        }

        if (minutes.toString().length == 1)
        {
            minutesStr = '0' + minutes;
        }
        else
        {
            minutesStr = minutes.toString();
        }

        if (isPM)
        {
            return hours + ':' + minutesStr + ' PM'
        }

        return hours + ':' + minutesStr + ' AM'
    }
    return '';
}

export function getDateTimeFromDateAndTimeAMPM(date?:Date, amPMString?: string) : Date
{
    if(date && amPMString)
    {
        var nonNullDate = new Date(date);
        var day = nonNullDate.getDate();
        var month = nonNullDate.getMonth();
        var year = nonNullDate.getFullYear();

        var strSplits = amPMString.split(' ');
        var timeSplits = strSplits[0].split(':');

        var minutes = parseInt(timeSplits[1]);
        var hours = parseInt(timeSplits[0]);

        if(strSplits[1] == 'PM')
        {
            hours = hours + 12;
        }

        return new Date(year, month, day, hours, minutes, 0);
    }

    return new Date();
}

export function getLocalTimeFromTimeZone(jsDate: Date, timeZoneId?: string)
{
    var tzDate = DateTime.fromJSDate(jsDate, {
        zone: timeZoneId
    });

    var local = DateTime.local().toFormat('ZZZZ');
    
    // var newDate = tzDate.setZone(local);
    var str = tzDate.setZone('utc').toISO();

    console.log(str);
    return str;
}

export async function meetingDataToLocalTime(meetingsData: MeetingsData[])
{
    var tConversionTimes: TimeZoneConversionDTO[] = [];
    var meetingsService: MeetingsService = new MeetingsService();

    if (meetingsData && meetingsData.length > 0)
    {
        meetingsData.forEach(meetingData => {
            if (meetingData && meetingData.dateOfReview && meetingData.timeZone && meetingData.timeZone.timeZoneId)
            {
                var scheduledDateTime = new Date(meetingData.dateOfReview);
                var scheduledDateTimeOffset = scheduledDateTime.getTimezoneOffset();
                var scheduledTimeZoneId = meetingData.timeZone.timeZoneId;

                if (meetingData.clientReferenceId === undefined || meetingData.clientReferenceId.length == 0)
                {
                    meetingData.clientReferenceId = crypto.randomUUID();
                }

                tConversionTimes.push({
                    sourceTimeZone: scheduledTimeZoneId,
                    clientReferenceId: meetingData.clientReferenceId,
                    sourceDateTime: scheduledDateTime,
                    clientOffSetMinutes: (new Date(meetingData.dateOfReview)).getTimezoneOffset(),
                });
            }
        });
    }

    if (tConversionTimes.length > 0) {
        var localTimesResponse = await meetingsService.convertTimeZoneToLocal(tConversionTimes);
        if (localTimesResponse && localTimesResponse.length > 0) {
            meetingsData.forEach(x => {
                var localTimeObj = localTimesResponse?.filter(y => y.clientReferenceId == x.clientReferenceId);
                if (localTimeObj && localTimeObj.length > 0 && localTimeObj[0]) {
                    x.browserLocalTime = localTimeObj[0].destinationDateTime;
                }
            });
        }
    }
    
    return meetingsData;
}

function getMonthName(month:number)
{
    var monthName = month.toString();
    switch(month)
    {
        case 0: 
        monthName = 'Jan'
        break;
        case 1: 
        monthName = 'Feb'
        break;
        case 2: 
        monthName = 'Mar'
        break;
        case 3: 
        monthName = 'Apr'
        break;
        case 4: 
        monthName = 'May'
        break;
        case 5: 
        monthName = 'Jun'
        break;
        case 6: 
        monthName = 'Jul'
        break;
        case 7: 
        monthName = 'Aug'
        break;
        case 8: 
        monthName = 'Sep'
        break;
        case 9: 
        monthName = 'Oct'
        break;
        case 10: 
        monthName = 'Nov'
        break;
        case 11: 
        monthName = 'Dec'
        break;
    }

    return monthName;
}
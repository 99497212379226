import { Label, MarqueeSelection, PrimaryButton, TextField } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { PortalConfiguration } from "../../Models/Admin/PortalConfiguration";
import { AdminServices } from "../../Services/AdminServices";
import HeadingComponent from "../Common/HeadingComponent";
import { LoadingIndicator } from "../Common/LoadingIndicatorComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";

interface IPortalConfigurationComponentProps {
    id?: string;
    title: string
}

export const PortalConfigurationComponent: FC<IPortalConfigurationComponentProps> = (props: IPortalConfigurationComponentProps): JSX.Element => {
    let adminService = new AdminServices();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [portalConfig, setPortalConfig] = useState<PortalConfiguration>();
    const [displayMessages, displayMessagesSet] = useMessages();

    const fetchPortalConfig = async () => {
        setIsLoading(true);
        var response = await adminService.fetchPortalConfig();
        if (response && !response.hasErrors && response.result) {
            setPortalConfig(response.result);
        }
        else
        {
            displayMessagesSet([{ message: "The portal configuration could not be loaded.", messageType: DisplayMessageType.Error }]);
            return;
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchPortalConfig();
    }, []);
    
    const savePortalConfig = async () => {
        setIsLoading(true);
        if (portalConfig != null)
        {
            var response = await adminService.savePortalConfig(portalConfig);
            if (response && !response.hasErrors && response.result) {
                displayMessagesSet([{ message: "The portal configuration has been updated successfully", messageType: DisplayMessageType.Success }]);
            }
            else {
                if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                    displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                }
                else {
                    displayMessagesSet([{ message: "The portal configuration could not be updated", messageType: DisplayMessageType.Error }]);
                }
            }
            setIsLoading(false);
        }
        else {
            displayMessagesSet([{ message: "The portal configuration could not be updated", messageType: DisplayMessageType.Error }]);
        }
        setIsLoading(false);
    }

    return (
        <div className="container">
            {isLoading && <LoadingIndicator label="Loading"/>}
            <div className="row">
                <HeadingComponent title="Configure messages to be displayed for all users" />
            </div>
            <div className="row mb-5" >
                <MessageComponent messages={displayMessages} />    
            </div>
            <div className="row">
                <div className="col-3">
                    <Label aria-label="HomeScreenMessage">Home Screen Mesage</Label>
                </div>
                <div className="col-4" style={{margin: 5}}>
                    <TextField
                        value={portalConfig?.homeScreenMessage}
                        multiline={true}
                        placeholder="Add a message to be displayed on home screen."
                        onChange = {(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {
                            setPortalConfig(portalConfig => ({
                                ...portalConfig!,
                                homeScreenMessage: newValue
                            }))
                        }}}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <Label aria-label="ReviewerScreenMessage">Reviewer Screen Mesage</Label>
                </div>
                <div className="col-4" style={{margin: 5}}>
                    <TextField
                        value={portalConfig?.reviewerScreenMessage}
                        multiline={true}
                        placeholder="Add a message to be displayed on reviewer screen."
                        onChange = {(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {if(newValue != undefined) {
                            setPortalConfig(portalConfig => ({
                                ...portalConfig!,
                                reviewerScreenMessage: newValue
                            }))
                        }}}
                    />
                </div>
            </div>
            <br></br>
            <PrimaryButton text="Save portal configuration" onClick={savePortalConfig}/>
        </div> 
    )
}
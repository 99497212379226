import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AdalConfig, adalFetch, AuthenticationContext, runWithAdal } from 'react-adal';
import { TopBarComponent } from './Components/Menubars/TopBarComponent'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/assets/styles/site.css'
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { initializeIcons } from '@uifabric/icons';
import { GlobalStateProvider } from './Components/Common/GlobalStateProvider';
import MsalRdirectLoginComponent from './Components/MsalRdirectLoginComponent';
initializeIcons();

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <AuthenticatedTemplate>
      <GlobalStateProvider>
        <App />
      </GlobalStateProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <MsalRdirectLoginComponent />
      </UnauthenticatedTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

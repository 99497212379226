import { Callout, FontIcon, Link, Text } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { FC } from "react";


interface Artifactdata {
    uploadon?: string,
    uploadby?: string
}

export const ArtifactInfoComponent: FC<Artifactdata> = ({ uploadby, uploadon }: Artifactdata): JSX.Element => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');


    return (
        <div>
            <Link to={""} onClick={toggleIsCalloutVisible}><FontIcon aria-label="Info" iconName="Info" color="blue" style={{ cursor: "pointer", color: 'blue',marginTop:'7px'}} id={buttonId} /></Link>
            {
                isCalloutVisible &&
                <Callout
                    role="dialog"
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <Text block variant="small" >
                        <span>Upload On</span>: {uploadon}
                    </Text>
                    <Text block variant="small" >
                        <span>Upload By</span>: {uploadby}
                    </Text>
                 </Callout>
            }
        </div>
    );
}

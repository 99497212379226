import { ConstrainMode, DetailsList, DetailsListLayoutMode, FontIcon, IColumn, Label, SelectionMode, Stack, Text } from "@fluentui/react";
import { Editor } from "@tinymce/tinymce-react";
import { FC, useState } from "react";
import { ReviewWorkItem } from "../../Models/WorkItems/ReviewWorkItem";

interface IReviewTasksnBugsComponent
{
    workItems: ReviewWorkItem[],
}

export const ReviewTasksnBugsComponent: FC<IReviewTasksnBugsComponent> = (props: IReviewTasksnBugsComponent) : JSX.Element => {

    const [workItemsColumns, workItemsColumnsSet] = useState<IColumn[]>([
        {
            key: 'TaskType',
            name: 'Type',
            minWidth: 50
        },
        {
            key: 'TaskId',
            name: 'Task Id',
            minWidth: 50
        },
        {
            key: 'Title',
            name: 'Title',
            minWidth: 200
        },
        {
            key: 'Description',
            name: 'Description',
            minWidth: 350
        },
        {
            key: 'RiskRating',
            name: 'Risk rating',
            minWidth: 150
        },
        {
            key: 'WeaknessType',
            name: 'Weakness type',
            minWidth: 150,
        },
    ]);
    const stackTokens = { childrenGap: 10, };

    function getRiskRating(rating: number | undefined){
        if (rating === 1){
            return '1 - Critical';
        }

        if (rating === 2){
            return '2 - Important';
        }

        if (rating === 3){
            return '3 - Moderate';
        }

        if (rating === 4){
            return '4 - Low';
        }

        if (rating === 5){
            return '5 - Defense in Depth';
        }
    }

    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var wkItem = item as ReviewWorkItem;
        if (column && wkItem) {
            switch (column!.key) {
                case 'TaskType':
                    if (wkItem.type == "Bug") {
                        return <FontIcon iconName="BugSolid" title="Bug" style={{color:'rgb(204, 41, 61)'}} />
                    }
                    return <FontIcon iconName="TaskSolid" title="Task" style={{color:'rgb(0, 122, 204)'}} />;
                case 'TaskId':
                    return <a href={wkItem.workItemLink} target="_blank" rel="noopener noreferrer" ><Label style={{cursor:'pointer'}}>{wkItem.taskId}</Label></a>;
                case 'Title':
                    return <Text title={wkItem.title}>{wkItem.title}</Text>;
                case 'Description':
                    {/* @ts-ignore*/}
                    return <Editor
                    init={{
                        skin: false,
                        content_css: false,
                        height: 50,
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                    }}
                    value={wkItem.description}
                    disabled={true} />;
                case 'RiskRating':
                    return <Text title={getRiskRating(wkItem.riskRating)}>
                        {
                            getRiskRating(wkItem.riskRating)
                        }</Text>;
                case 'WeaknessType':
                    return <Text title={wkItem.weaknessType}>{wkItem.weaknessType}</Text>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }
    
    return (
        <DetailsList
            items={props.workItems}
            columns={workItemsColumns}
            onRenderItemColumn={_renderItemColumn}
            selectionPreservedOnEmptyClick={true}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.horizontalConstrained}
        />
    );
}
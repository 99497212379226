import { FC, useContext, useEffect, useState } from "react";
import { Announced, Pivot, PivotItem } from "@fluentui/react";
import TeamMetadataComponent from "../Components/Admin/TeamMetadataComponent";
import { TimeZoneConfigurationComponent } from "../Components/Admin/TimeZoneConfiguration";
import { store } from "../Components/Common/GlobalStateProvider";
import { DisplayMessageType, MessageComponent, useMessages } from "../Components/Common/MessageComponent";
import { PortalConfigurationComponent } from "../Components/Admin/PortalConfigurationComponent";
import { ReviewTypeEditComponent } from "../Components/Admin/ReviewTypesEditComponent";
import { PortalAccessComponent } from "../Components/Admin/PortalAccessComponent";

export const AdminDashboardHomePage: FC = (): JSX.Element => {
    const { appState, appdispatch } = useContext(store);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [displayMessages, displayMessagesSet] = useMessages();
    
    useEffect(() => {
        if (appState.initStateLoaded) {
            if (!appState.userMetadata?.isAdmin) {
                setIsAdmin(false);
                displayMessagesSet([{message: "You are not authorised to view this section", messageType: DisplayMessageType.Error}]);
            }
            else
            {
                setIsAdmin(true);
            }
            //setIsAdmin(true);
        }
    }, []);

    return (
        <>
        <div className="container">
        <div className="row">
            <MessageComponent messages={displayMessages} />
        </div>
        
        {isAdmin && (        
            <Pivot aria-label="Admin dashboard">
                {/* <PivotItem headerText="Update Masters" itemIcon="MasterDatabase" style={{padding: '10px'}}>
                    <UpdateMastersComponent title="Update Masters" />
                </PivotItem> */}
                <PivotItem headerText="Teams configuration" itemIcon="ConfigurationSolid" style={{padding: '10px'}} >
                    <TeamMetadataComponent title="Teams configuration"></TeamMetadataComponent>
                </PivotItem>
                <PivotItem headerText="TimeZone configuration" itemIcon="ConfigurationSolid" style={{padding: '10px'}} >
                    <TimeZoneConfigurationComponent title="TimeZone configuration"></TimeZoneConfigurationComponent>
                </PivotItem>
                <PivotItem headerText="Portal configuration" itemIcon="ConfigurationSolid" style={{padding: '10px'}} >
                    <PortalConfigurationComponent title="Portal configuration"></PortalConfigurationComponent>
                </PivotItem>
                <PivotItem headerText="Review Types" itemIcon="" style={{padding:10}}>
                    <ReviewTypeEditComponent />
                </PivotItem>
                <PivotItem headerText="Access Management" itemIcon="ConfigurationSolid" style={{padding:10}}>
                    <PortalAccessComponent title={"Access Management"}></PortalAccessComponent>
                </PivotItem>
            </Pivot>
        )}
        </div>
        </>
    )
}

export default AdminDashboardHomePage;
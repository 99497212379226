import { CheckboxVisibility, ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react"

interface IProps<T>{
    data: T[];
    columns: IColumn[];
}

const DetailsListCustom = <T extends unknown>({data, columns}: IProps<T>) => {
    return(
        <div>
            <DetailsList 
                items={data}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selectionMode={ SelectionMode.single }
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={false}
                checkboxVisibility={CheckboxVisibility.hidden}   
                constrainMode={ConstrainMode.horizontalConstrained}                          
                columns={columns}
            />
        </div>
    )
};

export default DetailsListCustom;

import { DefaultButton, FontIcon, IconButton } from "@fluentui/react";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface ISideBarMenuItemComponentProps {
    id?: string,
    title: string,
    iconName: string,
    navigationPath: string,
    isMinimized: boolean,
    items?: any[],
    close:()=>void;
    onClick?: any
}

export const SideBarMenuItemComponent: FC<ISideBarMenuItemComponentProps> = (props: ISideBarMenuItemComponentProps): JSX.Element => {
    const [showMenu, showMenuSet] = useState(false);
    const show = (showMenu) ? "show" : "";
    const navigate = useNavigate();

    function toggleMenu() {
        showMenuSet(!show);
    }

    return (
        <>
            <div className="d-flex" onClick={toggleMenu}>
                <div>
                    <Link id={props.id}  to={props.navigationPath} aria-label={props.title == "" ? "menu" : props.title}> <FontIcon title={props.title} iconName={props.iconName} style={{ fontSize: 24 }} /> </Link>
                </div>
                {
                    !props.isMinimized && props.title.length > 0 &&
                    <>
                        <div className="me-auto" style={{ marginLeft: 10 }} onClick={props.close}>
                            <DefaultButton
                                id={props.id + "_exanded"}
                                name={props.title == "" ? "menu" : props.title}
                                className="btn rounded"
                                style={{ paddingLeft: 0, marginTop: 3, textAlign: "left" }}
                                aria-expanded="true"
                                aria-label={props.title == "" ? "menu" : props.title}
                                title={props.title == "" ? "menu" : props.title}
                                onClick={() => {

                                    if (props.navigationPath) 
                                    { 
                                        navigate(props.navigationPath); 
                                    }

                                }}>
                                {props.title}
                            </DefaultButton>
                        </div>
                        {
                            props.items && props.items.length > 0 && props.items.some(x => x.isVisible == true) &&
                            <div className="ms-auto">
                                <FontIcon  iconName={show ? "ChevronUp" : "ChevronDown"} style={{ fontSize: 14, marginTop: 8, cursor: 'pointer' }} />
                            </div>
                        }
                    </>
                }
            </div>
            {
                !props.isMinimized && props.items && props.items.length > 0 &&
                <div className={"collapse " + show}>
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        {
                            props.items.map(function (item, index) {
                                if (item.isVisible)
                                {
                                    return <li key={item.title + "_" + index} aria-label={props.title == "" ? "menu" : props.title}>
                                    <div className="d-flex" style={{ marginLeft: 6 }}>
                                        <Link id={item.id} to={item.navigationPath} className="link-dark rounded">
                                            <FontIcon iconName={item.iconName} style={{ fontSize: 18 }} />
                                            <span style={{ marginLeft: 10, marginTop: 2 }} onClick={props.close}>{item.title} </span>
                                        </Link>
                                    </div>
                                </li>;   
                                }
                            })
                        }
                    </ul>
                </div>
            }
        </>
    );
}

export default SideBarMenuItemComponent;
import { addDays, Checkbox, ChoiceGroup, DatePicker, defaultDatePickerStrings, IChoiceGroupOption, Label, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text } from "@fluentui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeadingComponent from "../../Components/Common/HeadingComponent";
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import { ReviewEasyIdAutoFill } from "../../Components/Common/ReviewEasyIdAutoFill";
import { ReviewTypeComponent } from "../../Components/Common/ReviewTypeComponent";
import { ServiceMemberPeoplePickerComponent } from "../../Components/Common/ServiceMemberPeoplePickerComponent";
import { ServiceTreeServicePickerComponent } from "../../Components/Common/ServiceTreeServicePickerComponent";
import { SpinnerPrimaryButton } from "../../Components/Common/SpinnerPrimaryButton";
import { TimeDropDownComponent } from "../../Components/Common/TimeDropDownComponent";
import { TimeZoneDropdownComponent } from "../../Components/Common/TimeZoneDropdownComponent";
import { UploadOrLinkArtifactsComponent } from "../../Components/Common/UploadOrLinkArtifactsComponent";
import { UserReviewTeamsComponent } from "../../Components/Common/UserReviewTeamsComponent";
import { AdhocMeeting } from "../../Models/Reviews/AdhocMeeting";
import { ExistingReviewIds } from "../../Models/Reviews/ExistingReviewIds";
import ServiceTreeService from "../../Models/ServiceTreeService";
import { Team } from "../../Models/Team";
import { TeamMetadata } from "../../Models/TeamMetadata";
import { MeetingTime } from "../../Models/TimeZones/MeetingTime";
import { TMPTimeZone } from "../../Models/TimeZones/TmpTimeZone";
import UserProfile from "../../Models/UserProfile";
import MeetingsService from "../../Services/MeetingsService";
import { isEmptyOrSpaces } from "../../Utilities/textUtilities";

export const AdhocReviewPage: FC = (): JSX.Element => {
    let reviewService = new MeetingsService();

    const stackTokens = { childrenGap: 10, };
    const today: Date = new Date(new Date());
    const yesterday = addDays(today, -1);

    const loadTeamMetadata = async (teamId: string) => {
        var response = await reviewService.getTeamMetaData(teamId);
        if (response && !response.hasErrors && response.result)
        {
            teamMetadataSet(response.result);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The teammetadata details could not be fetched", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const [dataSelectedServiceTreeService, dataSelectedServiceTreeServiceSet] = useState<ServiceTreeService>();
    const [dataSelectedTeam, dataSelectedTeamSet] = useState<Team>();
    useEffect(() => {
        if (dataSelectedTeam && dataSelectedTeam.id) {
            loadTeamMetadata(dataSelectedTeam.id);
        }
    }, [dataSelectedTeam]);
    const [teamMetadata, teamMetadataSet] = useState<TeamMetadata>();
    const [reviewType, reviewTypeSet] = useState<string>();
    const [dataOtherUsers, dataOtherUsersSet] = useState<UserProfile[]>([]);
    const [selectedDate, selectedDateSet] = useState<Date | undefined>(yesterday);
    const [linkedReview, linkedReviewSet] = useState<ExistingReviewIds>();
    const [reviewTimeZone, reviewTimeZoneSet] = useState<TMPTimeZone>();
    const [reviewTime, reviewTimeSet] = useState<MeetingTime>();


    const [isSendMeetingInvite, isSendMeetingInviteSet] = useState<boolean>(false);
    const onSendMeetingInviteChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        isSendMeetingInviteSet(!!checked);
    }

    const [isLinkToExistingReview, isLinkToExistingReviewSet] = useState<boolean>(false);
    const onLinkToExistinReviewChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        isLinkToExistingReviewSet(!!checked);
    }

    const [is30MinSlot, is30MinSlotSet] = useState<boolean>(false);
    const [is1hrMinSlot, is1hrMinSlotSet] = useState<boolean>(true);

    useEffect(() => {
        is1hrMinSlotSet(!is30MinSlot);
    }, [is30MinSlot]);
    useEffect(() => {
        is30MinSlotSet(!is1hrMinSlot);
    }, [is1hrMinSlot]);

    const navigate = useNavigate();

    const [slotDuration, setSlotDuration] = useState<IChoiceGroupOption[]>([
        { key: "30MinSlot", text: "30 min", styles: { field: { marginRight: "15px" } } },
        { key: "1hrMinSlot", text: "1 Hour", styles: { field: { marginRight: "15px" } } },
    ]);

    const onSlotDurationChangeChange = (ev?: React.FormEvent<HTMLInputElement | HTMLElement> | undefined, option?: IChoiceGroupOption | undefined) => {
        if (option && option.key) {
            if(option.key == "1hrMinSlot")
            {
                is1hrMinSlotSet(true);
            }
            else if(option.key == "30MinSlot")
            {
                is30MinSlotSet(true);
            }
        }
    }

    const onNextClick = async () => {
        // await new Promise(r => setTimeout(r, 2000));

        if (selectedDate) {
            var d = new Date(selectedDate);

            if (dataSelectedTeam === undefined || dataSelectedTeam.id === undefined || dataSelectedTeam.id.length === 0) {
                displayMessagesSet([{ message: "Please select a review team.", messageType: DisplayMessageType.Warning }]);
                return;
            }

            if (dataSelectedServiceTreeService === undefined || dataSelectedServiceTreeService.serviceId === undefined || dataSelectedServiceTreeService.serviceId.length === 0) {
                displayMessagesSet([{ message: "Please choose a service.", messageType: DisplayMessageType.Warning }]);
                return;
            }

            if (reviewType === undefined || reviewType.length === 0 || isEmptyOrSpaces(reviewType)) {
                displayMessagesSet([{ message: "Please select a review type.", messageType: DisplayMessageType.Warning }]);
                return;
            }

            if (selectedDate === undefined) {
                displayMessagesSet([{ message: "Please select the date of review.", messageType: DisplayMessageType.Warning }]);
                return;
            }

            if (dataOtherUsers === undefined || dataOtherUsers.length === 0) {
                displayMessagesSet([{ message: "Please add atleast one person from the selected service team.", messageType: DisplayMessageType.Warning }]);
                return;
            }

            if (reviewTimeZone === undefined || reviewTimeZone.id === undefined || reviewTimeZone.id.length === 0) {
                displayMessagesSet([{ message: "Please select the timezone of review.", messageType: DisplayMessageType.Warning }]);
                return;
            }

            if (reviewTime === undefined || reviewTime.id === undefined || reviewTime.id.length === 0) {
                displayMessagesSet([{ message: "Please select the time of review.", messageType: DisplayMessageType.Warning }]);
                return;
            }



            if (isLinkToExistingReview && (linkedReview === undefined || linkedReview.reviewId === undefined || linkedReview.reviewId.length === 0)) {
                displayMessagesSet([{ message: "Please choose a review to link the adhoc meeting with.", messageType: DisplayMessageType.Warning }]);
                return;
            }

            var adhocReview: AdhocMeeting = {
                securityReviewTeam: dataSelectedTeam,
                service: dataSelectedServiceTreeService,
                reviewType: reviewType,
                otherTeamMembers: dataOtherUsers,
                dateOfReview: selectedDate,
                sendTeamsMeeting: isSendMeetingInvite,
                timeZoneOfMeeting: reviewTimeZone,
                timeOfMeeting: reviewTime,
                linkToExistingMeeting: isLinkToExistingReview,
                existinReview: linkedReview,
                is1HourMeeting: is1hrMinSlot,
                is30MinMeeting: is30MinSlot,
                clientOffsetMinutes: d.getTimezoneOffset(),
            };


            var response = await reviewService.recordAdhocReview(adhocReview);
            if (response && !response.hasErrors && response.result) {
                navigate("/reviewdetail/" + response.result.id + "/" + response.result.service?.serviceId)
            }
            else {
                if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                    displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                }
                else {
                    displayMessagesSet([{ message: "The review details could not be updated", messageType: DisplayMessageType.Error }]);
                }
            }
        }
        else {
            displayMessagesSet([{ message: "Please select a date", messageType: DisplayMessageType.Warning }]);
        }
    }

    const [displayMessages, displayMessagesSet] = useMessages();
    return (
        <div className="container" style={{ padding: 10 }}>

            <div className="row">
                <HeadingComponent title="Ad-hoc Review" subtitle="Record or Schedule a Review" />
            </div>
            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>

            <div className="row">
                <div className="col">
                    <div className="row">
                        <UserReviewTeamsComponent
                        id="adhoc_review_userteams"
                        label="Security Review Team"
                        placeholder="Select your Review team"
                        onTeamSelected={(selectedTeam?: Team) => {
                            if (selectedTeam) {
                                dataSelectedTeamSet(selectedTeam);
                            }
                        }} />
                        {dataSelectedTeam && dataSelectedTeam.teamDescription && (
                            <Label title="reviewTeamDescription" style={{fontStyle: "italic", fontWeight: 400}}> {dataSelectedTeam?.teamDescription} </Label>
                        )}
                    </div>
                    <div className="row">
                        <Label htmlFor="ServiceChangePicker" aria-label="Service Name">
                            Service Name
                        </Label>
                        <ServiceTreeServicePickerComponent
                            itemLimit={1}
                            id='adhoc_review_ServicePicker'
                            placeholder="Select a service"
                            SelectedServices={dataSelectedServiceTreeService ? [dataSelectedServiceTreeService] : []}
                            onServiceSelected={(selectedServices) => {
                                if (selectedServices && selectedServices.length > 0 && selectedServices[0]) {
                                    dataSelectedServiceTreeServiceSet(selectedServices[0]);
                                }
                                else {
                                    dataSelectedServiceTreeServiceSet(undefined);
                                }
                            }} />
                    </div>

                    <div className="row">
                        <ReviewTypeComponent
                            id="adhoc_review_reviewType"
                            label="Review Type"
                            showSecurityConsultation={teamMetadata?.allowSC !== undefined ? teamMetadata.allowSC : true}
                            showThreatModelReview = {teamMetadata?.allowTM !== undefined ? teamMetadata.allowTM : true}
                            onReviewTypeSelected={function (selectedReviewType: string): void {
                                reviewTypeSet(selectedReviewType);
                            }} />
                    </div>

                    <div className="row">
                        <ServiceMemberPeoplePickerComponent
                            id="adhoc_review_ServiceMembers"
                            label="Service Team Member(s)"
                            itemLimit={50}
                            serviceData={dataSelectedServiceTreeService}
                            onUserSelected={(usersSelected: UserProfile[]) => {
                                dataOtherUsersSet(usersSelected);
                            }} />
                    </div>


                </div>
                <div className="col">
                    <div className="row">
                        <Label>
                            Date of Review
                        </Label>
                        <DatePicker
                            id="adhoc_review_reviewDate"
                            value={selectedDate}
                            onSelectDate={selectedDateSet as (date: Date | null | undefined) => void}
                            placeholder="Select of review"
                            ariaLabel="Select date of review"
                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultDatePickerStrings}
                        />
                    </div>

                    <div className="row">
                        <div className="row">
                            <div className="col">
                                <TimeZoneDropdownComponent
                                    id="adhoc_review_timezone"
                                    label="Select timezone."
                                    placeholder="Select timezone."
                                    onTimeZoneSelected={((selectedTimeZone?: TMPTimeZone) => {
                                        if (selectedTimeZone) {
                                            reviewTimeZoneSet(selectedTimeZone);
                                        }
                                    })} />

                            </div>
                            <div className="col">
                                <TimeDropDownComponent
                                    id="adhoc_review_time"
                                    label="Select time of meeting"
                                    placeholder="Select time of meeting"
                                    onTimeSelected={(selectedMeetingTime: MeetingTime) => {
                                        reviewTimeSet(selectedMeetingTime);
                                    }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Label>Duration</Label>

                                <Stack horizontal tokens={stackTokens}>
                                    <ChoiceGroup id="adhoc_review_duration" options ={slotDuration} onChange={onSlotDurationChangeChange} styles={{ flexContainer: { display: "flex" }, }} />
                                </Stack>
                            </div>
                        </div>
                    </div>

                    {
                        dataSelectedServiceTreeService && dataSelectedServiceTreeService.serviceId &&
                        <div className="row">
                            <Label></Label>
                            <Checkbox id="adhoc_review_linkexistingreview" label="Link to existing review" checked={isLinkToExistingReview} onChange={onLinkToExistinReviewChanged} />
                            <Text variant="smallPlus">Link to an existing review only if this Adhoc review is extension of previous review you conducted. </Text>
                        </div>
                    }
                    {
                        dataSelectedServiceTreeService
                        && dataSelectedServiceTreeService.serviceId
                        && dataSelectedTeam
                        && dataSelectedTeam.id
                        && isLinkToExistingReview
                        && reviewTimeZone
                        && reviewTime &&
                        <div className="row">
                            <Label>Review Id</Label>
                            <ReviewEasyIdAutoFill
                                id="adhoc_review_existingidpicker"
                                serviceId={dataSelectedServiceTreeService.serviceId}
                                securityTeamId={dataSelectedTeam.id}
                                onEasyIdSelected={(selectedId: ExistingReviewIds) => {
                                    if (selectedId && selectedId.reviewId) {
                                        linkedReviewSet(selectedId);
                                    }
                                }} />
                        </div>
                    }

                    {
                        selectedDate && selectedDate >= yesterday &&
                        <div className="row">
                            <Label></Label>
                            <Checkbox id="adhoc_review_sendteamsinvite" label="Send Teams Invite" checked={isSendMeetingInvite} onChange={onSendMeetingInviteChanged} />
                        </div>
                    }

                </div>
            </div>
            <div className="row">
                <div className="d-flex" style={{ margin: 20, padding: 10 }}>
                    <div className="mx-auto">
                        <Stack horizontal tokens={stackTokens}>
                            <StackItem>
                                <SpinnerPrimaryButton id="adhoc_review_recordbutton" onclick={onNextClick} text={isSendMeetingInvite ? "Schedule Review" : "Record Review"} executingText="Saving the Review information" />
                            </StackItem>
                        </Stack>
                    </div>
                </div>
            </div>

        </div>
    );
}
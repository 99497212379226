import { ComboBox, IComboBox, IComboBoxOption, IDropdownStyles } from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { Team } from "../../Models/Team";
import { store } from "./GlobalStateProvider";

interface IUserReviewTeamsComponent{
    id?: string
    label?: string,
    placeholder?: string,
    selectedTeamId?: string,
    onTeamSelected: (selectedTeam?: Team) => void,
}

export const UserReviewTeamsComponent: FC<IUserReviewTeamsComponent> = (props: IUserReviewTeamsComponent): JSX.Element => {
    
    var { appState, appdispatch } = useContext(store);
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 'stretch' },
    };
    
    const [cmbUserTeamsOptions, cmbUserTeamsOptionsSet] = useState<IComboBoxOption[]>([]);
    const [cmbUserSelectedTeamKey, cmbUserSelectedTeamKeySet] = useState<string>();
    const onReviewTeamChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            cmbUserSelectedTeamKeySet(option.key as string);

            var selectedTeams = appState.userRWTeams?.filter(x => x.id == option.key as string);
            if (selectedTeams && selectedTeams.length > 0)
            {
                props.onTeamSelected(selectedTeams[0]);
            }
            else {
                props.onTeamSelected(undefined);
            }
        }
    };
    
    useEffect(()=>{
        if(appState.initStateLoaded && appState.userRWTeams && appState.userRWTeams.length > 0)
        {
            var tTeamOptions: IComboBoxOption[] = [];

            appState.userRWTeams.forEach(team => {
                if (team.id && team.name && team.teamMetadata?.isActive)
                {
                    tTeamOptions.push({
                        key: team.id,
                        text: team.name,
                    });
                }
            })

            cmbUserTeamsOptionsSet(tTeamOptions);
        }
        
    },[]);

    useEffect(()=>{
        if (props.selectedTeamId && props.selectedTeamId.length > 0 && cmbUserTeamsOptions && cmbUserTeamsOptions.length > 0)
        {
            var selectedOption = cmbUserTeamsOptions.filter(x => x.key == props.selectedTeamId)
            if (selectedOption && selectedOption[0])
            {
                cmbUserSelectedTeamKeySet(props.selectedTeamId);
            }
        }
    },[props]);

    return (<ComboBox
        id={props.id}
        label={props.label}
        placeholder={props.placeholder}
        selectedKey={cmbUserSelectedTeamKey}
        styles={dropdownStyles}
        onChange={onReviewTeamChange}
        useComboBoxAsMenuWidth={true}
        options={cmbUserTeamsOptions} />);
}
import { addDays, addMonths, addYears, buildColumns, Checkbox, ComboBox, ConstrainMode, DatePicker, DefaultButton, DetailsList, DetailsListLayoutMode, FontIcon, FontWeights, getTheme, IButtonStyles, IColumn, IComboBox, IComboBoxOption, IconButton, IIconProps, Label, mergeStyleSets, Modal, noWrap, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SelectionMode, SpinButton, Stack, StackItem, TextField, Text, Dropdown, MessageBar, MessageBarType, Check } from "@fluentui/react";
import { useBoolean, useId, useRefEffect } from "@fluentui/react-hooks";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { start } from "repl";
import { store } from "../../Components/Common/GlobalStateProvider";
import { DisplayMessageType, MessageComponent, useMessages } from "../../Components/Common/MessageComponent";
import { SpinnerPrimaryButton } from "../../Components/Common/SpinnerPrimaryButton";
import { TimeDropDownComponent } from "../../Components/Common/TimeDropDownComponent";
import { TimeZoneDropdownComponent } from "../../Components/Common/TimeZoneDropdownComponent";
import { UserReviewTeamsComponent } from "../../Components/Common/UserReviewTeamsComponent";
import { Review } from "../../Models/Reviews/Review";
import { Schedule } from "../../Models/Reviews/Schedule";
import { TimeOff } from "../../Models/Reviews/TimeOff";
import { Team } from "../../Models/Team";
import { MeetingTime } from "../../Models/TimeZones/MeetingTime";
import { TMPTimeZone } from "../../Models/TimeZones/TmpTimeZone";
import MeetingsService from "../../Services/MeetingsService";
import { getDateFormat, getDateTimeFromDateAndTimeAMPM, getTimeFormat } from "../../Utilities/dateutilities";
import { isEmptyOrSpaces } from "../../Utilities/textUtilities";

export const AddSchedulesPage: FC = (): JSX.Element => {

    let meetingService: MeetingsService = new MeetingsService();

    const buttonStyles = { root: { marginRight: 8 } };

    const today: Date = new Date(new Date());
    const minDate = addDays(today, +1);
    const maxDate = addYears(today, 5);
    const stackTokens = { childrenGap: 10, };

    const [slotName, slotNameSet] = useState<string>();
    const [startDate, startDateSet] = useState<Date | undefined>();
    const [endDate, endDateSet] = useState<Date | undefined>();

    const [isSunChecked, isSunCheckedSet] = useState<boolean>(false);
    const [isMonChecked, isMonCheckedSet] = useState<boolean>(true);
    const [isTueChecked, isTueCheckedSet] = useState<boolean>(true);
    const [isWedChecked, isWedCheckedSet] = useState<boolean>(true);
    const [isThuChecked, isThuCheckedSet] = useState<boolean>(true);
    const [isFriChecked, isFriCheckedSet] = useState<boolean>(false);
    const [isSatChecked, isSatCheckedSet] = useState<boolean>(false);

    const [is30MinSlot, is30MinSlotSet] = useState<boolean>(false);
    const [is1hrMinSlot, is1hrMinSlotSet] = useState<boolean>(true);

    const [dataSelectedTime, dataSelectedTimeSet] = useState<MeetingTime>();
    const [dataSelectedTimeZone, dataSelectedTimeZoneSet] = useState<TMPTimeZone>();
    const [dataSelectedReviewTeam, dataSelectedReviewTeamSet] = useState<Team>();

    const theme = getTheme();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    useEffect(() => {
        if (!isModalOpen) {
            slotNameSet(undefined);
            startDateSet(undefined);
            endDateSet(undefined);
            isMonCheckedSet(true);
            isTueCheckedSet(true);
            isWedCheckedSet(true);
            isThuCheckedSet(true);
            isFriCheckedSet(false);
            selectedTimeZoneIdSet(undefined);
            selectedTimeIdSet(undefined);
            is1hrMinSlotSet(true);
            is30MinSlotSet(false);
            overlapWaningMessageSet("");
            showOverlapWaningSet(false);
            overrideOverlapWarningSet(false);
        }
    }, [isModalOpen]);

    /* Time off modal  */
    const [timeOffStartDate, timeOffStartDateSet] = useState<Date | undefined>();
    const [timeOffEndDate, timeOffEndDateSet] = useState<Date | undefined>();

    const [dataTeamSelectedTimeOffTzs, dataTeamSelectedTimeOffTzsSet] = useState<TMPTimeZone>();

    const [isTimeOffModalOpen, { setTrue: showTimeOffModal, setFalse: hideTimeOffModal }] = useBoolean(false);
    useEffect(() => {
        if (!isTimeOffModalOpen) {
            dataTeamSelectedTimeOffTzsSet(undefined);
            timeOffStartDateSet(undefined);
            timeOffEndDateSet(undefined);
            timeOffReasonSet(undefined);
        }

    }, [isTimeOffModalOpen]);

    const [timeOffReason, timeOffReasonSet] = useState<string>();

    const [timeOffSlots, timeOffSlotsSet] = useState<Schedule[]>([]);
    const [selectedTimeOffSlots, selectedTimeOffSlotsSet] = useState<Schedule[]>([]);
    /* Time off modal  */

    const [validTimeZoneIds, validTimeZoneIdsSet] = useState<string[]>([]);

    /*Schedules display*/
    const [dataTeamSchedules, dataTeamSchedulesSet] = useState<Schedule[]>([]);
    const [dataTeamTimeOffs, dataTeamTimeOffsSet] = useState<TimeOff[]>([]);
    const [dataSelectedScheduleForEdit, dataSelectedScheduleForEditSet] = useState<Schedule>();
    const [minWidth] = useState(160);
    const [teamScheduleColumns, teamScheduleColumnsSet] = useState<IColumn[]>(
        [
            {
                key: 'SlotName',
                name: 'Slot Name',
                minWidth: 120
            },
            {
                key: 'StartDate',
                name: 'Start Date',
                minWidth: 70
            },
            {
                key: 'EndDate',
                name: 'End Date',
                minWidth: 60
            },
            {
                key: 'StartTime',
                name: 'Start Time',
                minWidth: 80
            },
            {
                key: 'Duration',
                name: 'Duration',
                minWidth: 60
            },
            {
                key: 'ActiveWeekDays',
                name: 'Active Week Days',
                minWidth: 200,
            },
            {
                key: 'TimeZone',
                name: 'Time Zone',
                minWidth: 300
            },
            {
                key: 'QuickActions',
                name: 'Actions ',
                minWidth: 100
            },
        ]
    );
    const [teamTimeOffColumns, teamTimeOffColumnsSet] = useState<IColumn[]>(
        [
            {
                key: 'StartDate',
                name: 'Start Date',
                minWidth: 120
            },
            {
                key: 'EndDate',
                name: 'End Date',
                minWidth: 70
            },
            {
                key: 'TimeOffReason',
                name: 'Reason',
                minWidth: 200
            },
            {
                key: 'TimeZone',
                name: 'Time Zone',
                minWidth: 300
            },
            {
                key: 'QuickActions',
                name: 'Actions ',
                minWidth: 100
            },
        ]
    );

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',

        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const titleId = useId('title');

    const loadSchedulesForTeam = async (teamId: string) => {
        var response = await meetingService.getActiveSchedules(teamId);

        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            dataTeamSchedulesSet(response.result);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            displayMessagesSet([{ message: "No Schedules found for selected team", messageType: DisplayMessageType.Information }]);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The schedules could not be loaded.", messageType: DisplayMessageType.Error }]);
            }
        }
    }
    const loadTimeOffsForTeam = async (teamId: string) => {
        var response = await meetingService.getActiveTimeOffs(teamId);

        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            dataTeamTimeOffsSet(response.result);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            displayMessagesSet([{ message: "No Time Offs found for selected team", messageType: DisplayMessageType.Information }]);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The time Offs could not be loaded.", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    function reset() {
        displayMessagesSet([]);
        displayAddSchedulePanelMessagesSet([]);
        displayAddTimeOffPanelMessagesSet([]);
        displayEditSchedulePanelMessagesSet([]);
    }

    useEffect(() => {
    }, []);
    useEffect(() => {
        if (dataSelectedReviewTeam && dataSelectedReviewTeam.id) {
            loadSchedulesForTeam(dataSelectedReviewTeam.id);
            loadTimeOffsForTeam(dataSelectedReviewTeam.id);
        }
    }, [dataSelectedReviewTeam]);
    useEffect(() => {
        is1hrMinSlotSet(!is30MinSlot);
    }, [is30MinSlot]);
    useEffect(() => {
        is30MinSlotSet(!is1hrMinSlot);
    }, [is1hrMinSlot]);
    useEffect(() => {
        if (dataTeamSelectedTimeOffTzs && dataTeamSelectedTimeOffTzs.id) {
            var shortlistedTzSchedules = dataTeamSchedules.filter(x => x.tmpTimeZone?.id == dataTeamSelectedTimeOffTzs.id);
            if (shortlistedTzSchedules && shortlistedTzSchedules.length > 0) {
                timeOffSlotsSet(shortlistedTzSchedules);
            }
            else {
                timeOffSlotsSet([]);
            }
        }
    }, [dataTeamSelectedTimeOffTzs]);
    useEffect(() => {
        var shortlistedTzs = dataTeamSchedules.map(x => x.tmpTimeZone?.id !== undefined ? x.tmpTimeZone.id : "").filter((v, i, a) => a.indexOf(v) === i);
        if (shortlistedTzs && shortlistedTzs.length > 0) {
            validTimeZoneIdsSet(shortlistedTzs);
        }
    }, [dataTeamSchedules])

    const onSunChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isSunCheckedSet(checked as boolean);
        },
        [],
    );
    const onMonChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isMonCheckedSet(checked as boolean);
        },
        [],
    );

    const onTueChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isTueCheckedSet(checked as boolean);
        },
        [],
    );

    const onWedChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isWedCheckedSet(checked as boolean);
        },
        [],
    );

    const onThuChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isThuCheckedSet(checked as boolean);
        },
        [],
    );

    const onFriChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isFriCheckedSet(checked as boolean);
        },
        [],
    );
    const onSatChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            isSatCheckedSet(checked as boolean);
        },
        [],
    );

    const on30MinSlotCheck = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            is30MinSlotSet(checked as boolean);
        },
        [],
    );

    const on1hrSlotCheck = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            is1hrMinSlotSet(checked as boolean);
        },
        [],
    );

    const AddScheduleToTeam = async () => {
        displayAddSchedulePanelMessagesSet([]);

        let offset = new Date().getTimezoneOffset();

        if (slotName === undefined || slotName.length === 0) {
            displayAddSchedulePanelMessagesSet([{ message: "Please enter a slot name.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var isEmpty = isEmptyOrSpaces(slotName);
        if (isEmpty) {
            displayAddSchedulePanelMessagesSet([{ message: "The slot name must be valid and not white spaces.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined) {
            displayAddSchedulePanelMessagesSet([{ message: "The team configuration could not be determined", messageType: DisplayMessageType.Error }]);
            return;
        }

        if (startDate === undefined || endDate === undefined) {
            displayAddSchedulePanelMessagesSet([{ message: "The start date or end date cannot be null", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedTimeZone === undefined || dataSelectedTimeZone.id === undefined || dataSelectedTimeZone.id.length === 0) {
            displayAddSchedulePanelMessagesSet([{ message: "Please select Timezone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedTime === undefined || dataSelectedTime.id === undefined || dataSelectedTime.id.length === 0) {
            displayAddSchedulePanelMessagesSet([{ message: "Please select start time", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (selectedSlotVisibility === undefined || selectedSlotVisibility === null) {
            displayEditSchedulePanelMessagesSet([{ message: "Please select slot visibility", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var createSchedule: Schedule = {
            team: dataSelectedReviewTeam,
            name: slotName,
            startDate: startDate,
            endDate: endDate,
            isSundayActive: isSunChecked,
            isMondayActive: isMonChecked,
            isTuedayActive: isTueChecked,
            isWeddayActive: isWedChecked,
            isThudayActive: isThuChecked,
            isFridayActive: isFriChecked,
            isSaturdayActive: isSatChecked,
            slotStart: dataSelectedTime,
            is1HourSlot: is1hrMinSlot,
            is30MinSlot: is30MinSlot,
            tmpTimeZone: dataSelectedTimeZone,
            clientOffsetMinutes: offset,
            visibleDays: selectedSlotVisibility
        };

        var overlappingSchedules = findOverlappingSchedules(createSchedule, dataTeamSchedules)
        if (!overrideOverlapWarning && overlappingSchedules && overlappingSchedules.length > 0) {
            overlapWaningMessageSet("The schedule overlaps with existing schedules (" + getCommaSeparatedScheduleNames(overlappingSchedules) + ") in the same timezone. Ensure the ovelapping meetings are staffed accordingly to avoid conflicting meeting schedules.");
            showOverlapWaningSet(true);
            return;
        }

        var response = await meetingService.addScheduleToTeam(createSchedule);
        if (response && !response.hasErrors && response.result) {
            dataTeamSchedulesSet([...dataTeamSchedules, response.result]);
        }
        else {
            dataTeamSchedulesSet([]);
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The schedule could not be added", messageType: DisplayMessageType.Error }]);
            }
        }

        hideModal();
    }

    const DeleteTimeOff = async (teamId?: string, timeOffId?: string) => {
        if (timeOffId && timeOffId.length > 0 && teamId && teamId.length > 0) {
            var response = await meetingService.deleteTimeOff(teamId, timeOffId);
            if (response && !response.hasErrors && response.result) {
                var otherTimeOffs = dataTeamTimeOffs.filter(x => x.id !== timeOffId);
                if (otherTimeOffs && otherTimeOffs.length > 0) {
                    console.log(otherTimeOffs);
                    dataTeamTimeOffsSet(otherTimeOffs);
                }
                else {
                    dataTeamTimeOffsSet([]);
                }
            }
            else {
                if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                    displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                }
                else {
                    displayMessagesSet([{ message: "The time off could not be deleted", messageType: DisplayMessageType.Error }]);
                }
            }
        }
    }

    const onSlotNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        slotNameSet(newValue);
    }

    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var currentScheduleItem = item as Schedule;
        if (column && currentScheduleItem) {
            switch (column!.key) {
                case 'SlotName':
                    return <div>{currentScheduleItem.name}</div>;
                case 'StartDate':
                    return <div>{currentScheduleItem.startDateStr}</div>;
                case 'EndDate':
                    return <div>{currentScheduleItem.endDateStr}</div>;
                case 'StartTime':
                    return <div><Stack horizontal tokens={stackTokens}>{currentScheduleItem.slotStart?.timeAMPM} </Stack></div>;
                case 'Duration':
                    return <div>{currentScheduleItem.is1HourSlot ? "1 hr" : "30 min"}</div>
                case 'ActiveWeekDays':
                    return <div>
                        <Stack tokens={stackTokens} horizontal>
                            <StackItem>
                                <div className={currentScheduleItem.isSundayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isSundayActive ? "Active" : "Inactive"}>S</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isMondayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isMondayActive ? "Active" : "Inactive"}>M</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isTuedayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isTuedayActive ? "Active" : "Inactive"}>T</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isWeddayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isWeddayActive ? "Active" : "Inactive"}>W</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isThudayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isThudayActive ? "Active" : "Inactive"}>T</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isFridayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isFridayActive ? "Active" : "Inactive"}>F</div>
                            </StackItem>
                            <StackItem>
                                <div className={currentScheduleItem.isSaturdayActive ? "activeWeekday" : "inActiveWeekday"} title={currentScheduleItem.isSaturdayActive ? "Active" : "Inactive"}>S</div>
                            </StackItem>
                        </Stack>
                    </div>;
                case 'TimeZone':
                    return <div>{currentScheduleItem.tmpTimeZone?.displayName}</div>;
                case 'QuickActions':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            <FontIcon iconName="Edit"
                                style={{ fontSize: 14, color: "blue", cursor: 'pointer' }}
                                onClick={() => { reset(); reviewsTobeRescheduledSet([]); dataSelectedScheduleForEditSet(currentScheduleItem); showSlotEditModal(); }} />

                            <FontIcon iconName="Delete"
                                style={{ fontSize: 14, color: "blue", cursor: 'pointer' }}
                                onClick={() => { reset(); reviewsTobeCancelledSet([]); dataSelectedScheduleForDeleteSet(currentScheduleItem); showSlotDeleteModal(); }} />
                        </Stack>
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    function _renderTimeOffColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var currentScheduleItem = item as TimeOff;
        if (column && currentScheduleItem) {
            switch (column!.key) {
                case 'TimeOffReason':
                    return <div title={currentScheduleItem.timeOffReason}>{currentScheduleItem.timeOffReason}</div>;
                case 'StartDate':
                    return <div>{currentScheduleItem.startDateStr}</div>;
                case 'EndDate':
                    return <div>{currentScheduleItem.endDateStr}</div>;
                case 'TimeZone':
                    return <div>{currentScheduleItem.tmpTimeZone?.displayName}</div>;
                case 'QuickActions':
                    return <div>
                        <Stack horizontal tokens={stackTokens}>
                            <FontIcon iconName="Delete"
                                style={{ fontSize: 14, color: "blue", cursor: 'pointer' }}
                                onClick={() => { reset(); DeleteTimeOff(dataSelectedReviewTeam?.id, currentScheduleItem.id) }} />
                        </Stack>
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    const onTimeOffReasonChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        timeOffReasonSet(newValue);
    }

    const ValidateTimeOffToSlots = async () => {
        reviewsTobeRescheduledSet([]);
        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined) {
            displayAddTimeOffPanelMessagesSet([{ message: "The team configuration could not be determined", messageType: DisplayMessageType.Error }]);
            return;
        }

        if (dataTeamSelectedTimeOffTzs === undefined || dataTeamSelectedTimeOffTzs.id === undefined || dataTeamSelectedTimeOffTzs.id.length === 0) {
            displayAddTimeOffPanelMessagesSet([{ message: "Please select a time zone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (timeOffStartDate === undefined || timeOffEndDate === undefined) {
            displayAddTimeOffPanelMessagesSet([{ message: "The start date or end date cannot be null", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (timeOffReason === undefined || timeOffReason.length === 0) {
            displayAddTimeOffPanelMessagesSet([{ message: "Please enter a time off reason.", messageType: DisplayMessageType.Warning }]);
            return;
        }


        let offset = new Date().getTimezoneOffset();

        if (applyTimeOffToAllSlots) {
            selectedTimeOffSlotsSet([]);
        }

        var timeOff: TimeOff = {
            startDate: timeOffStartDate,
            endDate: timeOffEndDate,
            clientOffsetMinutes: offset,
            team: dataSelectedReviewTeam,
            timeOffReason: timeOffReason,
            selectedSlots: selectedTimeOffSlots,
            tmpTimeZone: dataTeamSelectedTimeOffTzs
        }

        var response = await meetingService.validateTimeOffs(timeOff);
        if (response && !response.hasErrors && response.result && response.result?.length > 0) {
            reviewsTobeRescheduledSet(response.result);
        }
        else if (response && !response.hasErrors && response.result?.length == 0) {
            enableAddTimeOffSet(true);
            displayAddTimeOffPanelMessagesSet([{ message: "The validation succeeded. Please click \"Add Timeoff\". to save", messageType: DisplayMessageType.Information }]);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayAddTimeOffPanelMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayAddTimeOffPanelMessagesSet([{ message: "The review details could not be updated", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const AddTimeOffToSlots = async () => {
        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined) {
            displayAddTimeOffPanelMessagesSet([{ message: "The team configuration could not be determined", messageType: DisplayMessageType.Error }]);
            return;
        }

        if (dataTeamSelectedTimeOffTzs === undefined || dataTeamSelectedTimeOffTzs.id === undefined || dataTeamSelectedTimeOffTzs.id.length === 0) {
            displayAddTimeOffPanelMessagesSet([{ message: "Please select a time zone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (timeOffStartDate === undefined || timeOffEndDate === undefined) {
            displayAddTimeOffPanelMessagesSet([{ message: "The start date or end date cannot be null", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (timeOffReason === undefined || timeOffReason.length === 0) {
            displayAddTimeOffPanelMessagesSet([{ message: "Please enter a time off reason.", messageType: DisplayMessageType.Warning }]);
            return;
        }


        let offset = new Date().getTimezoneOffset();

        if (applyTimeOffToAllSlots) {
            selectedTimeOffSlotsSet([]);
        }

        var timeOff: TimeOff = {
            startDate: timeOffStartDate,
            endDate: timeOffEndDate,
            clientOffsetMinutes: offset,
            team: dataSelectedReviewTeam,
            timeOffReason: timeOffReason,
            selectedSlots: selectedTimeOffSlots,
            tmpTimeZone: dataTeamSelectedTimeOffTzs
        }

        var response = await meetingService.addTimeOffs(timeOff);
        if (response && !response.hasErrors && response.result) {
            dataTeamTimeOffsSet([...dataTeamTimeOffs, response.result]);
            hideTimeOffModal();

            displayMessagesSet([{ message: "Successfully added the time off.", messageType: DisplayMessageType.Success }]);
        } else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The review details could not be updated", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    const OnSlotSelectedForTimeOff = async (slot: Schedule) => {
        if (slot && slot.id) {
            selectedTimeOffSlotsSet([...selectedTimeOffSlots, slot]);

            applyTimeOffToAllSlotsSet(false);
        }
    }

    const onRenderScheduleFooterContent = () => (
        <Stack horizontal tokens={stackTokens}>
            <SpinnerPrimaryButton text="Add Slot" executingText="Saving Schedule Information" onclick={AddScheduleToTeam} />
            <DefaultButton aria-label="Cancel" onClick={hideModal}>Cancel</DefaultButton>
        </Stack>
    );

    const [enableAddTimeOff, enableAddTimeOffSet] = useState<boolean>(false);
    const onRenderTimeOffFooterContent = () => (
        <Stack horizontal tokens={stackTokens}>
            {

                !enableAddTimeOff ? <SpinnerPrimaryButton text="Validate Add TimeOff" executingText="Validating the Time off Information" onclick={ValidateTimeOffToSlots} />
                    : <SpinnerPrimaryButton text="Add TimeOff" executingText="Saving the Addon Information" onclick={AddTimeOffToSlots} />

            }
            <DefaultButton aria-label="Cancel" onClick={hideTimeOffModal}>Cancel</DefaultButton>
        </Stack>
    );

    const [displayMessages, displayMessagesSet] = useMessages();
    const [displayAddSchedulePanelMessages, displayAddSchedulePanelMessagesSet] = useMessages();
    const [displayAddTimeOffPanelMessages, displayAddTimeOffPanelMessagesSet] = useMessages();
    const [displayEditSchedulePanelMessages, displayEditSchedulePanelMessagesSet] = useMessages();

    const [isSlotEditModalOpen, { setTrue: showSlotEditModal, setFalse: hideSlotEditModal }] = useBoolean(false);
    useEffect(() => {

    }, [isSlotEditModalOpen])
    const onRenderEditScheduleFooterContent = () => (
        <Stack horizontal tokens={stackTokens}>
            {
                !enableEditSchedule ? <SpinnerPrimaryButton text="Validate Edit" executingText="Validating Schedule Information Edit" onclick={validateEditSchedule} />
                    : <SpinnerPrimaryButton text="Save" executingText="Saving Schedule Information Edit" onclick={saveEditSchedule} />
            }

            <DefaultButton aria-label="Cancel" onClick={() => { hideSlotEditModal(); dataSelectedScheduleForEditSet(undefined); enableEditScheduleSet(false); }}>Cancel</DefaultButton>
        </Stack>
    );
    useEffect(() => {
        if (dataSelectedScheduleForEdit && dataSelectedScheduleForEdit.startDate && dataSelectedScheduleForEdit.endDate) {
            slotNameSet(dataSelectedScheduleForEdit.name);
            startDateSet(new Date(dataSelectedScheduleForEdit.startDate));
            endDateSet(new Date(dataSelectedScheduleForEdit.endDate));
            isSunCheckedSet(dataSelectedScheduleForEdit.isSundayActive ?? false);
            isMonCheckedSet(dataSelectedScheduleForEdit.isMondayActive ?? false);
            isTueCheckedSet(dataSelectedScheduleForEdit.isTuedayActive ?? false);
            isWedCheckedSet(dataSelectedScheduleForEdit.isWeddayActive ?? false);
            isThuCheckedSet(dataSelectedScheduleForEdit.isThudayActive ?? false);
            isFriCheckedSet(dataSelectedScheduleForEdit.isFridayActive ?? false);
            isSatCheckedSet(dataSelectedScheduleForEdit.isSaturdayActive ?? false);
            selectedTimeZoneIdSet(dataSelectedScheduleForEdit.tmpTimeZone?.id);
            selectedTimeIdSet(dataSelectedScheduleForEdit.slotStart?.id);
            is1hrMinSlotSet(dataSelectedScheduleForEdit.is1HourSlot ?? true);
            is30MinSlotSet(dataSelectedScheduleForEdit.is30MinSlot ?? false);
            selectedSlotVisibilitySet(dataSelectedScheduleForEdit.visibleDays);
            if (new Date(dataSelectedScheduleForEdit.startDate) < new Date()) {
                startDateEditDisabledSet(true);
            }
        }
        else {
            slotNameSet(undefined);
            startDateSet(undefined);
            endDateSet(undefined);
            isMonCheckedSet(true);
            isTueCheckedSet(true);
            isWedCheckedSet(true);
            isThuCheckedSet(true);
            isFriCheckedSet(false);
            selectedTimeZoneIdSet(undefined);
            selectedTimeIdSet(undefined);
            is1hrMinSlotSet(true);
            is30MinSlotSet(false);
        }
    }, [dataSelectedScheduleForEdit])
    const [selectedTimeZoneId, selectedTimeZoneIdSet] = useState<string>();
    const [selectedTimeId, selectedTimeIdSet] = useState<string>();
    const [startDateEditDisabled, startDateEditDisabledSet] = useState<boolean>(false);
    const validateEditSchedule = async () => {
        displayEditSchedulePanelMessagesSet([]);
        reviewsTobeRescheduledSet([]);

        let offset = new Date().getTimezoneOffset();

        if (slotName === undefined || slotName.length === 0) {
            displayEditSchedulePanelMessagesSet([{ message: "Please enter a slot name.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var isEmpty = isEmptyOrSpaces(slotName);
        if (isEmpty) {
            displayEditSchedulePanelMessagesSet([{ message: "The slot name must be valid and not white spaces.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined) {
            displayEditSchedulePanelMessagesSet([{ message: "The team configuration could not be determined", messageType: DisplayMessageType.Error }]);
            return;
        }

        if (startDate === undefined || endDate === undefined) {
            displayEditSchedulePanelMessagesSet([{ message: "The start date or end date cannot be null", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedTimeZone === undefined || dataSelectedTimeZone.id === undefined || dataSelectedTimeZone.id.length === 0) {
            displayEditSchedulePanelMessagesSet([{ message: "Please select Timezone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedTime === undefined || dataSelectedTime.id === undefined || dataSelectedTime.id.length === 0) {
            displayEditSchedulePanelMessagesSet([{ message: "Please select start time", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (selectedSlotVisibility === undefined || selectedSlotVisibility === null) {
            displayEditSchedulePanelMessagesSet([{ message: "Please select slot visibility", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var validateEditSchedule: Schedule = {
            id: dataSelectedScheduleForEdit?.id,
            team: dataSelectedReviewTeam,
            name: slotName,
            startDate: startDate,
            endDate: endDate,
            isSundayActive: isSunChecked,
            isMondayActive: isMonChecked,
            isTuedayActive: isTueChecked,
            isWeddayActive: isWedChecked,
            isThudayActive: isThuChecked,
            isFridayActive: isFriChecked,
            isSaturdayActive: isSatChecked,
            slotStart: dataSelectedTime,
            is1HourSlot: is1hrMinSlot,
            is30MinSlot: is30MinSlot,
            tmpTimeZone: dataSelectedTimeZone,
            clientOffsetMinutes: offset,
            visibleDays: selectedSlotVisibility
        };

        var response = await meetingService.validateEditScheduleToTeam(validateEditSchedule);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            reviewsTobeRescheduledSet(response.result);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            // Enable save.
            displayEditSchedulePanelMessagesSet([{ message: "The validation succeeded. You can save the edit now.", messageType: DisplayMessageType.Success }]);
            enableEditScheduleSet(true);
        }
        else {
            dataTeamSchedulesSet([]);
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayEditSchedulePanelMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayEditSchedulePanelMessagesSet([{ message: "The schedule could not be added", messageType: DisplayMessageType.Error }]);
            }
        }
    }
    const saveEditSchedule = async () => {
        displayEditSchedulePanelMessagesSet([]);

        let offset = new Date().getTimezoneOffset();

        if (slotName === undefined || slotName.length === 0) {
            displayEditSchedulePanelMessagesSet([{ message: "Please enter a slot name.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var isEmpty = isEmptyOrSpaces(slotName);
        if (isEmpty) {
            displayEditSchedulePanelMessagesSet([{ message: "The slot name must be valid and not white spaces.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedReviewTeam === undefined || dataSelectedReviewTeam.id === undefined) {
            displayEditSchedulePanelMessagesSet([{ message: "The team configuration could not be determined", messageType: DisplayMessageType.Error }]);
            return;
        }

        if (startDate === undefined || endDate === undefined) {
            displayEditSchedulePanelMessagesSet([{ message: "The start date or end date cannot be null", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedTimeZone === undefined || dataSelectedTimeZone.id === undefined || dataSelectedTimeZone.id.length === 0) {
            displayEditSchedulePanelMessagesSet([{ message: "Please select Timezone", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedTime === undefined || dataSelectedTime.id === undefined || dataSelectedTime.id.length === 0) {
            displayEditSchedulePanelMessagesSet([{ message: "Please select start time", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (selectedSlotVisibility === undefined || selectedSlotVisibility === null) {
            displayEditSchedulePanelMessagesSet([{ message: "Please select slot visibility", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var editSchedule: Schedule = {
            id: dataSelectedScheduleForEdit?.id,
            team: dataSelectedReviewTeam,
            name: slotName,
            startDate: startDate,
            endDate: endDate,
            isSundayActive: isSunChecked,
            isMondayActive: isMonChecked,
            isTuedayActive: isTueChecked,
            isWeddayActive: isWedChecked,
            isThudayActive: isThuChecked,
            isFridayActive: isFriChecked,
            isSaturdayActive: isSatChecked,
            slotStart: dataSelectedTime,
            is1HourSlot: is1hrMinSlot,
            is30MinSlot: is30MinSlot,
            tmpTimeZone: dataSelectedTimeZone,
            clientOffsetMinutes: offset,
            visibleDays: selectedSlotVisibility,
        };

        var response = await meetingService.saveEditScheduleToTeam(editSchedule);
        if (response && !response.hasErrors && response.result) {
            var otherSchedules = dataTeamSchedules.filter(x => x.id !== editSchedule.id);
            if (otherSchedules && otherSchedules.length > 0) {
                dataTeamSchedulesSet([...otherSchedules, response.result]);
            }
            else {
                dataTeamSchedulesSet([response.result]);
            }

            hideSlotEditModal();
        }
        else {
            dataTeamSchedulesSet([]);
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The schedule could not be saved", messageType: DisplayMessageType.Error }]);
            }
        }
    }
    const [reviewsTobeRescheduled, reviewsTobeRescheduledSet] = useState<Review[]>([]);
    const [enableEditSchedule, enableEditScheduleSet] = useState<boolean>(false);

    const [applyTimeOffToAllSlots, applyTimeOffToAllSlotsSet] = useState<boolean>(true);
    const OnSelectAllSlots = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        if (isChecked && isChecked === true) {
            applyTimeOffToAllSlotsSet(true);
        }
        else {

            applyTimeOffToAllSlotsSet(false);
        }
    }
    useEffect(() => {
        if (selectedTimeOffSlots && selectedTimeOffSlots.length > 0) {
            enableAddTimeOffSet(true);
        }
    }, [selectedTimeOffSlots]);

    const visibilityOptions: IComboBoxOption[] = [
        { key: 7, text: '7 Days (1 week)' },
        { key: 15, text: '15 Days (~2 weeks)' },
        { key: 30, text: '30 Days (~1 month)' },
        { key: 45, text: '45 Days (~1.5 months)' },
        { key: 60, text: '60 Days (~2 months)' },
        // { key: 75, text: '75 Days (~2.5 months)' },
        { key: 90, text: '90 Days (~3 months)' },
        // { key: 120, text: '120 Days (~4 months)' },
    ];
    const [selectedSlotVisibility, selectedSlotVisibilitySet] = useState<number>();
    const onVisibilityChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            selectedSlotVisibilitySet(option.key as number);
        }
    };
    useEffect(() => {

    }, [selectedSlotVisibility])

    const [showOverlapWaning, showOverlapWaningSet] = useState<boolean>(false);
    const [overlapWaningMessage, overlapWaningMessageSet] = useState<string>();
    const [overrideOverlapWarning, overrideOverlapWarningSet] = useState<boolean>(false);
    const onOverlapOverrideChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
        if (checked !== undefined && checked !== null) {
            overrideOverlapWarningSet(checked as boolean);
        }
    }

    function findOverlappingSchedules(schedule: Schedule, existingSchedules: Schedule[]): Schedule[] {
        const overlappingSchedules: Schedule[] = [];

        if (schedule?.startDate) {
            schedule.startDate.setHours(0, 0, 0, 0);
        }
        if (schedule?.endDate) {
            schedule.endDate.setHours(0, 0, 0, 0);
        }


        for (const existingSchedule of existingSchedules) {
            console.log("existingSchedule", existingSchedule);
            if (
                schedule.id !== existingSchedule.id && // Exclude the same schedule
                schedule.tmpTimeZone?.id == existingSchedule.tmpTimeZone?.id && // Check if they belong to the same timezone
                schedule.team?.id === existingSchedule.team?.id && // Check if they belong to the same team
                schedule.endDate && existingSchedule.startDate && schedule.startDate && existingSchedule.endDate && // check if all dates are present.
                ((new Date(schedule.startDate) >= new Date(existingSchedule.startDate) && new Date(schedule.endDate) <= new Date(existingSchedule.endDate)) || // Check if schedule is fully contained within existing schedule
                    (new Date(schedule.startDate) <= new Date(existingSchedule.startDate) && new Date(schedule.endDate) >= new Date(existingSchedule.endDate)) || // Check if existing schedule is fully contained within schedule
                    (new Date(schedule.startDate) <= new Date(existingSchedule.startDate) && new Date(schedule.endDate) >= new Date(existingSchedule.startDate)) || // Check if existing schedule starts within schedule
                    (new Date(schedule.startDate) <= new Date(existingSchedule.endDate) && new Date(schedule.endDate) >= new Date(existingSchedule.endDate))) // Check if existing schedule ends within schedule
                && (isOverlappingTimeSlots(schedule, existingSchedule))
            ) {
                overlappingSchedules.push(existingSchedule); // Add overlapping schedule to the array
            }
        }

        return overlappingSchedules;
    }

    function isOverlappingTimeSlots(scheduleA: Schedule, scheduleB: Schedule): boolean {

        if (!scheduleA.slotStart || !scheduleB.slotStart) {
            return false; // If meeting slot is not specified for either schedule, no overlap is possible
        }

        if (!scheduleA.slotStart.id || !scheduleB.slotStart.id) {
            return false; // If meeting slot is not specified for either schedule, no overlap is possible
        }

        const idA = parseInt(scheduleA.slotStart.id);
        const idB = parseInt(scheduleB.slotStart.id);

        const durationA = getMeetingSlotDuration(scheduleA);
        const durationB = getMeetingSlotDuration(scheduleB);

        const endA = idA + durationA;
        const endB = idB + durationB;

        return idA < endB && idB < endA; // Check if the time slots overlap
    }

    function getMeetingSlotDuration(schedule: Schedule): number {
        if (schedule.is1HourSlot) {
            return 2; // Assuming each hour slot corresponds to 2 sequential MeetingTime IDs
        } else if (schedule.is30MinSlot) {
            return 1; // Assuming each 30-minute slot corresponds to 1 MeetingTime ID
        } else {
            throw new Error("Meeting slot duration not specified.");
        }
    }
    function getCommaSeparatedScheduleNames(schedules: Schedule[]): string {
        const scheduleNames = schedules.map(schedule => schedule.name);
        return scheduleNames.join(", ");
    }
    function areWeekdaysActiveMatching(scheduleA: Schedule, scheduleB: Schedule): boolean {
        var isWeekDayOverlap = (
            (!scheduleA.isSundayActive || scheduleB.isSundayActive) &&
            (!scheduleA.isMondayActive || scheduleB.isMondayActive) &&
            (!scheduleA.isTuedayActive || scheduleB.isTuedayActive) &&
            (!scheduleA.isWeddayActive || scheduleB.isWeddayActive) &&
            (!scheduleA.isThudayActive || scheduleB.isThudayActive) &&
            (!scheduleA.isFridayActive || scheduleB.isFridayActive) &&
            (!scheduleA.isSaturdayActive || scheduleB.isSaturdayActive)
        );

        if (isWeekDayOverlap) {
            return true;
        }

        return false;
    }

    const [selectedDeleteScheduleId, selectedDeleteScheduleIdSet] = useState<string>();
    const [selectedDeleteScheduleTeamId, selectedDeleteScheduleTeamIdSet] = useState<string>();
    const [reviewsTobeCancelled, reviewsTobeCancelledSet] = useState<Review[]>([]);
    const [dataSelectedScheduleForDelete, dataSelectedScheduleForDeleteSet] = useState<Schedule>();
    useEffect(() => {
        if (dataSelectedScheduleForDelete) {
            selectedDeleteScheduleIdSet(dataSelectedScheduleForDelete.id);
            selectedDeleteScheduleTeamIdSet(dataSelectedScheduleForDelete.team?.id);
        }
    },[dataSelectedScheduleForDelete])
    const [enableDeleteSchedule, enableDeleteScheduleSet] = useState<boolean>(false);
    const [isSlotDeleteModalOpen, { setTrue: showSlotDeleteModal, setFalse: hideSlotDeleteModal }] = useBoolean(false);

    const [displayDeleteSchedulePanelMessages, displayDeleteSchedulePanelMessagesSet] = useMessages();

    useEffect(() => {
        if (isSlotDeleteModalOpen === false) {
            dataSelectedScheduleForDeleteSet(undefined);
        }
    }, [isSlotDeleteModalOpen])
    const onRenderDeleteScheduleFooterContent = () => (
        <Stack horizontal tokens={stackTokens}>
            {
                !enableDeleteSchedule ? <SpinnerPrimaryButton text="Validate Delete" executingText="Validating Schedule Information for delete" onclick={validateDeleteSchedule} />
                    : <SpinnerPrimaryButton text="Delete" executingText="Saving Schedule Information Edit" onclick={deleteSchedule} />
            }

            <DefaultButton aria-label="Cancel" onClick={() => { hideSlotDeleteModal(); dataSelectedScheduleForDeleteSet(undefined); enableDeleteScheduleSet(false); }}>Cancel</DefaultButton>
        </Stack>
    );
    const validateDeleteSchedule = async () => {
        displayDeleteSchedulePanelMessagesSet([]);
        reviewsTobeCancelledSet([]);

        if (dataSelectedScheduleForDelete === undefined || dataSelectedScheduleForDelete.id === undefined || dataSelectedScheduleForDelete.team?.id === undefined) {
            displayDeleteSchedulePanelMessagesSet([{message:"The schedule could not be determined", messageType: DisplayMessageType.Warning}]);
            return;
        }

        var response = await meetingService.validateDeleteScheduleToTeam(dataSelectedScheduleForDelete.id, dataSelectedScheduleForDelete?.team?.id);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            reviewsTobeCancelledSet(response.result);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            // Enable save.
            displayDeleteSchedulePanelMessagesSet([{ message: "The validation succeeded. You can delete schedule now.", messageType: DisplayMessageType.Success }]);
            enableDeleteScheduleSet(true);
        }
        else {
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayDeleteSchedulePanelMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayDeleteSchedulePanelMessagesSet([{ message: "The schedule delete could not be validated", messageType: DisplayMessageType.Error }]);
            }
        }
    }
    const deleteSchedule = async () => {
        displayDeleteSchedulePanelMessagesSet([]);

        if (dataSelectedScheduleForDelete === undefined || dataSelectedScheduleForDelete.id === undefined || dataSelectedScheduleForDelete.team?.id === undefined) {
            displayDeleteSchedulePanelMessagesSet([{message:"The schedule could not be determined", messageType: DisplayMessageType.Warning}]);
            return;
        }

        var response = await meetingService.deleteScheduleToTeam(dataSelectedScheduleForDelete.id, dataSelectedScheduleForDelete.team.id);
        if (response && !response.hasErrors && response.result) {

            dataTeamSchedulesSet(dataTeamSchedules.filter(schedule => schedule.id !== dataSelectedScheduleForDelete.id));
            hideSlotDeleteModal();
        }
        else {
            dataTeamSchedulesSet([]);
            if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
            }
            else {
                displayMessagesSet([{ message: "The schedule could not be saved", messageType: DisplayMessageType.Error }]);
            }
        }
    }

    return (<>
        <div className="container">
            <div className="row">
                <MessageComponent messages={displayMessages} />
            </div>

            <div className="row">
                <div className="d-flex">
                    <Stack horizontal tokens={stackTokens} className="me-auto" style={{width: 500}}>
                        <div className="row" style={{width: 500}}>
                            <UserReviewTeamsComponent
                                label="Select Review Team"
                                selectedTeamId={dataSelectedReviewTeam?.id}
                                placeholder="Select a Review team"
                                onTeamSelected={(selectedTeam?: Team) => {
                                        reset();
                                        dataTeamSchedulesSet([]);
                                        dataTeamTimeOffsSet([]);
                                        if (selectedTeam) {
                                            dataSelectedReviewTeamSet(selectedTeam);
                                        }
                                        else {
                                            dataSelectedReviewTeamSet(undefined);
                                            displayMessagesSet([{ message: "The team configuration could not be read.", messageType: DisplayMessageType.Error }]);
                                        }
                                    }
                                }
                            />
                        </div>
                    </Stack>
                    {
                        dataSelectedReviewTeam && dataSelectedReviewTeam.id &&
                        <Stack horizontal tokens={stackTokens} className="ms-auto" style={{ marginTop: 20, marginRight: 20 }}>
                            <Link to="#" onClick={() => { reset(); reviewsTobeRescheduledSet([]); showModal(); }} ><FontIcon iconName="Add" /> Add Slot</Link>
                            <Link to="#" onClick={() => { reset(); reviewsTobeRescheduledSet([]); enableAddTimeOffSet(false); applyTimeOffToAllSlotsSet(true); showTimeOffModal(); }} ><FontIcon iconName="Snooze" /> Add Time off</Link>
                        </Stack>
                    }
                </div>
            </div>
            {dataSelectedReviewTeam && dataSelectedReviewTeam.id &&
                <Pivot aria-label="Scheduling for the reviewers" >
                    <PivotItem headerText="Schedules" itemIcon="AccountManagement" >
                        <div className="row">
                            {dataTeamSchedules && dataTeamSchedules.length > 0 &&
                                <DetailsList
                                    items={dataTeamSchedules}
                                    columns={teamScheduleColumns}
                                    onRenderItemColumn={_renderItemColumn}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    constrainMode={ConstrainMode.horizontalConstrained}
                                />
                            }
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Time Offs" itemIcon="Snooze">
                        <div className="row">
                            {dataTeamTimeOffs && dataTeamTimeOffs.length > 0 &&
                                <DetailsList
                                    items={dataTeamTimeOffs}
                                    columns={teamTimeOffColumns}
                                    onRenderItemColumn={_renderTimeOffColumn}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    constrainMode={ConstrainMode.horizontalConstrained}
                                />
                            }
                        </div>
                    </PivotItem>
                </Pivot>
            }
        </div>

        {/* Add schedule panel */}
        <Panel
            type={PanelType.medium}
            isOpen={isModalOpen}
            onDismiss={hideModal}
            closeButtonAriaLabel="Close"
            headerText={"Create a slot for " + dataSelectedReviewTeam?.name}
            onRenderFooterContent={onRenderScheduleFooterContent}
        >

            <div className="row">
                <p>Each slot corresponds to one Calendar invite / team's meeting, between start to end time time per day.</p>
            </div>

            <div className="row">
                <MessageComponent messages={displayAddSchedulePanelMessages} />
            </div>

            <div className="row">
                <TextField label="Slot Name" placeholder="Enter a friendly name for the slot" value={slotName} onChange={onSlotNameChange} />
            </div>

            <div className="row">
                <DatePicker
                    label="Start Date"
                    placeholder="Select a start date for the series"
                    ariaLabel="Select a start date"
                    onSelectDate={startDateSet as (date?: Date | undefined | null) => void}
                    minDate={minDate}
                    maxDate={maxDate}
                    allowTextInput
                />
            </div>
            <div className="row">
                <DatePicker
                    label="End Date"
                    placeholder="Select a end date for the series"
                    ariaLabel="Select a end date"
                    onSelectDate={endDateSet as (date?: Date | undefined | null) => void}
                    minDate={startDate}
                    maxDate={maxDate}
                    allowTextInput
                />
            </div>
            <div className="row">
                <div className="col">
                    <Label>Active Days</Label>
                    <Stack tokens={stackTokens} horizontal style={{ marginTop: 0 }} >
                        <Checkbox label="Sun" checked={isSunChecked} onChange={onSunChange} />
                        <Checkbox label="Mon" checked={isMonChecked} onChange={onMonChange} />
                        <Checkbox label="Tue" checked={isTueChecked} onChange={onTueChange} />
                        <Checkbox label="Wed" checked={isWedChecked} onChange={onWedChange} />
                        <Checkbox label="Thu" checked={isThuChecked} onChange={onThuChange} />
                        <Checkbox label="Fri" checked={isFriChecked} onChange={onFriChange} />
                        <Checkbox label="Sat" checked={isSatChecked} onChange={onSatChange} />
                    </Stack>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Label>Time Zone</Label>
                    <TimeZoneDropdownComponent
                        placeholder="Select timezone for the slot."
                        onTimeZoneSelected={(selectedTimeZone?: TMPTimeZone) => {
                            if (selectedTimeZone) {
                                dataSelectedTimeZoneSet(selectedTimeZone);
                            }
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Label>Start Time</Label>
                    <TimeDropDownComponent
                        placeholder="Select start time for the slot."
                        onTimeSelected={(selectedTime: MeetingTime) => {
                            dataSelectedTimeSet(selectedTime);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="row">
                    <Label> Slot Span </Label>
                </div>
                <div className="row">
                    <Stack tokens={stackTokens} horizontal style={{ marginTop: 0 }} >
                        <Checkbox label="1 hour" checked={is1hrMinSlot} onChange={on1hrSlotCheck} />
                        <Checkbox label="30 min" checked={is30MinSlot} onChange={on30MinSlotCheck} />
                    </Stack>
                </div>
            </div>

            <div className="row">
                <div className="row">
                    <Label> Free Slot visibility </Label>
                </div>
                <div className="row">
                    <ComboBox
                        selectedKey={selectedSlotVisibility}
                        onChange={onVisibilityChanged}
                        placeholder={"Number of days in schedule service teams can see slots as Available from today."}
                        options={visibilityOptions}
                        useComboBoxAsMenuWidth={true} />
                </div>
            </div>

            {
                showOverlapWaning &&
                <div className="row">
                    <MessageBar
                        messageBarType={MessageBarType.warning}
                        isMultiline={true}
                    >
                        {overlapWaningMessage}
                    </MessageBar>

                    <Checkbox label="Allow Overlap" checked={overrideOverlapWarning} onChange={onOverlapOverrideChange} />
                </div>


            }
        </Panel>

        {/* Add time off panel */}
        <Panel
            type={PanelType.medium}
            isOpen={isTimeOffModalOpen}
            onDismiss={hideTimeOffModal}
            closeButtonAriaLabel="Close"
            headerText={"Create Time Off for " + dataSelectedReviewTeam?.name}
            onRenderFooterContent={onRenderTimeOffFooterContent}
        >

            <div className="row">
                <p>Each slot corresponds to one Calendar invite / team's meeting, between start to end time time per day.</p>
            </div>

            <div className="row">
                <MessageComponent messages={displayAddTimeOffPanelMessages} />
            </div>

            <div className="row">
                <TimeZoneDropdownComponent
                    label="Select Timezone for the time off."
                    placeholder="Select Timezone for the time off."
                    onTimeZoneSelected={(selectedTimeZone?: TMPTimeZone) => {
                        if (selectedTimeZone) {
                            dataTeamSelectedTimeOffTzsSet(selectedTimeZone);
                        }
                    }}
                />
            </div>
            <div className="row">
                <DatePicker
                    label="Start Date"
                    placeholder="Select a start date for the time off"
                    ariaLabel="Select a start date"
                    onSelectDate={timeOffStartDateSet as (date?: Date | undefined | null) => void}
                    minDate={minDate}
                    maxDate={maxDate}
                    allowTextInput
                />
            </div>
            <div className="row">
                <DatePicker
                    label="End Date"
                    placeholder="Select a end date for the series"
                    ariaLabel="Select a end date"
                    onSelectDate={timeOffEndDateSet as (date?: Date | undefined | null) => void}
                    minDate={timeOffStartDate}
                    maxDate={maxDate}
                    allowTextInput
                />
            </div>

            <div className="row">
                <TextField value={timeOffReason} onChange={onTimeOffReasonChange} label="Time off reason" placeholder="Enter a reason for setting time off." />
            </div>
            {
                timeOffSlots && timeOffSlots.length > 0 &&
                <div className="row">

                    <Label>Select the schedule(s) to apply the Time Off</Label>
                    <Stack tokens={stackTokens}>
                        <Stack.Item>
                            <Checkbox label={"Apply to all slots (Active and Created in future)"} checked={applyTimeOffToAllSlots} onChange={OnSelectAllSlots} />
                        </Stack.Item>
                        <Stack.Item>
                            <Text>or choose specific Slots</Text>
                        </Stack.Item>
                        {
                            timeOffSlots.map(x => {
                                return (<>
                                    <Stack.Item>
                                        <Checkbox label={x.name} onChange={() => { OnSlotSelectedForTimeOff(x) }} />
                                    </Stack.Item>
                                </>)
                            })
                        }
                    </Stack>
                </div>
            }

            {
                reviewsTobeRescheduled && reviewsTobeRescheduled.length > 0 &&
                <div className="row">
                    <div className="col">
                        <Label>These reviews have to be rescheduled or cancelled before proceeding with Time Off as these are booked slots in the time zone.</Label>
                        {
                            reviewsTobeRescheduled.map(r => {
                                return <div className="row">
                                    <div className="col">
                                        <Link to={"/reviewdetail/" + r.id + "/" + r.service?.serviceId} target="_blank">{r.easyReviewId}</Link>
                                    </div>
                                    <div className="col">
                                        {getDateFormat(r.meetingsData?.dateOfReview)} {getTimeFormat(r.meetingsData?.dateOfReview)}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            }

        </Panel>

        {/* Edit schedule panel */}
        <Panel
            type={PanelType.medium}
            isOpen={isSlotEditModalOpen}
            onDismiss={hideSlotEditModal}
            closeButtonAriaLabel="Close"
            headerText={"Edit slot for " + dataSelectedReviewTeam?.name}
            onRenderFooterContent={onRenderEditScheduleFooterContent}
        >

            <div className="row">
                <p>Each slot corresponds to one Calendar invite / team's meeting, between start to end time time per day.</p>
            </div>

            <div className="row">
                <MessageComponent messages={displayEditSchedulePanelMessages} />
            </div>

            <div className="row">
                <TextField label="Slot Name" placeholder="Enter a friendly name for the slot" value={slotName} onChange={onSlotNameChange} />
            </div>

            <div className="row">

                <DatePicker
                    label="Start Date"
                    placeholder="Select a start date for the series"
                    ariaLabel="Select a start date"
                    onSelectDate={startDateSet as (date?: Date | undefined | null) => void}
                    //minDate={minDate}
                    maxDate={maxDate}
                    value={startDate}
                    disabled={startDateEditDisabled}
                    allowTextInput
                />

            </div>
            <div className="row">
                <DatePicker
                    label="End Date"
                    placeholder="Select a end date for the series"
                    ariaLabel="Select a end date"
                    onSelectDate={endDateSet as (date?: Date | undefined | null) => void}
                    minDate={minDate}
                    maxDate={maxDate}
                    value={endDate}
                    allowTextInput
                />
            </div>
            <div className="row">
                <div className="col">
                    <Label>Active Days</Label>
                    <Stack tokens={stackTokens} horizontal style={{ marginTop: 0 }} >
                        <Checkbox label="Sun" checked={isSunChecked} onChange={onSunChange} />
                        <Checkbox label="Mon" checked={isMonChecked} onChange={onMonChange} />
                        <Checkbox label="Tue" checked={isTueChecked} onChange={onTueChange} />
                        <Checkbox label="Wed" checked={isWedChecked} onChange={onWedChange} />
                        <Checkbox label="Thu" checked={isThuChecked} onChange={onThuChange} />
                        <Checkbox label="Fri" checked={isFriChecked} onChange={onFriChange} />
                        <Checkbox label="Sat" checked={isSatChecked} onChange={onSatChange} />
                    </Stack>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Label>Time Zone</Label>
                    <TimeZoneDropdownComponent
                        selectedTimeZoneId={selectedTimeZoneId}
                        onTimeZoneSelected={(selectedTimeZone?: TMPTimeZone) => {
                            if (selectedTimeZone) {
                                dataSelectedTimeZoneSet(selectedTimeZone);
                            }
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Label>Start Time</Label>
                    <TimeDropDownComponent
                        selectedTimeId={selectedTimeId}
                        onTimeSelected={(selectedTime: MeetingTime) => {
                            dataSelectedTimeSet(selectedTime);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="row">
                    <Label> Slot Span </Label>
                </div>
                <div className="row">
                    <Stack tokens={stackTokens} horizontal style={{ marginTop: 0 }} >
                        <Checkbox label="1 hour" checked={is1hrMinSlot} onChange={on1hrSlotCheck} />
                        <Checkbox label="30 min" checked={is30MinSlot} onChange={on30MinSlotCheck} />
                    </Stack>
                </div>
            </div>

            <div className="row">
                <div className="row">
                    <Label> Free Slot visibility </Label>
                </div>
                <div className="row">
                    <ComboBox
                        selectedKey={selectedSlotVisibility}
                        onChange={onVisibilityChanged}
                        placeholder={"Number of days in schedule service teams can see slots as Available from today."}
                        options={visibilityOptions}
                        useComboBoxAsMenuWidth={true} />
                </div>
            </div>

            {
                reviewsTobeRescheduled && reviewsTobeRescheduled.length > 0 &&
                <div className="row">
                    <div className="col">
                        <Label>These reviews have to be rescheduled or cancelled before proceeding with edit as these are booked slots as per old schedule and fall outside the new edit.</Label>
                        {
                            reviewsTobeRescheduled.map(r => {
                                return <div className="row">
                                    <div className="col">
                                        <Link to={"/reviewdetail/" + r.id + "/" + r.service?.serviceId} target="_blank">{r.easyReviewId}</Link>
                                    </div>
                                    <div className="col">
                                        {getDateFormat(r.meetingsData?.dateOfReview)} {getTimeFormat(r.meetingsData?.dateOfReview)}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            }

        </Panel>

        {/* Delete schedule panel */}
        <Panel
            type={PanelType.medium}
            isOpen={isSlotDeleteModalOpen}
            onDismiss={hideSlotDeleteModal}
            closeButtonAriaLabel="Close"
            headerText={"Delete Schedule - " + dataSelectedScheduleForDelete?.name}
            onRenderFooterContent={onRenderDeleteScheduleFooterContent}
        >

            <div className="row">
                <MessageComponent messages={displayDeleteSchedulePanelMessages} />
            </div>

            <div className="row">
                <Text>To delete a review, you can also set the end date to current date and save the review.</Text>

            </div>

            {
                reviewsTobeCancelled && reviewsTobeCancelled.length > 0 &&
                <div className="row">
                    <div className="col">
                        <Label>These reviews have to be cancelled before proceeding with delete as these are booked slots as per published schedule.</Label>
                        {
                            reviewsTobeCancelled.map(r => {
                                return <div className="row">
                                    <div className="col">
                                        <Link to={"/reviewdetail/" + r.id + "/" + r.service?.serviceId} target="_blank">{r.easyReviewId}</Link>
                                    </div>
                                    <div className="col">
                                        {getDateFormat(r.meetingsData?.dateOfReview)} {getTimeFormat(r.meetingsData?.dateOfReview)}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            }
        </Panel>

    </>);
}


import { IBasePickerSuggestionsProps, IInputProps, ITag, TagPicker, ValidationState, Text, IPickerItemProps, Spinner, SpinnerSize, ISuggestionItemProps, Stack, Persona, PersonaSize } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { ExistingReviewIds } from "../../Models/Reviews/ExistingReviewIds";
import UserProfile from "../../Models/UserProfile";
import MeetingsService from "../../Services/MeetingsService";
import UserService from "../../Services/UserService";

interface IReviewEasyIdAutoFill {
    id?: string
    serviceId: string,
    securityTeamId: string
    onEasyIdSelected: (selectedId: ExistingReviewIds) => void,
}
export const ReviewEasyIdAutoFill: FC<IReviewEasyIdAutoFill> = (props: IReviewEasyIdAutoFill): JSX.Element => {
    let reviewService = new MeetingsService();
    let userService = new UserService();

    const stackTokens = { childrenGap: 10, };

    const [isComponentLoadError, isComponentLoadErrorSet] = useState<boolean>(false);
    const [easyReviewIds, easyReviewIdsSet] = useState<ExistingReviewIds[]>([]);
    useEffect(() => {
        let userAliases: string[] = [];

        easyReviewIds.forEach((dataReview) => {
            var requestorAlias = dataReview?.requestor?.userPrincipalName.split('@')[0];
            if (requestorAlias && requestorAlias.length > 0) {
                userAliases.push(requestorAlias);
            }

            dataReview?.otherTeamMembers?.forEach(member => {
                var memberAlias = member.userPrincipalName.split('@')[0];
                userAliases.push(memberAlias);
            });
        });

        if (userAliases && userAliases.length > 0) {
            loadUserProfiles(userAliases);
        }

    }, [easyReviewIds]);
    const [selectedEasyIds, selectedEasyIdsSet] = useState<ITag[] | undefined>([]);
    const [isLoadingReviews, isLoadingReviewsSet] = useState<boolean>(false);
    const [userProfiles, userProfilesSet] = useState<UserProfile[]>([]);
    useEffect(() => {
        isLoadingReviewsSet(false);
     }, [userProfiles]);

    const loadReviewIds = async (serviceId: string, securityTeamId: string) => {
        isLoadingReviewsSet(true);
        var response = await reviewService.getExistingReviewIds(serviceId, securityTeamId);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            easyReviewIdsSet(response.result);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
            easyReviewIdsSet([]);
        }
        else {
            isComponentLoadErrorSet(true);
        }

        
    }
    const loadUserProfiles = async (userAliases: string[]) => {
        var userProfiles = await userService.getUserProfiles(userAliases);
        userProfilesSet(userProfiles);
    }

    useEffect(() => {
        isComponentLoadErrorSet(false);
        if (props.serviceId && props.serviceId.length > 0 && props.securityTeamId && props.securityTeamId.length > 0 && easyReviewIds.length == 0) {
            loadReviewIds(props.serviceId, props.securityTeamId);
        }
    }, [props])
    useEffect(() => {
        if (selectedEasyIds && selectedEasyIds.length > 0) {
            var dataSelectedEasyId = easyReviewIds.filter(x => x.reviewId == selectedEasyIds[0].key);
            if (dataSelectedEasyId && dataSelectedEasyId.length > 0 && [0]) {
                props.onEasyIdSelected(dataSelectedEasyId[0]);
            }
        }
    }, [selectedEasyIds])
    const onResolveEasyIdSuggestions = async (
        filter: string,
        selectedItems: ITag[] | undefined
    ): Promise<ITag[]> => {

        if (filter) {
            var filteredTechTags = easyReviewIds.filter(x => x.easyReviewId?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
            if (filteredTechTags && filteredTechTags.length > 0) {
                var tTags: ITag[] = [];
                filteredTechTags.forEach(tag => {
                    if (tag.reviewId && tag.easyReviewId) {
                        tTags.push({
                            key: tag.reviewId,
                            name: tag.easyReviewId + " - " + tag.dateOfReview
                        });
                    }
                })

                console.log(tTags);
                return tTags;
            }
        }
        return [];
    };
    const getEasyIdTagTextFromItem = (item: ITag) => item.name;
    const pickerTechnologySuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Review Ids',
        noResultsFoundText: 'No easy id found!',
    };
    const easyIdInputProps: IInputProps = {
        placeholder: 'Enter reviewid  example: bookasa1234.',
        id:props.id
    };
    const onEasyIdTagChange = (items?: ITag[] | undefined) => {
        selectedEasyIdsSet(items);
        console.log(items);
    };
    function validateEasyIdTag(input: string): ValidationState {
        if (input.length > 0) {
            return ValidationState.valid;
        } else if (input.length > 15) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    }

    if (isComponentLoadError) {
        return <Text>Could not load the Existing review(s). </Text>
    }

    const onRenderSuggesstionItem = (props: ITag, itemProps: ISuggestionItemProps<ITag>): JSX.Element => {
        console.log(props);

        var dataItem = easyReviewIds.filter(x => x.reviewId === props.key);

        var requestor = userProfiles.filter(x => x.userPrincipalName === dataItem[0].requestor?.userPrincipalName);
        var others = userProfiles.filter(x => dataItem[0].otherTeamMembers?.some(y => y.userPrincipalName === x.userPrincipalName))
        return <Stack horizontal tokens={stackTokens}>
                <Text>{dataItem[0].easyReviewId}</Text>
                <Text>{dataItem[0].dateOfReview}</Text>
                <Stack horizontal>
                    {
                        [...requestor, ...others].map(x => {
                            return <Persona
                                style={{ marginTop: 20, marginBottom: 10 }}
                                {...x}
                                key={"ppp_" + x.id}
                                title={x.displayName}
                                hidePersonaDetails={true}
                                size={PersonaSize.size32}
                                imageUrl = {x.photo}
                            />
                        })
                    }
                </Stack>
            </Stack>
    }

    if (isLoadingReviews) {
        return <Spinner size={SpinnerSize.medium} title="loading reviews" />
    }

    return (<TagPicker
        itemLimit={1}
        aria-label="ReviewId picker"
        onResolveSuggestions={onResolveEasyIdSuggestions}
        getTextFromItem={getEasyIdTagTextFromItem}
        pickerSuggestionsProps={pickerTechnologySuggestionsProps}
        inputProps={easyIdInputProps}
        onChange={onEasyIdTagChange}
        onValidateInput={validateEasyIdTag}
        onRenderSuggestionsItem={onRenderSuggesstionItem}
    />)
}
import { FC, useEffect, useState } from "react";
import { ServiceTreeService } from "../../Models/ServiceTreeService";
import STServices from "../../Services/STServices";
import { IBasePickerSuggestionsProps, ITag, TagPicker } from "@fluentui/react";
import { ServiceTreeHierarchyStrings } from "../../Models/Constants/ServiceTreeHierarchy";

interface IServiceTreeHierarchyPickerComponent {
    id?: string,
    itemLimit: number,
    placeholder?: string,
    serviceTreeHierarchyType?: string,
    onServiceHierarchyObjectSelected: (selectedServices: ServiceTreeService[]) => void,
    selectedServiceTreeHierarchyObject?: ServiceTreeService[],
}

export const ServiceTreeHierarchyPickerComponent: FC<IServiceTreeHierarchyPickerComponent> = (props: IServiceTreeHierarchyPickerComponent): JSX.Element => {
    let stService = new STServices();

    const [dataSuggestedServiceTreeObjects, dataSuggestedServiceTreeObjectsSet] = useState<ServiceTreeService[]>([]);
    const [dataSelectedServiceTreeObject, dataSelectedServiceTreeObjectSet] = useState<ServiceTreeService[]>([]);
    const [selectedServiceTreeObject, selectedServiceTreeObjectSet] = useState<ITag[]>([]);

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested Services',
        noResultsFoundText: 'No matching ServiceTree Service found',
    };

    const serviceChangeSuggestedTags = async (filter: string, selectedItems?: ITag[] | undefined): Promise<ITag[]> => {
        var serviceTreeSuggestions: ITag[] = [];
        dataSuggestedServiceTreeObjectsSet([]);
        //api call based on props.type

        if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Division)
        {
            var stServices = await stService.getServicesByDivisionNameOrId(filter);
            if (stServices && !stServices.hasErrors && stServices.result) 
            {
                stServices.result.forEach(service => {
                    if (service.divisionId && service.divisionName) {
                        if (!serviceTreeSuggestions.some(x => x.key == service.divisionId))
                        {
                            serviceTreeSuggestions.push({
                                key: service.divisionId,
                                name: service.divisionName
                            });
                        }
                    }
                });
                dataSuggestedServiceTreeObjectsSet(stServices.result);
            }
        }
        else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Organization)
        {
            var stServices = await stService.getServicesByOrgNameOrId(filter);
            if (stServices && !stServices.hasErrors && stServices.result) 
            {
                stServices.result.forEach(service => {
                    if (service.organizationId && service.organizationName) {
                        if (!serviceTreeSuggestions.some(x => x.key == service.organizationId))
                        {
                            serviceTreeSuggestions.push({
                                key: service.organizationId,
                                name: service.organizationName
                            });
                        }
                    }
                });
                dataSuggestedServiceTreeObjectsSet(stServices.result);
            }
        }
        else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.ServiceGroup)
        {
            var stServices = await stService.getServicesByServiceGroupNameOrId(filter);
            if (stServices && !stServices.hasErrors && stServices.result) 
            {
                stServices.result.forEach(service => {
                    if (service.serviceGroupId && service.serviceGroupName) {
                        if (!serviceTreeSuggestions.some(x => x.key == service.serviceGroupId))
                        {
                            serviceTreeSuggestions.push({
                                key: service.serviceGroupId,
                                name: service.serviceGroupName
                            });
                        }
                    }
                });
                dataSuggestedServiceTreeObjectsSet(stServices.result);
            }
        }
        else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.TeamGroup)
        {
            var stServices = await stService.getServicesByTeamGroupNameOrId(filter);
            debugger;
            if (stServices && !stServices.hasErrors && stServices.result) 
            {
                stServices.result.forEach(service => {
                    if (service.teamGroupId && service.teamGroupName) {
                        if (!serviceTreeSuggestions.some(x => x.key == service.teamGroupId))
                        {
                            serviceTreeSuggestions.push({
                                key: service.teamGroupId,
                                name: service.teamGroupName
                            });
                        }
                    }
                });
                dataSuggestedServiceTreeObjectsSet(stServices.result);
            }
        }
        else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Service)
        {
            var stServices = await stService.getServicesByNameOrId(filter);
            if (stServices && !stServices.hasErrors && stServices.result) 
            {
                stServices.result.forEach(service => {
                    if (service.serviceId && service.serviceName) {
                        if (!serviceTreeSuggestions.some(x => x.key == service.serviceId))
                        {
                            serviceTreeSuggestions.push({
                                key: service.serviceId,
                                name: service.serviceName
                            });
                    }
                    }
                });
                dataSuggestedServiceTreeObjectsSet(stServices.result);
            }
        }
        return serviceTreeSuggestions;
    };

    const onServiceHierarchyObjectChanged = (items?: ITag[] | undefined) => {
        console.log('changed');
        var serviceTreeItemAdded: ServiceTreeService[] = [];
        if (items && items.length > 0) {
            if (items.length > dataSelectedServiceTreeObject.length) {
                if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Division)
                {
                    let newItems = items.filter(x => !dataSelectedServiceTreeObject.some(y => y.divisionId === x.key));
                    if (newItems && newItems.length > 0) {
                        serviceTreeItemAdded = dataSuggestedServiceTreeObjects.filter(x => x.divisionId == newItems[0].key);
                    }
                }
                else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Organization)
                {
                    let newItems = items.filter(x => !dataSelectedServiceTreeObject.some(y => y.organizationId === x.key));
                    if (newItems && newItems.length > 0) {
                        serviceTreeItemAdded = dataSuggestedServiceTreeObjects.filter(x => x.organizationId == newItems[0].key);
                    }
                }
                else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.TeamGroup)
                {
                    let newItems = items.filter(x => !dataSelectedServiceTreeObject.some(y => y.teamGroupId === x.key));
                    if (newItems && newItems.length > 0) {
                        serviceTreeItemAdded = dataSuggestedServiceTreeObjects.filter(x => x.teamGroupId == newItems[0].key);
                    }
                }
                else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.ServiceGroup)
                {
                    let newItems = items.filter(x => !dataSelectedServiceTreeObject.some(y => y.serviceGroupId === x.key));
                    if (newItems && newItems.length > 0) {
                        serviceTreeItemAdded = dataSuggestedServiceTreeObjects.filter(x => x.serviceGroupId == newItems[0].key);
                    }
                }
                else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Service)
                {
                    let newItems = items.filter(x => !dataSelectedServiceTreeObject.some(y => y.serviceId === x.key));
                    if (newItems && newItems.length > 0) {
                        serviceTreeItemAdded = dataSuggestedServiceTreeObjects.filter(x => x.serviceId == newItems[0].key);
                    }
                }
                props.onServiceHierarchyObjectSelected([...dataSelectedServiceTreeObject, serviceTreeItemAdded[0]]);
            }
            else {
                console.log("item removed");
                if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Division)
                {
                    var newSelection = dataSelectedServiceTreeObject.filter(x => items.find(y => y.key == x.divisionId));
                    props.onServiceHierarchyObjectSelected([...newSelection]);
                }
                else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Organization)
                {
                    var newSelection = dataSelectedServiceTreeObject.filter(x => items.find(y => y.key == x.organizationId));
                    props.onServiceHierarchyObjectSelected([...newSelection]);
                }
                if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.TeamGroup)
                {
                    var newSelection = dataSelectedServiceTreeObject.filter(x => items.find(y => y.key == x.teamGroupId));
                    props.onServiceHierarchyObjectSelected([...newSelection]);
                }
                else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.ServiceGroup)
                {
                    var newSelection = dataSelectedServiceTreeObject.filter(x => items.find(y => y.key == x.serviceGroupId));
                    props.onServiceHierarchyObjectSelected([...newSelection]);
                }
                else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Service)
                {
                    var newSelection = dataSelectedServiceTreeObject.filter(x => items.find(y => y.key == x.serviceId));
                    props.onServiceHierarchyObjectSelected([...newSelection]);
                }
            }


        }
        else {
            dataSelectedServiceTreeObjectSet([]);
            props.onServiceHierarchyObjectSelected([]);

        }
    }

    const setSelectedServiceTreeObject = () => {
        let ttags: ITag[] = [];
        if (dataSelectedServiceTreeObject && dataSelectedServiceTreeObject.length > 0) {
            if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Division)
            {
                    dataSelectedServiceTreeObject?.forEach(x => {
                    if (x && x.divisionId && x.divisionName) {
                        ttags.push(
                            {
                                key: x.divisionId,
                                name: x.divisionName
                            });
                    }
                });
            }
            else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Organization)
            {
                    dataSelectedServiceTreeObject?.forEach(x => {
                    if (x && x.organizationId && x.organizationName) {
                        ttags.push(
                            {
                                key: x.organizationId,
                                name: x.organizationName
                            });
                    }
                });
            }
            else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.ServiceGroup)
            {
                    dataSelectedServiceTreeObject?.forEach(x => {
                    if (x && x.serviceGroupId && x.serviceGroupName) {
                        ttags.push(
                            {
                                key: x.serviceGroupId,
                                name: x.serviceGroupName
                            });
                    }
                });
            }
            else  if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.TeamGroup)
            {
                    dataSelectedServiceTreeObject?.forEach(x => {
                    if (x && x.teamGroupId && x.teamGroupName) {
                        ttags.push(
                            {
                                key: x.teamGroupId,
                                name: x.teamGroupName
                            });
                    }
                });
            }
            else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Service)
            {
                dataSelectedServiceTreeObject?.forEach(x => {
                    if (x && x.serviceId && x.serviceName) {
                        ttags.push(
                            {
                                key: x.serviceId,
                                name: x.serviceName
                            });
                    }
                });
            }

            selectedServiceTreeObjectSet(ttags);
        }
        else {
            selectedServiceTreeObjectSet([]);
        }
    }

    const onServiceHierarchyObjectSelected = (selectedItem?: ITag | undefined): ITag => {
        console.log('selected');
        return selectedItem!;
    }

    useEffect(() => {
        setSelectedServiceTreeObject();
    }, [dataSelectedServiceTreeObject]);

    useEffect(() => {
        var newServices: ServiceTreeService[] = [];
        if (props.selectedServiceTreeHierarchyObject && props.selectedServiceTreeHierarchyObject.length > 0) {
            if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Division)
            {
                newServices = props.selectedServiceTreeHierarchyObject.filter(x => !dataSelectedServiceTreeObject.some(y => y.divisionId === x.divisionId))
            }
            else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Organization)
            {
                newServices = props.selectedServiceTreeHierarchyObject.filter(x => !dataSelectedServiceTreeObject.some(y => y.organizationId === x.organizationId))
            }
            else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.ServiceGroup)
            {
                newServices = props.selectedServiceTreeHierarchyObject.filter(x => !dataSelectedServiceTreeObject.some(y => y.serviceGroupId === x.serviceGroupId))
            }
            else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.TeamGroup)
            {
                newServices = props.selectedServiceTreeHierarchyObject.filter(x => !dataSelectedServiceTreeObject.some(y => y.teamGroupId === x.teamGroupId))
            }
            else if (props.serviceTreeHierarchyType == ServiceTreeHierarchyStrings.Service)
            {
                newServices = props.selectedServiceTreeHierarchyObject.filter(x => !dataSelectedServiceTreeObject.some(y => y.serviceId === x.serviceId))
            }
            
            if (newServices && newServices.length > 0) {
                dataSelectedServiceTreeObjectSet([...dataSelectedServiceTreeObject, ...newServices]);
            }
            else {
                dataSelectedServiceTreeObjectSet([...props.selectedServiceTreeHierarchyObject]);
            }

        }
        else {
            dataSelectedServiceTreeObjectSet([]);
        }
    }, [props]);

    return (
        <TagPicker
            aria-label="Service hierarchy picker"
            removeButtonAriaLabel="Remove"
            selectionAriaLabel="Selected servicetree object"
            resolveDelay={400}
            onResolveSuggestions={serviceChangeSuggestedTags}
            selectedItems={selectedServiceTreeObject}
            onChange={onServiceHierarchyObjectChanged}
            onItemSelected={onServiceHierarchyObjectSelected}
            itemLimit={props.itemLimit}
            pickerSuggestionsProps={pickerSuggestionsProps}
            inputProps={{
                id: props.id,
                placeholder: props.placeholder
            }}
        />
    );
}
import { ComboBox, IComboBox, IComboBoxOption, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { MeetingTime } from "../../Models/TimeZones/MeetingTime";
import MeetingsService from "../../Services/MeetingsService";

interface ITimeDropDownComponent{
    id?: string,
    label?: string,
    placeholder?: string,
    selectedTimeId?: string,
    onTimeSelected: (selectedServcices: MeetingTime) => void,
}
export const TimeDropDownComponent : FC<ITimeDropDownComponent> = (props: ITimeDropDownComponent): JSX.Element =>{
    let reviewService = new MeetingsService();
    const [isComponentLoadError, isComponentLoadErrorSet] = useState<boolean>(false);
    const [dataTimes, dataTimesSet] = useState<MeetingTime[]>([]);
    const [comboBoxTimeOptions, comboBoxTimeOptionsSet] = useState<IComboBoxOption[]>([]);
    const [comboBoxSelectedTimeOption, comboBoxSelectedTimeOptionSet] = useState<string>();

    const loadTimeDropwdown = async () => {
        var tmpTime = await reviewService.getMeetingTimes();
        if (tmpTime && !tmpTime.hasErrors && tmpTime.result && tmpTime.result?.length > 0) {
            dataTimesSet(tmpTime.result);
        }
        else{
            isComponentLoadErrorSet(true);
        }
    };

    const onTimeChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            comboBoxSelectedTimeOptionSet(option.key as string);
            var selectedTimeZone = dataTimes.filter(x => x.id == option.key);
            if (selectedTimeZone && selectedTimeZone.length > 0 && selectedTimeZone[0])
            {
                props.onTimeSelected(selectedTimeZone[0]);
            }
        }
    };
    useEffect(()=>{
        if (dataTimes && dataTimes.length > 0)
        {
            var tOptions: IComboBoxOption[] = [];
            dataTimes.forEach(t => {
                if (t.id && t.timeAMPM)
                {
                    tOptions.push({
                        key: t.id,
                        text: t.timeAMPM
                    })
                }
            });

            comboBoxTimeOptionsSet(tOptions);
            
            if (props.selectedTimeId)
            {
                comboBoxSelectedTimeOptionSet(props.selectedTimeId as string);
                var selectedTimeZone = dataTimes.filter(x => x.id == props.selectedTimeId);
                if (selectedTimeZone && selectedTimeZone.length > 0 && selectedTimeZone[0])
                {
                    props.onTimeSelected(selectedTimeZone[0]);
                }
            }
        } 
    },[dataTimes])

    useEffect(()=>{
        isComponentLoadErrorSet(false);
        loadTimeDropwdown();
    }, []);
    
    if (isComponentLoadError)
    {
        return <Text>Could not load the Time value(s) to select</Text>
    }

    return (<ComboBox
        id={props.id}
        label={props.label}
        selectedKey= {comboBoxSelectedTimeOption}
        placeholder={props.placeholder}
        useComboBoxAsMenuWidth={true}
        options={comboBoxTimeOptions}
        onChange={onTimeChange} />
    );
}
import { FC, useContext, useEffect, useState } from "react";
import { DisplayMessageType, MessageComponent, useMessages } from "../Common/MessageComponent";
import { LoadingIndicator } from "../Common/LoadingIndicatorComponent";
import HeadingComponent from "../Common/HeadingComponent";
import { ComboBox, CommandBarButton, ConstrainMode, DatePicker, DefaultButton, DetailsList, DetailsListLayoutMode, FontIcon, IColumn, IComboBox, IComboBoxOption, IDatePickerStyles, IPersonaProps, Label, Panel, PanelType, Persona, PersonaSize, SelectionMode, Stack, StackItem, Text, addDays, defaultDatePickerStrings } from "@fluentui/react";
import { MsPeoplePickerComponent } from "../Common/MsPeoplePickerComponent";
import UserProfile from "../../Models/UserProfile";
import ServiceTreeService from "../../Models/ServiceTreeService";
import { ServiceTreeHierarchyPickerComponent } from "../Common/ServiceTreeHierarchyPickerComponent";
import { ServiceTreeHierarchyStrings } from "../../Models/Constants/ServiceTreeHierarchy";
import { useBoolean } from "@fluentui/react-hooks";
import { SpinnerPrimaryButton } from "../Common/SpinnerPrimaryButton";
import { ServiceReader } from "../../Models/ServiceReader";
import { store } from "../Common/GlobalStateProvider";
import AdminServices from "../../Services/AdminServices";
import { getDateFormat, getTimeFormat } from "../../Utilities/dateutilities";

interface IPortalAccessComponentProps {
    id?: string;
    title: string
}

export const PortalAccessComponent: FC<IPortalAccessComponentProps> = (props: IPortalAccessComponentProps): JSX.Element => {
    let adminService = new AdminServices();
    
    var { appState, appdispatch } = useContext(store);
    const stackTokens = { childrenGap: 10, };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataServiceReaders, dataServiceReadersSet] = useState<ServiceReader[]>([]);
    const [displayMessages, displayMessagesSet] = useMessages();
    const [dataSelectedUsers, dataSelectedUsersSet] = useState<UserProfile[]>([]);
    const [cmbServiceTreeOptions, cmbServiceTreeOptionsSet] = useState<IComboBoxOption[]>(
        [
            {
                key: ServiceTreeHierarchyStrings.Division,
                text: 'Division'
            },
            {
                key: ServiceTreeHierarchyStrings.Organization,
                text: 'Organization'
            },
            {
                key: ServiceTreeHierarchyStrings.ServiceGroup,
                text: 'Service Group'
            },
            {
                key: ServiceTreeHierarchyStrings.TeamGroup,
                text: 'Team Group'
            },
            {
                key: ServiceTreeHierarchyStrings.Service,
                text: 'Service'
            },
        ]);
    const [cmbSelectedServiceTreeOption, cmbSelectedServiceTreeOptionSet] = useState<string>();
    const [selectedServiceTreeId, selectedServiceTreeIdSet] = useState<string>("");
    const [selectedServiceTreeName,selectedServiceTreeNameSet]=useState<string>("");
    const [dataSelectedServiceTreeHierarchyObject, dataSelectedServiceTreeHierarchyObjectSet] = useState<ServiceTreeService[]>([]);
    const [isAddModalOpen, { setTrue: showAddModal, setFalse: hideAddModal }] = useBoolean(false);

    const today: Date = new Date(new Date());
    const minDate = today;
    const maxDate = addDays(today, 30);
    const [accessExpirationDate, accessExpirationDateSet] = useState<Date | undefined>();
    const datePickerStyles: Partial<IDatePickerStyles> = { root: { width: 'auto', marginTop: -2 } };
    
    useEffect(() => {
        if (!isAddModalOpen) {
            dataSelectedUsersSet([]);
            cmbSelectedServiceTreeOptionSet("");
            dataSelectedServiceTreeHierarchyObjectSet([]);
        }
    }, [isAddModalOpen])

    const [requestorPersona, requestorPersonaSet] = useState<IPersonaProps>(
        {
            text: '' //appState.userProfile?.displayName
        });

    useEffect(() => {
        fetchServiceReaders();
    }, [])

    const fetchServiceReaders = async () => {
        reset();
        setIsLoading(true);
        var response = await adminService.getServiceReaders();
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            dataServiceReadersSet(response.result);
        }
        else if (response && response.result && response.result.length == 0) {
            dataServiceReadersSet([]);
        }
        else {
            displayMessagesSet([{ message: "Some error occurred while fetching service readers.", messageType: DisplayMessageType.Warning }]);
            return;
        }
        setIsLoading(false);
    }

    const onServiceTreeOptionChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            cmbSelectedServiceTreeOptionSet(option.key as string);
        }
    };

    const onSaveNewUserConfiguration = async() => {
        reset();
        setIsLoading(true);
        if (dataSelectedUsers == null || dataSelectedUsers.length == 0)
        {
            displayMessagesSet([{ message: "Please select a valid user.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (cmbSelectedServiceTreeOption == null || cmbSelectedServiceTreeOption == undefined)
        {
            displayMessagesSet([{ message: "Please select a valid service hierarchy option.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (dataSelectedServiceTreeHierarchyObject == null || dataSelectedServiceTreeHierarchyObject.length == 0)
        {
            displayMessagesSet([{ message: "Please select a valid service tree value.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        if (accessExpirationDate === undefined)
        {
            displayMessagesSet([{ message: "Please set a valid expiration date.", messageType: DisplayMessageType.Warning }]);
            return;
        }

        var newUserConfig: ServiceReader = {
            user : dataSelectedUsers[0],
            serviceHierarchyType: cmbSelectedServiceTreeOption,
            serviceTreeId: selectedServiceTreeId,
            serviceTreeName:selectedServiceTreeName,
            expirationDate: accessExpirationDate
        }

        var response = await adminService.addServiceReader(newUserConfig);
        if (response && response.result && !response.hasErrors) {
            hideAddModal();
            dataServiceReadersSet([...dataServiceReaders, newUserConfig]);
            displayMessagesSet([{ message: "User configuration saved successfully.", messageType: DisplayMessageType.Success }]);
        }
        else if (response && response.businessErrors.length > 0)
        {
            hideAddModal();
            displayMessagesSet([{ message: response.businessErrors[0].message!, messageType: DisplayMessageType.Warning }]);
            dataServiceReadersSet(dataServiceReaders);
        }
        else
        {
            hideAddModal();
            displayMessagesSet([{ message: "Some error occurred while fetching service readers.", messageType: DisplayMessageType.Warning }]);
            dataServiceReadersSet(dataServiceReaders);
        }
        setIsLoading(false);
    }

    const reset = () => {
        displayMessagesSet([]);
        dataServiceReadersSet([]);
        dataSelectedUsersSet([]);
        cmbSelectedServiceTreeOptionSet("");
        dataSelectedServiceTreeHierarchyObjectSet([]);
        accessExpirationDateSet(undefined);
    }

    const onReviewTypeAddFooterContent = () => {
        return (
            <Stack horizontal tokens={stackTokens}>
                <SpinnerPrimaryButton text="Add" onclick={onSaveNewUserConfiguration} executingText="Add the user configuration" />
                <DefaultButton onClick={hideAddModal}>Cancel</DefaultButton>
            </Stack>
        );
    }    

    const [workItemsColumns, workItemsColumnsSet] = useState<IColumn[]>([
        {
            key: 'User',
            name: 'User',
            minWidth: 150
        },
        {
            key: 'ServiceTreeHierarchy',
            name: 'ServiceTree Hierarchy',
            minWidth: 150
        },
        {
            key: 'ServiceTreeId',
            name: 'ServiceTree Id',
            minWidth: 230
        },
        {
            key: 'ServiceTreeName',
            name: 'ServiceTree Name',
            minWidth: 240
        },
        {
            key: 'ExpirationDate',
            name: 'Expiration Date',
            minWidth: 100
        },
        {
            key: 'QuickActions',
            name: 'Actions ',
            minWidth: 50
        }
    ]);
    
    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var userAccessItem = item as ServiceReader;
        let userPersona: IPersonaProps | undefined = {
            text:  userAccessItem.user.displayName
        };

        if (column && userAccessItem) {
            switch (column!.key) {
                case 'User':
                    return <Persona style={{ marginTop: 20, marginBottom: 10, fontSize:"11px"}} {...userPersona} title={userPersona.text} size={PersonaSize.size32} />
                case 'ServiceTreeHierarchy':
                    return <Text title = {userAccessItem.serviceHierarchyType} style={{fontSize:"11px"}}> {userAccessItem.serviceHierarchyType}</Text>;
                case 'ServiceTreeId':
                    return <Text title = {userAccessItem.serviceTreeId} style={{fontSize:"11px"}}>  {userAccessItem.serviceTreeId}</Text>;
                case 'ServiceTreeName':
                    return <Text title={userAccessItem.serviceTreeName} style={{fontSize:"11px"}}>{userAccessItem.serviceTreeName}</Text>
                case 'ExpirationDate':
                    return <Text title = "expiration date" style={{fontSize:"11px"}}> {getDateFormat(userAccessItem.expirationDate) }</Text>;
                case 'QuickActions':
                    return <Stack horizontal tokens={stackTokens}>
                        <Stack.Item>
                            <FontIcon iconName="Delete" style={{cursor:"pointer"}} onClick={(e) => { 
                                var leave = window.confirm("Are you sure you want to delete?")
                                    if (leave) {
                                       DeleteUser(userAccessItem.serviceTreeId, userAccessItem) 
                                       
                                    }
                                }} />
                        </Stack.Item>
                    </Stack>
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }
   const DeleteUser=async (id?:string, serviceReader?: ServiceReader)=>{
     if(id !=null && serviceReader!=null){
       var response = await adminService.removeServiceReader(serviceReader);
       if (response && response.result && !response.hasErrors){
        var remain=dataServiceReaders.filter((x)=>{
            return x.serviceTreeId!==id || x.user.id!==serviceReader.user.id;
           })
           dataServiceReadersSet(remain)
           selectedServiceTreeIdSet('');
       }
       
     }
   }
    

    return (
        <div className="container">
            <div className="row">
                <HeadingComponent title="Manage access for users per service tree configuration" />
            </div>
            <div className="row mb-5" >
                <MessageComponent messages={displayMessages} />    
            </div>
            <div className="row">
                <div className="d-flex">
                    <Stack horizontal tokens={stackTokens} className="ms-auto" style={{ marginRight: 970 }}>
                        <StackItem>
                            <CommandBarButton id={"btnAddUserConfiguration"} iconProps={{ iconName: 'Add' }} text="Add User Configuration" onClick={() => {
                                showAddModal();
                            }} />
                        </StackItem>
                    </Stack>
                </div>
            </div>
            {isLoading && <LoadingIndicator label="Loading"/>}
            {dataServiceReaders && dataServiceReaders.length > 0 && (
                <div className="row">
                    <DetailsList
                        items={dataServiceReaders}
                        columns={workItemsColumns}
                        onRenderItemColumn={_renderItemColumn}
                        selectionPreservedOnEmptyClick={true}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        constrainMode={ConstrainMode.horizontalConstrained}
                    />
                </div>
            )}
            {/* Add panel */}
            <Panel
                type={PanelType.large}
                isOpen={isAddModalOpen}
                onDismiss={hideAddModal}
                closeButtonAriaLabel="Close"
                headerText={"Add Service Reader"}
                onRenderFooterContent={onReviewTypeAddFooterContent}
                >
                <div className="row">
                    <div className="col-3">
                        {/* search by user in mspeoplepicker and search in getsecuritygroup() for SG */}
                        <Label>Users</Label>
                        <MsPeoplePickerComponent
                            itemLimit={1}
                            selectedUsers={dataSelectedUsers}
                            onUserSelected={(selectedUsers: UserProfile[]) => {
                                if (selectedUsers && selectedUsers.length > 0) {
                                    dataSelectedUsersSet(selectedUsers);
                                }
                                else {
                                    dataSelectedUsersSet([]);
                                }
                            }} />
                    </div>
                    <div className="col-2">
                        <ComboBox
                            label="Service Tree Hierarchy"
                            options={cmbServiceTreeOptions}
                            selectedKey={cmbSelectedServiceTreeOption}
                            useComboBoxAsMenuWidth={true}
                            onChange={onServiceTreeOptionChange}
                        />
                    </div>
                    <div className="col-3">
                        <Label>Service Tree Id</Label>
                        <ServiceTreeHierarchyPickerComponent
                            itemLimit={1}
                            id={props.id + "_serviceHierarchyPicker"}
                            serviceTreeHierarchyType = {cmbSelectedServiceTreeOption}
                            placeholder="Search by service names"
                            selectedServiceTreeHierarchyObject={dataSelectedServiceTreeHierarchyObject ? [...dataSelectedServiceTreeHierarchyObject] : []}
                            onServiceHierarchyObjectSelected={(selectedServices) => {
                                if (selectedServices && selectedServices.length > 0) {
                                    dataSelectedServiceTreeHierarchyObjectSet(selectedServices);
                                    if (cmbSelectedServiceTreeOption == ServiceTreeHierarchyStrings.Division)
                                    {
                                        selectedServiceTreeIdSet(selectedServices[0]!.divisionId!)
                                        selectedServiceTreeNameSet(selectedServices[0]!.divisionName!)
                                    }
                                    else if (cmbSelectedServiceTreeOption == ServiceTreeHierarchyStrings.Organization)
                                    {
                                        selectedServiceTreeIdSet(selectedServices[0]!.organizationId!)
                                        selectedServiceTreeNameSet(selectedServices[0]!.organizationName!)
                                    }
                                    else if (cmbSelectedServiceTreeOption == ServiceTreeHierarchyStrings.ServiceGroup)
                                    {
                                        selectedServiceTreeIdSet(selectedServices[0]!.serviceGroupId!)
                                        selectedServiceTreeNameSet(selectedServices[0]!.serviceGroupName!)
                                    }
                                    else if (cmbSelectedServiceTreeOption == ServiceTreeHierarchyStrings.TeamGroup)
                                    {
                                        selectedServiceTreeIdSet(selectedServices[0]!.teamGroupId!)
                                        selectedServiceTreeNameSet(selectedServices[0]!.teamGroupName!)
                                    }
                                    else if (cmbSelectedServiceTreeOption == ServiceTreeHierarchyStrings.Service)
                                    {
                                        selectedServiceTreeIdSet(selectedServices[0]!.serviceId!)
                                        selectedServiceTreeNameSet(selectedServices[0]!.serviceName!)
                                        
                                    }
                                }
                                else {
                                    dataSelectedServiceTreeHierarchyObjectSet([]);
                                }
                        }} />
                    </div>
                    <div className="col-4">
                        <DatePicker
                            label="Access Expiration Date"
                            value={accessExpirationDate}
                            onSelectDate={accessExpirationDateSet as (date: Date | null | undefined) => void}
                            styles={datePickerStyles}
                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            minDate={minDate}
                            maxDate={maxDate}
                            strings={defaultDatePickerStrings}
                            allowTextInput
                        />
                    </div>
                </div>
            </Panel> 
        </div>
    )
}
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { CSSProperties, FC, useState } from "react";
interface ISpinnerPrimaryButton {
    id?:string,
    text: string
    onclick: any
    executingText?: string,
    style?:CSSProperties,
}
export const SpinnerPrimaryButton: FC<ISpinnerPrimaryButton> = (props: ISpinnerPrimaryButton): JSX.Element => {
    const [isRunningEvent,isRunningEventSet] = useState<boolean>(false);
    const onclick = async() =>
    {
        isRunningEventSet(true);
        try
        {
           await props.onclick();
        }
        catch
        {

        }
        isRunningEventSet(false);
    }

    if (isRunningEvent) {
        return <Spinner size={SpinnerSize.medium} label={props.executingText} style = {props.style} />;
    }

    return (
        <PrimaryButton aria-label={props.text} id={props.id} text={props.text} onClick={onclick} style = {props.style} />
    );
}
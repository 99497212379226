import { FontIcon, Stack } from "@fluentui/react";
import { FC } from "react";
import { text } from "stream/consumers";
import { copyToClipBoard } from "../../Utilities/textUtilities";

interface ICopyToClipboard{
    text?: string
}

export const CopyToClipboard : FC<ICopyToClipboard> = (props: ICopyToClipboard): JSX.Element =>{

    const onCopyClick = async () => {
        copyToClipBoard(props.text);
    }
    return (<Stack horizontal style={{cursor:"pointer"}}><FontIcon iconName="Copy" onClick={onCopyClick} /> </Stack>);
}
import headers from "./headers";

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function http<T>(
  request: RequestInfo
): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(
    request
  );
  try {
    // may error if there is no body
    response.parsedBody = await response.json();
  } catch (ex) { }

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

export async function get<T>(
  path: string,
  headers: any
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, { method: "get", headers: headers }));
};


export async function getFile(path: string, headers: any) {
  var response = await fetch(new Request(path, { method: 'get', headers: headers }));
  let contentDispositionHeader = response.headers.get("Content-Disposition");
  console.log(response);
  console.log(contentDispositionHeader);
  let fileName: string | null = contentDispositionHeader;
  var blob = await response.blob();
  var fileByte = blob.size;
  if (fileName === undefined || fileName === null) {
    fileName = "download";
  }
  else {
    fileName = fileName.split("filename=")[1].split(";")[0].trim();
    fileName = fileName.replaceAll('"','')
    console.log(fileName);
  }

  console.log(fileName);

  var objectUrl = window.URL.createObjectURL(blob);
  const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
  a.href = objectUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(objectUrl);
  return { fileName, fileByte };

}

export async function getBase64StringFromBinary(path: string, headers: any) {
  var binary = '';
  var response = await fetch(new Request(path, { method: 'get', headers: headers }));
  var blob = await response.blob();
  var buffer = await blob.arrayBuffer()
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export async function post<T>(
  path: string,
  body: any,
  headers: any
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, { method: "post", body: JSON.stringify(body), headers: headers }));
};

export async function put<T>(
  path: string,
  body: any,
  headers: any
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, { method: "put", body: JSON.stringify(body), headers: headers }));
};

export async function remove<T>(
  path: string,
  headers: any
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, { method: "delete", headers: headers }));
};

export async function removeWithBody<T>(
  path: string,
  body: any,
  headers: any
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, { method: "delete", body: JSON.stringify(body), headers: headers }));
};